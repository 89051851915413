import { ExceedancesRowProps, DisplayExceedanceType } from "../../store/view-workspace";
import { WorkspaceGroup, STATE_NO_DATA } from "../../store/service-client";
import React from "react";
// import { Depths, SharedColors, NeutralColors } from "@uifabric/fluent-theme/lib/fluent";
import { Depths, NeutralColors, SharedColors } from "@uifabric/fluent-theme";
import { IconButton, DefaultButton, PrimaryButton } from "office-ui-fabric-react";
import ExceedanceRow from "./exceedances-row-new";

export interface ExceedanceGroupProps {
    group: WorkspaceGroup;
    expanded: boolean;
    rows: ExceedancesRowProps[];
    exceedanceType: DisplayExceedanceType;
    canEdit: boolean;
    expand: any;
    deleteGroup: any;
    showNoData: boolean
}


export class ExceedancesGroup extends React.Component<ExceedanceGroupProps> {

    constructor(props: any) {
        super(props);
    }

    noDataExceedanceFilter(rows: ExceedancesRowProps[]) {
        var exceedanceCount = 0;
        if (this.props.showNoData === false) {
            exceedanceCount = rows.filter(r => r.states.some(s => s.name != STATE_NO_DATA && s.met)).length;
        }
        else exceedanceCount = rows.length;
        return exceedanceCount;
    }

    exceedanceCount() {
        if (this.props.rows && this.props.exceedanceType === 'SUPPRESSED') {
            const rc = this.props.rows.filter(r => this.props.group.tags.some(t => t === r.tag && r.states.length > 0 && r.suppressed));
            return this.noDataExceedanceFilter(rc);
        }
        else {
            const rc = this.props.rows.filter(r => this.props.group.tags.some(t => t === r.tag && r.states.length > 0 && r.states.some(s => s.met || (s.name == STATE_NO_DATA && r.currentValue === undefined)) && !r.suppressed));
            return this.noDataExceedanceFilter(rc);
        }
    }

    exceedanceBackground() { return this.exceedanceCount() === 0 ? NeutralColors.gray80 : SharedColors.red20; }
    filterNoData(r: ExceedancesRowProps) {
        if (this.props.showNoData) {
            return r;
        }
        const statesToShow = r.states.filter(s => s.name != STATE_NO_DATA);
        if (statesToShow.length > 0) {
            return { ...r, states: statesToShow };
        }
        return undefined;
    }

    render() {
        const toDisplay = this.props.group.tags.map(t => {
            const r: ExceedancesRowProps | undefined = this.props.rows!.find(td => td.tag === t)
            switch (this.props.exceedanceType) {
                case 'ALL':
                    if (r) {
                        return this.filterNoData(r);
                    }
                    break;
                case 'SUPPRESSED':
                    if (r && r.suppressed && r.states) {
                        const statesToShow = r.states.filter(s => s.met);
                        const row = { ...r, states: statesToShow };
                        return this.filterNoData(row);
                    }
                    break;

                //Old code
                // case 'EXCEEDANCE':
                //     if (r && !r.suppressed && r.states && r.states.some(s => s.met)) {
                //         const statesToShow = r.states.filter(s => s.met);
                //         const row = { ...r, states: statesToShow };
                //         return this.filterNoData(row);
                //     }
                //     break;

                case 'EXCEEDANCE':
                    if (r && !r.suppressed && r.states && r.states.some(s => s.met || (s.name == STATE_NO_DATA && r.currentValue === undefined))) {
                        const statesToShow = r.states.filter(s => s.met || (s.name == STATE_NO_DATA && r.currentValue === undefined));
                        const row = { ...r, states: statesToShow };
                        return this.filterNoData(row);
                    }
                    break;
                default:
            }
            return undefined;
        });

        return (<React.Fragment>
            <div className='ms-Grid ' style={{ boxShadow: Depths.depth4, marginBottom: '10px' }}>
                <div className='ms-Grid-row' style={{ margin: '5px 0px' }} >
                    <div className='ms-Grid-col ms-sm5 ms-md6'>
                        <div className='ms-Grid-col ms-sm1'><IconButton iconProps={{ iconName: this.props.expanded ? 'ChevronDownSmall' : 'ChevronRightSmall' }} onClick={() => this.props.expand(this.props.group)} /></div>
                        <div className='ms-Grid-col ms-sm6'>
                            <div style={{ fontWeight: 600, fontSize: '18px', display: 'flex', height: '32px', alignItems: 'center' }}>{this.props.group.name}</div>
                        </div>
                    </div>
                    <div className='ms-Grid-col ms-sm7 ms-md6'>
                        <div style={{ display: 'flex', flexFlow: 'row', justifyContent: 'flex-end' }}>

                            <div><DefaultButton>Exceedances</DefaultButton><DefaultButton style={{ backgroundColor: this.exceedanceBackground(), color: '#fff' }}>{this.exceedanceCount().toString()} </DefaultButton></div>
                            <div><DefaultButton>Tags</DefaultButton><PrimaryButton>{this.props.group.tags.length}</PrimaryButton></div>
                            {this.props.canEdit && (<div style={{ marginLeft: '10px' }}><IconButton iconProps={{ iconName: 'Delete' }} onClick={() => { this.props.deleteGroup(this.props.group) }} /></div>)}
                        </div>
                    </div>
                </div>
                <div className='ms-Grid-Row' style={{ display: this.props.expanded ? 'block' : 'none' }}>
                    {

                        toDisplay.map(r => {
                            if (r) {
                                return (<ExceedanceRow
                                    key={`${this.props.group.name}-${r.tag}`}
                                    group={this.props.group}
                                    filterText={[""]}
                                    {...r}
                                />)
                            }
                            else
                                return undefined;
                        })
                    }
                </div>
            </div>
        </React.Fragment>);
    }
}
