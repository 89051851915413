import React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { Link } from 'react-router-dom';
//import { ColourManager } from './index';
import { LocationDescriptor } from 'history';
import { getTheme } from 'office-ui-fabric-react/lib/Styling';

export type BreadcrumbLink = { text: string, to: LocationDescriptor<any> };
type BreadcrumbProps = { links: BreadcrumbLink[]};
export class Breadcrumbs extends React.Component<BreadcrumbProps, {}> {
    render() {
        const theme = getTheme();
        
        //const linkStyle = { textDecoration: 'none', color: ColourManager.AppColour };
        const linkStyle = { textDecoration: 'none', color: theme.palette.themePrimary };
        const liStyle = { display: 'inline-block', lineHeight: '20px' };
        const divider = (
            <li style={liStyle}>
                <Icon iconName="ChevronRightSmall" style={{ fontSize: 15, marginLeft: 8, marginRight: 8, lineHeight: '20px' }} />
            </li>
        );

        const links = this.props.links.map((link, i) => (
            <React.Fragment key={`breadcrumb-${i}`}>
                {divider}
                <li style={liStyle}>
                    {link.to ? <Link style={linkStyle} to={link.to}>{link.text}</Link> : <div style={linkStyle}>{link.text}</div>}
                </li>
            </React.Fragment>
        ));

        return (
            <ul
                style={{
                    listStyle: 'none',
                    lineHeight: '16px',
                    font: '20px Segoe UI',
                    marginTop: '20px',
                    marginBottom: '0px',
                    marginLeft: 0,
                    paddingLeft: 0
                }}
            >
                <li style={liStyle}>
                    <Link style={linkStyle} to="/"><Icon iconName="Home" style={{ fontSize: '20px', lineHeight: '20px' }} /></Link>
                </li>
                {links}
            </ul>
        );
    }
}