import * as React from 'react';
import { Panel, PanelType } from 'office-ui-fabric-react';
import {SupportedFunctions} from './SupportedFunctions';

type HelpProps = { viewHelpPanel: boolean, onDismissed: any }
type HelpState = HelpProps 

export class HelpPanel extends React.Component<HelpProps, HelpState>{
    constructor(props: HelpProps) {
        super(props);
        this.state = { ...props}
    }

    onDismissed = () => {
        this.props.onDismissed();
    };

    render() {
        return (
            <React.Fragment>
                <Panel isOpen={this.props.viewHelpPanel}
                    type = {PanelType.medium}
                    onDismissed={this.onDismissed}>
                        <p>
                            Enter <b>name,description, execution schedule and valid Date </b>for the Model
                        </p>
                    <p><b>
                        Basic Expressions creates calculated expression by breaking it into step by step process.
                        It supports expressions involving addition(+), subtraction(-), multiply(*), divide(/)</b>
                    </p>
                    <p>
                        <b>1.</b> Click <b>Add Basic Expression</b> and select tag or value.
                    </p>
                    <p>
                        <b>2.</b> Repeat step 1 to add more rows.
                    </p>
                    <p>
                        <b>3.</b> Select any one operation in each row(except first).
                    </p>
                    <p>
                        <b>4.</b> Use brackets ( to clarify the order to complete operations.
                    </p>
                    <p>
                    <b>Complex interface allows users to enter or copy expression in free text form.
                        It supports variety of mathematical operations not available in Basic interface.</b>
                    </p>
                    <p>
                    <b>1.</b> Click <b>Add Complex Expression</b>
                    </p>
                    <p>
                    <b>2.</b>Enter or copy expression into text field provided
                    </p>
                    <p>
                    <b>3.</b>To input a tag type @followed by tagId(dont'use special characters in search)
                    </p>
                    <p>
                    <b>4.</b>Select tag from the search that appears.
                    </p>
                    <p>
                    <b>5.</b>Ensure a space character exists before and after every tag in your expression.
                    </p>
                    <p>
                        <b>Supported Functions for Complex Interface</b>
                        <SupportedFunctions 
                        />
                    </p>
                </Panel>
            </React.Fragment>
        )
    }
}