import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import * as ListWorkspacesStore from '../store/list-workspaces';
import { MessageBox, MessageBoxType, MessageBoxSize } from './common';
import { ApplicationState } from '../store';
import { connect } from 'react-redux';
import { Depths } from '@uifabric/fluent-theme'
import {
    Shimmer, ShimmerElementType, CommandBar, ICommandBarItemProps,
    Link, FontSizes, Dialog, DialogFooter, PrimaryButton, DefaultButton,
    Dropdown, IDropdownOption, TextField
} from 'office-ui-fabric-react';
import { FilterBox } from './common/filter-box';
import { anythingIncludesText } from '../utils/FilterText';
import { AuthenticatedUser } from '../auth';
import { ActionButton } from 'office-ui-fabric-react';
import { Confirmation } from './common/confirmation';
//import { getTheme } from 'office-ui-fabric-react/lib/Styling';
import { Workspace, PeriodOptions } from '../store/slice-reducers/common-types'

type ListWorkspacesProps = ListWorkspacesStore.ListWorkspacesState
    & typeof ListWorkspacesStore.actionCreators
    & { currentUser: AuthenticatedUser }
    & RouteComponentProps<{}>;

type ListWorkspacesState = {
    filters: FilterProperties;
    showDeleteConfirmation: boolean;
    deleteWorkspaceName: string;
    showUpdatePeriodDialog: boolean;
    updateWorkspaceName: string;
}

//const theme = getTheme();

const TableRowStyle: React.CSSProperties = {
    display: 'flex',
    width: '100%',
    flexFlow: 'row nowrap',
    lineHeight: '20px',
    paddingTop: '13px',
    paddingBottom: '13px',
    borderBottom: `1px solid rbg(25, 57, 80)`
};

export class ListWorkspaces extends React.Component<ListWorkspacesProps, ListWorkspacesState> {
    constructor(props: ListWorkspacesProps) {
        super(props);
        this.state = {
            filters: { searchText: '', sortDirection: SortDirection.Ascending },
            showDeleteConfirmation: false,
            deleteWorkspaceName: "",
            showUpdatePeriodDialog: false,
            updateWorkspaceName: ''
        };
    }

    componentDidMount() {
        if (this.props.error === undefined && !this.props.isLoading) {
            this.props.initialLoad(this.props.currentUser);
        }
    }

    componentDidUpdate() {
        // if (this.props.error === undefined && !this.props.isLoading) {
        //     this.props.initialLoad(this.props.currentUser);
        // }
    }

    componentWillUnmount() {
        this.props.clearData();
    }

    getWorkspacePeriodDetails(wsName: string) {

    }

    renderLoading() {
        const shimmerRow = (key: number) => {
            return (
                <div key={key} style={TableRowStyle}>
                    <div style={{ flex: 1 }}>
                        <Shimmer
                            key={key}
                            shimmerElements={[
                                { type: ShimmerElementType.line, width: '25%' },
                                { type: ShimmerElementType.gap, width: '75%' },
                            ]}
                        />
                    </div>
                </div>
            );
        };

        return (
            <React.Fragment>
                <div style={{ marginTop: '6em' }}>
                    {[...Array(10)].map((_, i) => shimmerRow(i))}
                </div>
            </React.Fragment>
        );
    }

    render() {
        let body: React.ReactFragment = '';
        if (this.props.error !== undefined) {
            body = (
                <div style={{ width: '100%', marginTop: '1em' }}>
                    <MessageBox type={MessageBoxType.Error} size={MessageBoxSize.Compact}>
                        <h3 style={{ margin: 0 }}>Error: {this.props.error}</h3>
                    </MessageBox>
                </div>
            );
        } else if (this.props.isLoading) {
            body = this.renderLoading();
        } else if (this.props.workspaces) {
            body = (
                <React.Fragment>
                    {/* <div style={{ textAlign: 'center', marginLeft: 'auto', width: '50%', marginRight: '25%', marginTop: '1em' }}>
                        <FilterBox
                            filter={this.state.filters.searchText}
                            hideLabel={true}
                            hideGarbageIcon={true}
                            isInCommandBar={false}
                            initialFocus={false}
                            onFilterChange={(p) => { this.setState({ filters: {...this.state.filters, searchText: p.filter || '' }}); }}
                        />
                    </div> */}

                    <WorkspaceListGrid
                        workspaces={this.props.workspaces}
                        onViewWorkspace={(urlName: string) => this.props.history.push(`/view-workspace/${urlName}`)}
                        onEditWorkspace={(urlName: string) => this.props.history.push(`/edit-workspace/${urlName}`)}
                        onToggleFavourite={{}}
                        onCreateNewWorkspace={() => this.props.history.push('/create-workspace')}
                        onCreateEnvelope={(urlName: string) => this.props.history.push(`/create-envelope/${urlName}`)}
                        onPermissions={(urlName: string) => this.props.history.push(`/secure-workspace/${urlName}`)}

                        onDelete={(urlName: string) => this.setState({ showDeleteConfirmation: true, deleteWorkspaceName: urlName })}
                        onDeleteWorkspace={(urlName: string) => {
                            this.setState({ showDeleteConfirmation: false }, () => { this.props.deleteWorkspace(urlName, this.props.currentUser) })
                        }}
                        filters={this.state.filters}
                        showDeleteConfirmation={this.state.showDeleteConfirmation}
                        deleteWorkspaceName={this.state.deleteWorkspaceName}
                        cancelDeleteWorkspace={() => this.setState({ showDeleteConfirmation: false })}

                        onPeriodUpdateClick={(urlName: string) => this.props.getWorkspacePeriodDetails(urlName)}
                        showUpdatePeriodDialog={this.props.showUpdatePeriodDialog ? this.props.showUpdatePeriodDialog : false}
                        updateWorkspaceName={this.props.updateWorkspaceName ? this.props.updateWorkspaceName : ''}
                        currentPeriod={this.props.currentPeriod ? this.props.currentPeriod : PeriodOptions.LastHour}
                        duration={this.props.duration ? this.props.duration : 0}
                        durationUnit={this.props.durationUnit ? this.props.durationUnit : 'Days'}
                        cancelUpdatePeriod={() => this.props.cancelUpdateWorkspacePeriod()}
                        updatePeriod={(urlName: string, currentPeriod: PeriodOptions, duration: number, durationUnit: string) => {
                            this.props.updateWorkspacePeriod(urlName, currentPeriod, duration, durationUnit);
                        }
                        }
                    />
                </React.Fragment>
            );
        }

        return (
            <React.Fragment>
                <WorkspaceListCommandBar
                    onCreateNewWorkspace={() => this.props.history.push('/create-workspace')}
                    onBulkUpload={() => this.props.history.push('/bulk-upload')}
                    onChangeFilterProperties={(filters: FilterProperties) => { this.setState({ filters }); }}
                />
                <div>
                    {body}
                </div>
            </React.Fragment>
        );
    }
}

type FilterProperties = { searchText: string, sortDirection: SortDirection };

enum SortDirection { Ascending, Descending }
type WorkspaceListCommandBarProps = { onCreateNewWorkspace: any, onChangeFilterProperties: any, onBulkUpload: any };
type WorkspaceListCommandBarState = FilterProperties;
export class WorkspaceListCommandBar extends React.Component<WorkspaceListCommandBarProps, WorkspaceListCommandBarState> {
    newWorkspace = () => { if (this.props.onCreateNewWorkspace) { this.props.onCreateNewWorkspace(); } };
    bulkUpload = () => { if (this.props.onBulkUpload) { this.props.onBulkUpload(); } };
    constructor(props: WorkspaceListCommandBarProps) {
        super(props);
        this.state = { searchText: '', sortDirection: SortDirection.Ascending };
    }

    render() {
        const barItemsLeft: ICommandBarItemProps[] = [
            {
                key: 'new',
                name: 'Create New Workspace',
                iconProps: { iconName: 'FabricNewFolder' },
                onClick: () => { this.newWorkspace(); }
            },
            {
                key: 'bulk-upload',
                name: 'Bulk Upload',
                iconProps: { iconName: 'BulkUpload' },
                onClick: () => { this.bulkUpload(); }
            }
        ];

        return (
            <div>
                <CommandBar
                    styles={{ root: {} }}
                    items={barItemsLeft}
                    farItems={[]}
                />
            </div>
        );
    }
}

export type WorkspaceListGridProps = {
    workspaces: Workspace[],
    onViewWorkspace: any,
    onToggleFavourite: any,
    onEditWorkspace: any,
    onCreateNewWorkspace?: any,
    onCreateEnvelope?: any,
    filters: FilterProperties,
    onPermissions: any,

    onDelete: any,
    showDeleteConfirmation: boolean,
    deleteWorkspaceName?: string,
    onDeleteWorkspace: any,
    cancelDeleteWorkspace: any,

    showUpdatePeriodDialog: boolean,
    updateWorkspaceName: string,
    cancelUpdatePeriod: any,
    onPeriodUpdateClick: any,
    updatePeriod: any

    currentPeriod: PeriodOptions;
    duration: number;
    durationUnit: string;
};

type LocalState = {
    allWorkspaces: boolean;
    filters: FilterProperties;

    duration: number;
    durationUnit: string;
    currentPeriod: PeriodOptions
}

export class WorkspaceListGrid extends React.Component<WorkspaceListGridProps, LocalState> {
    /**
     *
     */
    constructor(props: WorkspaceListGridProps) {
        super(props);
        this.state = {
            allWorkspaces: false,
            filters: { searchText: '', sortDirection: SortDirection.Ascending },
            currentPeriod: PeriodOptions.LastHour,
            duration: 0,
            durationUnit: 'Days'
        };
    }

    componentDidUpdate(prevProps: WorkspaceListGridProps) {
        const { currentPeriod, duration, durationUnit } = this.props;
        if (prevProps.currentPeriod !== this.props.currentPeriod) {
            this.setState({ currentPeriod: currentPeriod, duration: duration, durationUnit: durationUnit })
        }
    }


    viewWorkspace = (urlName: string) => { if (this.props.onViewWorkspace) { this.props.onViewWorkspace(urlName); } };
    toggleFavourite = (urlName: string) => { if (this.props.onToggleFavourite) { this.props.onToggleFavourite(urlName); } };
    editWorkspace = (urlName: string) => { if (this.props.onEditWorkspace) { this.props.onEditWorkspace(urlName); } };
    newWorkspace = () => { if (this.props.onCreateNewWorkspace) { this.props.onCreateNewWorkspace(); } };
    createEnvelope = (urlName: string) => { if (this.props.onCreateEnvelope) { this.props.onCreateEnvelope(urlName); } };
    onPermissions = (urlName: string) => { if (this.props.onPermissions) { this.props.onPermissions(urlName); } };
    onDelete = (urlName: string) => { if (this.props.onDelete) { this.props.onDelete(urlName); } };
    onDeleteWorkspace = (urlName: string) => { if (this.props.onDeleteWorkspace) { this.props.onDeleteWorkspace(urlName); } }
    cancelDeleteWorkspace = () => { this.props.cancelDeleteWorkspace(); }
    onPeriodUpdateClick = (urlName: string) => { if (this.props.onPeriodUpdateClick) { this.props.onPeriodUpdateClick(urlName); } };

    changeSortDirection = (sortDirection: SortDirection) => {
        this.setState({ filters: { sortDirection: sortDirection, searchText: this.state.filters.searchText } });
    }

    onCurrentPeriodChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        const po: PeriodOptions = parseInt(item.key.toString(), 10);
        this.setState({ currentPeriod: po });
    }

    updatePeriod = () => {
        const duration = this.state.currentPeriod !== PeriodOptions.Custom ? 0 : this.state.duration;
        const durationUnit = this.state.currentPeriod !== PeriodOptions.Custom ? '' : this.state.durationUnit;
        this.props.updatePeriod(this.props.updateWorkspaceName, this.state.currentPeriod, duration, durationUnit);
    }

    render() {
        let sortedAndFilteredWorkspaces = this.props.workspaces;
        if (this.state.filters.searchText.length > 0) {
            sortedAndFilteredWorkspaces = sortedAndFilteredWorkspaces
                .filter(w => anythingIncludesText(this.state.filters.searchText, [w.name]));
        }

        switch (this.state.filters.sortDirection) {
            case SortDirection.Ascending: {
                sortedAndFilteredWorkspaces.sort((a, b) => {
                    const aName = a.name.toLocaleLowerCase();
                    const bName = b.name.toLocaleLowerCase();
                    if (aName < bName) { return -1; }
                    if (aName > bName) { return 1; }
                    return 0;
                });
                break;
            }
            case SortDirection.Descending: {
                sortedAndFilteredWorkspaces.sort((a, b) => {
                    const aName = a.name.toLocaleLowerCase();
                    const bName = b.name.toLocaleLowerCase();
                    if (aName < bName) { return 1; }
                    if (aName > bName) { return -1; }
                    return 0;
                });
                break;
            }
            default: {
                break;
            }
        }

        const myWorkspaces = sortedAndFilteredWorkspaces.filter(w => w.isOwnerOrCreator);
        const otherWorkspaces = sortedAndFilteredWorkspaces.filter(w => !w.isOwnerOrCreator);

        const headerStyle = { paddingBottom: '10px', borderBottom: `2px solid rgb(25, 57, 80)`, marginBottom: 0, display: 'flex', flexFlow: 'row', alignContent: 'center', justifyContent: 'space-between' };
        const shadowboxStyle = { boxShadow: Depths.depth8, padding: '10px', marginLeft: '5px', marginTop: '30px' };//{ boxShadow: '0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)', padding: '10px', marginLeft: '5px', marginTop:'30px' };

        const workspacesToDisplay = !this.state.allWorkspaces ? myWorkspaces.length > 0 ? myWorkspaces : otherWorkspaces : otherWorkspaces;

        const barItemsRight: ICommandBarItemProps[] = [
            {
                key: 'sort',
                name: 'Sort',
                iconProps: { iconName: 'SortLines' },
                subMenuProps: {
                    items: [
                        {
                            key: 'sortAscending',
                            name: 'Ascending',
                            canCheck: true,
                            checked: this.state.filters.sortDirection === SortDirection.Ascending,
                            iconProps: { iconName: 'SortDown' },
                            onClick: () => { this.changeSortDirection(SortDirection.Ascending); }
                        },
                        {
                            key: 'sortDescending',
                            name: 'Descending',
                            canCheck: true,
                            checked: this.state.filters.sortDirection === SortDirection.Descending,
                            iconProps: { iconName: 'SortUp' },
                            onClick: () => { this.changeSortDirection(SortDirection.Descending); }
                        },
                    ]
                }

            },
        ];

        const periodOptions = [
            { key: PeriodOptions.LastHour, text: 'last hour' },
            { key: PeriodOptions.Today, text: 'today' },
            { key: PeriodOptions.Yesterday, text: 'yesterday' },
            { key: PeriodOptions.Last7Days, text: 'last 7 days' },
            { key: PeriodOptions.Custom, text: 'custom date range' },
        ];

        const durationUnits = [
            { key: 'Days', text: 'Days' },
            { key: 'Weeks', text: 'Weeks' },
            { key: 'Months', text: 'Months' }
        ];

        return (
            <React.Fragment>
                <Confirmation
                    show={this.props.showDeleteConfirmation}
                    title={'Delete Workspace'}
                    onConfirm={() => this.props.onDeleteWorkspace(this.props.deleteWorkspaceName)}
                    onCancel={() => this.props.cancelDeleteWorkspace()}
                >
                    <p>Are you sure you want to delete this workspace?</p>
                    <p>All of its envelopes and states will be deleted.</p>
                    <p><b>This operation cannot be undone.</b></p>
                </Confirmation>


                <Dialog
                    hidden={!this.props.showUpdatePeriodDialog}
                    onDismiss={() => this.props.cancelUpdatePeriod()}
                    title={`Update Period for Workspace - ${this.props.updateWorkspaceName}`}
                    minWidth={850}
                >

                    <div className="ms-Grid-row">
                        <div className="ms-Grid-col  ms-md2"><b>Period Time</b></div>
                        <div className="ms-Grid-col  ms-md10">
                            <div className="ms-Grid-row">
                                {/* <div className="ms-Grid-col  ms-md1" /> */}
                                <div className="ms-Grid-col ms-md12">
                                    <Dropdown
                                        onChange={this.onCurrentPeriodChange}
                                        placeholder="Select Period time"
                                        options={periodOptions}
                                        selectedKey={periodOptions[this.state.currentPeriod].key}
                                    />
                                </div>
                            </div>

                        </div>
                    </div>

                    {this.state.currentPeriod === PeriodOptions.Custom &&
                        <div className="ms-Grid-row" style={{ paddingTop: '10px' }}>
                            <div className="ms-Grid-col  ms-md2" />
                            <div className="ms-Grid-col  ms-md10" >
                                <div className="ms-Grid-row">
                                    <div className="ms-Grid-col ms-md12">
                                        <div className="ms-Grid-row">
                                            <div className="ms-Grid-col  ms-md6">
                                                <Dropdown
                                                    label="Custom Duration (unit)"
                                                    selectedKey={this.state.durationUnit}
                                                    onChange={(event, item: IDropdownOption) => this.setState({ durationUnit: item.key.toString() })}
                                                    placeholder="Select an option"
                                                    options={durationUnits}
                                                /></div>
                                            <div className="ms-Grid-col  ms-md6">
                                                <TextField label="Duration"
                                                    placeholder="Please enter duration"
                                                    type="number"
                                                    value={this.state.duration.toString()}
                                                    onChange={(_, val) => {
                                                        if (!val || val.length <= 3) {
                                                            this.setState({ duration: Number(val) })
                                                        } else {
                                                            this.setState({ duration: Number(val.slice(0, 3)) })
                                                        }
                                                    }} />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    }

                    <DialogFooter>
                        <PrimaryButton onClick={this.updatePeriod} text="Update" />
                        <DefaultButton onClick={() => this.props.cancelUpdatePeriod()} text="Cancel" />
                    </DialogFooter>
                </Dialog>


                <div style={shadowboxStyle}>
                    <div style={headerStyle}>
                        <div style={{ display: 'flex' }}>
                            {myWorkspaces.length > 0 && (<div className={!this.state.allWorkspaces ? 'active' : ''} style={{ fontSize: FontSizes.xLarge, cursor: 'pointer', borderBottom: !this.state.allWorkspaces ? '1px solid red' : '0px solid transparent' }} onClick={() => this.setState({ allWorkspaces: false })} >
                                My Workspaces
                            </div>)}
                            <div className={!this.state.allWorkspaces ? 'active' : ''} style={{ marginLeft: '20px', fontSize: FontSizes.xLarge, cursor: 'pointer', borderBottom: this.state.allWorkspaces ? '1px solid red' : '0px solid transparent' }} onClick={() => this.setState({ allWorkspaces: true })}>
                                {myWorkspaces.length > 0 ? "Other Workspaces" : "Workspaces"}
                            </div>
                        </div>
                        <div style={{ display: 'flex', flexFlow: 'row' }}>
                            <div style={{ display: 'flex 2' }}>
                                <FilterBox
                                    filter={this.state.filters.searchText}
                                    hideLabel={true}
                                    hideGarbageIcon={true}
                                    isInCommandBar={false}
                                    initialFocus={false}
                                    onFilterChange={(p) => { this.setState({ filters: { ...this.state.filters, searchText: p.filter || '' } }); }}
                                />
                            </div>
                            <div style={{ display: 'flex 1' }}>
                                <CommandBar items={[]} farItems={barItemsRight} styles={{ root: { height: '32px' } }} />
                            </div>
                        </div>
                    </div>
                    <div style={{ maxHeight: '55vh', overflowX: 'hidden', overflowY: 'auto' }}>
                        {workspacesToDisplay.map(w => (
                            <div key={`${w.name}_${new Date().getTime()}`} style={TableRowStyle}>
                                <div style={{ flex: '1' }}>
                                    <Link
                                        href={`view-workspace/${w.urlName}`}
                                        onClick={(e) => { e.preventDefault(); this.props.onViewWorkspace(w.urlName); }}
                                    >
                                        {w.name}
                                    </Link>
                                </div>
                                <div>
                                    {w.isOwnerOrCreator && (<ActionButton
                                        onMouseDown={(e: any) => e.stopPropagation()}
                                        styles={{ menuIcon: { display: 'none' }, root: { height: 'auto' } }}
                                        iconProps={{ iconName: 'MoreVertical' }}
                                        menuProps={{
                                            items: [
                                                // { key: 'manage', text: 'Manage ...', iconProps: { iconName: 'Hide' }, 
                                                //     onClick: (e: any) => this.editWorkspace(w.urlName) },
                                                {
                                                    key: 'permissions', text: 'Permissions ...', iconProps: { iconName: 'Permissions' },
                                                    onClick: (e: any) => { this.onPermissions(w.urlName); }
                                                },
                                                {
                                                    key: 'delete', text: 'Delete ...', iconProps: { iconName: 'Delete' },
                                                    onClick: (e: any) => { this.onDelete(w.urlName); }
                                                },
                                                {
                                                    key: 'edit', text: 'Update ...', iconProps: { iconName: 'Edit' },
                                                    onClick: (e: any) => { this.onPeriodUpdateClick(w.urlName); }
                                                }
                                            ]
                                        }}
                                    />)}
                                </div>
                            </div>
                        ))}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => {
    const { listWorkspaces, entities } = state;
    const { workspaces } = entities;
    const { byId: wSpaces } = workspaces;
    const newState = Object.values(wSpaces);

    return {
        isLoading: listWorkspaces.isLoading,
        error: listWorkspaces.error,
        workspaces: newState,
        deleteWorkspaceName: listWorkspaces.deleteWorkspaceName,
        showDeleteConfirmation: listWorkspaces.showDeleteConfirmation,
        showUpdatePeriodDialog: listWorkspaces.showUpdatePeriodDialog,
        updateWorkspaceName: listWorkspaces.updateWorkspaceName,
        currentPeriod: listWorkspaces.currentPeriod,
        duration: listWorkspaces.duration,
        durationUnit: listWorkspaces.durationUnit
    }
}

// Wire up the React component to the Redux store
export default (connect(mapStateToProps, ListWorkspacesStore.actionCreators)(ListWorkspaces));