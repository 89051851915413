import React, {  } from 'react';
import { AppearanceManager } from '.';
import { getId, Label, TextField, TooltipHost, ITextField } from 'office-ui-fabric-react';

type LabelledTextFieldProps = {
    label: string,
    multiline?: boolean,
    value?: string,
    defaultValue?: string,
    labelLeftPadding?: string,
    required?: boolean,
    errorMessage?: string,
    helpMessage?: string,
    disabled?: boolean,
    marginBottom?: string,
    enableIcon?: boolean,
    labelStyles?: any,
    additionalProps?: any,
    selectOnEntry?: boolean,
    onChange?: (event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) => void
    onBlur?: (event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) => void
};

type LabelledTextFieldState = {
    errorMessage?: string;
    currentValue?: string;
};

type ValueOptions = {
    value?: string;
    defaultValue?: string;
};

export class LabelledTextField extends React.Component<LabelledTextFieldProps, LabelledTextFieldState> {
    originalValue?: string;
    defaultBottomMargin = '2em';
    initialValues: ValueOptions = {};
    constructor(props: LabelledTextFieldProps) {
        super(props);
        if ( this.props.value ) {
            this.originalValue = this.props.value;
        } else if ( this.props.defaultValue ) {
            this.originalValue = this.props.defaultValue;
        } else {
            this.originalValue = '';
        }
        this.state = { errorMessage: props.errorMessage, currentValue: this.originalValue};
        if ( this.props.defaultValue ) {
            this.initialValues.defaultValue = this.props.defaultValue;
        } else if ( this.props.value ) {
            this.initialValues.value = this.props.value;
        }
    }

    _refTextField: React.RefObject<ITextField> = React.createRef();
    
    render() {
        const controlId = getId('TextField');
        const labelId = getId('Label');
        let labelStyles = this.props.labelLeftPadding === undefined
            ? AppearanceManager.FormLabelStyles
            : { ...AppearanceManager.FormLabelStyles, paddingLeft: this.props.labelLeftPadding };
        labelStyles = {...labelStyles, ...this.props.labelStyles};
        const label = (
            <Label
                id={labelId}
                htmlFor={controlId}
                style={{ ...labelStyles, color: this.props.errorMessage === undefined ? labelStyles.color : '#a71930' }}
            >
                {this.props.label}
            </Label>
        );
        const selectOnEntry = this.props.selectOnEntry === true ?  { 
            onFocus: () => {
                if ( this._refTextField.current ) { 
                    this._refTextField.current.select(); } }
                } : {};

        let iconName: string = '';
        if ( this.props.disabled === true ) {
            iconName = 'FieldReadOnly';
        } else if ( this.state.currentValue !== this.originalValue ) {
            iconName = 'FieldChanged';
        } else if ( this.props.enableIcon === true ) {        
            iconName = 'FieldNotChanged';
        }
        let iconProps = this.props.enableIcon === true ? {
            iconProps : { iconName: iconName }
        } : {};

        const control = (
            <TextField
                componentRef={this._refTextField}
                {...iconProps}
                id={controlId}
                multiline={this.props.multiline}
                autoAdjustHeight={this.props.multiline}
                disabled={this.props.disabled === true ? true : false}
                styles={{
                    root: { flexGrow: 1 },
                    wrapper: { flexGrow: 1 },
                    fieldGroup: { border: 'none' },
                    field: {
                        fontSize: AppearanceManager.FormFieldStyles.fontSize,
                        lineHeight: AppearanceManager.FormFieldStyles.lineHeight,
                        backgroundColor: AppearanceManager.FormFieldStyles.backgroundColor,
                        border: this.props.errorMessage === undefined
                            ? AppearanceManager.FormFieldStyles.border as string
                            : '1px solid #a71930'
                    },
                    icon: {
                        color: 'black'
                    }
                }}
                {...selectOnEntry}
                {...this.initialValues}
                placeholder={this.props.helpMessage}
                onChange={(e, v) => this.onChange(e, v)}
                onBlur={(e) => this.onBlur(e)}
                errorMessage={this.props.errorMessage}
            />
        );

        let marginBottom = this.props.marginBottom ? this.props.marginBottom : this.defaultBottomMargin;
        if (this.props.helpMessage !== undefined) {
            return (
                <div style={{ display: 'flex', marginBottom: marginBottom }}>
                    <TooltipHost
                        content={this.props.helpMessage}
                        tooltipProps={{ style: { overflowY: 'hidden' } }}
                        styles={{ root: { display: 'inline-block' } }}
                    >
                        {label}
                    </TooltipHost>
                    {control}
                </div>
            );
        } else {
            return (
                <div style={{ display: 'flex', marginBottom: marginBottom }}>
                    {label}
                    {control}
                </div>
            );
        }
    }

    onChange(event: React.FormEvent<HTMLInputElement | HTMLTextAreaElement>, newValue?: string) {
        if (this.props.onChange !== undefined) {
            this.props.onChange(event, newValue);
        }
        this.setState( { currentValue : newValue });
    }

    onBlur(event: React.FocusEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (this.props.onBlur !== undefined) {
            this.props.onBlur(event);
        }
    }

}