import * as React from 'react';
import { Dialog, DialogType, DialogFooter, PrimaryButton, DefaultButton } from 'office-ui-fabric-react';
import { LabelledTextField } from './labelled-text-field';
type InputDialogProps = {
    show?: boolean, 
    dialogTitle?: string,
    inputLabel?: string,
    onDismiss?: any,
    width?: number
};
export class InputDialog extends React.Component<InputDialogProps, {value?: string}> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    _closeDialog(v?: string) {
        if ( this.props.onDismiss ) {
            this.props.onDismiss(v);
        }
    }

    render() {
        return (
            <Dialog
                dialogContentProps={{
                    type: DialogType.normal,
                    title: this.props.dialogTitle ? this.props.dialogTitle : 'Enter a value',
                    
                }}
                hidden={!this.props.show}
                modalProps={{
                    isBlocking: false    
                }}
                minWidth={this.props.width ? this.props.width : undefined}
            >
                <div style={{}}>
                    <LabelledTextField 
                        label={this.props.inputLabel ? this.props.inputLabel : 'Value'}
                        onChange={(e: any, v: string) => this.setState({value: v})}
                        labelStyles={{ maxWidth: 'max-content', marginRight: '1em'}}
                    />
                </div>
                <DialogFooter>
                    <PrimaryButton onClick={() => this._closeDialog(this.state.value)} text="Save" />
                    <DefaultButton onClick={() => this._closeDialog()} text="Cancel" />
                </DialogFooter>
            </Dialog>
        );
    }
}