import React from 'react';
import { AuthenticatedUser } from '../../auth';
//import woodsideLogo from './woodside-logo-36.png';
import { SolutionMasthead } from './solution-masthead';
//import { getTheme } from 'office-ui-fabric-react/lib/Styling';

//const theme = getTheme();
export class GlobalTab extends React.Component<{ user?: AuthenticatedUser }, {  }> {
    
    render() {
        const divStyles: React.CSSProperties = {
            height: '60px',
            width: '100%',
            backgroundColor: 'rgb(25, 57, 80)',
            display: 'flex',
            flexFlow: 'column',
            //alignItems: 'center',
            borderBottom: `4px solid rgb(50, 111, 154)`,
            justifyContent: 'center' 
        };
        // const logoStyles: React.CSSProperties = {
        //     width: 37,
        //     display: 'flex',
        //     //paddingTop: (46 - 36) / 2,
        //     paddingLeft: 20
        // };

        return (
            <React.Fragment>
                <div style={divStyles}>
                    <div style={{display: 'flex', flexFlow: 'row'}}>
                        {/* <div style={logoStyles}><a href="http://connect/"><img src={woodsideLogo} /></a></div> */}
                        <div style={{ flexGrow: 1 }} >
                        <SolutionMasthead
                            title="SURVEILLANCE HUB"
                            initial="S"
                            colour='#fff'
                        />
                        </div>
                        <div style={{ textAlign: 'right', marginRight: 20, lineHeight: '46px', color: '#fff' }}>
                            {this.props.user === undefined ? '' : this.props.user.fullnameAndTitle}
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }
}
