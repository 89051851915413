import { SuppressionRule } from '../store/service-client/index';
import { ExceedancesRowProps } from '../store/view-workspace';

export function getApplicableSuppressedTagsFromRules(apexDate: number,
                                                     rules: SuppressionRule[],
                                                     exceedances?: ExceedancesRowProps[]): string[] {
    return rules
        // only select applicable rules
        .filter( rule => isApplicableInformantTagRule(rule, apexDate, exceedances) ||
                         isApplicableAdhocRule(rule, apexDate))
        // reduce to tags
        .map( rule =>  rule.suppressed_tags)
        // reduce to a single list
        .reduce( (p, c) => p.concat(c), [])
        // filter out duplicates
        .filter( (tag: string, i: number, list: string[]) => list.indexOf(tag) === i);
}

export function isApplicableInformantTagRule(rule: SuppressionRule, apexDate: number, exceedances?: ExceedancesRowProps[]): boolean {
    // not applicable if there aren't actually any exceedances
    if ( exceedances === undefined || exceedances.length === 0 || ! rule.informant_tag ) {
        return false;
    }

    // if dates are specified, then apex date must be within them
    if ( rule.start_time && rule.start_time > apexDate ) {
        return false;
    } else if ( rule.end_time && rule.end_time <= apexDate ) {
        return false;
    }
    
    // informant tags can be "and'd" or "or'd"
    // method is to iterate through each informant tag and evaluate its applicability
    // then reduce results according to "and" or "or" setting
    // let logicalAnd = true;
 
    const informantTag = rule.informant_tag;
    if(informantTag){
        if(informantTag.state){         
           
           return exceedances.find(e=> e.tag == informantTag.tag && e.states && e.states.some(s=> s.met ==true)) !== undefined;
        }
        return exceedances.find( e => e.tag === informantTag.tag && e.states.length > 0) !== undefined;
    }
    return false;
}

export function isApplicableAdhocRule(rule: SuppressionRule, apexDate: number): boolean {
    if ( (rule.informant_tag) || ! (rule.start_time && rule.end_time )  ) {
        return false;
    }

    return rule.start_time <= apexDate && rule.end_time > apexDate;
}