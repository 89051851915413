import React from 'react'
import { withRouter } from 'react-router-dom';
import { ViewDescription } from './view-description'
import { Button } from 'office-ui-fabric-react';
import { Dialog, DialogType} from 'office-ui-fabric-react';
import { RouteComponentProps } from 'react-router'

export type StateDescriptionState = {
    editedDescription: string,
    show: boolean
};

export type StateDescriptionProps = RouteComponentProps<{workspaceName:string}> & {
    envelopeId:string,
    stateName: string,
    description: string,
    canEdit:boolean,
    onDisplayTypeChange: Function,
    displayType: StateDisplayType
} ;

export type StateDisplayType = "readonly" | "none";

export class StateDescriptionWithRoute extends React.Component<StateDescriptionProps, StateDescriptionState >{
    constructor(props:any){
        super(props);
        this.state = {
            editedDescription: this.props.description,
            show: this.props.displayType != "none"
        };
    }

    componentDidUpdate(prop:StateDescriptionProps,state: StateDescriptionState){
        if(prop.displayType != this.props.displayType){
            this.setState({show : this.props.displayType != "none"});
        }
    }

    getContent(){
        switch(this.props.displayType){
            case "readonly":
                return (<ViewDescription 
                    description={this.props.description} 
                    stateName={this.props.stateName} 
                    >
                        {this.props.canEdit ? (<Button onClick={()=> this.props.history.push(`/edit-envelope/${this.props.match.params.workspaceName}/${this.props.envelopeId}`)}>Edit</Button>) : undefined}
                </ViewDescription>);
                break;
            case "none":
            default:
                return (<React.Fragment></React.Fragment>);
        }
    }

    render(){
        let content = this.getContent();
        
        return (<Dialog
            minWidth={500}
            hidden={!this.state.show}
            onDismiss={() => 
                this.props.onDisplayTypeChange("none")
            }
            dialogContentProps={{
                type: DialogType.normal,
                title: `${this.props.stateName}`}}>
                    {content}
            </Dialog>)
    }
}

export const StateDescription = withRouter(StateDescriptionWithRoute);