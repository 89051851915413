import { AppThunkAction } from '../index';
import { ViewWorkspaceAction } from "../slice-reducers/view-workspace";
import { AuthenticatedUser } from "../../auth";
import * as ServiceClient from '../service-client'
import * as LoadUserWorkspaces from '../loading-data/user-workspaces';
import * as TagDetailData from '../loading-data/tag-detail';
import * as TagCurrentValueData from '../loading-data/tag-current-value';
import { ForceLoadWorkspaceEnvelopes} from '../loading-data/envelopes';
import { LoadGroupsForWorkspace } from '../loading-data/workspace-group'
import { WorkspaceGroup, PeriodOptions, IWorkspaceTimePeriod } from '../slice-reducers/common-types';
import { DisplayExceedanceType, ExceedancesRowProps } from '../view-workspace';
import { isAfter } from 'date-fns';
import { allOfTheseIncludesText } from '../../utils/FilterText';
import { getApplicableSuppressedTagsFromRules } from '../../utils/Suppression';
import { BuildExceedancesDisplayValues } from '../../utils/Exceedance';
import * as ViewWorkspaceStore from '../../store/view-workspace';
import { isNullOrUndefined } from 'util';


const BATCH_SIZE = 20;
const CONCURRENCY = 50;

export const actionCreators = {
    initialLoad: (
        user: AuthenticatedUser,
        workspaceName: string,
        filter?: string,
        displayExceedanceType?: DisplayExceedanceType
    ): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        dispatch({ type: 'INITIAL_LOAD', workspaceName, currentUser: user });
        try {
            const response = await ServiceClient.getServiceClient().getPeriodForWorkspace(workspaceName);
            if (response.length) {
                const timePeriod: PeriodOptions = parseInt(PeriodOptions[response[0].currentPeriod], 10);
                let newstartTime, newendTime, time;
                if (response[0].currentPeriod !== 'Custom') {
                    time = ViewWorkspaceStore.getStartEndTime(timePeriod);
                } else {
                    time = ViewWorkspaceStore.getCustomStartEndTime(response[0].durationUnit, response[0].duration);
                }
                newstartTime = time.startTime;
                newendTime = time.endTime;

                const wsPeriod: IWorkspaceTimePeriod = {
                    workspaceName: workspaceName,
                    currentPeriod: timePeriod,
                    periodStartTime: newstartTime,
                    periodEndTime: newendTime
                };
                dispatch({ type: 'LOAD_WORKSPACE_TIME_PERIOD', payload: { workspaceTimePeriod: wsPeriod } });
            } else {
                const time = ViewWorkspaceStore.getStartEndTime(PeriodOptions.LastHour);
                const newstartTime = time.startTime;
                const newendTime = time.endTime;
                const wsPeriod: IWorkspaceTimePeriod = {
                    workspaceName: workspaceName,
                    currentPeriod: PeriodOptions.LastHour,
                    periodStartTime: newstartTime,
                    periodEndTime: newendTime
                }
                dispatch({ type: 'LOAD_WORKSPACE_TIME_PERIOD', payload: { workspaceTimePeriod: wsPeriod } });
            }

            const { viewWorkspace } = getState();
            const { workspaceTimePeriod } = viewWorkspace;
            const defaultTime = ViewWorkspaceStore.getStartEndTime(PeriodOptions.LastHour);
            const startTime = isNullOrUndefined(workspaceTimePeriod) ? defaultTime.startTime : workspaceTimePeriod.periodStartTime;
            const endTime = isNullOrUndefined(workspaceTimePeriod) ? defaultTime.endTime : workspaceTimePeriod.periodEndTime;

            const serviceClient = ServiceClient.getServiceClient();
            const userSpaces = await LoadUserWorkspaces.LoadUserWorkspaces(dispatch, getState, user);

            const wsPermissions: ServiceClient.UserWorkspace = userSpaces[workspaceName];

            const canEdit = wsPermissions && (wsPermissions.creator || wsPermissions.owner || wsPermissions.member);

            const tagsInWorkspace = await ForceLoadWorkspaceEnvelopes(dispatch, workspaceName);
            if (!(tagsInWorkspace && tagsInWorkspace.length > 0)) {
                dispatch({ type: 'NO_ENVELOPES_DEFINED', emptyWorkspace: true, canEdit: canEdit });
                return;
            }
            dispatch({ type: 'INITIAL_LOAD_TAGS_IN_WORKSPACE', canEdit: canEdit, totalTagCount: tagsInWorkspace.length });

            const tagsToGet = tagsInWorkspace.map(t => t.tag);
            const tagDetails = await TagDetailData.LoadTagsDetails(dispatch, getState, tagsToGet);
            const currentValues = await TagCurrentValueData.LoadTagCurrentValues(dispatch, getState, tagsToGet, startTime(), endTime());
            const updatedTime = Date.now();

            Object.values(currentValues).map(v => {
                const td = tagDetails[v.tag];
                if (td) {
                    td.currentValueTime = v.time;
                    td.currentValue = v.value;
                    td.updated = new Date(updatedTime);
                }
            });

            dispatch({ type: 'INITIAL_LOAD_TAG_DETAILS', tagDetails: tagDetails });
            const displayType: DisplayExceedanceType = viewWorkspace.displayExceedanceType || 'EXCEEDANCE';
            const { resultsWithSuppression, allSuppressionRules, filteredRows, suppressedRows } = await EvaluateAndFilter(workspaceName, tagDetails, startTime(), endTime(), serviceClient, tagsInWorkspace, displayType);
            const exceedanceCount = filteredRows.filter((e => e.states.some(s => (s.met && s.name !== ServiceClient.STATE_NOT_TRIGGERED) || (s.name === ServiceClient.STATE_NO_DATA && e.currentValue === undefined)) && !e.suppressed)).length;

            //Get Workspace Groups
            await LoadGroupsForWorkspace(dispatch, getState, workspaceName);

            const filteredWithExceedance = filteredRows.filter(r => r.states.some(s => s.met));

            dispatch({ type: 'INITIAL_LOAD_RESPONSE', initialExceedances: resultsWithSuppression, initialSuppressionRules: allSuppressionRules, canEdit: canEdit, exceedanceCount: exceedanceCount, suppressedTagCount: suppressedRows.length, displayExceedanceType: displayType, startTime: startTime(), endTime: endTime() });
            dispatch({ type: 'FILTER_EXCEEDANCES_SUCCESS', allExceedances: resultsWithSuppression, filteredExceedances: filteredWithExceedance, suppressedTagCount: suppressedRows.length, displayExceedanceType: displayType, exceedanceTagCount: exceedanceCount, startTime: startTime(), endTime: endTime() });
        } catch (e) {
            dispatch({ type: 'INITIAL_LOAD_ERROR', errorMessage: e.message });
            // throw (e);
        }
    },
    clearData: (): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        dispatch({ type: 'CLEAR_DATA_VIEW_WORKSPACE' });
    },
    updateSuppressionList: (
        filter?: string
    ): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        const serviceClient = ServiceClient.getServiceClient();
        const { viewWorkspace } = getState();
        const { currentExceedances, workspaceName, displayExceedanceType, startTime, endTime } = viewWorkspace;
        const allSuppressionRules = await serviceClient.getActiveSuppressionRules(workspaceName);
        dispatch({ type: 'LOAD_SUPPRESSION_RULES_SUCCESS', suppressionRules: allSuppressionRules })
        dispatch({ type: 'FILTER_EXCEEDANCES' });
        const { filteredRows, suppressedRows } = FilterExceedances(currentExceedances, viewWorkspace.displayExceedanceType, filter, allSuppressionRules);
        const exceedanceCount = filteredRows.filter((e => !e.states.every((s) => s.name === ServiceClient.STATE_NOT_TRIGGERED))).length;
        const filteredResults = currentExceedances.map(r => { return { ...r, suppressed: suppressedRows.some(s => s.tag === r.tag) } });

        dispatch({ type: 'FILTER_EXCEEDANCES_SUCCESS', allExceedances: filteredResults, filteredExceedances: filteredRows, suppressedTagCount: suppressedRows.length, displayExceedanceType: displayExceedanceType, exceedanceTagCount: exceedanceCount, startTime: startTime, endTime: endTime });
    },
    getSuppressionRuleHistory: (identifier: string): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        // 
    },
    filterExceedances: (
        currentExceedances: ExceedancesRowProps[],
        filter?: string,
        suppressionRules?: ServiceClient.SuppressionRule[],
        displayType?: DisplayExceedanceType,
        sortColumn?: string,
        sortDescending?: boolean
    ): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        dispatch({ type: 'FILTER_EXCEEDANCES' });
        const { viewWorkspace } = getState();
        const { displayExceedanceType, startTime, endTime } = viewWorkspace;
        const exceedanceType = displayType || displayExceedanceType;

        const { filteredRows, suppressedRows } = FilterExceedances(currentExceedances, exceedanceType, filter, suppressionRules);
        //Map the suppressed value back to the currentExceptions array.
        const resultsWithSuppression = currentExceedances.map(r => { return { ...r, suppressed: suppressedRows.some(t => t.tag === r.tag) } });


        const exceedanceCount = filteredRows.filter((e => e.states.some(s => (s.met && s.name !== ServiceClient.STATE_NOT_TRIGGERED) || (s.name === ServiceClient.STATE_NO_DATA && e.currentValue === undefined)) && !e.suppressed)).length;

        if (sortColumn) {
            const sortedRows = copyAndSort(filteredRows, sortColumn, sortDescending);
            dispatch({ type: 'FILTER_EXCEEDANCES_SUCCESS', allExceedances: resultsWithSuppression, filteredExceedances: sortedRows, exceedanceTagCount: exceedanceCount, suppressedTagCount: suppressedRows.length, displayExceedanceType: exceedanceType, startTime: startTime, endTime: endTime });
        }
        else {
            dispatch({ type: 'FILTER_EXCEEDANCES_SUCCESS', allExceedances: resultsWithSuppression, filteredExceedances: filteredRows, exceedanceTagCount: exceedanceCount, suppressedTagCount: suppressedRows.length, displayExceedanceType: displayType || 'EXCEEDANCE', startTime: startTime, endTime: endTime });
        }
    },
    exportDashboardToCsv: (
        dashboardInfo: any[],
        startTime: Date,
        endTime: Date
    ): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        dispatch({ type: 'EXPORT_DASHBOARD_CSV', dashboardInfo, startTime, endTime });
    },
    reEvaluate: (currentPeriod: PeriodOptions, startTime: () => Date, endTime: () => Date, filter?: string): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        const { viewWorkspace, entities } = getState();
        const { workspaceName } = viewWorkspace;
        const { tagDetails } = entities;
        const { byId: tagsById } = tagDetails;
        dispatch({ type: 'REFRESH_CURRENT' });
        const serviceClient = ServiceClient.getServiceClient();
        let detailValues = Object.values(tagsById);

        const tagsInWorkspace = await ForceLoadWorkspaceEnvelopes(dispatch, workspaceName);

        const currentValues = await TagCurrentValueData.LoadTagCurrentValues(dispatch, getState, tagsInWorkspace.map(t => t.tag), startTime(), endTime());
        const updatedTime = new Date();
        Object.values(currentValues).map(v => {
            if (tagsById[v.tag]) {
                tagsById[v.tag].currentValueTime = v.time;
                tagsById[v.tag].currentValue = v.value;
                tagsById[v.tag].updated = updatedTime;
            }
        });

        if (detailValues && detailValues.length > 0) {
            const myState = { ...viewWorkspace };
            const { resultsWithSuppression, allSuppressionRules, filteredRows, suppressedRows } = await EvaluateAndFilter(workspaceName, tagsById, startTime(), endTime(), serviceClient, tagsInWorkspace, viewWorkspace.displayExceedanceType, filter);

            const exceedanceCount = filteredRows.filter((e => e.states.some(s => (s.met && s.name !== ServiceClient.STATE_NOT_TRIGGERED) || (s.name === ServiceClient.STATE_NO_DATA && e.currentValue === undefined)) && !e.suppressed)).length;
            //const { groups, rows } = await mapWsGroupsToExGroups(myState.workspaceGroups || [], filteredRows);

            const filteredWithExceedance = filteredRows.filter(r => r.states.some(s => s.met));

            //Added
            const wsPeriod: IWorkspaceTimePeriod = {
                workspaceName: workspaceName,
                currentPeriod: currentPeriod,
                periodStartTime: startTime,
                periodEndTime: endTime
            };
            dispatch({ type: 'LOAD_WORKSPACE_TIME_PERIOD', payload: { workspaceTimePeriod: wsPeriod } });

            dispatch({ type: 'INITIAL_LOAD_RESPONSE', initialExceedances: resultsWithSuppression, initialSuppressionRules: allSuppressionRules, canEdit: myState.canEdit, exceedanceCount: exceedanceCount, suppressedTagCount: suppressedRows.length, displayExceedanceType: myState.displayExceedanceType, startTime: startTime(), endTime: endTime() });
            dispatch({ type: 'FILTER_EXCEEDANCES_SUCCESS', allExceedances: resultsWithSuppression, filteredExceedances: filteredWithExceedance, suppressedTagCount: suppressedRows.length, displayExceedanceType: myState.displayExceedanceType, exceedanceTagCount: exceedanceCount, startTime: startTime(), endTime: endTime() });
        }
        else {
            dispatch({ type: 'NO_ENVELOPES_DEFINED', emptyWorkspace: true, canEdit: viewWorkspace.canEdit });
        }
    },
    setSelectedTags: (selectedTag: ServiceClient.TagEnvelopeDefinition, checked: boolean): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getstate) => {
        const { viewWorkspace } = getstate();
        const { selectedTags } = viewWorkspace;
        if (!selectedTags) {
            dispatch({ type: 'SET_SELECTED_TAGS', payload: { selectedTags: [selectedTag] } });
        } else {
            const myTags = selectedTags!.some(t => t.tag == selectedTag.tag) ?
                !checked ? selectedTags.filter(t => t.tag != selectedTag.tag)
                    : selectedTags
                : selectedTags!.concat(selectedTag);
            dispatch({ type: 'SET_SELECTED_TAGS', payload: { selectedTags: [...myTags] } });
        }
    },
    clearSelectedTags: (): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getstate) => {
        dispatch({ type: 'CLEAR_SELECTED_TAGS' });
    },
    expandGroup: (group: ServiceClient.WorkspaceGroup): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        const { entities } = getState();
        const { workspaceGroups } = entities;
        const { byId } = workspaceGroups;

        if (byId) {
            const toChange = byId[group.id];
            if (toChange) {
                toChange.expanded = !group.expanded;
                dispatch({ type: 'GROUP_EXPANDED', payload: { workspaceGroup: toChange } });
            }
        }
    },
    expandAllGroups: (): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        const { entities } = getState();
        const { workspaceGroups } = entities;
        const { byId } = workspaceGroups;
        //const { workspaceGroups } = viewWorkspace;
        const wsGroups: WorkspaceGroup[] = Object.values(byId);
        if (wsGroups) {

            var expanded = !wsGroups.every(g => g.expanded === true);
            const newGroups = wsGroups.map(g => { return { ...g, expanded: expanded } });
            dispatch({ type: 'GROUPS_EXPANDED', payload: { workspaceGroups: newGroups } });
        }
    },
    deleteGroup: (group: ServiceClient.WorkspaceGroup): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        //Remove the group from the Workspace Groups...
        const response = await ServiceClient.getServiceClient().removeWorkspaceGroup(group.workspace, group.id);
        if (response) {
            dispatch({ type: 'REMOVE_GROUP_FROM_WORKSPACE', payload: { group: group, workspace: group.workspace } });
        }
        else {
            throw Error(`Failed to delete group ${group.name}`);
        }
        //This means we need to add the Tags to the Tags Not in A Group Group...
    },
    getWorkSpaceWithTimePeriod: (workspaceName: string): AppThunkAction<ViewWorkspaceAction> => async (dispatch, getState) => {
        const response = await ServiceClient.getServiceClient().getPeriodForWorkspace(workspaceName);
        if (response) {
            dispatch({ type: 'LOAD_WORKSPACE_TIME_PERIOD', payload: { workspaceTimePeriod: response[0] } });
        }
        else {
            throw Error(`Failed to delete group`);
        }
    }
};

async function EvaluteWorkspace(workspaceName: string, tags: ServiceClient.TagEnvelopeDefinition[], startTime: Date, endTime: Date, detailsMap: Record<string, ServiceClient.TagDetail>, showAllStates: boolean): Promise<ExceedancesRowProps[]> {
    const allExceedances = tags.length > 0 ? await loadAllExceedances(workspaceName, startTime, endTime, tags) : [];
    const result = BuildExceedancesDisplayValues(tags, allExceedances, detailsMap, startTime, endTime);
    return result.map(r => Object.assign({}, { ...r, suppressed: false }));
}


async function EvaluateAndFilter(workspaceName: string, tagDetails: Record<string, ServiceClient.TagDetail>, startTime: Date, endTime: Date, serviceClient: ServiceClient.ServiceClient, tagsInWorkspace: ServiceClient.TagEnvelopeDefinition[], filterMode: DisplayExceedanceType, filter?: string) {
    const currentValues = Object.values(tagDetails);
    const tagsWithHighWater = GetTagsWithHighwater(currentValues, tagsInWorkspace, startTime);
    const tagsWithoutHighwater = tagsInWorkspace.filter(t => !tagsWithHighWater.some(h => h.tag == t.tag));
    // const tagsToEvaluate = tagsWithHighWater.concat(tagsWithoutHighwater);
    const result = await EvaluteWorkspace(workspaceName, tagsWithHighWater, startTime, endTime, tagDetails, filterMode === "ALL");
    // result.push(...tagsWithoutHighwater.map(t => <ExceedancesRowProps>{ ...t, states: t.states.filter(s => s.name == ServiceClient.STATE_NO_DATA), events: [], suppressed: false }));
    result.push(...tagsWithoutHighwater.map(t => <ExceedancesRowProps>{ ...t, events: [], suppressed: false }));
    const allSuppressionRules = await serviceClient.getAllSuppressionRules(workspaceName);

    const { filteredRows, suppressedRows } = FilterExceedances(result, filterMode, filter, allSuppressionRules);
    //Map the suppressed value back to the currentExceptions array.
    const resultsWithSuppression = result.map(r => { return { ...r, suppressed: suppressedRows.some(t => t.tag === r.tag) } });

    return { resultsWithSuppression, allSuppressionRules, filteredRows, suppressedRows };
}


function FilterExceedances(
    currentExceedances: ExceedancesRowProps[],
    displayType: DisplayExceedanceType, filter?: string,
    suppressionRules?: ServiceClient.SuppressionRule[]): { filteredRows: ExceedancesRowProps[], suppressedRows: ExceedancesRowProps[] } {

    const maskedTags = suppressionRules
        ? getApplicableSuppressedTagsFromRules(new Date().getTime(), suppressionRules, currentExceedances)
        : [];

    const filterText = filter === undefined ? '' : filter;
    const terms = filterText.replace(/[ ]+/g, ' ').split(' ');

    const exceedenceRows = currentExceedances
        .map(row => {
            return { ...row, suppressed: maskedTags.some((t) => t === row.tag), filterText: terms };
        });
    const suppressedRows = exceedenceRows.filter(r => r.suppressed);

    switch (displayType) {
        case 'ALL':
            const filteredRows = exceedenceRows.filter(row => terms && terms.length > 0 &&
                allOfTheseIncludesText(terms, [row.tag, row.description!, row.unit!, ...row.states.map((s) => s.name)]))
            return { filteredRows: filteredRows, suppressedRows: suppressedRows };
            break;
        case 'EXCEEDANCE':
            const exfilteredRows = exceedenceRows.filter(row => terms && terms.length > 0 &&
                allOfTheseIncludesText(terms, [row.tag, row.description!, row.unit!, ...row.states.map((s) => s.name)]))
                .filter(row => !row.states.every((s) => s.name === ServiceClient.STATE_NOT_TRIGGERED) && !row.suppressed);
            exfilteredRows.forEach(r => r.states = r.states.filter(s => s.met || (s.name === ServiceClient.STATE_NO_DATA && r.currentValue === undefined)));
            return { filteredRows: exfilteredRows, suppressedRows: suppressedRows };
            break;
        case 'SUPPRESSED':
            return { filteredRows: suppressedRows, suppressedRows: suppressedRows }
            break;
    }

}

function copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
    const key = columnKey as keyof T;
    return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
}

function GetTagsWithHighwater(tagCurrentValues: ServiceClient.TagDetail[], tagsInWorkspace: ServiceClient.TagEnvelopeDefinition[], startTime: Date) {
    const fitleredHighWater = tagCurrentValues.filter(t => t.currentValue != undefined && t.currentValue != "" && t.currentValueTime && isAfter(t.currentValueTime, startTime));
    return tagsInWorkspace.filter(t => fitleredHighWater.map(v => v.name).some(v => v == t.tag));
}



async function loadAllExceedances(workspaceName: string, startTime: Date, endTime: Date, tagsInWorkspace: ServiceClient.TagEnvelopeDefinition[]) {
    try {
        let batches = buildBatchesOfTags(tagsInWorkspace, BATCH_SIZE);
        let results: ServiceClient.TagExceeedance[][] = [];
        while (batches.length > 0) {
            let sliceSize = Math.min(batches.length, CONCURRENCY);
            let currentBatches = batches.slice(0, sliceSize);
            batches = batches.slice(sliceSize);
            try {
                results = results.concat(await Promise.all(currentBatches.map(async batch => loadExceedanceBatch(workspaceName, startTime, endTime, batch))));
            }
            catch (error) {
                throw error;
            }
        }
        if (results.some((r: any) => r instanceof Error)) {
            //Map All Errors down to one.
            throw new Error("Error retrieving exceedences.");
        }
        const flattenedResults = ([] as ServiceClient.TagExceeedance[]).concat(...results);
        return flattenedResults;
    } catch (error) {
        throw error;
    }
}


async function loadExceedanceBatch(workspaceName: string, startTime: Date, endTime: Date, tagBatch: ServiceClient.TagEnvelopeDefinition[]) {
    if (!tagBatch || tagBatch.length == 0) {
        return [];
    }
    const wsName = workspaceName;
    const tagBatchNames = tagBatch.map(t => t.tag);
    const exceedances = await ServiceClient.getServiceClient().getExceedance(wsName, tagBatchNames, startTime, endTime, true);
    return exceedances;
}

function buildBatchesOfTags(tags: ServiceClient.TagEnvelopeDefinition[], batchSize: number) {
    let batches: ServiceClient.TagEnvelopeDefinition[][] = [];
    let currentBatch: ServiceClient.TagEnvelopeDefinition[] = [];

    for (let tagIndex = 0; tagIndex < tags.length; tagIndex++) {
        currentBatch.push(tags[tagIndex]);
        if (currentBatch.length === batchSize) {
            batches.push(currentBatch);
            currentBatch = [];
        }
    }
    batches.push(currentBatch);
    currentBatch = [];
    return batches;
}