import { ColourManager } from ".";

export enum PrimaryColours {
    darkGrey = '#636466',
    sand = '#f2f0ed',
    navy = '#1c405a'
}

export enum SecondaryColours {
    violet = '#858fc2',
    darkViolet = '#6671ac',
    blueGrey = '#6a94b0',
    darkBlueGrey = '#4e7898',
    aqua = '#4a8f9b',
    darkAqua = '#357783',
    teal = '#10a399',
    darkTeal = '#048479',
    deepGreen = '#439b73',
    darkDeepGreen = '#2a8057',
    green = '#75b12e',
    darkGreen = '#3f860a',
    orange = '#df7e1e',
    darkOrange = '#ca4b06'
}

export enum StatusColours {
    greenGreen = '#75b12e',
    darkRed = '#a71930',
    orange = '#df7e1e',
    blueGrey = '#6a94b0'
}

export enum StringColours {
    Green = 'Green',
    Red = 'Red',
    Orange = 'Orange',
    BlueGrey = 'Blue/Grey'
}

export const AppColour = SecondaryColours.darkOrange;


export function stringToStatusColor(colour: string): ColourManager.StatusColours {
    let col: ColourManager.StatusColours = ColourManager.StatusColours.darkRed;
    switch (colour.trim().toUpperCase()) {
        case "DARKRED":
        case "RED":
            col = ColourManager.StatusColours.darkRed;
            break;
        case "ORANGE":
            col = ColourManager.StatusColours.orange;
            break;
        case "GREENGREEN":
        case "GREEN":
            col = ColourManager.StatusColours.greenGreen;
            break;
        case "BLUE/GREY":
        case "BLUE":
        case "GREY":
        default:
            col = ColourManager.StatusColours.blueGrey;
            break;
    }
    return col;
}

export function colorToStatusColor(colour: string) {
    switch (colour.trim()) {
        case '#75b12e':
            return StatusColours.darkRed
            break;
        case '#a71930':
            return StatusColours.orange
            break;
        case '#df7e1e':
            return StatusColours.greenGreen
            break;
        case '#6a94b0':
            return StatusColours.blueGrey
            break;
        default:
            return StatusColours.blueGrey
    }
}
