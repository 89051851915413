export interface Workspace {
    name: string;
    urlName: string;
    isOwnerOrCreator?: boolean;
    createdBy?: string;
    createdOn?: Date;
    groups?: number[];
    tags?: string[];
    envelopes?: number[];
    suppressions?: number[];
}

export interface CurrentWorkspace {
    name: string;
    groups: number[];
    tags: string[];
    envelopes: number[];
    suppressions: number[];
    creator: string;
}

export interface WorkspaceGroup {
    id: number;
    name: string;
    tags: string[];
    groups: number[];
    parentId?: number;
    workspace: string;
    expanded: boolean
}

export interface TagDetail {
    id: number;
    name: string,
    data_type: string,
    frequency: number,
    description: string,
    unit_of_measure: string,
    plant_name: string,
    fetched: Date
}

export interface TagCurrentValue {
    tag: string,
    value: string,
    time: Date,
    fetched: Date
}

export interface Envelope {
    id: number;
    tag: string;
    states: State[];
}


export interface Exceedance {
    id: string,
    tag: string,
    state: string,
    met: boolean,
    data_count?: number,
    met_data_count?: number,
    met_conditions: number[][],
    fetched: Date
}



export interface State {
    id: string,
    name: string,
    priority: number,
    description: string,
    short_description: string,
    colour: string,
    conditions: Condition[]
    displayName: string,
    met?: boolean;
}

export interface Condition {
    id: string,
    formula_type: string,
    low_threshold: number | undefined,
    high_threshold: number | undefined,
}

export interface Suppression {
    id: number,
    start_time?: number | null;
    end_time?: number;
    informant_tags: string;
    informant_tag_state: string;
    suppressed_tags: string[];
    comment?: string;
    created_by: string;
    created_date?: number;
    modified_by?: string;
    modified_date?: number;
}


export enum PeriodOptions { LastHour, Today, Yesterday, Last7Days, Custom }
export interface IWorkspaceTimePeriod {
    workspaceName: string;
    currentPeriod: PeriodOptions;
    periodStartTime: () => Date;
    periodEndTime: () => Date;
}

