import { Reducer } from 'redux';
import { AppThunkAction } from './index';
import * as ServiceClient from './service-client/index';

// STATE - This defines the type of data maintained in the Redux store.
export interface AddSuppressionState {
    isInProgress: boolean;
    errorMessage?: string;
    isSuccess?: boolean;
}

export enum SuppressionLength { RestOfToday = -1, RestOfShift = -2 }

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface ClearData { type: 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE_CLEAR_DATA'; }
export interface PutSuppressionRule { type: 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE'; }
export interface PutSuppressionRuleSuccess { type: 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE_PUT_SUPPRESSION_RULE_SUCCESS'; }
export interface PutSuppressionRuleError { type: 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE_PUT_SUPPRESSION_RULE_ERROR'; errorMessage: string; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = ClearData | PutSuppressionRule | PutSuppressionRuleSuccess | PutSuppressionRuleError;

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    clearData: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE_CLEAR_DATA' });
    },
    putSuppressionRule: (workspaceName: string, rule: ServiceClient.SuppressionRule): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE' });
        try {
            await ServiceClient.getServiceClient().putSuppressionRule(workspaceName, rule);
            dispatch({ type: 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE_PUT_SUPPRESSION_RULE_SUCCESS' });
        } catch (e) {
            dispatch( { type: 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE_PUT_SUPPRESSION_RULE_ERROR', errorMessage: e.toString() });
        }
    }
};

// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
export const reducer: Reducer<AddSuppressionState> = (state: AddSuppressionState, action: KnownAction) =>  {
    switch (action.type) {
        case 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE_CLEAR_DATA': {
            return { isInProgress: false };
        }
        case 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE': {
            return { ...state, isInProgress: true, errorMessage: undefined, isSuccess: undefined };
        }

        case 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE_PUT_SUPPRESSION_RULE_ERROR': {
            return { ...state, isInProgress: false, errorMessage: action.errorMessage };
        }

        case 'ADHOC_SUPPRESSION_PUT_SUPPRESSION_RULE_PUT_SUPPRESSION_RULE_SUCCESS': {
            return { ...state, isInProgress: false, errorMessage: undefined, isSuccess: true };
        }

        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || {  };
};