import { combineReducers } from 'redux'
import { removeItemFromArray } from '../reducerUtilities';
import { WorkspaceGroup } from './common-types'; 
import { GroupAddedAction } from '../actions/group' 
import { AddGroupToWorkspaceAction, RemoveGroupFromWorkspaceAction, AddTagToGroupAction, RemoveTagFromGroupAction } from './common-actions';
import { GroupExpandedAction, GroupsExpandedAction } from './view-workspace';

export interface WorkspaceGroupsState {
    byId: Record<number, WorkspaceGroup>;
    allIds: number[];
}

type RenameGroupAction = {type: 'RENAME_GROUP', payload: { workspace: string, groupId: number, newName:string }};
export type GetGroupsAction = {type: 'GET_GROUPS', payload: { groups: WorkspaceGroup[] }};
type AddGroupToGroupAction = {type: 'ADD_GROUP_TO_GROUP', payload: { groupId: number, groupToAdd: WorkspaceGroup  }};


export type GroupAction = GroupAddedAction | AddGroupToWorkspaceAction | RemoveGroupFromWorkspaceAction | AddTagToGroupAction | RemoveTagFromGroupAction | RenameGroupAction | GetGroupsAction | AddGroupToGroupAction | GroupExpandedAction | GroupsExpandedAction;



function getGroups(state: Record<number,WorkspaceGroup>, action: GetGroupsAction){
    const { payload} = action;
    const { groups } = payload;
    
    const newState = groups.reduce((map, g)=> {
            map[g.id] = g;
            return map;
        },<Record<number, WorkspaceGroup>>{}
    );
    return {
        ...state,
        ...newState
    };
}

function renameGroup(state: Record<number,WorkspaceGroup>, action: RenameGroupAction){
    const { payload} = action;
    const { groupId, newName } = payload;
    var wsGroup: WorkspaceGroup = state[groupId];
    return {
        ...state,
        [groupId]: {
            ...wsGroup,
            name: newName
        }
    }
}

function addGroup(state:Record<number,WorkspaceGroup>, action: AddGroupToWorkspaceAction ){
    const { payload} = action;
    const { group } = payload;
    return {
        ...state,
        [group.id] : {...group}
    }
}

function updateGroups(state:Record<number,WorkspaceGroup>, action: GroupAddedAction ){
    const { group } = action;
    //If the group has tags
    if(group.tags && group.tags.length > 0){
        //And the Tags Without Groups Group has tags.
        if(state[0] && state[0].tags && state[0].tags.length > 0) {
            //Remove Any Tags added in the added group from Tags Without Group Group
            state[0].tags = state[0].tags.filter((t:string) => group.tags.some(gt=> gt == t) == false); 
        }
    }
    return {
        ...state,
        [group.id]: {...group}
    } 
}

function removeGroup(state: Record<number,WorkspaceGroup>, action: RemoveGroupFromWorkspaceAction){
    const { payload} = action;
    const { group } = payload;
    if(group.id != 0)
    {
        //Get All the groups but the one getting removed.
        const groups = Object.values(state).filter(s=> s.id !== group.id);
        //Add the tags from the removed group to the 'Tags Without Group group'
        groups[0].tags = groups[0].tags.concat(group.tags);
        //Create a map from the Group Array
        const newState = groups.reduce((c,g)=> { 
            c[g.id] = g;
            return c;
        }, <Record<number,WorkspaceGroup>>{});
        //Return that as the new state.
        return newState;
    }
    //You cant remove the 'Tags Without Group group.
    return state;
}

function addTag(state: Record<number,WorkspaceGroup>, action: AddTagToGroupAction ): Record<number,WorkspaceGroup>{
    const { payload } = action;
    const { group, tag } = payload;
    const wsGroup = state[group];
    const newGroup = {...wsGroup};
    newGroup.tags = wsGroup.tags.concat(tag);
    return {
        ...state,
        [group]: newGroup
        }
}


function removeTag(state: Record<number,WorkspaceGroup>, action: RemoveTagFromGroupAction){
    const {payload } = action;
    const {group, tag } = payload;
    const wsGroup:WorkspaceGroup = state[group];
    if(wsGroup && wsGroup.tags && wsGroup.tags.length){
        return {
            ...state,
            [group]: {
                ...wsGroup,
                tags: removeItemFromArray(wsGroup.tags, tag)
            }
        }
    }
    return state;
}

function addGroupToGroup(state: Record<number,WorkspaceGroup>, action: AddGroupToGroupAction){
    const {payload } = action;
    const {groupId, groupToAdd } = payload;
    const wsGroup:WorkspaceGroup = state[groupId];
    return {
        ...state,
        [groupId]: {
            ...wsGroup,
            groups: wsGroup.groups.concat(groupToAdd.id)
        },
        [groupToAdd.id]: { ...groupToAdd}
    }
}

function groupExpanded(state: Record<number,WorkspaceGroup>, action: GroupExpandedAction){
    const { payload } = action;
    const { workspaceGroup } = payload;
    return {
        ...state,
        [workspaceGroup.id]: {
            ...workspaceGroup,
        }
    }
}

function groupsExpanded(state: Record<number,WorkspaceGroup>, action: GroupsExpandedAction){
    const { payload } = action;
    const { workspaceGroups } = payload;
    workspaceGroups.forEach(g=> {
        state[g.id] = g;
    });
    
    return {
        ...state,
    }
}


//WorkspaceGroup By Id Slice
function groupsById(state= <Record<number,WorkspaceGroup>>{}, action: GroupAction) {
    switch(action.type){
        case 'ADD_GROUP_TO_WORKSPACE':
            return addGroup(state,action);
        case 'ADD_GROUP_TO_GROUP':
            return addGroupToGroup(state,action);
        case 'ADD_TAG_TO_GROUP':
            return addTag(state,action);
        case 'GET_GROUPS':
            return getGroups(state,action);
        case 'GROUP_ADDED':
            return updateGroups(state, action);
        case 'REMOVE_GROUP_FROM_WORKSPACE':
            return removeGroup(state,action);
        case 'REMOVE_TAG_FROM_GROUP':
            return removeTag(state,action);
        case 'RENAME_GROUP':
            return renameGroup(state,action);
        case 'GROUP_EXPANDED':{
            return groupExpanded(state,action);
        }
        case 'GROUPS_EXPANDED':
            return groupsExpanded(state,action);
        default:
            return state;
    }
}


function addGroupId(state: number[], action: AddGroupToWorkspaceAction){
    const { payload } = action;
    const { group } = payload;
    if(state.some(s=> s == group.id)){
        return state;
    }
    else{
        return state.concat(group.id);
    }
}

function addGroupIds(state: number[], action: GetGroupsAction){
    const { payload } = action;
    const { groups } = payload;
    //Only Add ids that dont already exist.
    const toAdd = groups.filter(g=> !state.some(s=> s == g.id));
    return state.concat(toAdd.map(g=> g.id));
}

function removeGroupId(state: number[], action:RemoveGroupFromWorkspaceAction){
    const { payload } = action;
    const { group } = payload;
    return removeItemFromArray(state,group.id);
}

function allGroups(state: number[] = [], action:GroupAction){
    switch(action.type){
        case 'GET_GROUPS':
            return addGroupIds(state, action);
        case 'ADD_GROUP_TO_WORKSPACE':
            return addGroupId(state, action);
        case 'REMOVE_GROUP_FROM_WORKSPACE':
            return removeGroupId(state, action);
        default:
            return state;
    }
}

//Workspaces slice reducer
export const workspaceGroupsReducer = combineReducers({
    byId: groupsById,
    allIds: allGroups
});
