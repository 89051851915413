import { combineReducers } from 'redux' 
import {} from './common-actions'
//import { TagDetail } from './common-types'
import { TagDetail } from '../service-client'
import { removeItemFromArray } from '../reducerUtilities';
import { GetTagsAction, AddTagAction, RemoveTagAction ,TagAction } from '../actions/tag-detail'
export interface TagsState {
    byId: Record<string, TagDetail>;
    allIds: string[];
}


function getTags(state: Record<string, TagDetail>, action: GetTagsAction){
    const { payload } = action;
    const { tags } = payload; 
    const results = tags.reduce((map,tag)=> {
        map[tag.name] = tag;
        return map;
    },<Record<string,TagDetail>>{});
    
    return { ...state, ...results};
}

function addTag(state: Record<string,TagDetail>, action: AddTagAction){
    const { payload } = action;
    const { tag } = payload;
    return {
        ...state,
        [tag.name]: tag
    };
}

function removeTag(state: Record<string,TagDetail>, action: RemoveTagAction){
    const { payload } = action;
    const { tag } = payload;
    delete state[tag.name];
    return {
        ...state
    };
}

function tagDetailsById(state: Record<string,TagDetail> = <Record<string,TagDetail>>{}, action: TagAction){
    switch (action.type){
        case 'GET_TAG_DETAILS':
            return getTags(state, action);
        case 'ADD_TAG_DETAILS':
            return addTag(state,action);
        case 'REMOVE_TAG_DETAILS':
            return removeTag(state,action);
        default:
            return state;
    }
}

function addTagId(state:string[],action: AddTagAction){
    const { payload } = action;
    const { tag } = payload;
    return state.concat(tag.name);
    
}

function addTagIds(state:string[],action: GetTagsAction){
    const { payload } = action;
    const { tags } = payload;
    return state.concat(tags.map(t=> t.name));
}

function removeTagId(state:string[], action: RemoveTagAction){
    const { payload } = action;
    const { tag } = payload;
    return removeItemFromArray(state,tag.name);
}

function allTagDetails(state:string[] = [], action: TagAction){
    switch(action.type){
        case 'ADD_TAG_DETAILS':
            return addTagId(state,action);
        case 'GET_TAG_DETAILS':
            return addTagIds(state,action);
        case 'REMOVE_TAG_DETAILS':
            return removeTagId(state,action);
        default:
            return state;
    }
}

export const tagDetailsReducer =combineReducers({
    byId: tagDetailsById,
    allIds: allTagDetails
});