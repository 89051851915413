import { WorkspaceRole, AuthType } from './service-client/index';
import { Reducer } from 'redux';
import { AppThunkAction } from './index';
import * as ServiceClient from '../store/service-client';

export type SecureWorkspaceState = {
    workspaceRoles: WorkspaceRole[],
    isWorkspaceSecurityLoading: boolean,
    errorMessage?: string,
    reloadRoles?: boolean;
};
export interface LoadWorkspaceSecurity { type: 'LOAD_WORKSPACE_SECURITY'; }
export interface LoadWorkspaceSecuritySuccess { type: 'LOAD_WORKSPACE_SECURITY_SUCCESS'; roles: WorkspaceRole[]; }
export interface LoadWorkspaceSecurityError { type: 'LOAD_WORKSPACE_SECURITY_ERROR'; errorMessage: string; }
export interface AddWorkspaceUser { type: 'ADD_WORKSPACE_USER'; }
export interface AddWorkspaceUserSuccess { type: 'ADD_WORKSPACE_USER_SUCCESS'; }
export interface AddWorkspaceUserError { type: 'ADD_WORKSPACE_USER_ERROR'; errorMessage: string; }
export interface DeleteWorkspaceUser { type: 'DELETE_WORKSPACE_USER'; }
export interface DeleteWorkspaceUserSuccess { type: 'DELETE_WORKSPACE_USER_SUCCESS'; }
export interface DeleteWorkspaceUserError { type: 'DELETE_WORKSPACE_USER_ERROR'; errorMessage: string; }

type KnownAction = LoadWorkspaceSecurity | LoadWorkspaceSecuritySuccess | LoadWorkspaceSecurityError |
    AddWorkspaceUser | AddWorkspaceUserError | AddWorkspaceUserSuccess |
    DeleteWorkspaceUser | DeleteWorkspaceUserError | DeleteWorkspaceUserSuccess;

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    getWorkspaceRoles: (workspaceName: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch( { type: 'LOAD_WORKSPACE_SECURITY' });
        try {
            let roles = await ServiceClient.getServiceClient().getWorkspaceSecurity(workspaceName);
            dispatch({type: 'LOAD_WORKSPACE_SECURITY_SUCCESS', roles: roles});
        } catch (e) {
            dispatch({type: 'LOAD_WORKSPACE_SECURITY_ERROR', errorMessage: e.toString()});
        }
    },
    addWorkspaceUser: (workspaceName: string, principal: string, authType: string): AppThunkAction<KnownAction> =>
        async(dispatch, getState) => {
        dispatch({type: 'ADD_WORKSPACE_USER'});

        try {
            let auth: AuthType|undefined = undefined;
            switch ( authType.toUpperCase() ) {
                case 'OWNER': {
                    auth = AuthType.OWNER;
                    break;
                }
                case 'MEMBER': {
                    auth = AuthType.MEMBER;
                    break;
                }
                default: {
                    throw new Error(`Unknown auth type: ${authType}`);
                }
            }
            await ServiceClient.getServiceClient().addWorkspaceUser(workspaceName, principal, auth);
            dispatch({type: 'ADD_WORKSPACE_USER_SUCCESS'});
        } catch (e) {
            dispatch({type: 'ADD_WORKSPACE_USER_ERROR', errorMessage: e.toString()});
        }
    },
    deleteWorkspaceUser: (workspaceName: string, principal: string, authType: string): AppThunkAction<KnownAction> =>
        async(dispatch, getState) => {
        dispatch({type: 'DELETE_WORKSPACE_USER'});

        try {
            let auth: AuthType|undefined = undefined;
            switch ( authType.toUpperCase() ) {
                case 'OWNER': {
                    auth = AuthType.OWNER;
                    break;
                }
                case 'MEMBER': {
                    auth = AuthType.MEMBER;
                    break;
                }
                default: {
                    throw new Error(`Unknown auth type: ${authType}`);
                }
            }
            await ServiceClient.getServiceClient().deleteWorkspaceUser(workspaceName, principal, auth);
            dispatch({type: 'DELETE_WORKSPACE_USER_SUCCESS'});
        } catch (e) {
            dispatch({type: 'DELETE_WORKSPACE_USER_ERROR', errorMessage: e.toString()});
        }
    }
};

export const reducer: Reducer<SecureWorkspaceState> = (state: SecureWorkspaceState, action: KnownAction) =>  {
    switch (action.type) {
        case 'LOAD_WORKSPACE_SECURITY': {
            return { workspaceRoles: [], isWorkspaceSecurityLoading: true, errorMessage: undefined, reloadRoles: false};
        }
        case 'LOAD_WORKSPACE_SECURITY_SUCCESS': {
            return { workspaceRoles: action.roles, isWorkspaceSecurityLoading: false};
        }
        case 'LOAD_WORKSPACE_SECURITY_ERROR': {
            return { workspaceRoles: [], isWorkspaceSecurityLoading: false, errorMessage: action.errorMessage};
        }
        case 'ADD_WORKSPACE_USER': {
            return {...state, errorMessage: undefined, reloadRoles: false};
        }
        case 'ADD_WORKSPACE_USER_ERROR': {
            return {...state, errorMessage: action.errorMessage};
        }
        case 'ADD_WORKSPACE_USER_SUCCESS': {
            return {...state, reloadRoles: true};
        }
        case 'DELETE_WORKSPACE_USER': {
            return {...state, errorMessage: undefined, reloadRoles: false};
        }
        case 'DELETE_WORKSPACE_USER_ERROR': {
            return {...state, errorMessage: action.errorMessage};
        }
        case 'DELETE_WORKSPACE_USER_SUCCESS': {
            return {...state, reloadRoles: true};
        }
        default:
    }
    return {} as SecureWorkspaceState;
};