import * as React from 'react';
import { connect } from 'react-redux';
import {
    Panel, 
    PrimaryButton, 
    DefaultButton, 
    TextField, 
    ChoiceGroup, 
    IChoiceGroupOption, 
    ProgressIndicator, 
    Toggle, 
    PanelType, 
    Checkbox, 
    Label, 
    getId, Dropdown
} from 'office-ui-fabric-react';
import { endOfDay, addHours,  } from 'date-fns';
import { ApplicationState } from '../../store/index';
import * as AddSuppressionStore from '../../store/add-suppression';
import * as EditWorkspace from '../../store/edit-workspace';
import { AuthenticatedUser } from '../../auth/index';
import { MessageBox, MessageBoxType, MessageBoxSize, DateTimePicker } from '../common/index';
import { SuppressionRule, TagEnvelopeDefinition, InformantTag } from '../../store/service-client/index';
import TagPickerTwo from '../tag-picker/tag-picker-two';
import { TagPickerStyle } from '../tag-picker/tag-picker';
import { EnvelopeTagHandler, TagHandler } from '../tag-picker/tag-handlers';
import './add-suppression.css';
import { PrimaryColours } from '../common/colour-manager';


export type AddSuppressionProps = AddSuppressionStore.AddSuppressionState
    & typeof AddSuppressionStore.actionCreators & typeof EditWorkspace.actionCreators
    & {
        currentUser: AuthenticatedUser,
        tags: TagEnvelopeDefinition[],
        currentEnvelopes: TagEnvelopeDefinition[],
        workspaceName: string,
        onDismissed: any,
        enableTagPicker?: boolean,
        showStartDate?: boolean,
        showEndDatePickerOnly?: boolean,
        tagPickerStyle?: TagPickerStyle,
        allowInformantTag?: boolean,
        lockToSingleTag?: boolean,
        singleTagPlantName?: string,
        dataReady: boolean,
        detailsLoaded: boolean
    };

type AddSuppressionState = {
    isOpen: boolean,
    startTime?: Date,
    endTime?: Date,
    duration: AddSuppressionStore.SuppressionLength | number | Date,
    reason: string,
    tagHandler?: TagHandler<TagEnvelopeDefinition>,
    tagPickerStyle?: TagPickerStyle,
    suppressedTags: TagEnvelopeDefinition[],
    informantEnabled?: boolean,
    informantTagObj?: TagEnvelopeDefinition,
    informantTags: InformantTag[],
    informantTag?: string,
    informantState?: string
};

class AddSuppression extends React.Component<AddSuppressionProps, AddSuppressionState> {
    constructor(props: AddSuppressionProps) {
        super(props);
        
        let now = new Date();
        if (this.props.showStartDate) {
            now.setHours(now.getHours(), 0, 0, 0);
        }

        let durationOption: number | Date = AddSuppressionStore.SuppressionLength.RestOfToday;
        if (this.props.showEndDatePickerOnly) {
            durationOption = new Date();
        }

        this.state = { 
            isOpen: true,
            duration: durationOption,
            reason: '',
            tagPickerStyle: this.props.tagPickerStyle ? this.props.tagPickerStyle : 
                (this.props.tags && this.props.tags.length > 1 ? TagPickerStyle.MULTI_TAG : TagPickerStyle.SINGLE_TAG), 
            suppressedTags: this.props.tags,
            startTime: now,
            endTime: now,
            informantTags: []
        };
    }

    getSelectedKey = (duration: (AddSuppressionStore.SuppressionLength | number | Date)) =>
        (duration instanceof Date ? 'custom' : duration.toString());

    onEndDurationGroupChange = (ev: any, option: IChoiceGroupOption) => {
        if (option.key !== 'custom' && option.key !==  AddSuppressionStore.SuppressionLength.RestOfToday.valueOf().toString()) {
            this.setState({ endTime: addHours(this.state.startTime!, parseInt(option.key, 0))});
        }
        this.setState({ duration: option.key === 'custom' ? new Date() : parseInt(option.key, 0) });
    };

    onSelectStartDate = (date: Date) => {
        this.setState({ startTime: date});
    }

    onSelectEndDate = (date: Date) => {
        this.setState({ endTime: date});
    }

    onSubmit = () => {
        let startTime: number|undefined = this.props.showStartDate ? this.state.startTime!.getTime() : new Date().getTime();
        let endTime = this.state.endTime!.getTime();

        if (this.state.duration === AddSuppressionStore.SuppressionLength.RestOfToday) {
            endTime = endOfDay(this.state.startTime!).getTime();
        }

        const suppressionRule: SuppressionRule = {
            start_time: startTime,
            end_time: endTime,
            suppressed_tags: this.state.suppressedTags
                                .filter(t => t.tag !== '')
                                .map(s => s.tag),
            comment: this.state.reason,
            created_by: this.props.currentUser.email,
            modified_by: this.props.currentUser.email
        };
        if ( this.state.informantEnabled && this.state.informantTag ) {
            suppressionRule.informant_tag = { tag: this.state.informantTag, state: this.state.informantState };
            suppressionRule.start_time = undefined;
            suppressionRule.end_time = Date.parse('2999-12-31');
        }
        this.props.putSuppressionRule(this.props.workspaceName, suppressionRule);
    };

    onDismissed = () => { this.props.clearData(); this.props.onDismissed(); };

    componentDidMount() {
        if (!(this.props.currentEnvelopes && this.props.currentEnvelopes.length > 0)) {
            this.props.loadAllEnvelopes(this.props.currentUser,this.props.workspaceName);
        }
    }

    componentDidUpdate(prev:AddSuppressionProps){
        if(prev.isInProgress && !this.props.isInProgress && this.props.isSuccess)
        {
            this.onDismissed();            
        }
    }
    componentWillUnmount() {
        this.props.clearData();
        this.props.clearEnvelopes();
    }


    static getDerivedStateFromProps(props: AddSuppressionProps, state: AddSuppressionState): AddSuppressionState {
        if (props.dataReady && props.currentEnvelopes && props.currentEnvelopes.length > 0 && !state.tagHandler) {
            props.loadTagDetails(props.currentEnvelopes);
            return { ...state };
        } else if (props.detailsLoaded && !state.tagHandler) {
            return { ...state, tagHandler: new EnvelopeTagHandler(props.currentEnvelopes!) };
        }
        return { ...state };
    }

    render() {
        const labelStyle = {
            fontWeight: 800,
            color: PrimaryColours.darkGrey,
            marginBottom: '0.4em',
            marginTop: '1em'
        };
        const errorMessage = this.props.errorMessage
            ? (<MessageBox type={MessageBoxType.Error} size={MessageBoxSize.Baby}>{this.props.errorMessage}</MessageBox>)
            : undefined;

        const successMessage = ( 
                <MessageBox type={MessageBoxType.Success} size={MessageBoxSize.Baby}>
                    Successfully added Suppression.
                    <div ><DefaultButton onClick={this.onDismissed}>Close</DefaultButton></div>
        </MessageBox>);

        const startDatePickerOnly = (
            this.state.informantEnabled ? (<br/>) : (
            <div>
                <div style={labelStyle}>Start Time</div>
                <div style={{ display: 'inline-block', lineHeight: '32px', marginLeft: '10px' }}>
                    <DateTimePicker 
                        onSelectDate={this.onSelectStartDate}
                        //onSelectTime={this.onSelectStartHour}
                        isRequired
                    />
                </div>
            </div>
            )
        );

        const endDatePickerOnly = (
            this.state.informantEnabled ? (<br/>) : (
            <div>
                <div style={labelStyle}>End Time</div>
                <div style={{ display: 'inline-block', lineHeight: '32px', marginLeft: '10px' }}>
                    <DateTimePicker 
                        onSelectDate={this.onSelectEndDate}
                        //onSelectTime={this.onSelectEndHour}
                        minDate={this.state.startTime}
                        isRequired
                    />
                </div>
                <div>
                    {this.state.startTime && this.state.endTime && this.state.startTime > this.state.endTime ? (<label style={{fontSize: '9pt', color:'red'}}>End Date must be after Start Date.</label>) : undefined }
                </div>
            </div>)
        );

        const radioDateOptions: IChoiceGroupOption[] = [
            { key: AddSuppressionStore.SuppressionLength.RestOfToday.valueOf().toString(), text: 'for the rest of today' },
            { key: '1', text: 'for 1 hour' },
            { key: '8', text: 'for 8 hours' },
            { key: '24', text: 'for 24 hours' },
            { key: '168', text: 'for 7 days' },
            {
                
                key: 'custom', text: 'dates I choose', onRenderField: (props: IChoiceGroupOption, render: any) => {
                    return (
                        <div>
                            {render!(props)}
                            <div style={{ display: props.checked ? 'block' : 'none', lineHeight: '32px', marginLeft: '2em', marginTop: '0.5em' }}>
                                <DateTimePicker 
                                    onSelectDate={this.onSelectEndDate}
                                    //onSelectTime={this.onSelectEndHour}
                                    disabled={!props!.checked}
                                />
                            </div>
                        </div>
                    );
                }
            }
        ];

        const radioRef = getId('radioDate');

        const endDateRadioAndDate = (
            <React.Fragment>
                <Label htmlFor={radioRef}>Duration</Label>
                <ChoiceGroup
                    styles={{ root: { marginLeft: '1em', marginBottom: '1em' } }}
                    id={radioRef}
                    selectedKey={this.getSelectedKey(this.state.duration)}
                    onChange={this.onEndDurationGroupChange}
                    options={this.props.allowInformantTag && this.state.informantEnabled === true ? 
                            radioDateOptions.concat([{key: '0', text: 'until further notice'} ])
                        : radioDateOptions}
                />
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <Panel
                    className='ms-Panel-main-custom-large'
                    isOpen={this.state.isOpen}
                    isLightDismiss={true}
                    headerText="Add Suppression"
                    type={PanelType.custom}
                    onRenderFooterContent={() => {
                        return this.props.isSuccess ?  successMessage  : 
                        (
                        <div>
                            <PrimaryButton
                                disabled={!this.state.startTime || !this.state.endTime || !this.state.reason || this.state.suppressedTags.length <= 0 || this.props.isInProgress || this.state.startTime > this.state.endTime}
                                text="Add Suppression"
                                style={{ marginRight: '8px' }}
                                onClick={() => this.onSubmit()}
                            />
                            <DefaultButton
                                text="Cancel"
                                onClick={() => this.setState({ isOpen: false })}
                            />
                            {this.props.isInProgress ? <ProgressIndicator /> : undefined}
                            
                        </div>
                    )}}
                    onDismissed={this.onDismissed}
                >
                    {errorMessage}
                    <div>
                        { this.state.tagPickerStyle === TagPickerStyle.MULTI_TAG ? undefined : (
                            <Toggle
                                inlineLabel={true}
                                label="Allow selection of multiple tags at once"
                                onText="On"
                                offText="Off"
                                onChange={(e: any, f: boolean) => this.setState({
                                    tagPickerStyle: f ? TagPickerStyle.MULTI_TAG :
                                        TagPickerStyle.SINGLE_TAG
                                })}
                            />
                        )}
                        
                        <TagPickerTwo
                            tagsAvailableForSelection={this.props.currentEnvelopes ? this.props.currentEnvelopes : []}
                            inputDisabled={!(this.props.enableTagPicker === true)}
                            selectedTags={this.state.suppressedTags}
                            pickerStyle= {TagPickerStyle.SINGLE_TAG} //{this.state.tagPickerStyle ? this.state.tagPickerStyle : TagPickerStyle.SINGLE_TAG}
                            onSelectionUpdated={(selection: TagEnvelopeDefinition[]) => this.setState({suppressedTags: selection})}
                            key='main'
                        />
                        <div style={{ marginBottom: '2em' }} />
                    </div>

                    {this.props.allowInformantTag ? this.specifyInformantTag() : undefined}
                
                    <div>
                        {this.props.showStartDate ? startDatePickerOnly : undefined}
                        {this.props.showEndDatePickerOnly ? endDatePickerOnly : endDateRadioAndDate}
                    </div>
                    <div style={{marginTop:'20px'}}>
                        <TextField
                            label="Suppression Creator" 
                            value={this.props.currentUser.fullnameAndTitle}
                            readOnly={true}
                        />
                    </div>
                    <div style={{marginTop:'20px'}}>
                        <TextField
                            required
                            label="Suppression Reason"
                            value={this.state.reason}
                            multiline={true}
                            onChange={(_, v) => this.setState({ reason: v ? v : '' })}
                        />
                    </div>
                </Panel>
            </React.Fragment>
        );

    }

    showStatePicker(): JSX.Element {
        let stateOptions = this.state.informantTagObj && this.state.informantTagObj.states ? this.state.informantTagObj.states.map((s) => ({key: s.name, text: s.name + ' ' + s.displayRange ,value:JSON.stringify(s) })) : []
        return (
            <React.Fragment>
                 <div style={{flex:4, flexFlow:'row', display:'flex', marginTop:'10px'}}>
                    <div style={{flex:1, display:'flex'}}>
                        <Label style={{ fontWeight: 800, flex: 1}}>State</Label>
                    </div>
                    <div style={{flex:19, display:'flex'}}>
                        <Dropdown
                            placeholder="select the state"
                            options={
                                stateOptions
                            }
                            onChange={(e, o, i) => this.setState({ informantState: String(o!.key) })}
                            style={{
                                width: '400px',
                                backgroundColor: 'white',
                                border: '1px solid #6a94b0',
                            }}
                            onBlur={(_) => { return; }}
                            />
                    </div>
                </div>
            </React.Fragment>
        )
    }

    specifyInformantTag(): JSX.Element {
        return (
            <div>
                <div>
                    <Checkbox
                        style={{flex: 1}}
                        label="Specify an informant tag"
                        onChange={(_, v: any) => 
                            {
                                if(!v)
                                {
                                    this.setState({informantEnabled: v, informantTag: undefined, informantState: undefined, informantTagObj: undefined, endTime: new Date('2999/12/31') })    
                                }
                                else{
                                    this.setState({informantEnabled: v, endTime: this.state.startTime })
                                }
                            }
                        }
                    />
                </div>
                { this.state.informantEnabled ? (<div style={{boxShadow: '0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)', padding: '15px', marginTop:'20px', marginBottom:'20px'}}>
                    <div style={{marginBottom: '1em', display: 'flex', justifyContent: 'space-between'}}>
                        <TagPickerTwo
                            lockToSingleTag
                            tagsAvailableForSelection={this.props.currentEnvelopes ? this.props.currentEnvelopes.filter((e)=> !this.state.suppressedTags.some((t)=> t.tag == e.tag)) : []}
                            inputDisabled={!(this.props.enableTagPicker === true)}
                            selectedTags={this.state.informantTag && this.props.currentEnvelopes ? this.props.currentEnvelopes.filter((t)=> t.tag == this.state.informantTag) : []}
                            pickerStyle={TagPickerStyle.SINGLE_TAG}
                            onSelectionUpdated={(selection: TagEnvelopeDefinition[]) => {
                                // TODO: currently only supporting a single informant tag. in future, support multiple
                                if ( selection && selection.length === 1 ) {
                                    this.setState({informantTag: selection[0].tag, informantTagObj: this.props.currentEnvelopes!.find((t)=> t.tag === selection[0].tag )});
                                }
                                else{
                                    this.setState({informantTag: undefined, informantState: undefined, informantTagObj: undefined})
                                }
                            }}
                            key='informant'
                        />
                    </div>
                    {
                        (this.state.informantTag && this.state.informantEnabled) ? this.showStatePicker() : undefined
                    }
                </div>): <br/>}
            </div>
        );
    }
}

const mapSuppressionStateToProps = (state: ApplicationState) => { 
    const { addSuppression, editWorkspace, viewWorkspace } = state;
    const suppressionState = { 
        isInProgress: addSuppression.isInProgress, 
        errorMessage: addSuppression.errorMessage,
        isSuccess: addSuppression.isSuccess,
        currentEnvelopes: editWorkspace.currentEnvelopes || [],
        dataReady: editWorkspace.dataReady || false,
        detailsLoaded: editWorkspace.detailsLoaded || false,
        currentUser: viewWorkspace.user || editWorkspace.user
    };
    return suppressionState;
};

const mapDispatchToProps =  {
        clearData: AddSuppressionStore.actionCreators.clearData,
        putSuppressionRule: AddSuppressionStore.actionCreators.putSuppressionRule,
        loadAllEnvelopes: EditWorkspace.actionCreators.loadAllEnvelopes,
        loadTagDetails: EditWorkspace.actionCreators.loadTagDetails,
        clearEnvelopes: EditWorkspace.actionCreators.clearEnvelopes,
        deleteWorkspace: EditWorkspace.actionCreators.deleteWorkspace,
        renameWorkspace: EditWorkspace.actionCreators.renameWorkspace,
        addGroup: EditWorkspace.actionCreators.addGroup
}

export default connect(mapSuppressionStateToProps, mapDispatchToProps)(AddSuppression);
