import { EditEnvelopeStateProps } from '../store/edit-envelope';
import { TagEnvelopeState,  StateCondition } from '../store/service-client';

export enum EnvelopeStateConditions { Above, Below, InBetween, Outside, EqualTo, FlatLine }

export function ConditionsToDisplayRange(conditions?: StateCondition[]) {
    if (!conditions || conditions.length == 0) {
        return '';
    }
    let friendlyConditions = EnvelopeConditionsToTriggerAndValues(conditions);
    switch (friendlyConditions.triggerCondition) {
        case EnvelopeStateConditions.Above: {
            return `> ${friendlyConditions.value1}`;
        }
        case EnvelopeStateConditions.Below: {
            return `< ${friendlyConditions.value1}`;
        }
        case EnvelopeStateConditions.EqualTo: {
            return `= ${friendlyConditions.value1}`;
        }
        case EnvelopeStateConditions.InBetween: {
            return `${friendlyConditions.value1} : ${friendlyConditions.value2}`;
        }
        case EnvelopeStateConditions.Outside: {
            return `[ ${friendlyConditions.value1} : ${friendlyConditions.value2} ]`;
        }
        case EnvelopeStateConditions.FlatLine: {
            return `flatline`;
        }
        default: {
            return '';
        }

    }
}

export function TriggerConditionToString(triggerCondition: EnvelopeStateConditions): string{
    let tCondition: string = ""
    switch(triggerCondition){
        case EnvelopeStateConditions.Above:
            tCondition = "Above";
        break;
        case EnvelopeStateConditions.Below:
            tCondition = "Below";
            break;
        case EnvelopeStateConditions.EqualTo:
            tCondition ="Equal"
            break;
        case EnvelopeStateConditions.FlatLine:
            tCondition = "Flat"
            break;
        case EnvelopeStateConditions.InBetween:
            tCondition = "Inbetween"
            break;
        case EnvelopeStateConditions.Outside:
            tCondition = "Outside";
            break;
        default:
            tCondition = "Outside";
    }
    return tCondition;
}


export function EnvelopeStateToDisplayProps(
    s: TagEnvelopeState,
): EditEnvelopeStateProps {
    let { triggerCondition, value1, value2 } = EnvelopeConditionsToTriggerAndValues(s.conditions);
    return {
        _id: new Date().getTime() + (Math.random() * 10),
        name: s.name,
        colour: s.colour,
        description: s.description,
        short_description: s.short_description,
        triggerCondition, value1, value2
    } as EditEnvelopeStateProps;
}



export function EnvelopeConditionsToTriggerAndValues(conditions: StateCondition[]) {
    let triggerCondition: EnvelopeStateConditions | undefined;
    let value1: number | undefined;
    let value2: number | undefined;
    if (conditions.length === 1 && conditions.find(c => c.formula_type === 'max')) {
        triggerCondition = EnvelopeStateConditions.Above;
        value1 = conditions.find(c => c.formula_type === 'max')!.low_threshold!;
        value2 = undefined;
    }
    if (conditions.length === 1 && conditions.find(c => c.formula_type === 'min')) {
        triggerCondition = EnvelopeStateConditions.Below;
        value1 = conditions.find(c => c.formula_type === 'min')!.high_threshold!;
        value2 = undefined;
    }
    if (conditions.length === 1
        && conditions.find(c => c.formula_type === 'equal' && c.low_threshold === 0 && c.high_threshold === 1
            && c.formula_config !== undefined && c.formula_config.variant !== undefined)) {
        triggerCondition = EnvelopeStateConditions.EqualTo;
        value1 = conditions
            .find(c => c.formula_type === 'equal' && c.low_threshold === 0 && c.high_threshold === 1 && c.formula_config !== undefined)!
            .formula_config!.variant;
        value2 = undefined;
    }
    if (conditions.length === 1
        && conditions.find(c => c.formula_type === 'raw' && c.high_threshold !== undefined && c.low_threshold !== undefined)) {
        triggerCondition = EnvelopeStateConditions.InBetween;
        value1 = conditions.find(c => c.formula_type === 'raw'
            && c.high_threshold !== undefined && c.low_threshold !== undefined)!.low_threshold!;
        value2 = conditions.find(c => c.formula_type === 'raw'
            && c.high_threshold !== undefined && c.low_threshold !== undefined)!.high_threshold!;
    }
    if (conditions.length === 1 && conditions.find(c => c.formula_type === 'stdev' && c.high_threshold === 0)) {
        triggerCondition = EnvelopeStateConditions.FlatLine;
    }
    if (conditions.length === 2
        && conditions.find(c => c.formula_type === 'min') && conditions.find(c => c.formula_type === 'max')) {
        if ((conditions[0].formula_type === 'min' && conditions[0].high_threshold !== undefined)
            && conditions[1].formula_type === 'max' && conditions[1].low_threshold !== undefined) {
            triggerCondition = EnvelopeStateConditions.Outside;
            value1 = conditions[0].high_threshold;
            value2 = conditions[1].low_threshold;
        }
        if ((conditions[0].formula_type === 'max' && conditions[0].low_threshold !== undefined)
            && conditions[1].formula_type === 'min' && conditions[1].high_threshold != undefined) {
            triggerCondition = EnvelopeStateConditions.InBetween;
            value1 = conditions[0].low_threshold;
            value2 = conditions[1].high_threshold;
        }
    }

    return { triggerCondition, value1, value2 };

}

export function MapConstraintsValuesToString(states: TagEnvelopeState[]){
    var results = states.map(s=> s.conditions.map(c=> [c.low_threshold, c.high_threshold]));
    var values = results.reduce((p, c) => p.concat(c)).reduce((p,c)=> p.concat(c)).filter(v=> v);
    return values;
}