import * as React from 'react';
import { WorkspaceRole, RoleType, AuthType } from '../../store/service-client/index';
import { Shimmer, ShimmerElementType, IPersonaProps, ActionButton, TooltipHost, Label } from 'office-ui-fabric-react';
import {  AppearanceManager } from '../common/index';
import { PrimaryColours } from '../common/colour-manager';
import { RolePersona } from './RolePersona';
import { AuthenticatedUser } from '../../auth/index';
import { InputDialog } from '../common/input-dialog';
import { Confirmation, ConfirmationType } from '../common/confirmation';

type WorkspaceSecurityViewProps = {
    roles: WorkspaceRole[];
    isLoading: boolean;
    workspaceName: string;
    currentUser: AuthenticatedUser;
    deleteUserCallback?: any;
    addUserCallback?: any;
};

type WorkspaceSecurityState = {
    showInputDialog?: boolean,
    showDeleteConfirmation?: boolean,
    authType?: string,
    principal?: string
};

export class WorkspaceSecurityView extends React.Component<WorkspaceSecurityViewProps, WorkspaceSecurityState> {
    constructor(props: any) {
        super(props);
        this.state = { };
    }

    readonly OWNER = 'OWNER';
    readonly MEMBER = 'MEMBER';

    convertWorkspaceRoleToPersona(role: WorkspaceRole): IPersonaProps {
        let initials = '?';
        try {
            initials = role.principal
                .substring(0, role.principal.indexOf('@'))
                .split('.')
                .map( t => t.substring(0, 1))
                .join('')
                .toUpperCase();
        } catch (e) {
            // 
        }
        return { 
            imageInitials: initials,
            text: role.principal,
            secondaryText: role.role_type === RoleType.GROUP ? 'Group' : 'User',
            tertiaryText: role.auth_type === AuthType.CREATOR ? 'Creator' :
                            (role.auth_type === AuthType.MEMBER ? 'Member' : 'Owner')
        } as IPersonaProps;
    }

    renderPersona(persona?: IPersonaProps, hideDelete?: boolean): JSX.Element {
        return this.props.isLoading || !persona ? (
            <Shimmer 
                width="100%"
                shimmerElements={[
                    { type: ShimmerElementType.circle, width: 50, height: 50},
                    { type: ShimmerElementType.gap, width: '10px'},
                    { type: ShimmerElementType.line, width: '250px', height: 50}
                ]}
            />
        ) : (
            <RolePersona 
                key={`user-${persona.tertiaryText}-${persona.text}`}
                persona={persona}
                hideDelete={hideDelete}
                onDelete={persona.secondaryText!.toUpperCase() === 'GROUP' ? undefined :
                    (email: string, authType: string) => this.setState({principal: email, authType: authType!, showDeleteConfirmation: true})}
            />
        );
    }

    fieldsetStyle = {
        flex: 1,
        border: 'solid 1px ' + PrimaryColours.darkGrey 
    };

    isAllowedToAddRoles() {
        return this.props.roles 
            && this.props.roles.find(role => this.props.currentUser 
            && (role.auth_type === AuthType.CREATOR || role.auth_type === AuthType.OWNER) 
            && role.principal.toUpperCase() === this.props.currentUser.email.toUpperCase()
        ) !== undefined;
    }

    addUser(user: string) {
        if ( this.props.addUserCallback ) {
            this.props.addUserCallback(this.props.workspaceName, user, this.state.authType);
        }
    }

    deleteUser(user: string, authType: string) {
        if ( this.props.deleteUserCallback ) {
            this.props.deleteUserCallback(this.props.workspaceName, user, authType);
        }
    }
    render() {
        let creator;
        let loadingText = 'Loading...';
        try {
            creator = this.renderPersona(
                this.convertWorkspaceRoleToPersona(this.props.roles.find(role => role.auth_type === AuthType.CREATOR)!),
                true);
        } catch (e) {
            creator = loadingText;
        }

        return (
            <React.Fragment>
                <InputDialog
                    inputLabel="Email address"
                    dialogTitle={`Add ${this.state.authType}`}
                    width={450}
                    show={this.state.showInputDialog === true}
                    onDismiss={(v: string) => {
                        if ( v ) {
                            this.addUser(v);
                        }
                        this.setState({showInputDialog: false});
                    }}
                />
                <Confirmation
                    title="Delete user?"
                    type={ConfirmationType.DELETE}
                    show={this.state.showDeleteConfirmation}
                    onConfirm={() => {
                        this.deleteUser(this.state.principal!, this.state.authType!);
                        this.setState({showDeleteConfirmation: false});
                    }}
                    onCancel={() => this.setState({showDeleteConfirmation: false})}
                >
                    Are you sure you want to delete {this.state.authType} 
                    <p style={{fontWeight: 800}}>
                        {this.state.principal}?
                    </p>
                </Confirmation>

                <div style={{display: 'flex', flexFlow:'row', marginTop: '40px'}}>
                    <Label style={{ fontWeight: 'bold',
                                    fontSize: '16px',
                                    lineHeight: '24px',
                                    marginRight:'20px',
                                    display: 'FLEX' 
                    }}>Creator</Label>
                    {creator === loadingText ? (
                        <Shimmer width="250px" style={{display: 'flex'}}/>
                    ) : (
                        <div style={{ display: 'flex' }}>{creator}</div>
                        )
                    }
                    </div>

                <div style={{display: 'flex'}}>
                    <fieldset style={this.fieldsetStyle}>
                        <legend style={{...AppearanceManager.FormLabelStyles, paddingRight: "5px", paddingLeft: "5px" , width: undefined}}>Owners</legend>
                        {this.props.isLoading ? this.renderPersona() : undefined}
                        { (!this.props.roles) ? undefined :
                         this.props.roles.filter( r => r.auth_type === AuthType.OWNER)
                                         .map( r => this.convertWorkspaceRoleToPersona(r) )
                                         .map( r => this.renderPersona(r, !this.isAllowedToAddRoles()))}
                        {this.isAllowedToAddRoles() ? (
                            <TooltipHost tooltipProps={{ onRenderContent: () => this.ownerHelp}}>
                                <ActionButton
                                    iconProps={{iconName: 'Add'}}
                                    onClick={() => this.setState({showInputDialog: true, authType: this.OWNER})}
                                />
                            </TooltipHost>
                        ) : undefined }
                        <p style={{ fontSize: '12px' }}>{this.ownerHelp}</p>
                    </fieldset>

                    <div style={{flex: 0.1}}/>

                    <fieldset style={this.fieldsetStyle}>
                    <legend style={{...AppearanceManager.FormLabelStyles, paddingRight: "5px", paddingLeft: "5px", width: undefined}}>Members</legend>
                        {this.props.isLoading ? this.renderPersona() : undefined}
                        { (!this.props.roles) ? undefined :
                         this.props.roles.filter( r => r.auth_type === AuthType.MEMBER)
                                         .map(r => this.convertWorkspaceRoleToPersona(r))
                                         .map( r => this.renderPersona(r,!this.isAllowedToAddRoles()))}
                        {this.isAllowedToAddRoles() ? (
                            <TooltipHost tooltipProps={{ onRenderContent: () => this.memberHelp}}>
                                <ActionButton 
                                    iconProps={{iconName: 'Add'}}
                                    onClick={() => this.setState({showInputDialog: true, authType: this.MEMBER})}
                                />
                            </TooltipHost>
                        ) :  undefined }
                        <p style={{ fontSize: '12px' }}>{this.memberHelp}</p>
                    </fieldset>
                </div>
            </React.Fragment>
        );
    }

    ownerHelp = (
        <React.Fragment>
            <p>
                Owners can grant other users permission to be an owner, or to be a member. They can also revoke
                these permissions from a user.
            </p>
            <p>
                Owner permission is a superset of member permission
            </p>
        </React.Fragment>
    );

    memberHelp = (
        <React.Fragment>
        <p>
            Members can add, modify and remove envelopes from a workspace.
        </p>
        <p>
            Members can not add or remove users from a workspace
        </p>
    </React.Fragment>
    );
}