import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { ExceedancesTableFilter, ExceedancesTableFilterOptions, PeriodOptions } from './exceedances-table-filter';
import { ApplicationState } from '../../store';
import * as ViewWorkspaceStore from '../../store/view-workspace';
import { connect } from 'react-redux';
import { Breadcrumbs, ColourManager } from '../common/index';
import ManageSuppressionList from './manage-suppression-list';
import { distanceInWordsToNow, format } from 'date-fns';
import { AuthenticatedUser } from '../../auth/index';
import { TagEnvelopeDefinition, TagEnvelopeState, DisplayWorkspaceGroup, STATE_NO_DATA } from '../../store/service-client';
import ExceedancesTable from './exceedances-table';
import { userWorkspacesSelector } from '../../store/selectors/user-workspace-selector';
import { workspaceGroupSelector } from '../../store/selectors/workspace-group-selector';
import { DisplayExceedanceType } from '../../store/view-workspace';
import { actionCreators } from '../../store/action-creators/view-workspace-action-creator'
import { WorkspaceGroup } from '../../store/service-client'
import { isNullOrUndefined } from 'util';
import { EnvelopeConditionsToTriggerAndValues, EnvelopeStateConditions } from '../../utils/condition-converters';

type ShowState = 'All' | 'AllAndSuppressed' | 'Exceedence' | 'ExceedenceAndSuppressed' | 'Suppressed'


export type ViewWorkspaceProps = ViewWorkspaceStore.ViewWorkspaceState
    & {
        currentUser: AuthenticatedUser,
        workspaceGroups?: WorkspaceGroup[]
    }
    & typeof actionCreators
    & RouteComponentProps<{ url: string, workspaceName: string }>;

type ViewWorkspaceState = ExceedancesTableFilterOptions & {
    emptyWorkspace?: boolean,
    isManageSuppressionOpen?: boolean,
    lastUpdatedText?: string,
    updateLastUpdateIntervalHandle?: any,
    currentEnvelopes?: TagEnvelopeDefinition[],
    workspaceGroups?: DisplayWorkspaceGroup[],
    showGroups: boolean,
    showState: ShowState,
    exceedanceDisplayType: DisplayExceedanceType
};


export class ViewWorkspace extends React.Component<ViewWorkspaceProps, ViewWorkspaceState> {
    constructor(props: any) {
        super(props);
        const defaultTimePeriod = PeriodOptions.LastHour;
        const { startTime, endTime } = ViewWorkspaceStore.getStartEndTime(defaultTimePeriod);
        this.state = {
            currentPeriod: PeriodOptions.LastHour,
            periodStartTime: startTime,
            periodEndTime: endTime,
            isManageSuppressionOpen: false,
            lastUpdatedText: undefined,
            exceedanceDisplayType: 'EXCEEDANCE',
            sortColumn: undefined,
            sortDescending: false,
            workspaceGroups: [],
            showGroups: true,
            showState: 'Exceedence',
            filter: ''
        }
    }

    componentDidMount() {
        this.props.initialLoad(this.props.currentUser, this.props.match.params.workspaceName);
    }

    componentDidUpdate(prevProps: ViewWorkspaceProps, prevState: ViewWorkspaceState) {
        if (!isNullOrUndefined(this.props.workspaceTimePeriod)
            && prevState.currentPeriod !== this.props.workspaceTimePeriod.currentPeriod) {

            const defaultTimePeriod = PeriodOptions.LastHour;
            const { startTime, endTime } = ViewWorkspaceStore.getStartEndTime(defaultTimePeriod);
            const currentPeriod = isNullOrUndefined(this.props.workspaceTimePeriod) ? PeriodOptions.LastHour : this.props.workspaceTimePeriod.currentPeriod;
            const periodStartTime = isNullOrUndefined(this.props.workspaceTimePeriod) ? startTime : this.props.workspaceTimePeriod.periodStartTime;
            const periodEndTime = isNullOrUndefined(this.props.workspaceTimePeriod) ? endTime : this.props.workspaceTimePeriod.periodEndTime;

            this.setState({
                currentPeriod: currentPeriod,
                periodStartTime: periodStartTime,
                periodEndTime: periodEndTime
            });
        }
    }

    componentWillUnmount() {
        this.clearUpdateLastUpdateIntervalHandle();
        //this.props.clearData();
    }

    onToggleDisplayInGroups = () => {
        this.setState({ showGroups: !this.state.showGroups });
    }

    onFilterChange = (options: ExceedancesTableFilterOptions) => {
        this.setState({
            currentPeriod: options.currentPeriod,
            periodStartTime: options.periodStartTime,
            periodEndTime: options.periodEndTime,
            filter: options.filter,
            sortColumn: options.sortColumn,
            sortDescending: options.sortDescending
        })
    }

    onShowAllTagsChange = (displayType: ViewWorkspaceStore.DisplayExceedanceType) => {
        this.setState({ exceedanceDisplayType: displayType });
    }
    onSortByColumn = (column: string, sortDescending: boolean) => {
        this.setState({
            sortColumn: column,
            sortDescending: sortDescending
        }, () => {
            //this.onFilterChange(newstate);
            this.props.filterExceedances(this.props.currentExceedances, this.state.filter,
                this.props.suppressionRules, this.state.exceedanceDisplayType, this.state.sortColumn, this.state.sortDescending);
        });
    }

    onPeriodChange = (currentPeriod: PeriodOptions, customStartTime: Date, customEndTime: Date) => {
        let { startTime, endTime } = ViewWorkspaceStore.getStartEndTime(currentPeriod);
        if (currentPeriod === PeriodOptions.Custom) {
            startTime = () => ViewWorkspaceStore.roundDateTimeDownToTheHour(customStartTime);
            endTime = () => ViewWorkspaceStore.roundDateTimeDownToTheHour(customEndTime);
        }
        const newstate = {
            ...this.state,
            currentPeriod: currentPeriod,
            periodStartTime: startTime,
            periodEndTime: endTime,
        };
        this.setState(
            newstate,
            () => { this.props.reEvaluate(this.state.currentPeriod, this.state.periodStartTime, this.state.periodEndTime, this.state.filter); }
        );
    }

    calculateTimeSinceUpdate = () => {
        this.setState({
            lastUpdatedText:
                this.props.dataLoadedAt
                    ? `Last refreshed
                    ${distanceInWordsToNow(this.props.dataLoadedAt, { addSuffix: true })}
                    (${format(this.props.dataLoadedAt, 'h:mm A')})`
                    : undefined
        });
    };

    setUpdateLastUpdateIntervalHandle = () => {
        if (this.state.updateLastUpdateIntervalHandle === undefined) {
            this.setState({
                updateLastUpdateIntervalHandle: setInterval(
                    () => { this.calculateTimeSinceUpdate(); },
                    (60 * 1000))
            });
        }
    };

    clearUpdateLastUpdateIntervalHandle = () => {
        if (this.state.updateLastUpdateIntervalHandle !== undefined) {
            clearInterval(this.state.updateLastUpdateIntervalHandle);
            this.setState({ updateLastUpdateIntervalHandle: undefined });
        }
    };

    onCreateEnvelope = () => {
        this.props.history.push(`/edit-envelope/${this.props.workspaceName}`);
    }

    onManageSuppressionRules = () => {
        this.setState({ isManageSuppressionOpen: true });
    };

    onRefresh = () => {
        this.props.reEvaluate(this.state.currentPeriod, this.state.periodStartTime, this.state.periodEndTime, this.state.filter);
    };

    onExport = () => {
        const periodStartTime = this.state.periodStartTime;
        const periodEndTime = this.state.periodEndTime;
        const currentExceedances = this.props.currentExceedances;
        console.log('groups: ', this.props);
        const groups = this.props.workspaceGroups;

        const tagInfoWithoutGrp = currentExceedances.map(ex => {
            if (ex.states && ex.states.length > 0) {
                return ex.states.map((s) => this.mapExceptionState(ex, s));
            }
            else {
                return [{
                    Tag: ex.tag,
                    State: '',
                    Type: '',
                    Colour: '',
                    Min: '',
                    Max: '',
                }]
            }

        }).reduce((result, cur) => result.concat(cur)).filter(s => s.State != STATE_NO_DATA);

        const formattedInformation: any[] = [];
        tagInfoWithoutGrp.map(item => {
            const tagAssociatedGroups: string[] = [];
            if (groups) {
                groups.forEach(gr => {
                    if (gr.tags.find(tag => tag === item.Tag)) {
                        tagAssociatedGroups.push(gr.name);
                    }
                });

                tagAssociatedGroups.forEach(tgr => {
                    formattedInformation.push({
                        Workspace: this.props.workspaceName,
                        Group: tgr,
                        ...item
                    })
                });
            } else {
                formattedInformation.push({
                    Workspace: this.props.workspaceName,
                    Group: 'Tags Without a Group',
                    ...item
                })
            }
        });

        console.log('currentExceedancesWithoutNoData', formattedInformation);

        this.props.exportDashboardToCsv(formattedInformation, periodStartTime(), periodEndTime());
    }


    manageSuppressionDismissed = () => {
        this.setState(
            { isManageSuppressionOpen: false },
            () => this.props.updateSuppressionList(this.state.filter)
        );
    }



    mapExceptionState = (ex: ViewWorkspaceStore.ExceedancesRowProps, s: TagEnvelopeState) => {
        let { triggerCondition, value1, value2 } = EnvelopeConditionsToTriggerAndValues(s.conditions);
        let { min, max } = this.calculateMinMaxValue(triggerCondition, value1, value2);
        return {
            Tag: ex.tag,
            State: s.name,
            Type: EnvelopeStateConditions[triggerCondition!],
            Colour: this.colorToString(s.colour),
            Min: min,
            Max: max,
        }
    }

    colorToString = (colour: string): string => {
        let col: ColourManager.StringColours = ColourManager.StringColours.Red;
        switch (colour) {
            case ColourManager.StatusColours.darkRed:
                col = ColourManager.StringColours.Red;
                break;
            case ColourManager.StatusColours.orange:
                col = ColourManager.StringColours.Orange;
                break;
            case ColourManager.StatusColours.greenGreen:
                col = ColourManager.StringColours.Green;
                break;
            case ColourManager.StatusColours.blueGrey:
                col = ColourManager.StringColours.BlueGrey;
                break;
            default:
                col = ColourManager.StringColours.Red;
        }
        return col.toString();
    }

    calculateMinMaxValue = (triggerCondition: any, value1: number | undefined, value2: number | undefined) => {
        let min, max;
        if (triggerCondition === EnvelopeStateConditions.Above) {
            min = '';
            max = value1 === undefined ? '' : value1;
        } else if (triggerCondition === EnvelopeStateConditions.Below) {
            min = value1 === undefined ? '' : value1;
            max = '';
        } else if (triggerCondition === EnvelopeStateConditions.EqualTo) {
            min = value1 === undefined ? '' : value1;
            max = value1 === undefined ? '' : value1;
        } else {
            min = value1 === undefined ? '' : value1;
            max = value1 === undefined ? '' : value2;
        }
        return { min, max };
    }

    render() {
        return (
            <React.Fragment>
                <Breadcrumbs links={[{ to: this.props.match.url, text: `${this.props.workspaceName}` }]} />
                <div style={{ width: '100%', display: 'flex' }}>
                    <div style={{ flex: 1, textAlign: 'right', marginTop: 10, marginBottom: "8px" }}>{this.state.lastUpdatedText}</div>
                </div>
                <ExceedancesTableFilter
                    filter={this.state.filter}
                    disabled={this.props.isInitialLoadLoading}
                    onFilterChange={this.onFilterChange}
                    filterBouncePeriod={300}
                    currentPeriod={this.state.currentPeriod}
                    periodStartTime={this.state.periodStartTime}
                    periodEndTime={this.state.periodEndTime}
                    onCreateEnvelope={this.onCreateEnvelope}
                    onManageSuppressionRules={this.onManageSuppressionRules}
                    onRefresh={this.onRefresh}
                    onPeriodChange={this.onPeriodChange}
                    onExport={this.onExport}
                    exportDisabled={this.props.isInitialLoadLoading || (this.props.currentExceedances === undefined) ||
                        (this.props.currentExceedances && this.props.currentExceedances.length === 0)}
                    workspaceUrlName={this.props.workspaceName}
                    isOwnerOrMember={this.props.canEdit}
                />
                <ExceedancesTable
                    filterTwo={this.state.filter}
                    workspaceGroups={this.props.workspaceGroups}
                    rows={this.props.currentExceedances}
                    isLoading={this.props.isInitialLoadLoading}
                    error={this.props.initialLoadErrorMessage}
                    currentPeriod={this.state.currentPeriod}
                    suppressionRules={this.props.suppressionRules}
                    emptyWorkspace={this.props.emptyWorkspace}
                    currentUser={this.props.currentUser}
                    workspaceName={this.props.workspaceName}
                    onCloseAdhocSuppression={() => this.props.updateSuppressionList()}
                    onShowAllTagsChange={this.onShowAllTagsChange}
                    onSortByColumn={this.onSortByColumn}
                    exceedanceDisplayType={this.state.exceedanceDisplayType}
                    sortColumn={this.state.sortColumn}
                    sortDescending={this.state.sortDescending}
                    canEdit={this.props.canEdit}
                    periodStartTime={this.state.periodStartTime}
                    periodEndTime={this.state.periodEndTime}
                    totalTagCount={this.props.totalTagCount || 0}
                    exceedenceCount={this.props.exceedanceTagCount || 0}
                    suppressionCount={this.props.suppressedTagCount || 0}
                    {...this.props as RouteComponentProps<{ url: string, workspaceName: string }>} />


                {this.state.isManageSuppressionOpen && this.props.canEdit
                    ?
                    (
                        <ManageSuppressionList
                            currentEnvelopes={this.props.currentExceedances}
                            workspace={this.props.workspaceName}
                            initialSuppressionRules={this.props.suppressionRules}
                            currentUser={this.props.currentUser}
                            onDismissed={this.manageSuppressionDismissed}
                        />)
                    : null}
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState, ownProps: RouteComponentProps<{ url: string, workspaceName: string }>) => {
    const { viewWorkspace } = state;
    const { workspaceName } = ownProps.match.params;
    return {
        isInitialLoadLoading: viewWorkspace.isInitialLoadLoading,
        initialLoadErrorMessage: viewWorkspace.initialLoadErrorMessage,
        workspaceName: workspaceName,
        currentExceedances: viewWorkspace.currentExceedances,
        filteredExceedances: viewWorkspace.filteredExceedances,
        updatesLoadErrorMessage: viewWorkspace.updatesLoadErrorMessage,
        emptyWorkspace: viewWorkspace.emptyWorkspace,
        suppressionRules: viewWorkspace.suppressionRules,
        dataLoadedAt: viewWorkspace.dataLoadedAt,
        putSuppressonRuleInProgress: viewWorkspace.putSuppressonRuleInProgress,
        putSuppressonRuleSuccess: viewWorkspace.putSuppressonRuleSuccess,
        putSuppressonRuleError: viewWorkspace.putSuppressonRuleError,
        canEdit: viewWorkspace.canEdit,
        workspaceGroups: workspaceGroupSelector(state, ownProps),
        suppressedTagCount: viewWorkspace.suppressedTagCount,
        totalTagCount: viewWorkspace.totalTagCount,
        exceedanceTagCount: viewWorkspace.exceedanceTagCount,
        displayExceedanceType: viewWorkspace.displayExceedanceType,
        allEnvelopes: viewWorkspace.allEnvelopes,
        startTime: viewWorkspace.startTime,
        endTime: viewWorkspace.endTime,
        filterText: viewWorkspace.filterText,
        user: viewWorkspace.user,
        selectedTags: viewWorkspace.selectedTags,
        userWorkspaces: userWorkspacesSelector(state),
        workspaceTimePeriod: viewWorkspace.workspaceTimePeriod
    }

}

// Wire up the React component to the Redux store
export default withRouter((connect(mapStateToProps, actionCreators)(ViewWorkspace)));