import { ApolloServiceClient } from './apollo-service-client';
//import { Workspace } from '../workspace-common-types';
import { Envelope } from '../envelope-common-types';
import { EditEnvelopeStateProps,EnvelopeProps, EditEnvelopeExpressionProps } from '../edit-envelope';
import { IPersonaProps } from 'office-ui-fabric-react/lib/components/Persona/Persona.types';
import { ExceedanceEventProps } from '../view-workspace';

export interface ServiceClient {
    addWorkspaceUser(workspaceName: string, principal: string, authType: AuthType): Promise<void>;
    deleteWorkspaceUser(workspaceName: string, principal: string, authType: AuthType): Promise<void>;
    getWorkspaceEnvelopes(workspaceName: string, options?: EnvelopeOptions): Promise<TagEnvelopeDefinition[]>;
    getWorkspaceEnvelope(workspaceName: string, envelopeId: string): Promise<TagEnvelopeDefinition>;
    getWorkspaceEnvelopesExploded(workspaceName: string, options?: EnvelopeOptions): Promise<TagEnvelopeDefinition[]>;
    getExceedance(workspaceName: string, tags: string[], startTime: Date, endTime: Date, hideDataPoints: boolean): Promise<TagExceeedance[]>;
    getTagsDetails(tags: string[]): Promise<TagDetail[]>;
    getTagsHighWater(tags: string[]): Promise<TagValue[]>;
    getTagsLastValue(tags: string[], start_time: Date, end_time: Date): Promise<TagValue[]>;
    getWorkspaces(): Promise<any[]>;
    getWorkspaceSecurity(workspaceName: string): Promise<WorkspaceRole[]>;
    searchForTags(searchText: string): Promise<TagDetail[]>;
    deleteEnvelope(workspaceName: string, envelopeId: string): Promise<boolean>;
    getFormulas(): Promise<Formula[]>;
    createWorkspace(workspaceName: string): Promise<string>;
    deleteWorkspace(workspaceName: string): Promise<boolean>;
    createEnvelope(workspaceName: string, envelopeInformation: Envelope): Promise<string>;
    getActiveSuppressionRules(workspaceName: string): Promise<SuppressionRule[]>;
    getAllSuppressionRules(workspaceName: string): Promise<SuppressionRule[]>;
    //getSuppressionRuleHistory(identifier: string): Promise<SuppressionRule[]>;
    putSuppressionRule(workspaceName: string, rule: SuppressionRule): Promise<SuppressionRule>;
    getUserWorkspaces(username: string): Promise<UserWorkspace[]>;
    //setColourForState(workspaceName: string, tag: string, state: string, colour: string): Promise<void>;
    //getStateColoursForWorkspace(workspaceName: string): Promise<{ tag: string, state: string, colour: string }[]>;
    renameWorkspace(workspaceName: String, newWorkspaceName: String): Promise<void>;
    createEnvelopeNew(workspaceName: string, tag: string, states: EditEnvelopeStateProps[], calculations?:EditEnvelopeExpressionProps): Promise<string>;
    putEnvelopeNew(workspaceName: string, tag: string, envelopeId: string, states: EditEnvelopeStateProps[]): Promise<TagEnvelopeDefinition>;
    updateEnvelope(workspaceName: string, env: TagEnvelopeDefinition): Promise<TagEnvelopeDefinition>;
    addEnvelopes(workspaceName: string, tags: EnvelopeProps[]): Promise<string>;
    getTagDetailsForWorkspace(workspaceName: string): Promise<TagDetail[]>;
    getHighWatersForWorkspace(workspaceName: string): Promise<TagValue[]>;
    addWorkspaceGroup(workspaceName: string, group: WorkspaceGroup): Promise<WorkspaceGroup>;
    removeWorkspaceGroup(workspaceName: string, groupId: number): Promise<boolean>;
    removeTagFromWorkspaceGroup(group: WorkspaceGroup, tags: string[]): Promise<WorkspaceGroup>;
    getWorkspaceGroups(workspaceName: string): Promise<WorkspaceGroup[]>;
    getWorkspaceGroupExceedences(workspaceName: string, group: string, start_time: Date, end_time: Date): Promise<TagExceeedance[]>;
    updateWorkspaceGroup(group: WorkspaceGroup): Promise<WorkspaceGroup>;
    putPeriodForWorkspace(name: String, currentPeriod: String, duration: number, durationUnit: string): Promise<any>;
    getPeriodForWorkspace(name: String): Promise<any>;
    getAllWorkspaces(): Promise<any>
    getExpressionForEnvelopes(workspaceName:string, tagName:string):Promise<EditEnvelopeExpressionProps>;
    putExpressionForEnvelopes(workspaceName:string, tagName:string, calculations?:EditEnvelopeExpressionProps):Promise<any>;
    putTagType(tagName:string, tagType:string):Promise<any>;
    getTagType(tagName: String): Promise<string>;
    getUnitAndExecutionSchedule(attribute: string): Promise<any>
}

export interface EnvelopeOptions {
    requestStates?: boolean;
    explodeEnvelopesByState?: boolean;
}

export const DefaultEnvelopeOptions = {
    requestStates: true,
    explodeEnvelopesByState: true
} as EnvelopeOptions;

export function getServiceClient() {
    return new ApolloServiceClient() as ServiceClient;
}

export interface TagExceeedance {
    id: number;
    tag: string;
    states: TagExceedanceState[];
}

export interface TagExceedanceState {
    id: number;
    name: string;
    points: TagExceedanceStatePoint[];
    data_count?: number;
    met_data_count?: number,
    met: boolean
}

export interface TagExceedanceStatePoint {
    time: number;
    value: number;
}

export interface TagDetail {
    id: string;
    name: string;
    description: string;
    unitOfMeasure: string;
    plantName: string;
    currentValue?: string;
    currentValueTime?: Date;
    updated?: Date;
}

export interface TagValue {
    name: string;
    value: string;
    time: string;
}

export interface WorkspaceGroup {
    id: number;
    name: string;
    groups: number[];
    tags: string[];
    workspace: string;
    parentId?: number;
    expanded: boolean;
}



export interface DisplayWorkspaceGroup {
    id: number;
    name: string;
    groups: DisplayWorkspaceGroup[];
    tags: TagEnvelopeDefinition[];
    exceedances: number;
    suppressions: number;
    workspaceName?: string;
    expanded: boolean;
}

export interface TagEnvelopeDefinition extends IPersonaProps {
    id: string;
    tag: string;
    description?: string;
    unit?: string;
    plantName?: string;
    states: TagEnvelopeState[];
    currentValue?: string;
}

export interface TagEnvelopeState {
    name: string;
    priority: number;
    colour: string;
    conditions: StateCondition[];
    displayRange?: string;
    description: string;
    short_description: string;
    met: boolean;
    events?: ExceedanceEventProps[];
}

export interface StateCondition {
    id?: string;
    low_threshold: number | undefined;
    high_threshold: number | undefined;
    formula_type: string;
    formula_config?: { variant: number | undefined };
    isNew?: boolean;
}

export interface Formula {
    formula_type: string;
    description?: string;
    hits?: number;
}

export interface SuppressionRule {
    start_time?: number | null;
    end_time?: number;
    identifier?: string;
    informant_tag?: InformantTag;
    suppressed_tags: string[];
    comment?: string;
    created_by: string;
    created_date?: number;
    modified_by?: string;
    modified_date?: number;
}

export interface InformantTag {
    tag: string;
    state?: string | null;
}

export enum AuthType { CREATOR, OWNER, MEMBER }
export enum RoleType { USER, GROUP }
export interface WorkspaceRole {
    principal: string;
    auth_type: AuthType;
    role_type: RoleType;
}

export interface UserWorkspace {
    workspace: string;
    creator: boolean;
    owner: boolean;
    member: boolean;
}

export interface Attributes {
    key: string
    text: string
}

export const STATE_NO_DATA = 'no-data';
export const STATE_NOT_TRIGGERED = 'Not Triggered';