import * as ServiceClient from '../service-client';
import { Workspace } from '../slice-reducers/common-types';
const serviceClient = ServiceClient.getServiceClient();


export async function LoadWorkspaces(dispatch:any, getState:any): Promise<Record<string,Workspace>>{
    const wSpaces = await _getWorkspaces(dispatch);
    return wSpaces;
}

async function _getWorkspaces(dispatch:any){
    const workspaces = await serviceClient.getWorkspaces() || [];
    dispatch({type: 'GET_WORKSPACES', payload: { workspaces: workspaces}});
    const wSpaces = MapUserWorkspaces(workspaces);
    return wSpaces;
}

export function MapUserWorkspaces(workspaces: Workspace[]) {
    if(!workspaces){ return <Record<string, Workspace>>{};}
    return workspaces.reduce((map, g) => {
        map[g.name] = g;
        return map;
    }, <Record<string, Workspace>>{});
}
