import * as React from 'react';
import * as SearchTags from '../../store/search-tags';
import { TagEnvelopeDefinition } from '../../store/service-client/index';
import { ApplicationState } from '../../store/index';
import { connect } from 'react-redux';
import { ListPeoplePicker, NormalPeoplePicker, BasePeoplePicker, IPeoplePickerProps }
    from 'office-ui-fabric-react/lib/components/pickers/PeoplePicker/PeoplePicker';
import { IPersonaProps } from 'office-ui-fabric-react/lib/components/Persona/Persona.types';
import { TagSelectionItem, TagSelectionItemStyle } from './tag-selection-item';
import { IBasePicker, DefaultButton, Label, IPickerItemProps, IBasePickerProps } from 'office-ui-fabric-react';
import { TagPickerStyle} from './tag-picker'
import { allOfTheseIncludesText } from '../../utils/FilterText';
import './tag-picker.css';

type TagPickerTwoProps = SearchTags.SearchTagsState & typeof SearchTags.actionCreators &
{
    selectedTags?: TagEnvelopeDefinition[];
    tagsAvailableForSelection: TagEnvelopeDefinition[],
    onSelectionUpdated?: any,
    pickerStyle: TagPickerStyle,
    inputDisabled?: boolean,
    allDisabled?: boolean,
    selection?: string,
    delay?: number,
    lockToSingleTag?: boolean,
    showTagsOnLoad?: boolean
};

type TagPickerTwoState = {
    suggestions: TagPersonaProps[],
    selected: TagEnvelopeDefinition[],
    currentSearch?: string,
    searchText?: string,
    key: string
};


interface TagPersonaProps extends IPersonaProps {
    tag: TagEnvelopeDefinition
}

export class TagPickerTwo extends React.Component<TagPickerTwoProps, TagPickerTwoState> {
    constructor(props: any) {
        super(props);
        let uniqid = btoa(new Date().getTime().toString() + Math.random());
        this.state = {
            suggestions: [],
            selected: this.props.selectedTags ? this.props.selectedTags : [],
            currentSearch: '',
            searchText: '',
            key: uniqid
        };
    }

    convertTagToPersona(tag: TagEnvelopeDefinition): TagPersonaProps {
        let retVal = {
            tag: tag,
            imageInitials: tag.plantName ? tag.plantName : '',
            text : tag.tag,
            secondaryText: tag.description ? tag.description : '',
            tertiaryText: tag.unit ? tag.unit : ''
        } as TagPersonaProps;
        
        return retVal;
    }

    private beginSearching = async (filterText: string) => {
        try {
            if(filterText)
            {
            let terms = filterText ? filterText.trim().split(' ') : [];
            
            this.setState({ suggestions: this.props.tagsAvailableForSelection.filter((t)=> {
                return allOfTheseIncludesText(terms, [t.tag, t.description!, t.unit!, t.plantName!]);
            }).map(this.convertTagToPersona)});
            } else {
                this.setState({suggestions: this.props.tagsAvailableForSelection.map(this.convertTagToPersona)});
            }
            this.setState({ currentSearch: filterText });
        } catch (r) {
            // tslint:disable-next-line:no-console
        }

        return this.prepareSuggestions(this.state.suggestions);
    }

    prepareSuggestions(tags: TagPersonaProps[]): TagPersonaProps[] {
        let selected = this._pickerRef.current!.items!.map(i => i.text);

        let results= tags.filter(p => selected.indexOf(p.text) < 0)

        results.sort((s1: IPersonaProps, s2: IPersonaProps) => {
            if (s1.text && s2.text) {
                return s1.text.localeCompare(s2.text);
            }
            return 0;
        });
        return results;
    }

    _onItemSelected(selectedItem: TagPersonaProps): TagPersonaProps | null {
        if (this._pickerRef.current !== undefined &&
            this._pickerRef.current!.items!.find(i => i.text === selectedItem.text)) {
            return null;
        }

        const selectedTags: TagEnvelopeDefinition[] = [ selectedItem.tag ];

        if (this.props.onSelectionUpdated) {
            this.props.onSelectionUpdated(selectedTags);
        }
        return selectedItem;
    }

    _onMultiItemSelected(selectedItem: TagPersonaProps): void {
        let selectedTag: TagPersonaProps|undefined = undefined;
        let suggested = this.state.suggestions.filter( s => {
            let persona = s;
            if ( persona.text !== selectedItem.text ) {
                return true;
            } else {
                selectedTag = s;
                return false;
            }
        });
        let selected = this.state.selected.filter( s => {
            return s !== selectedItem.tag;
        }).concat(selectedTag!.tag);
        this.setState( {suggestions: suggested, selected: selected}, () => {
            if ( this.props.onSelectionUpdated ) {
                this.props.onSelectionUpdated( selected );
            }
        });
        if ( this._pickerRef.current ) {
            let picker = this._pickerRef.current as BasePeoplePicker;
            picker.setState( {items : picker.items.concat(selectedTag!)});
        }
    }

    _onRenderSelectedItem(proops: IPickerItemProps<IPersonaProps>): JSX.Element {
        return (
            <TagSelectionItem
                item={proops.item}
                key={`selected-${proops.item.text}`}
                tagSelectionItemStyle={TagSelectionItemStyle.COMPACT}
                onClick={() => {
                    let picker = this._pickerRef.current as BasePeoplePicker;
                    picker.setState({ items: picker.items.filter(i => i.text !== proops.item.text) });
                    this._removeSelectedItem(proops.item);
                }}
            />
        );
    }

    _removeSelectedItem(removedItem: IPersonaProps) {
        this.setState(   {selected: this.state.selected.filter( s => {
            let persona = this.convertTagToPersona(s);
            return persona.text !== removedItem.text;
                    })}, () => {
                        if ( this.props.onSelectionUpdated ) {
                            this.props.onSelectionUpdated(this.state.selected);
                        }
        });
    }

    _onRenderSuggestionsItem(item: IPersonaProps): JSX.Element {
        return (
            <TagSelectionItem
                item={item}
                key={`suggestion-${item.text}`}
                tagSelectionItemStyle={TagSelectionItemStyle.NORMAL}
                searchText={this.state.currentSearch}
            />
        );
    }

    _onClearSelection() {
        let picker = (this._pickerRef.current as BasePeoplePicker);
        this.setState({ selected: [], searchText: ''}, () => {
            picker.setState({ items: [] });
            let protectedKey = 'input';
            if(picker[protectedKey].current) picker[protectedKey].current!.clear();
            if(this.props.onSelectionUpdated)
            {
                this.props.onSelectionUpdated([]);
            }
        });
        this.beginSearching('');
    }

    componentDidUpdate(prevProps:Readonly<TagPickerTwoProps>){
        if(prevProps.tagsAvailableForSelection != this.props.tagsAvailableForSelection)
        {
            const sug = this.props.tagsAvailableForSelection.map(this.convertTagToPersona);
            this.setState({suggestions: sug});
        }

        if(prevProps.selectedTags != this.props.selectedTags)
        {
            this.setState({selected: this.props.selectedTags ? this.props.selectedTags : []})
        }
    }

    _pickerRef = React.createRef<IBasePicker<IPersonaProps>>();
    render() {
        let single = this.props.pickerStyle === TagPickerStyle.SINGLE_TAG;
        let multi = this.props.pickerStyle === TagPickerStyle.MULTI_TAG;
        if ( this._pickerRef.current ) {
            let picker = this._pickerRef.current as BasePeoplePicker;
            let items = this.state.selected.map( s => this.convertTagToPersona(s));
            picker.setState( {items : items});
        }

        let defaultPersonas: TagPersonaProps[] = [];
        let tagSearchFn = this.beginSearching;

        let pickerProps: IPeoplePickerProps = {            
            componentRef: this._pickerRef,
            disabled: this.props.allDisabled === true,
            onItemSelected: (item: TagPersonaProps) => this._onItemSelected(item),
            onRenderItem: (pickerItem: IPickerItemProps<TagPersonaProps>) => this._onRenderSelectedItem(pickerItem),
            onRenderSuggestionsItem: (item: TagPersonaProps) => this._onRenderSuggestionsItem(item),
            onResolveSuggestions: tagSearchFn,
            pickerCalloutProps: {
                styles: {
                    calloutMain: { 
                        minWidth: '36em',
                        display: undefined
                    }
                }
            },
            pickerSuggestionsProps: { noResultsFoundText: 'No tags found', loadingText: 'Loading Tags ...' },
            resolveDelay: this.props.delay ? this.props.delay : 500,
            inputProps: {                
                defaultVisibleValue: this.state.searchText,
            },
            defaultSelectedItems: defaultPersonas,
        };

        let bPickerProps: IBasePickerProps<IPersonaProps> = {
            ...pickerProps,
            styles: {
                itemsWrapper: { flexFlow: 'column'},
                input: { flexGrow: "0.5"}
            },
            
        };

        if(this.props.lockToSingleTag){
            bPickerProps= {
                ...bPickerProps, itemLimit: 1
            }
        }

        let title = { title: single ? undefined : 'Click to remove' };
        let theCursor = single ? undefined : 'pointer';
        let style = { 
            className: 'selectedSuggestions',
            style : {
                display: 'flex', justifyContent: 'space-between',
                cursor: theCursor, width: '40em',
            }
        };
        let clearSelection = (
                <DefaultButton
                    disabled={this.props.allDisabled === true ? true : false} 
                    style={{ flex: 2, marginRight:'5px'  }}
                    onClick={() => {
                        this._onClearSelection();
                    }}
                >
                    Clear Selection
                </DefaultButton>
            );
        
        return (
            <div style={{display: 'flex'}}>
                <div
                    {...title}
                    {...style}
                >             
                    <Label style={{ fontWeight: 800, flex: 0.57}}>Tag</Label>
                    <div className={multi ? 'selectedSuggestions' : undefined} style={{ flex: 5 }}>
                        {this.props.pickerStyle === TagPickerStyle.SINGLE_TAG ? 
                            (<NormalPeoplePicker {...bPickerProps} />)
                            : (<ListPeoplePicker  {...pickerProps} />)}
                    </div>
                    {clearSelection}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => state.searchTags;
// Wire up the React component to the Redux store
export default (connect(mapStateToProps, SearchTags.actionCreators)(TagPickerTwo));