import * as React from 'react';
import { AppearanceManager, ColourManager } from '../common';
import { EditEnvelopeExpressionProps } from '../../store/edit-envelope';
import { DateTimePicker } from '../common/index';
import { ActionButton, Dropdown, Label, TextField, DefaultButton, IDropdownOption } from 'office-ui-fabric-react';
import * as ServiceClient from '../../store/service-client';
import { TagHandler } from '../tag-picker/tag-handlers';
import { Basic, Equation } from './Basic';
import { MentionEditor } from './MentionEditor';

type ExpressionProps = { calculations: EditEnvelopeExpressionProps, viewExpressionComponent: boolean, tagHandler?: TagHandler<ServiceClient.TagEnvelopeDefinition>, onDelete?: Function, onChange?: Function };
type ExpressionState = EditEnvelopeExpressionProps & {
    basicExpressions: Equation[]
    complexExpressions: string
    keyCount: number
    viewGrid:boolean
    unitOptions:IDropdownOption[]
    scheduleOptions:IDropdownOption[]
}

export class Expression extends React.Component<ExpressionProps, ExpressionState> {
    constructor(props: ExpressionProps) {
        super(props);
        this.state = { ...props.calculations, basicExpressions: [], 
            complexExpressions: '', viewGrid:false,  keyCount: 0,unitOptions:[], scheduleOptions:[] };
    }

    componentDidMount() {
        this.getUnitsAndExecutionSchedule()
    }
        
    componentDidUpdate(prevProps: ExpressionProps, prevState: ExpressionState) {
        if (this.props.calculations !== prevProps.calculations &&
            this.props.calculations!== undefined &&
            this.props.calculations.expression!== undefined) {
            this.setState({
                description:this.props.calculations.description,
                schedule:this.props.calculations.schedule,
                validFrom:new Date(this.props.calculations.validFrom!.toString()),
                unit_of_measure:this.props.calculations.unit_of_measure,
                complexExpressions:this.props.calculations.expression!,
                viewGrid:true
            });
        }
    }

    delete = () => { if (this.props.onDelete) { this.props.onDelete(); } };
    change = () => { if (this.props.onChange) {
        this.props.onChange(this.state); 
    } };

    async getUnitsAndExecutionSchedule() {
        const serviceClient = ServiceClient.getServiceClient();
        const unitOptions = await serviceClient.getUnitAndExecutionSchedule("UNIT_OF_MEASURE")
        const unitOptionsFilter = Array.from(new Set(unitOptions.map((u: any) => u.key))).map((v: any) => {
            return unitOptions.find((a: any) => a.key === v)
        })
        this.setState({ unitOptions: unitOptionsFilter })
        const scheduleOptions = await serviceClient.getUnitAndExecutionSchedule("EXECUTION_SCHEDULE")
        this.setState({ scheduleOptions: scheduleOptions })
    }

    onSelectValidDate = (date: Date) => {
        this.setState({ validFrom: date }, this.change);
    }

    deleteGrid(index: number) {
        let newEquationRow = this.state.basicExpressions;
        newEquationRow.splice(index, 1);
        this.setState({ basicExpressions: newEquationRow }, this.change);
        if(newEquationRow.length == 0){
            this.setState({keyCount:0})
        }
    }

    changeGrid(changedGrid: any, index: number) {
        let newEquationRow = this.state.basicExpressions;
        newEquationRow[index] = changedGrid;
        this.setState({ basicExpressions: newEquationRow }, this.change);
    }

    deleteEquation() {
        this.setState({ viewGrid: false}, this.change);
    }

    changeEquation(changedExpression: any) {
        this.setState({ complexExpressions: changedExpression }, this.change);
    }

    getKey() {
        const newKey = this.state.keyCount + 1
        this.setState({ keyCount: newKey }, this.change)
        return newKey
    }

    addNewGrid = () => this.setState({
        basicExpressions: [...this.state.basicExpressions, {
            _id: this.getKey(),
            selectedTag: undefined,
            brackets: { '(': false, ')': false },
            operation: { '+': false, '-': false, '*': false, '/': false },
            constant: '',
            tagHandler: this.props.tagHandler
        }], viewGrid: false
    }, this.change);

    renderBasic() {
        return this.state.basicExpressions.map((b, i) => (
            <Basic
                key={b._id}
                equation={b}
                onChange={(r: any) => this.changeGrid(r, i)}
                onDelete={() => this.deleteGrid(i)}
            />));
    }

    renderComplex() {
        return (
            <MentionEditor 
                finalEquation = {this.state.complexExpressions}
                viewGrid = {this.state.viewGrid}
                onChange={(r: any) => this.changeEquation(r)}
                onDelete = {()=>this.deleteEquation()}
                />
        )
    }

    renderButtons() {
        return (
            <React.Fragment>
                    <DefaultButton iconProps={{ iconName: 'Add' }} style={{ marginTop: '28px' }} onClick={() => this.addNewGrid()}>
                        Add Basic Expression
                </DefaultButton>
                    <DefaultButton iconProps={{ iconName: 'Add' }} style={{ marginTop: '28px' }} onClick={() => this.setState({ viewGrid: true, basicExpressions: [], keyCount: 0 }, this.change)}>
                        Add Complex Expression
                </DefaultButton>
            </React.Fragment>
        );
    }

    render() {
        if (this.props.viewExpressionComponent) {
            const kebabMenu = (
                <React.Fragment>
                    <ActionButton
                        onClick={(e: any) => { this.delete(); }}
                        styles={{ menuIcon: { display: 'none' }, root: { height: 'auto' }, icon: { color: ColourManager.StatusColours.darkRed, fontSize: '20px' } }}
                        iconProps={{ iconName: 'clear' }}
                    />
                </React.Fragment>
            );
            return (
                <React.Fragment>
                    <div style={{ borderTop: '1px solid #eeeeee', borderBottom: '1px solid #eeeeee', paddingTop: '10px', paddingBottom: '10px' }}>
                        <div style={{ display: 'flex' }}>
                            <div style={{ flex: 1, flexFlow: 'row', display: 'flow', justifyContent: 'space-between' }}>
                                <TextField label={'Description'} defaultValue={this.state.description}
                                    onChange={(_, v) => this.setState({ description: String(v) }, this.change)}
                                    styles={{
                                        fieldGroup: { marginTop: '8px' },
                                        subComponentStyles: {
                                            label: {
                                                root: {
                                                    width: '170px',
                                                    fontWeight: 'bold',
                                                    fontSize: '16px',
                                                    lineHeight: '24px'
                                                }
                                            }
                                        }
                                    }}
                                />
                            </div>
                            <div style={{ flex: 1, paddingLeft: '5em' }}>
                            <div style={{ display: 'flex' }}>
                                <Label style={{ ...AppearanceManager.FormLabelStyles, flexShrink: 1 }}>Execution Schedule</Label>
                            </div>
                            <div style={{paddingTop:'0.5em'}}>
                            <Dropdown
                                    label={undefined}
                                    style={{ width: '250px' }}
                                    styles={{ root: { paddingRight: '1em' } }}
                                    options={this.state.scheduleOptions}
                                    defaultSelectedKey={this.state.schedule}
                                    onChange={(_, v) => this.setState({ schedule: String(v!.key) }, this.change)}
                                />
                            </div>
                        </div>
                        <div style={{ flex: 1, paddingLeft: '5em' }}>
                            <div style={{ display: 'flex' }}>
                                <Label style={{ ...AppearanceManager.FormLabelStyles, flexShrink: 1 }}>Unit Of Measure</Label>
                            </div>
                            <div style={{paddingTop:'0.5em'}}>
                            <Dropdown
                                    label={undefined}
                                    style={{ width: '250px' }}
                                    styles={{ root: { paddingRight: '1em' } }}
                                    options={this.state.unitOptions}
                                    defaultSelectedKey={this.state.unit_of_measure}
                                    onChange={(_, v) => this.setState({ unit_of_measure: String(v!.key) }, this.change)}
                                />
                            </div>
                        </div>
                        <div style={{ flex: 1, paddingLeft: '1em' }}>
                            <div style={{ display: 'flex' }}>
                                <Label style={{ ...AppearanceManager.FormLabelStyles, flexShrink: 1 }}>ValidFrom</Label>
                            </div>
                            <div style={{paddingTop:'0.5em'}}>
                            <DateTimePicker
                                        defaultDate = {this.state.validFrom}
                                        onSelectDate={this.onSelectValidDate}
                                        showTimeComponent = {false}
                                    />
                            </div>
                        </div>
                            <div style={{ width: '30px', paddingTop: '5px', textAlign: 'right' }}>
                                {kebabMenu}
                            </div>
                        </div>
                        </div>
                        {this.renderBasic()}
                        {this.renderComplex()}
                        {this.renderButtons()}
                </React.Fragment>
            );
        }
        else {
            return (
                <div></div>
            )
        }
    }
}