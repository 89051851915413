export function updateObject(oldObject: any, newValues:any) {
    // Encapsulate the idea of passing a new object as the first parameter
    // to Object.assign to ensure we correctly copy data instead of mutating
    return Object.assign({}, oldObject, newValues);
}

export function updateItemInArray(array: any[], itemId:number, updateItemCallback:any) {
    const updatedItems = array.map(item => {
      if (item.id !== itemId) {
        // Since we only want to update one item, preserve all others as they are now
        return item
      }
      // Use the provided callback to create an updated item
      const updatedItem = updateItemCallback(item)
      return updatedItem
    })
    return updatedItems
}

export function removeItemFromArray(array: any[], itemId: any) {
  if (array.some(a => a.id !== undefined)) {
    const updatedItems = array.map(item => {
      if (item.id !== itemId) {
        return item;
      }
    });
    return updatedItems;
  }
  //for arrays with string and number as values not objects.
  else{
    return array.filter(item => item !== itemId);
  }
}
