import { Reducer } from 'redux';
import { AppThunkAction } from './';
import { TagHandler, KnownTag } from '../components/tag-picker/tag-handlers';

// STATE - This defines the type of data maintained in the Redux store.
export interface SearchTagsState {
    searchIsLoading?: boolean;
    searchErrorMessage?: string;
    searchIsSuccess?: boolean;
    searchedTags?: KnownTag[];
    searchKey?: string;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface SearchingForTags {
    type: 'SEARCHING_FOR_TAGS';
}

export interface SearchingForTagsSuccess {
    type: 'SEARCHING_FOR_TAGS_SUCCESS';
    searchedTags: KnownTag[];
    key: string;
}

export interface SearchingForTagsError {
    type: 'SEARCHING_FOR_TAGS_ERROR';
    errorMessage: string;
}

export interface ClearSearchedTags {
    type: 'CLEAR_SEARCHED_TAGS';
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = SearchingForTags | SearchingForTagsSuccess | SearchingForTagsError | ClearSearchedTags;

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    searchingForTags: (searchText: string, handler: TagHandler<KnownTag>, key: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SEARCHING_FOR_TAGS' });
        try {
            const tags = await handler.searchForTags(searchText);
            dispatch({ type: 'SEARCHING_FOR_TAGS_SUCCESS', searchedTags: tags, key: key});
        } catch (ex) {
            dispatch({ type: 'SEARCHING_FOR_TAGS_ERROR', errorMessage: ex.toString() });
        }
    },
    clearSearchedTags: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({type: 'CLEAR_SEARCHED_TAGS'});
    }
};

// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
export const reducer: Reducer<SearchTagsState> = (state: SearchTagsState, action: KnownAction) =>  {
    if ( action && action.type ) {
        switch (action.type) {
            case 'SEARCHING_FOR_TAGS':
                return { searchIsLoading: true, searchIsSuccess: false, searchedTags: []};
            case 'SEARCHING_FOR_TAGS_SUCCESS':
                return { searchIsLoading: false, searchIsSuccess: true, searchedTags: action.searchedTags, searchKey: action.key};
            case 'SEARCHING_FOR_TAGS_ERROR':
                return { searchIsLoading: false, searchIsSuccess: false, searchErrorMessage: action.errorMessage, searchedTags: []};
            case 'CLEAR_SEARCHED_TAGS':
                return { ...state, searchedTags: []};
            default:
                break;
        }
    }
    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || { searchedTags: [] };
};