import { Reducer } from 'redux';
import {  GroupAddErrorAction, AddGroupAction, ClearGroupAction, GroupAddedAction } from './actions/group'
import { AddGroupToWorkspaceAction } from './slice-reducers/common-actions'

type KnownAction = AddGroupToWorkspaceAction | GroupAddErrorAction | AddGroupAction | ClearGroupAction | GroupAddedAction;

export interface AddGroupState {
    inProgress:boolean;
    error?: string,
    success: boolean;
}


export const reducer: Reducer<AddGroupState> = (state: AddGroupState, action: KnownAction) =>  {
    switch (action.type) {
        case 'ADD_GROUP': {
            return { inProgress: true, success: false, error: undefined };
        }
        case 'GROUP_ADDED': {
            return { inProgress: false, success: true, error: undefined };
        }
        case 'GROUP_ADD_ERROR': {
            return { inProgress: false, error: action.errorMessage, success: false };
        }
        case 'CLEAR_GROUP_DATA': {
            return { inProgress: false, error: undefined, success: false };
        }
        default:
            return state || {};
    }
};

