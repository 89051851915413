import * as React from 'react';
import * as Showdown from 'showdown';


type MarkdownProps = {
    description: string
}

export class Markdown extends React.Component<MarkdownProps> {
    constructor(props: any) {
        super(props);
    }
    
    
    render(){
        const converter = new Showdown.Converter({
            tables: true,
            simplifiedAutoLink:true,
            strikethrough:true,
            tasklists:true,
            openLinksInNewWindow: true
        });
        return (<React.Fragment>
                <div dangerouslySetInnerHTML={{__html: converter.makeHtml(this.props.description) }} />    
            </React.Fragment>)
    }
}


