import { Reducer } from 'redux';
import { AppThunkAction } from './';
import * as ServiceClient from './service-client';
import { AuthenticatedUser } from '../auth/index';
import { UserWorkspaceAction } from '../store/slice-reducers/user-workspace';
import * as UserWorkspaceData from '../store/loading-data/user-workspaces';
import * as WorkspaceData from '../store/loading-data/workspace';
import { Workspace, PeriodOptions } from './slice-reducers/common-types';
import { WorkspaceAction } from './slice-reducers/workspace';


// STATE - This defines the type of data maintained in the Redux store.
export interface ListWorkspacesState {
    isLoading?: boolean;
    error?: string;
    workspaces?: Workspace[];
    deleteWorkspaceName?: string;
    showDeleteConfirmation?: boolean;

    showUpdatePeriodDialog?: boolean;
    updateWorkspaceName?: string;
    currentPeriod?: PeriodOptions;
    duration?: number;
    durationUnit?: string;
}



// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export interface ListWorkspaces { type: 'LIST_WORKSPACES'; }
export interface ListWorkspacesResponse { type: 'LIST_WORKSPACES_RESPONSE'; workspaces: Workspace[]; }
export interface ListWorkspacesLoadError { type: 'LIST_WORKSPACES_ERROR'; errorMessage: string; }
export interface ClearData { type: 'CLEAR_DATA'; }
export interface DeleteWorkspace { type: 'DELETE_WORKSPACE'; }
export interface DeleteWorkspaceError { type: 'DELETE_WORKSPACE_ERROR'; errorMessage: string; }
export interface DeleteWorkspaceSuccess { type: 'DELETE_WORKSPACE_SUCCESS'; }
export interface DeleteWorkspaceCancel { type: 'DELETE_WORKSPACE_CANCEL'; }
export interface GetWorkspacePeriod {
    type: 'LOAD_WORKSPACE_PERIOD';
    workspaceName: string,
    currentPeriod: PeriodOptions,
    duration: number,
    durationUnit: string
}
export interface GetWorkspacePeriodError { type: 'WORKSPACE_PERIOD_ERROR'; errorMessage: string; }
export interface UpdateWorkspacePeriod { type: 'UPDATE_WORKSPACE_PERIOD' }
export interface UpdateWorkspacePeriodCancel { type: 'UPDATE_WORKSPACE_PERIOD_CANCEL' }

type KnownAction = ListWorkspaces | ListWorkspacesResponse | ListWorkspacesLoadError | ClearData
    | DeleteWorkspace | DeleteWorkspaceError | DeleteWorkspaceSuccess | DeleteWorkspaceCancel
    | UserWorkspaceAction | WorkspaceAction | GetWorkspacePeriod | GetWorkspacePeriodError
    | UpdateWorkspacePeriod | UpdateWorkspacePeriodCancel;

async function loadWorkspaces(dispatch: any, getState: any, user: AuthenticatedUser) {
    const workspaces = await WorkspaceData.LoadWorkspaces(dispatch, getState);
    const result = Object.values(workspaces);

    //const result = await ServiceClient.getServiceClient().getWorkspaces();
    if (result === undefined) {
        dispatch({ type: 'LIST_WORKSPACES_ERROR', errorMessage: 'There was a problem loading workspaces' });
        return [];
    }

    const userWorkspaces = await UserWorkspaceData.LoadUserWorkspaces(dispatch, getState, user); //await ServiceClient.getServiceClient().getUserWorkspaces(user.email);

    const workspaceWithCreator = result.map(r => ({
        name: r.name,
        urlName: r.name,
        isOwnerOrCreator: userWorkspaces[r.name] ? userWorkspaces[r.name].creator || userWorkspaces[r.name].owner : false
    }));
    return workspaceWithCreator;
}


// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    initialLoad: (user: AuthenticatedUser): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'LIST_WORKSPACES' });
        try {

            const workspaceWithCreator = await loadWorkspaces(dispatch, getState, user);
            dispatch({ type: 'GET_WORKSPACES', payload: { workspaces: workspaceWithCreator } });
            dispatch({
                type: 'LIST_WORKSPACES_RESPONSE',
                workspaces: workspaceWithCreator
            });
        } catch (error) {
            dispatch({ type: 'LIST_WORKSPACES_ERROR', errorMessage: error.message });
            return;
        }
    },
    clearData: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'CLEAR_DATA' });
    },
    deleteWorkspace: (workspaceName: string, user: AuthenticatedUser): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'DELETE_WORKSPACE', payload: { workspace: workspaceName } });
        try {
            const serviceClient = ServiceClient.getServiceClient()
            // // get current envelopes.
            // let currentEnvelopes = await serviceClient.getWorkspaceEnvelopes(workspaceName);
            // // delete them all
            // if(currentEnvelopes && currentEnvelopes.length > 0)
            // {
            //     await Promise.all(currentEnvelopes.map(async envelope => {
            //         if(envelope.tag){
            //             return await serviceClient.deleteEnvelope(workspaceName, envelope.id);
            //         }
            //         return true;
            //     }));
            // }

            // const { entities } = getState();
            // const { workspaceGroups } = entities;
            // const { byId } = workspaceGroups;

            // await Promise.all(Object.values(byId).map(async (g:ServiceClient.WorkspaceGroup)=> {
            //     //await serviceClient.removeTagFromWorkspaceGroup(g, g.tags);
            //     await serviceClient.removeWorkspaceGroup(workspaceName, g.id);
            // }));

            // attempt to delete the workspace
            await serviceClient.deleteWorkspace(workspaceName);

            const workspaces = await loadWorkspaces(dispatch, getState, user);
            dispatch({ type: 'GET_WORKSPACES', payload: { workspaces: workspaces } });
            dispatch({ type: 'DELETE_WORKSPACE_SUCCESS' });

        } catch (e) {
            dispatch({ type: 'DELETE_WORKSPACE_ERROR', errorMessage: e.toString() });
        }
    },
    cancelDeleteWorkspace: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: 'DELETE_WORKSPACE_CANCEL' });
    },
    getWorkspacePeriodDetails: (workspaceName: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            const response = await ServiceClient.getServiceClient().getPeriodForWorkspace(workspaceName);
            if (response.length) {
                const timePeriod: PeriodOptions = parseInt(PeriodOptions[response[0].currentPeriod], 10);
                const duration = response[0].duration;
                const durationUnit = response[0].durationUnit;
                dispatch({ type: 'LOAD_WORKSPACE_PERIOD', workspaceName: workspaceName, currentPeriod: timePeriod, duration: duration, durationUnit: durationUnit });
            } else {
                dispatch({ type: 'LOAD_WORKSPACE_PERIOD', workspaceName: workspaceName, currentPeriod: PeriodOptions.LastHour, duration: 0, durationUnit: 'Days' });
            }
        } catch (error) {
            dispatch({ type: 'WORKSPACE_PERIOD_ERROR', errorMessage: error.message });
            return;
        }
    },
    updateWorkspacePeriod: (workspaceName: string, currentPeriod: PeriodOptions, duration: number, durationUnit: string): AppThunkAction<KnownAction> => async (dispatch) => {
        try {
            await ServiceClient.getServiceClient()
                .putPeriodForWorkspace(workspaceName, PeriodOptions[currentPeriod].toString(), duration, durationUnit);
            dispatch({ type: 'UPDATE_WORKSPACE_PERIOD' });
        } catch (error) {
            dispatch({ type: 'WORKSPACE_PERIOD_ERROR', errorMessage: error.message });
            return;
        }
    },
    cancelUpdateWorkspacePeriod: (): AppThunkAction<KnownAction> => async (dispatch) => {
        dispatch({ type: 'UPDATE_WORKSPACE_PERIOD_CANCEL' });
    },
};

// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
export const reducer: Reducer<ListWorkspacesState> = (state: ListWorkspacesState, action: KnownAction) => {
    switch (action.type) {
        case 'LIST_WORKSPACES': {
            return { isLoading: true, error: undefined, workspaces: [] };
        }
        case 'LIST_WORKSPACES_RESPONSE': {
            return { isLoading: false, error: undefined, workspaces: action.workspaces };
        }
        case 'LIST_WORKSPACES_ERROR': {
            return { isLoading: false, error: action.errorMessage, workspaces: undefined };
        }
        case 'CLEAR_DATA': {
            return { isLoading: false, workspaces: undefined };
        }
        case 'DELETE_WORKSPACE': {
            return { isLoading: true, showDeleteConfirmation: false };
        }
        case 'DELETE_WORKSPACE_ERROR': {
            return { isLoading: false, error: action.errorMessage, workspaces: undefined };
        }
        case 'DELETE_WORKSPACE_SUCCESS': {
            return { isLoading: false, showDeleteConfirmation: false, deleteWorkspaceName: "" };
        }
        case 'DELETE_WORKSPACE_CANCEL': {
            return { showDeleteConfirmation: false, deleteWorkspaceName: "", workspaces: state.workspaces };
        }
        case 'LOAD_WORKSPACE_PERIOD': {
            return {
                isLoading: false,
                showUpdatePeriodDialog: true,
                updateWorkspaceName: action.workspaceName,
                currentPeriod: action.currentPeriod,
                duration: action.duration,
                durationUnit: action.durationUnit
            };
        }
        case 'WORKSPACE_PERIOD_ERROR': {
            return {
                isLoading: false,
                error: action.errorMessage,
                showUpdatePeriodDialog: false,
                updateWorkspaceName: ''
            };
        }
        case 'UPDATE_WORKSPACE_PERIOD': {
            return {
                isLoading: false,
                showUpdatePeriodDialog: false,
                updateWorkspaceName: ''
            };
        }
        case 'UPDATE_WORKSPACE_PERIOD_CANCEL': {
            return {
                isLoading: false,
                showUpdatePeriodDialog: false,
                updateWorkspaceName: ''
            };
        }
        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || { currentExceedances: undefined };
};
