export interface Envelope {
    tag: string;
    envelopeStates: EnvelopeState[];
}

export interface EnvelopeState {
    name: string;
    priority: number;
    colour: string;
    condition: EnvelopeStateCondition;
    sequence: number;
    formula?: string;
}

export enum ConditionType {
    LOWHIGH = 'LOWHIGH',
    LOW = 'LOW',
    HIGH = 'HIGH',
    CUSTOM = 'CUSTOM'
}

export interface EnvelopeStateCondition {
    type: ConditionType;
    low_threshold?: number;
    high_threshold?: number;
    formula_type?: string;
}