import { msalGetToken } from './react-msal';
import * as msal from "@azure/msal-browser";

const msalConfig: msal.Configuration = {
  auth: {
      clientId: process.env.REACT_APP_AAD_CLIENTID!,
      authority: 'https://login.microsoftonline.com/woodsideenergy.onmicrosoft.com',
      redirectUri: process.env.REACT_APP_BASEURL
  }
};

export const msalInstance = new msal.PublicClientApplication(msalConfig);

export interface AuthenticatedUser {
    firstname: string;
    lastname: string;
    fullnameAndTitle: string;
    email: string;
    sessionExpiration: Date;
    token: string;
}

export function getAdUser(): AuthenticatedUser {
    const userAccount = msalInstance.getActiveAccount();

    return {
        firstname: userAccount!.idTokenClaims!['given_name'] as string,
        lastname: userAccount!.idTokenClaims!['family_name'] as string,
        fullnameAndTitle: userAccount!.name!,
        email: userAccount!.username!,
        sessionExpiration: userAccount!.idTokenClaims!.exp! === undefined ? new Date(0) : new Date(userAccount!.idTokenClaims!.exp! * 1000), // exp is in UNIX time,
        token: "" // not used?
    };
}

export function acquireTokenAsync(resource: string) {
    const tokenPromise = msalGetToken(msalInstance, resource);
    return tokenPromise;
}