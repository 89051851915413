export const FormLabelStyles: React.CSSProperties = {
    width: '250px',
    fontWeight: 'bold',
    fontSize: '16px',
    lineHeight: '24px'
};

export const FormFieldStyles: React.CSSProperties = {
    fontSize: '16px',
    lineHeight: '24px',
    backgroundColor: 'white',
    border: '1px solid #6a94b0',
};
