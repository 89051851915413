import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { Breadcrumbs, ColourManager } from './common'
import { EnvelopeStateConditions, EditEnvelopeStateProps } from '../store/edit-envelope'
import { connect } from 'react-redux';
import { ApplicationState } from '../store/index';
import * as WorkspaceStateStore from '../store/bulk-upload';
import * as ServiceClient from '../store/service-client/index';
import { PrimaryButton, Button, IconButton, TooltipHost } from 'office-ui-fabric-react';
import { getId } from 'office-ui-fabric-react/lib/Utilities';
import * as Papa from 'papaparse';
import { MessageBox, MessageBoxType, MessageBoxSize} from './common/index';
import { Icon } from 'office-ui-fabric-react';
import { Spinner, SpinnerSize } from 'office-ui-fabric-react/lib/Spinner';
import { BulkEditState } from '../store/bulk-upload';
import { TriggerConditionToString } from '../utils/condition-converters';



type WorkspaceStateProps = WorkspaceStateStore.BulkUploadState
    & typeof WorkspaceStateStore.actionCreators
    & RouteComponentProps<{}>;

type WorkspaceState = { name: string, highlight:boolean };

const headerStyle = { paddingBottom: '10px', borderBottom: `2px solid ${ColourManager.AppColour}`, marginBottom: 0 };
const shadowboxStyle = { boxShadow: '0 3.2px 7.2px 0 rgba(0, 0, 0, 0.132), 0 0.6px 1.8px 0 rgba(0, 0, 0, 0.108)', padding: '10px', marginLeft: '5px', marginTop:'30px' };

const dropZoneStyle = { 
    height: "200px",
    width: "200px",
    backgroundColor: "#fff",
    border: "2px dashed rgb(187, 186, 186)",
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexFlow: "column",
    fontSize: "16px",
    cursor: "pointer"
};

const dropzoneInfoFieldStyle = {
    display:"flex", 
    flex:"1",
    paddingBottom:"10px"
}

export class BulkUpload extends React.Component<WorkspaceStateProps, WorkspaceState> {
    serviceClient: ServiceClient.ServiceClient;
    constructor(props: any) {
        super(props);
        this.state = { 
            name: '',
            highlight: false,
        };
        this.fileInputRef = React.createRef();
        this.openFileDialog = this.openFileDialog.bind(this);
        this.onDragOver = this.onDragOver.bind(this);
        this.onDragLeave = this.onDragLeave.bind(this);
        this.onDrop = this.onDrop.bind(this);
    }
    fileInputRef: React.RefObject<any>;
    createWS(ws:any): void{
        this.props.createWorkspaceWithEnvelopes(ws);
    }
   
    clearUploadData(){
        this.props.clearData();
    }
    
    componentWillUnmount() {
        this.props.clearData();
    }

    openFileDialog() {
        //if (this.props.disabled) return;
        this.fileInputRef.current.click();
    }

    onDragOver(evt:any){
        this.setState({highlight:true});
    }
    onDragLeave(){
        this.setState({highlight:false});
    }
    onDrop(evt:any){
        evt.preventDefault();
        this.fileInputRef.current.click();
    }

    handleFile(evt:any){
        let self = this;
        Promise.all(evt.dataTransfer.files.map((f:any) => 
            new Promise((resolve,reject)=> 
                Papa.parse(f, { 
                    header: true,
                    dynamicTyping: false,
                    complete: resolve,
                    error: reject
                }
            ))
        )).then(function(results:any){
            let rows:any[] = [];
            results.map((r:any)=> {
                rows.push(...r.data);
            });
            self.props.showWorkspacesToCreate(rows);
        })
        .catch((err:any) => console.log(err))
    }

    renderStateCondValue(state: EditEnvelopeStateProps){
        switch(state.triggerCondition){
            case EnvelopeStateConditions.Above:
                return (
                    <React.Fragment>
                        <div style={{flex: 1}}></div>
                        <div style={{flex: 1}}>{state.value1}</div>
                    </React.Fragment>       
                );
                break;
            case EnvelopeStateConditions.Below: 
                return (
                    <React.Fragment>
                        <div style={{flex: 1}}>{state.value1}</div>
                        <div style={{flex: 1}}></div>
                    </React.Fragment>       
                );
                break;
            case EnvelopeStateConditions.EqualTo:
                return (
                    <React.Fragment>
                        <div style={{flex: 1}}>{state.value1}</div>
                        <div style={{flex: 1}}></div>
                    </React.Fragment>       
                );
                break;
            case EnvelopeStateConditions.FlatLine:
                return (
                    <React.Fragment>
                        <div style={{flex: 1}}>--</div>
                        <div style={{flex: 1}}>--</div>
                    </React.Fragment>       
                );
                break;
            case EnvelopeStateConditions.InBetween:
            case EnvelopeStateConditions.Outside:
            default:
                return (
                    <React.Fragment>
                        <div style={{flex: 1}}>{state.value1}</div>
                        <div style={{flex: 1}}>{state.value2}</div>
                    </React.Fragment>       
                );
        }
    }
    private _hostId: string = getId('tooltipHost');

    renderState(state: BulkEditState){
        let tCondition = TriggerConditionToString(state.triggerCondition);
        return (<div style={{flex:4, flexFlow:"row", display:"flex", padding: "3px 0px", backgroundColor: state.isValid ? "transparent": "rgba(255, 0, 0, 0.2)"}} >
                    <div style={{flex: 2}}>
                        <div style={{ flex: 2, justifyContent: "center", textAlign: "center", color: "white",margin:"0px 10px 0px 0px", padding:"5px", backgroundColor: state.colour}}>{state.name}</div>
                    </div>
                    <div style={{flex: 1}}>{tCondition}</div>
                    {
                        this.renderStateCondValue(state)
                    }
                </div>)
    }

    renderEnvState(state:BulkEditState){
        return (
        <React.Fragment>
            { state.errors && state.errors.length > 0 ? 
                <TooltipHost
                    tooltipProps={{
                        onRenderContent: ()=> {
                            return (
                                <div>
                                    This tag will not be uploaded due to the following errors
                                    <ul>
                                        { state.errors && state.errors.length > 0 ? state.errors.map((e)=> (<li>{e}</li>)): undefined}
                                    </ul>
                                </div>
                            )
                        }
                    }}
                    id={this._hostId}
                    calloutProps={{ gapSpace: 0 }}
                    styles={{ root: { display: 'inline-block' } }}
                >
                { this.renderState(state) }
                </TooltipHost>
            : this.renderState(state)
            }
        </React.Fragment>
        )
    }

    renderAddCancel() {
        return (<React.Fragment>
            <PrimaryButton text="Create Workspaces" onClick={e =>  this.createWS(this.props.workspacesToCreate)} />
            <Button text="Cancel" onClick={e => this.clearUploadData()} style={{marginLeft:"20px"}}/> 
        </React.Fragment>)
    }

    renderRemoveWorkspaceButton(ws:any){
        return (
        <React.Fragment>
            <IconButton title="Remove Workspace" ariaLabel="Remove Workspace" iconProps={{iconName:"clear"}}
            onClick={e=> {
                if (this.props.workspacesToCreate) {
                    let removed= this.props.workspacesToCreate.filter((f)=> f.workspace!=ws.workspace)
                    this.props.setWorkspacesForUpload(removed);
                }
            }}
        />
        </React.Fragment>)
    }

    renderGroups(g: WorkspaceStateStore.BulkEditGroup[], workspaceName:string){
        return (<React.Fragment>
            {g.map(g=> { return this.renderGroup(g,workspaceName)})}
        </React.Fragment>)
    }

    renderGroup(g: WorkspaceStateStore.BulkEditGroup, workspaceName:string){
        return (
            <React.Fragment>
                <h3>{g.group}</h3>
                { g.envelopes.map(e=> { return this.renderEnvelope(e,workspaceName); })}
            </React.Fragment>
        )
    }

    renderEnvelopes(envs: WorkspaceStateStore.BulkEditEnvelope[], workspaceName: string){
        return (<React.Fragment>   
            <ul style={{listStyle:"none", display: "flex", flexFlow:"column"}}>
                <li style={{flexFlow:"row", padding:"10px 0px", display:"flex"}}>
                {this.props.isLoading || this.props.isSuccess ? (<div style={{flex: 1, fontWeight:"bold"}}>Uploaded</div>) : undefined }
                    <div style={{flex: this.props.isLoading || this.props.isSuccess ? 3 : 4, fontWeight:"bold"}}>Envelope</div>
                    <div style={{flex: 2, fontWeight:"bold"}}>State</div>
                    <div style={{flex: 1, fontWeight:"bold"}}>Type</div>
                    <div style={{flex: 1, fontWeight:"bold"}}>Min</div>
                    <div style={{flex: 1, fontWeight:"bold"}}>Max</div>
                </li>
                { envs.map(e=> { return this.renderEnvelope(e,workspaceName)}) }
            </ul>
        </React.Fragment>)
    }

    renderEnvelope(env:WorkspaceStateStore.BulkEditEnvelope, workspaceName:string){
        if(env.uploaded){
            return (<li key={`${workspaceName}-${env.tag}`} style={{padding: "7px 0px", flexFlow:"row", display:"flex"}}>
            <div style={{flex: 1}}>
                <Icon iconName="Accept" 
                        style={{
                            color: ColourManager.StatusColours.greenGreen,
                            fontWeight: 800,
                            fontSize: 25,
                            height: 25,
                            width: 25,
                            margin: '0 25px'}} />
            </div>
            <div style={{flex: 3}}>{env.tag}</div>
            <div style={{flex: 5, flexFlow:"column", display: "flex"}}>
                {
                    env.states.map((state,i)=> this.renderEnvState(state))
                }
                </div>
            </li>)
        }
        else
        {
            return (<li style={{padding: "7px 0px", flexFlow:"row", display:"flex"}}>
            {this.props.isLoading || this.props.isSuccess ? (<div style={{flex: 1, fontWeight:"bold"}}>
                { env.states.some((s)=> s.errors && s.errors.length > 0) ? (<Icon iconName="Clear" 
                            style={{
                                color: ColourManager.StatusColours.darkRed,
                                fontWeight: 800,
                                fontSize: 25,
                                height: 25,
                                width: 25,
                                margin: '0 25px'}} />) : undefined}
            </div>) : undefined }
            <div style={{flex: this.props.isLoading || this.props.isSuccess ? 3: 4}}>{env.tag}</div>
            <div style={{flex: 5, flexFlow:"column", display: "flex"}}>
                {
                    env.states.map((state,i)=> this.renderEnvState(state))
                }
                </div>
            </li>)
        }
    }

    render() {
        if (this.props.errorMessage !== undefined) {
            return (
                <React.Fragment>
                    <Breadcrumbs
                        links={[
                            {
                                to: this.props.match.url,
                                text: `Bulk Upload`
                            }
                        ]}
                    />
                <div style={{ width: '100%', marginTop: '1em' }}>
                    <MessageBox type={MessageBoxType.Error} size={MessageBoxSize.Normal}>
                        <h3 style={{ margin: 20 }}>Error: {this.props.errorMessage}</h3>
                    </MessageBox>
                </div>
                </React.Fragment>
            );
        }
        
        if(this.props.workspacesToCreate && this.props.workspacesToCreate.length > 0){
            return (
                <React.Fragment>
                    <Breadcrumbs
                        links={[
                            {
                                to: this.props.match.url,
                                text: `Bulk Upload`
                            }
                        ]}
                    />
                    <br></br>
                    <br></br>
                    { this.props.isSuccess ? 
                    (<div style={{ width: '100%', marginTop: '1em' }}>
                            <MessageBox type={MessageBoxType.Information} size={MessageBoxSize.Normal}>
                                <h3 style={{ margin: 20 }}>Bulk Upload Successful</h3>
                            </MessageBox>
                    </div>) : undefined }
                    { this.props.envelopeErrors && this.props.envelopeErrors.length > 0 ? 
                        (<div style={{ width: '100%', marginTop: '1em' }}>
                            <MessageBox type={MessageBoxType.Error} size={MessageBoxSize.Normal}>
                            <div style={{flex: '1'}}><ul style={{ margin: 0, padding: 0, listStyle: 'none' }}>
                                {
                                    (this.props.envelopeErrors as any[]).map( (envError, i) => 
                                        (<li style={{ display: 'block', marginTop: '2px', marginBottom: '2px', listStyle: 'none' }}>
                                            {envError}
                                        </li>)
                                    ) 
                                }
                                </ul></div>
                            </MessageBox>
                        </div>) : (<br/>)
                    }
                    
                    {this.props.isLoading || this.props.isSuccess ?  undefined : this.renderAddCancel()}
                    <br></br>
                    { this.props.workspacesToCreate!.map((ws, i) => 
                            (<div key={`ws-${ws}`}>
                                <div style={{...shadowboxStyle, backgroundColor: ws.uploaded ? "rgb(117, 177, 46, .30)" : "transparent" }}>
                                    <h4 style={headerStyle}>
                                        {ws.workspace}
                                        <div style={{marginRight:"2px", marginLeft:"auto", float:"right"}}>
                                        { this.props.isLoading || this.props.isSuccess ? undefined : this.renderRemoveWorkspaceButton(ws) }
                                        </div>
                                    </h4>
                                    { (ws.groups && ws.groups.length > 0) && this.renderGroups(ws.groups,ws.workspace)}
                                    {(ws.envelopes&& ws.envelopes.length > 0) && this.renderEnvelopes(ws.envelopes,ws.workspace)}
                                </div>
                            </div>)
                        )}
                    <br></br>
                    <br></br>
                    {this.props.isLoading || this.props.isSuccess ?  undefined : this.renderAddCancel()}
                </React.Fragment>
            )
        }
        

        return (
            <React.Fragment>
                <Breadcrumbs
                    links={[
                        {
                            to: this.props.match.url,
                            text: `Bulk Upload`
                        }
                    ]}
                />

                { this.props.csvError ? (<div style={{ width: '100%', marginTop: '1em' }}>
                    <MessageBox type={MessageBoxType.Error} size={MessageBoxSize.Normal}>
                        <div style={{flexFlow:'column', display:'flex', justifyContent: 'center'}}>
                            <h3 style={{ margin: 0 }}>Error: Invalid csv file, ensure header row and all required fields are present.</h3>
                        </div>
                    </MessageBox>
                    </div>) : undefined
                }
                {this.props.isLoading ? (<div style={{flex: 1, display:'flex', justifyContent:'center', flexFlow: 'space-around'}}><Spinner size={SpinnerSize.large} label='Validating csv file... ' labelPosition='top'/></div>) : undefined }
                <div style={{display:"flex", flexFlow:"row", marginTop:"30px" }}>
                    <div style={{display:"flex", flexFlow:"row" }}>
                        <div style={{margin:"10px"}}>
                            <div style={ dropZoneStyle }
                            onClick={this.openFileDialog}
                            onDragOver={this.onDragOver}
                            onDragLeave={this.onDragLeave}
                            onDrop={this.onDrop}
                            >
                                <Icon iconName="cloudUpload" style={{fontSize:"50px"}} />
                                <input type='file'
                                    style={{display:"none"}}
                                    ref={this.fileInputRef}
                                    id='file'
                                    className='input-file ms-Button ms-Button--primary'
                                    accept='.csv'
                                    onChange={e => Papa.parse(e.target.files![0], { 
                                        header: true,
                                        dynamicTyping: true,
                                        complete: (r) => this.props.showWorkspacesToCreate(r.data)
                                    })}
                                />
                                <span>Upload File</span>
                            </div>
                        </div>
                    </div>
                    <div style={{display:"flex", flexFlow:"column", flex:"4", marginLeft:"20px"}}>
                        <div style={{padding:"10px"}}>
                            <h3>Upload a <b>CSV</b> file with the format</h3>
                        </div>
                        <div style={{padding:"15px"}}>
                            <b>Workspace, Tag, State, Type, Colour, Min, Max</b>
                        </div>

                        <div style={{padding:"15px"}}>
                            <b>The File Must Include A Header Row</b>
                        </div>

                        <div style={{display:"flex", flexFlow:"column", padding:"10px"}}>
                            <div  style={{display:"flex", flexFlow:"row"}}>
                                <div style={dropzoneInfoFieldStyle}>Workspace</div>
                                <div style={{...dropzoneInfoFieldStyle, flex:"7"}}>The name of the Workspace to be created</div>
                            </div>
                            <div  style={{display:"flex", flexFlow:"row"}}>
                                <div style={dropzoneInfoFieldStyle}>Group</div>
                                <div style={{...dropzoneInfoFieldStyle, flex:"7"}}>The name of the Group within the Workspace to be created</div>
                            </div>
                            <div  style={{display:"flex", flexFlow:"row"}}>
                                <div style={dropzoneInfoFieldStyle}>Tag</div>
                                <div style={{...dropzoneInfoFieldStyle, flex:"7"}}>Tag to be Surveilled</div>
                            </div>
                            <div  style={{display:"flex", flexFlow:"row"}}>
                                <div style={dropzoneInfoFieldStyle}>State</div>
                                <div style={{...dropzoneInfoFieldStyle, flex:"7"}}>Optional: The name of the state you are setting thresholds for will default to Out Of Bounds</div>
                            </div>
                            <div  style={{display:"flex", flexFlow:"row"}}>
                                <div style={dropzoneInfoFieldStyle}>Type</div>
                                <div style={{...dropzoneInfoFieldStyle, flex:"7"}}>Optional: The type of condition (Above, Below, Equal, Flat, Inside, Outside), if not provided the system will make a best guess depending on the Min and Max values entered.</div>
                            </div>
                            <div  style={{display:"flex", flexFlow:"row"}}>
                                <div style={dropzoneInfoFieldStyle}>Colour</div>
                                <div style={{...dropzoneInfoFieldStyle, flex:"7"}}>Optional: The Colour of the State (Red, Orange, Green, Blue/Grey) will default to Red</div>
                            </div>
                            <div  style={{display:"flex", flexFlow:"row"}}>
                                <div style={dropzoneInfoFieldStyle}>Min</div>
                                <div style={{...dropzoneInfoFieldStyle, flex:"7"}}>Optional: If the Tag Value is below this value the state will show (this field is optional depending on the Type selected)</div>
                            </div>
                            <div  style={{display:"flex", flexFlow:"row"}}>
                                <div style={dropzoneInfoFieldStyle}>Max</div>
                                <div style={{...dropzoneInfoFieldStyle, flex:"7"}}>Optional: If the Tag Value is above this value the state will show (this field is optional depending on the Type selected) </div>
                            </div>
                            <div  style={{display:"flex", flexFlow:"row", marginTop:"30px"}}>
                                Once processed the Workspaces will be displayed before they are created.
                            </div>
                            <div  style={{display:"flex", flexFlow:"row", marginTop:"10px"}}>
                                Clicking the X will remove that workspace from the upload.
                            </div>
                            <div  style={{display:"flex", flexFlow:"row", marginTop:"10px"}}>
                                You can create multiple states for an Tag by repeating the tag as a new row
                                with a different state name. 
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }
}

const mapStateToProps = (state: ApplicationState) => state.bulkUpload;

// Wire up the React component to the Redux store
export default (connect(mapStateToProps, WorkspaceStateStore.actionCreators)(BulkUpload));