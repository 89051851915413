import * as React from 'react';
import { getId, Label, DatePicker, DelayedRender, TooltipHost } from 'office-ui-fabric-react';
import { AppearanceManager } from '.';
import './labelled-date-picker.css';

export class LabelledDatePicker extends React.Component<{
    label?: string,
    multiline?: boolean,
    labelLeftPadding?: string,
    value?: Date,
    errorMessage?: string,
    helpMessage?: string,
    onSelectDate?: (newValue?: Date) => void,
    onBlur?: () => void
}, {}> {
    render() {
        const controlId = getId('DatePicker');
        const labelId = getId('Label');
        const labelStyles = this.props.labelLeftPadding === undefined
            ? AppearanceManager.FormLabelStyles
            : { ...AppearanceManager.FormLabelStyles, paddingLeft: this.props.labelLeftPadding };

        let errorMessageElement = (
            <React.Fragment>
                <div style={{ width: '100%' }} />
                <div
                    className="ms-TextField-errorMessage ms-font-s ms-clearfix"
                    style={{ flexGrow: 1, color: 'rgb(168, 0, 0)', paddingLeft: '250px', marginTop: '2px' }}
                >
                    <div role="alert">
                        <DelayedRender>
                            <span data-automation-id="error-message">{this.props.errorMessage}</span>
                        </DelayedRender>
                    </div>
                </div>
            </React.Fragment>
        );

        let label = (
            <Label
                id={labelId}
                htmlFor={controlId}
                style={{ ...labelStyles, color: this.props.errorMessage === undefined ? labelStyles.color : '#a71930' }}
            >
                {this.props.label}
            </Label>
        );

        label = (this.props.helpMessage !== undefined
            ? (
                <TooltipHost
                    content={this.props.helpMessage}
                    tooltipProps={{ style: { overflowY: 'hidden' } }}
                    styles={{ root: { display: 'inline-block' } }}
                >
                    {label}
                </TooltipHost>)
            : label);

        let datePicker = (
            <DatePicker
                id={controlId}
                isMonthPickerVisible={false}
                minDate={new Date()}
                value={this.props.value}
                placeholder={this.props.helpMessage}
                onSelectDate={(d) => this.onSelectDate(d)}
                onAfterMenuDismiss={() => this.onAfterMenuDismiss()}
                className={this.props.errorMessage !== undefined ? 'error' : ''}
            />
        );

        if (this.props.label === undefined) {
            return datePicker;
        } else {
            return (
                <div style={{ display: 'flex', flexFlow: 'wrap', marginBottom: '30px' }}>
                    {this.props.label === undefined ? null : label}
                    {datePicker}
                    {this.props.errorMessage !== undefined ? errorMessageElement : null}
                </div>
            );
        }
    }

    onSelectDate(newValue?: Date | null) {
        if (this.props.onSelectDate !== undefined) {
            this.props.onSelectDate(newValue === null ? undefined : newValue);
        }
    }

    onAfterMenuDismiss() {
        if (this.props.onBlur !== undefined) {
            this.props.onBlur();
        }
    }
}