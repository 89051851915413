import * as React from 'react';
import { DatePicker, Dropdown } from 'office-ui-fabric-react';
import { getHours, setHours,setMinutes, setSeconds } from 'date-fns';


type DateTimePickerProps = {
    disabled?: boolean;
    defaultDate?: Date;
    defaultTime?: number;
    isRequired?: boolean;
    minDate?: Date;
    onSelectDate?: (date: Date) => void;
    showTimeComponent?:boolean
};

export class DateTimePicker extends React.Component<DateTimePickerProps, {dateValue: Date, timeValue: number }> {
    
    constructor(props: DateTimePickerProps) {
        super(props);
        const dateToUse = this.props.defaultDate ? this.props.defaultDate : new Date(); 
        this.state = {
            dateValue: dateToUse,
            timeValue: getHours(dateToUse), 
            };
    }

    componentDidUpdate(prevProps: DateTimePickerProps, prevState: any) {
        if (this.props.defaultDate !== prevProps.defaultDate) {
            const dateToUse = this.props.defaultDate ? this.props.defaultDate : new Date();
            this.setState({
                dateValue: dateToUse,
                timeValue:getHours(dateToUse)
            });
        }
    }

    onSelectDate(newDate?: Date | null) {
        if (newDate !== undefined && newDate !== null) {
            this.setState({...this.state, dateValue: newDate}, ()=> this.setDate())
        }
    }

    onSelectTime(newTime: number | undefined) {
        if (newTime !== undefined) {
            this.setState({...this.state, timeValue: newTime}, ()=> this.setDate());
        }
    }

    setDate(){
        const changedDate = setSeconds(setMinutes(setHours(this.state.dateValue, this.state.timeValue),0),0);
        if (this.props.onSelectDate !== undefined) {
            if(this.state.dateValue && this.state.timeValue){
                
                this.props.onSelectDate(changedDate)
            }
        }
    }

    renderTime(){
        const times = {
            0: '12:00 AM',  1: '01:00 AM',  2: '02:00 AM',  3: '03:00 AM',  4: '04:00 AM',  5: '05:00 AM',
            6: '06:00 AM',  7: '07:00 AM',  8: '08:00 AM',  9: '09:00 AM', 10: '10:00 AM', 11: '11:00 AM',
           12: '12:00 PM', 13: '01:00 PM', 14: '02:00 PM', 15: '03:00 PM', 16: '04:00 PM', 17: '05:00 PM',
           18: '06:00 PM', 19: '07:00 PM', 20: '08:00 PM', 21: '09:00 PM', 22: '10:00 PM', 23: '11:00 PM'
        };
        return(
            <Dropdown
                        defaultSelectedKey={this.state.timeValue}
                        onChange={(_, o) => this.onSelectTime(o ? o.key as number : undefined)}
                        styles={{ root: { display: 'flex', width: '10em', marginLeft: '5px' }}}
                        options={Object.keys(times).map(t => ({ key: parseInt(t, 0), text: times[t] }))}
                        disabled={this.props.disabled}
                    />
        )
    }

    render() {
        return (
                <div style={{display: 'flex', flexFlow: 'row'}}>
                    <DatePicker
                        isRequired= {this.props.isRequired}
                        style={{ display: 'flex', flex: 1 ,marginRight: '8px'}}
                        onSelectDate={(d) => this.onSelectDate(d)}
                        value={this.state.dateValue}
                        disabled={this.props.disabled}
                        minDate= {this.props.minDate}
                    />
                    {this.props.showTimeComponent || this.props.showTimeComponent == undefined
                    ?this.renderTime():null}
                </div>
        );
    }
}