import React from 'react';
import { ExceedancesRowProps } from '../../store/view-workspace';
import { ActionButton, Checkbox } from 'office-ui-fabric-react';
import AddSuppression from './add-suppression-new';
import './exceedances-row.css';
import { TagEnvelopeDefinition, TagEnvelopeState, WorkspaceGroup, getServiceClient } from '../../store/service-client/index'
import { TagPickerStyle } from '../tag-picker/tag-picker'
import { ExceedanceRowState } from './exceedance-row-state';
import { MapConstraintsValuesToString } from '../../utils/condition-converters';
import { ApplicationState } from '../../store';
import { connect } from 'react-redux';
import { actionCreators as ViewWorkspaceActionCreators } from '../../store/action-creators/view-workspace-action-creator';
import { actionCreators as GroupCreators } from '../../store/action-creators/group-action-creator';
import { withRouter, RouteComponentProps } from 'react-router-dom';

export type ExceedancesRowState = {
    isAdhocSuppressionOpened: boolean,
    checked: boolean,
    showData: boolean
};

type ExceedanceComponentRowState = {
    workspaceName: string,
    canEdit: boolean,
    showSuppressMenu: boolean,
    onSelection: any,
    startTime: Date, 
    endTime: Date
}

type ExceedancesRowComponentProps = ExceedanceComponentRowState & ExceedancesRowProps & RouteComponentProps & {
    updateSuppressionList: any,
    selected: boolean,
    group?: WorkspaceGroup,
    updateGroup: any,
    removeTagFromGroup: any
};

export class ExceedancesRow extends React.Component<ExceedancesRowComponentProps, ExceedancesRowState> {
    openAdhocSuppression = () => { this.setState({ isAdhocSuppressionOpened: true }); };
    closeAdhocSuppression = () => { this.setState({ isAdhocSuppressionOpened: false }, () => this.props.updateSuppressionList()); };

    constructor(props: any) {
        super(props);
        this.state = {
            checked: this.props.selected,
            isAdhocSuppressionOpened: false,
            showData: false
        };
    }

    _selection = () => {
        this.setState({checked: ! this.state.checked}, () => {
            if ( this.props.onSelection ) {
                this.props.onSelection(this.props as TagEnvelopeDefinition, this.state.checked);
            }
        });
    }

    async _viewTagData(tag: string, states: TagEnvelopeState[]) {
        //Map the Condition Values From the states To a Tag Array.

        //Prefix expression tags with 'model_tag.' - needed for plotting in P2
        var serviceClient = getServiceClient();
        const tagType = await serviceClient.getTagType(tag);
        tag = tagType === 'Expression' ? `model_tag.${tag}` : tag;

        const tagArr: any[] = [`{${tag}}`];
        if(states && states.length > 0)
        {
            tagArr.push(...MapConstraintsValuesToString(states));
        }
        const tags = tagArr.join(',');
        const ps2TrendUrl = process.env.REACT_APP_P2_TREND_URL;
        const startTime = this.props.startTime.toISOString(); //new Date().toISOString(); // this.props.startTime
        const endTime =  this.props.endTime.toISOString(); //new Date().toISOString(); // this.props.endTime
        const p2url = `${ps2TrendUrl}?tags=${tags}&startTime=${startTime}&endTime=${endTime}&frameless=true`;
        window.open(p2url, '_blank');
    }

    _removeTagFromGroup(tag: string, group: WorkspaceGroup){
        const tags = group.tags.filter(t=> t != tag);
        group.tags = tags;
        //this.props.updateGroup(group);
        this.props.removeTagFromGroup(group,tag);
    }

    render() {
        const items = [
            { key: 'data', text: 'Plot Trend ...', iconProps: { iconName: 'chart' }, 
                onClick: (e: any) => {  this._viewTagData(this.props.tag, this.props.states);  }
            }];
        if(this.props.canEdit && this.props.showSuppressMenu){
            items.push({ key: 'suppress', text: 'Suppress ...', iconProps: { iconName: 'Hide' }, 
                onClick: (e: any) => this.openAdhocSuppression() });
        }
        if(this.props.canEdit)
        {
            items.push({ key: 'editEnvelope', text: 'Edit Envelope', iconProps: { iconName: 'Edit'},
                onClick: (e:any) => this.props.history.push(`/edit-envelope/${this.props.workspaceName}/${this.props.id}`) } );
        }
        if(this.props.canEdit && this.props.group && this.props.group.name != 'Tags Without a Group'){
            items.push({ key: 'removeTagFromGroup', text: 'Remove Tag From Group', iconProps: { iconName: 'Delete'},
                onClick: (e:any) => this._removeTagFromGroup(this.props.tag, this.props.group!) } );
        }
        
        const kebabMenu = <React.Fragment>
                <ActionButton
                    onMouseDown={(e: any) => e.stopPropagation()}
                    styles={{ menuIcon: { display: 'none' }, root: { height: 'auto' } }}
                    iconProps={{ iconName: 'MoreVertical' }}
                    menuProps={{ items: items}}
                />
            </React.Fragment>

        return (
            <React.Fragment>
                <div className='ms-Grid'  >
                    <div className='ms-Grid-row' style={{marginBottom: '5px', marginTop:'5px'}}>
                        <div className='ms-Grid-col ms-sm6 ms-xl3 ms-xxl3 ms-xxxl3' >
                            <div className='ms-Grid-Row'>
                                {this.props.canEdit && (<div className='ms-Grid-col ms-sm2'><Checkbox checked={this.state.checked} onChange={ this._selection}/></div>)}
                            </div>
                            <div className='ms-Grid-col ms-sm10' style={{minWidth:'100px', overflowWrap:'break-word'}}>{this.props.tag}</div>
                        </div>
                        <div className='ms-Grid-col ms-xl4 ms-xxl3 ms-xxxl3 ms-hiddenLgDown' style={{minWidth:'100px', overflowWrap:'break-word'}} >
                                {this.props.description}
                        </div>
                        <div className='ms-Grid-col ms-xxl1 ms-xxxl1 ms-hiddenXlDown'>{this.props.unit}</div>
                        <div className='ms-Grid-col ms-sm6 ms-xl4 ms-xxl4 ms-xxxl4' >
                        {
                        (this.props.states ? (this.props.states).map((s, i) => 
                            (<ExceedanceRowState 
                                key={`${this.props.tag}-${s.name}`} 
                                tag={this.props.tag} rowState={s} 
                                canEdit={this.props.canEdit} 
                                workspace={this.props.workspaceName} 
                                envelopeId={this.props.id}
                                suppressed={this.props.suppressed}
                                currentValue ={this.props.currentValue}
                            />)): (<br/>)) 
                        }
                        </div>
                        <div className='ms-Grid-col ms-sm1 ms-xl1 ms-xxl1 ms-xxxl1 ms-hiddenLgDown'>
                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm9 ms-xl8' >{this.props.currentValue}</div>
                                <div className='ms-Grid-col ms-sm3 ms-xl2'>
                                    <div style={{display:'flex', flexFlow:'row nowrap', alignItems:'right', justifyContent: 'flex-end'}}></div>
                                    {kebabMenu}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                {this.state.isAdhocSuppressionOpened
                    ? (
                        <AddSuppression
                            tagPickerStyle={TagPickerStyle.MULTI_TAG}
                            enableTagPicker
                            allowInformantTag
                            showStartDate
                            showEndDatePickerOnly
                            tags={[this.props as TagEnvelopeDefinition]}
                            workspaceName={this.props.workspaceName}
                            onDismissed={this.props.updateSuppressionList}
                            singleTagPlantName={this.props.plantName}
                        />)
                    : null}
                
            </React.Fragment>
        );
    }

    componentDidUpdate(prev:ExceedancesRowComponentProps){
        if(prev.selected != this.props.selected){
            this.setState({checked: this.props.selected});
        }
    }

}

const mapStateToProps = (state: ApplicationState, ownProps:any) => {
    const { viewWorkspace } = state;
    const workspaceName = viewWorkspace.workspaceName;
    const { selectedTags } = viewWorkspace;
    const selTags =  selectedTags ? selectedTags : [];
    const newState = {
        startTime: viewWorkspace.startTime,
        endTime: viewWorkspace.endTime,
        workspaceName: workspaceName,
        canEdit:viewWorkspace.canEdit,
        showSuppressMenu: viewWorkspace.displayExceedanceType !== "SUPPRESSED" && viewWorkspace.canEdit,
        selected: selTags.some(t=> t.tag == ownProps.tag)
    }
    return newState;
} 

const mapDispatchToProps = {
    updateSuppressionList: ViewWorkspaceActionCreators.updateSuppressionList,
    onSelection: ViewWorkspaceActionCreators.setSelectedTags,
    updateGroup: GroupCreators.updateGroup,
    removeTagFromGroup: GroupCreators.removeTagFromGroup
};

// Wire up the React component to the Redux store
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(ExceedancesRow));