import * as ServiceClient from '../store/service-client';
import { ExceedancesRowProps } from '../store/view-workspace';
import { ColourManager } from '../components/common';
import { ConditionsToDisplayRange } from './condition-converters';
import { isWithinRange } from 'date-fns';

export function BuildExceedancesDisplayValues(
    tagsInWorkspace: ServiceClient.TagEnvelopeDefinition[],
    allExceedances: ServiceClient.TagExceeedance[],
    tagDetails: Record<string, ServiceClient.TagDetail>,
    startTime: Date,
    endTime: Date) {

    //Map the Workspace tags to exceedences.
    //Anything that doesnt exist in exceedences mark as no-data
    const result = tagsInWorkspace.map(tag => {
        const ex = allExceedances.find(e => e.tag == tag.tag);
        const tagDetail = tagDetails[tag.tag];

        let currentValue;
        // const currentValue = tagDetail ? tagDetail.currentValue ? tagDetail.currentValueTime ?
        //     isWithinRange(tagDetail.currentValueTime, startTime, endTime)
        //         ? parseFloat(tagDetail.currentValue) : undefined : undefined : undefined : undefined;

        if (tagDetail && tagDetail.currentValue && tagDetail.currentValueTime) {
            if (isWithinRange(tagDetail.currentValueTime, startTime, endTime)) {
                currentValue = parseFloat(tagDetail.currentValue);
            }
        }

        const exceedance = ({
            id: tag!.id || "",
            tag: tag.tag,
            description: tagDetail ? tagDetail.description : '--',
            plantName: tagDetail ? tagDetail.plantName : '',
            unit: tagDetail ? tagDetail.unitOfMeasure : '--',
            states: mapStates(tag, ex, currentValue),
            currentValue: currentValue,
            events: [],
            suppressed: false,

        } as ExceedancesRowProps);
        return exceedance;
    });

    return result;
}

function mapStates(tag: ServiceClient.TagEnvelopeDefinition, ex?: ServiceClient.TagExceeedance, tagCurrentValue?: number): ServiceClient.TagEnvelopeState[] {
    if (ex === undefined) {
        return [{
            priority: 999,
            description: "",
            short_description: "",
            name: ServiceClient.STATE_NO_DATA,
            colour: ColourManager.StatusColours.blueGrey,
            conditions: [],
            met: false
        }];
    }
    const states = ex && ex.states ? ex.states.map((s) => {
        let tState = tag.states && tag.states.length > 0 ? tag.states.find(tw => tw.name === s.name) : undefined;
        if (!tState) {
            tState = {
                priority: 999,
                description: "",
                short_description: "",
                name: s.name,
                colour: ColourManager.StatusColours.blueGrey,
                conditions: [],
                met: true
            }
        }

        return <ServiceClient.TagEnvelopeState>{
            name: s.name,
            priority: tState!.priority,
            colour: tState!.colour,
            displayRange: ConditionsToDisplayRange(tState!.conditions),
            currentValue: tagCurrentValue,
            conditions: tState!.conditions,
            description: tState!.description,
            short_description: tState!.short_description || "",
            met: tagCurrentValue === undefined && s.name === "no-data" ? true : s.met
        }
    }
    ) : [];

    return states;
}