export function anythingIncludesText(filterText: string, searchables: string[]): boolean {
    if (searchables === undefined || searchables.length === 0) {
        return false;
    }
    let searchingFor = filterText ? filterText.toUpperCase() : '';
    return searchables
        .map(searchable => searchable !== undefined && 
                           searchable !== null && 
                           searchable.toUpperCase().indexOf(searchingFor) >= 0)
        .reduce((p, c) => p || c);
}

export function allOfTheseIncludesText(filters: string[], searchables: string[]): boolean {
    if ( filters === undefined || filters.length === 0 ) {
        return true;
    }

    return filters
        .map( filterText => anythingIncludesText(filterText, searchables))
        .reduce( (p: boolean, c: boolean) => p && c, true );
}

class SplitTerms {
    positive: string[];
    negative: string[];
}

export function splitTextToTerms(searchText: string): string[] {
    let cleanText = searchText ? searchText.trim() : '';
    if ( cleanText.length > 0 ) {
        return cleanText.replace(/[\t ]+ /g, ' ').split(' ');
    } else {
        return [];
    }
}

export function splitTermsToPositiveAndNegative(terms: string[]): SplitTerms {
    let result = new SplitTerms;
    result.positive = terms.filter(t => t.indexOf('!') !== 0);
    result.negative = terms.filter(t => t.indexOf('!') === 0).map(t => t.substring(1));
    return result;
}

export function transformTermToQueryComponent(term: string): string {
    if ( term === undefined || term === null || term.trim().length === 0 ) {
        return '';
    }

    let result = '';
    let firstChar = term.charAt(0);
    switch (firstChar) {
        case '@': 
            term = term.substring(1);
            result = `(plant_name eq '${term}')`;
            break;
        case '#':
            term = term.substring(1);
            result = `(unit_of_measure eq '${term}')`;
            break;
        default:
            var namespaceMatches = term.match(/^\w+::/);
            if (namespaceMatches) {
                var namespace_match = namespaceMatches[0];
                var namespace = namespace_match.split("::")[0];
                var updated_term = term.replace(namespace_match,"");
                result = `namespace eq '${namespace}' and (contains(name,'${updated_term}') or contains(description,'${updated_term}'))`;
            } else {
                result = `(contains(name,'${term}') or contains(description,'${term}'))`;
            }
            break;
    }
    return result;
}

export function convertSearchTextToOdataQuery(searchText: string): string {
    let terms = splitTextToTerms(searchText);
    let {positive, negative} = splitTermsToPositiveAndNegative(terms);

    let positiveTerms = undefined;
    if ( positive.length > 0 ) {
        positiveTerms = positive.map(t => transformTermToQueryComponent(t)).join(' and ');
    }
    let negativeTerms = undefined;
    if ( negative.length > 0 ) {
        negativeTerms = negative.map(t => transformTermToQueryComponent(t)).map(q => `(not ${q})`).join(' and ');
    }
    
    let result = [positiveTerms, negativeTerms].filter(q => q !== undefined).join(' and ');
    return result;
}