import * as ServiceClient from '../service-client';
import { WorkspaceGroup } from '../service-client';
import { Workspace } from '../slice-reducers/common-types';
import { LoadWorkspaceEnvelopes } from './envelopes';

export async function LoadGroupsForWorkspace(dispatch:any, getState: any, workspaceName: string): Promise<ServiceClient.WorkspaceGroup[]>{
    const serviceClient = ServiceClient.getServiceClient();
    
    const { entities } = getState();
    const { workspaces } = entities;
    const { workspaceGroups } = entities;
    const {allIds, byId } = workspaceGroups;
    const workspace:Workspace = workspaces[workspaceName];

    if(workspace && workspace.groups && workspace.groups.every((g:number)=> allIds.some((a:number)=> a === g)))
    {
        const loadedGroups = workspace.groups.map((g:number)=> byId[g]);
        return loadedGroups; 
    }
    
    const groupList = await serviceClient.getWorkspaceGroups(workspaceName);
    
    //Check that all tags are in a group... if not add the Tags Without Group Group.
    const groupTags = groupList.reduce((c:string[],i)=> {
        const tagsToAdd = i.tags && i.tags.length ? i.tags : []; 
            return c.concat(tagsToAdd);
    },[]);

    
    const tags = !workspace ? await getTags(dispatch,getState, workspaceName) : workspace.tags || [] ; 
    
   const tagsWithoutGroups = tags.filter(t=> groupTags.some(gt=> gt == t)==false);
        
    if(tagsWithoutGroups && tagsWithoutGroups.length > 0){
        const noGroupGroup:WorkspaceGroup = { id: 0, name: 'Tags Without a Group', workspace: workspaceName, tags: tagsWithoutGroups, groups:[], expanded:false };
        groupList.push(noGroupGroup); 
    }
    
    dispatch({type: 'GET_GROUPS', payload:{ groups: groupList }});
    return groupList;
}

async function getTags(dispatch:any,getState:any, workspaceName:string){
    const wsTags = await LoadWorkspaceEnvelopes(dispatch,getState,workspaceName);
    return wsTags.map(t=> t.tag);
}