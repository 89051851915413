import { createSelector } from 'reselect';
import { ApplicationState } from '..';
import { workspaceSelector } from './workspace-selector';
import { UserWorkspace } from '../service-client';

const selectUserWorkspaces = (state:ApplicationState) => state.entities.userWorkspaces.byId;

export const userWorkspacesSelector = createSelector([selectUserWorkspaces], (spaces) => {
    const userSpaces: UserWorkspace[] = Object.values(spaces);
    return userSpaces;
});

export const selectUserWorkspace = createSelector([workspaceSelector, selectUserWorkspaces], (workspace, userWorkspaces)=> {
    if(workspace && userWorkspaces){
        return userWorkspaces[workspace.name];
    } 
    return undefined;
});

export const selectCanEdit = createSelector([selectUserWorkspace], (uSpace)=> {
    const canEdit = uSpace ? uSpace.creator || uSpace.owner || uSpace.member : false;
    return canEdit;
});
