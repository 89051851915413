import React from 'react';
import { Icon } from 'office-ui-fabric-react';
import { ColourManager } from '.';

export enum MessageBoxType { Success, Error, Information }
export enum MessageBoxSize { Normal, Compact, Baby }
export class MessageBox extends React.Component<{ type: MessageBoxType, size?: MessageBoxSize }, {}> {
    render() {
        let iconName;
        let colour;
        let height;
        let iconFontSize;
        switch (this.props.type) {
            case MessageBoxType.Success: {
                iconName = 'Completed';
                colour = ColourManager.StatusColours.greenGreen;
                break;
            }
            case MessageBoxType.Error: {
                iconName = 'Warning';
                colour = ColourManager.StatusColours.darkRed;
                break;
            }
            case MessageBoxType.Information: {
                iconName = 'Info';
                colour = ColourManager.StatusColours.blueGrey;
                break;
            }
            default: {
                break;
            }
        }
        switch (this.props.size) {
            case MessageBoxSize.Compact: {
                height = 32;
                iconFontSize = 32;
                break;
            }
            case MessageBoxSize.Baby: {
                height = 16;
                iconFontSize = 24;
                break;
            }
            default: {
                height = 64;
                iconFontSize = 64;
                break;
            }

        }
        return (
            <div
                style={{
                    backgroundColor: ColourManager.PrimaryColours.sand,
                    width: '100%', padding: '1em',
                    borderLeftColor: colour,
                    borderLeftWidth: 3,
                    borderLeftStyle: 'solid',
                    //minHeight: height,
                    flexFlow: 'row',
                    alignContent: 'center',
                    justifyContent: 'flex-start',
                    display:'flex'
                }}
            >
                <div style={{ width: iconFontSize, display: 'flex', alignContent: 'center', justifyContent: 'center' }}>
                    <Icon iconName={iconName} color={colour} styles={{ root: { fontSize: `${iconFontSize}px`, color: colour } }} />
                </div>
                <div style={{ marginLeft: height + 16, height: '100%', display:'flex', flexFlow: 'row',justifyContent:'space-between', alignContent: 'center' }}>
                    {this.props.children}
                </div>
            </div>
        );
    }
}