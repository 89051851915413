import { Reducer } from 'redux';
import { AppThunkAction } from './';
import * as ServiceClient from './service-client';
import { addHours } from 'date-fns';
// STATE - This defines the type of data maintained in the Redux store.
export interface ManageSuppressionState {
    saveInProgress?: boolean;
    saveErrorMessage?: string;
    saveSuccess?: boolean;

    workspaceName: string;
    suppressionRules?: ServiceClient.SuppressionRule[];
    changedSuppressionRules?: ServiceClient.SuppressionRule[];
    
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface Reset { type: 'MANAGE_SUPPRESSION_RESET'; suppressionRules?: ServiceClient.SuppressionRule[]; workspaceName: string; }

export interface ManageSuppressionEndNow { type: 'MANAGE_SUPPRESSION_END_NOW'; identifier: string; }

export interface ManageSuppressionSave { type: 'MANAGE_SUPPRESSION_SAVE'; }
export interface ManageSuppressionSaveSuccess { type: 'MANAGE_SUPPRESSION_SAVE_SUCCESS'; }
export interface ManageSuppressionSaveError { type: 'MANAGE_SUPPRESSION_SAVE_ERROR'; message: string; }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = Reset | ManageSuppressionEndNow | ManageSuppressionSave | ManageSuppressionSaveSuccess | ManageSuppressionSaveError;

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    reset: (workspaceName: string, suppressionRules?: ServiceClient.SuppressionRule[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'MANAGE_SUPPRESSION_RESET', suppressionRules, workspaceName });
    },
    updateSuppressionList: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        const serviceClient = ServiceClient.getServiceClient();
        const allSuppressionRules = await serviceClient.getAllSuppressionRules(getState().manageSuppression.workspaceName);
        dispatch({
            type: 'MANAGE_SUPPRESSION_RESET',
            suppressionRules: allSuppressionRules,
            workspaceName: getState().manageSuppression.workspaceName
        });
    },
    suppressionEndNow: (rule: ServiceClient.SuppressionRule, currentUser: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'MANAGE_SUPPRESSION_SAVE' });

        try {
            if (rule) {
                rule.end_time = new Date().getTime();
                await ServiceClient.getServiceClient().putSuppressionRule(getState().manageSuppression.workspaceName, rule);
            }
    
            dispatch({ type: 'MANAGE_SUPPRESSION_SAVE_SUCCESS' });
        } catch (e) {
            dispatch({ type: 'MANAGE_SUPPRESSION_SAVE_ERROR', message: e.toString() });
        }
    },
    extendSuppression: (identifier: string, hours: number, currentUser:string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'MANAGE_SUPPRESSION_SAVE' });

        try {
            let rule = getState().manageSuppression.suppressionRules!.find(r => r.identifier === identifier);
            if (rule) {
                let endTime = rule.end_time ? rule.end_time : new Date().getTime();
                await ServiceClient.getServiceClient().putSuppressionRule(getState().manageSuppression.workspaceName, {
                   ...rule,
                    end_time: addHours(endTime, hours).getTime(),
                    modified_by: currentUser
                });
            }
            dispatch({ type: 'MANAGE_SUPPRESSION_SAVE_SUCCESS' });
        } catch (e) {
            dispatch({ type: 'MANAGE_SUPPRESSION_SAVE_ERROR', message: e.toString() });
        }
    }
};

// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
export const reducer: Reducer<ManageSuppressionState> = (state: ManageSuppressionState, action: KnownAction) =>  {
    switch (action.type) {
        case 'MANAGE_SUPPRESSION_RESET': {
            return {
                saveInProgress: false,
                suppressionRules: action.suppressionRules,
                workspaceName: action.workspaceName,
                changedSuppressionRules: [],
                currentEnvelopes: []
            };
        }
        case 'MANAGE_SUPPRESSION_SAVE': {
            return { ...state, saveInProgress: true };
        }
        case 'MANAGE_SUPPRESSION_SAVE_SUCCESS': {
            return { ...state, saveInProgress: false, saveSuccess: true, saveErrorMessage: undefined, changedSuppressionRules: [] };
        }
        case 'MANAGE_SUPPRESSION_SAVE_ERROR': {
            return { ...state, saveInProgress: false, saveSuccess: undefined, saveErrorMessage: action.message };
        }
        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || { workspaceName: '' };
};