import '@babel/polyfill';

import * as React from 'react';
import { applyMiddleware, compose, createStore, StoreEnhancer, Store, combineReducers } from 'redux';
import thunk from 'redux-thunk';
import * as ReactDOM from 'react-dom';
import App from './App';
import { Fabric, loadTheme, createTheme } from 'office-ui-fabric-react';
import { initializeIcons } from 'office-ui-fabric-react/lib/Icons';
//import { FluentCustomizations, FluentTheme, FluentStyles } from '@uifabric/fluent-theme'
import { ApplicationState, reducers, dispatchLogger } from './store';
import { Provider } from 'react-redux';
import { runWithMsal } from './auth/react-msal';
import { msalInstance } from './auth';
//import * as PinpointAnalytics from './analytics';

import * as themes from './themes/themePallets'

const DO_NOT_LOGIN = false;

import 'normalize.css';
import 'office-ui-fabric-react/dist/css/fabric.css';

if (typeof window !== 'undefined' && window.fetch === undefined) {
    // fetch() polyfill for making API calls.
    require('whatwg-fetch');
}

const store = configureStore((window as any).initialReduxState as ApplicationState);

function configureStore(initialState?: ApplicationState) {
    // Build middleware. These are functions that can process the actions before they reach the store.
    const windowIfDefined = typeof window === 'undefined' ? null : window as any;
    // If devTools is installed, connect to it
    const devToolsExtension = windowIfDefined && windowIfDefined.devToolsExtension as () => StoreEnhancer;

    const createStoreWithMiddleware = compose(
        applyMiddleware(thunk, dispatchLogger),
        devToolsExtension ? devToolsExtension() : (f: any) => f
    )(createStore);

    // Combine all reducers and instantiate the app-wide store instance
    const allReducers = combineReducers<ApplicationState>(Object.assign({}, reducers));
    return createStoreWithMiddleware(allReducers, initialState) as Store<ApplicationState>;
}

// tslint:disable-next-line:no-string-literal
window['Logging'] = {
    level: 3,
    log: function (message: any) {
        // tslint:disable-next-line:no-console
        // console.log(message);
    },
    piiLoggingEnabled: false
};



const theme: any = createTheme(themes.mainBlue);

const loadedTheme = loadTheme(theme);
initializeIcons('https://static2.sharepointonline.com/files/fabric/assets/icons/');

document.body.style.backgroundColor = theme.white; //'white';

//PinpointAnalytics.init();

runWithMsal(
    msalInstance,
    () => {
        ReactDOM.render(
            <Provider store={store}>
                <Fabric theme={loadedTheme}>
                    <App />
                </Fabric>
            </Provider>,
            document.getElementById('root')
        );
    },
    DO_NOT_LOGIN);