import { Reducer } from 'redux';
import { AppThunkAction } from './index';
import * as ServiceClient from './service-client/index';
import { EnvelopeProps } from './edit-envelope';
import { AddWorkspaceAction } from './slice-reducers/common-actions';
import { ForceLoadUserWorkspaces } from './loading-data/user-workspaces';
import { AuthenticatedUser } from '../auth';



// STATE - This defines the type of data maintained in the Redux store.
export interface CreateWorkspaceState {
    isLoading: boolean;
    errorMessage?: string;
    fileUploaded?: boolean;
    createdWorkspace?: string;
    updateMessage?: string[];
    envelopeErrors?: string[];
    workspacesToCreate?: CreateWorkspaceWithEnvelopesProps[];
    // Newly Added
    createdWorkspaceWithTime?: string;
}

export interface CreateWorkspaceWithEnvelopesProps {
    workspace: string;
    envelopes: EnvelopeProps[];
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface CreateWorkspace {
    type: 'CREATE_WORKSPACE';
}

export interface CreateWorkspaceSuccess {
    type: 'CREATE_WORKSPACE_SUCCESS';
    createdWorkspace: string;
    timePeriod: string;
}

export interface CreateWorkspaceError {
    type: 'CREATE_WORKSPACE_ERROR';
    message: string;
}

interface ClearData {
    type: 'CLEAR_DATA';
}

export interface CreateWorkspaceWithEnvelope {
    type: 'CREATE_WORKSPACE_WITH_ENVELOPES'
}

export interface CreateWorkspaceWithEnvelopeSuccess {
    type: 'CREATE_WORKSPACE_WITH_ENVELOPES_SUCCESS';
    createdWorkspace: string;
    errors?: string[];
}

export interface CreateWorkspaceWithEnvelopeUpdate {
    type: 'CREATE_WORKSPACE_WITH_ENVELOPES_UPDATE';
    message: string;
}

export interface CreateWorkspaceWithEnvelopeError {
    type: 'CREATE_WORKSPACE_WITH_ENVELOPES_ERROR';
    message: string;
}

export interface ShowWorkspacesWithEnvelopesToCreate {
    type: 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE';
    workspaces?: CreateWorkspaceWithEnvelopesProps[];
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
// type KnownAction = CreateWorkspace | CreateWorkspaceSuccess | CreateWorkspaceError | ClearData | CreateWorkspaceWithEnvelope | CreateWorkspaceWithEnvelopeSuccess | CreateWorkspaceWithEnvelopeError | CreateWorkspaceWithEnvelopeUpdate | ShowWorkspacesWithEnvelopesToCreate | AddWorkspaceAction;
type KnownAction = CreateWorkspace | CreateWorkspaceSuccess | CreateWorkspaceError | ClearData | CreateWorkspaceWithEnvelope
    | CreateWorkspaceWithEnvelopeSuccess | CreateWorkspaceWithEnvelopeError | CreateWorkspaceWithEnvelopeUpdate
    | ShowWorkspacesWithEnvelopesToCreate | AddWorkspaceAction;


// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    createWorkspace: (workspaceName: string, currentPeriod: string, duration: number, durationUnit: string, user: AuthenticatedUser): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'CREATE_WORKSPACE' });
        try {
            const workspaceResponse = await ServiceClient.getServiceClient().createWorkspace(workspaceName);
            if (workspaceResponse === workspaceName) {
                try {
                    await ServiceClient.getServiceClient()
                        .putPeriodForWorkspace(workspaceName, currentPeriod, duration, durationUnit);
                    dispatch({ type: 'CREATE_WORKSPACE_SUCCESS', createdWorkspace: workspaceName, timePeriod: currentPeriod });
                } catch (ex) {
                    dispatch({ type: 'CREATE_WORKSPACE_ERROR', message: ex.toString() });
                }
            }
            await ForceLoadUserWorkspaces(dispatch, getState, user);
            // dispatch({ type: 'CREATE_WORKSPACE_SUCCESS', createdWorkspace: workspaceName });
        } catch (ex) {
            dispatch({ type: 'CREATE_WORKSPACE_ERROR', message: ex.toString() });
        }
    },
    clearData: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'CLEAR_DATA' });
    },
    showWorkspacesToCreate: (workspaces?: CreateWorkspaceWithEnvelopesProps[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE', workspaces: workspaces });
    },
    createWorkspaceWithEnvelopes: (workspaces: CreateWorkspaceWithEnvelopesProps[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'CREATE_WORKSPACE_WITH_ENVELOPES' });
        const client = ServiceClient.getServiceClient();

        const allWorkspaces = await client.getWorkspaces();

        let errors = [];
        try {
            for (const workspace of workspaces) {
                if (!allWorkspaces.some((w) => w.name == workspace.workspace)) {
                    await client.createWorkspace(workspace.workspace);
                }
                try {
                    // Add Envelopes to Surveillance API
                    await client.addEnvelopes(workspace.workspace, workspace.envelopes);
                    dispatch({ type: 'CREATE_WORKSPACE_WITH_ENVELOPES_UPDATE', message: `Created ${workspace.workspace}` });
                }
                catch (ex) {
                    errors.push(`Error Adding Envelopes to ${workspace.workspace}: ${ex.message}`);
                }
            }
            const wsNames = workspaces.map((w) => w.workspace).join(',');
            dispatch({ type: 'CREATE_WORKSPACE_WITH_ENVELOPES_SUCCESS', createdWorkspace: wsNames, errors: errors });
        }
        catch (ex) {
            dispatch({ type: 'CREATE_WORKSPACE_WITH_ENVELOPES_ERROR', message: ex.toString() });
        }
    }
};

// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
export const reducer: Reducer<CreateWorkspaceState> = (state: CreateWorkspaceState, action: KnownAction) => {
    switch (action.type) {
        case 'CREATE_WORKSPACE':
            return { isLoading: true, isSuccess: false, createdWorkspace: undefined };
        case 'CREATE_WORKSPACE_SUCCESS':
            return { isLoading: false, isSuccess: true, createdWorkspace: action.createdWorkspace, currentPeriod: action.timePeriod };
        case 'CREATE_WORKSPACE_ERROR':
            return { isLoading: false, isSuccess: false, errorMessage: action.message };
        case 'CLEAR_DATA':
            return { isLoading: false, isSuccess: false, createdWorkspace: undefined };
        case 'CREATE_WORKSPACE_WITH_ENVELOPES':
            return { isLoading: true, isSuccess: false, createdWorkspace: undefined };
        case 'CREATE_WORKSPACE_WITH_ENVELOPES_UPDATE':
            const messages = state.updateMessage ? [...state.updateMessage, action.message] : [action.message];
            return { isLoading: true, isSuccess: true, updateMessage: messages };
        case 'CREATE_WORKSPACE_WITH_ENVELOPES_SUCCESS':
            return { isLoading: false, isSuccess: true, createdWorkspace: action.createdWorkspace, envelopeErrors: action.errors };
        case 'CREATE_WORKSPACE_WITH_ENVELOPES_ERROR':
            return { isLoading: false, isSuccess: false, errorMessage: action.message };
        case 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE':
            return { isLoading: false, isSuccess: false, workspacesToCreate: action.workspaces };
        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || { count: 0 };
};