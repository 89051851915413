import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store/index';
import * as WorkspaceStateStore from '../store/create-workspace';
import { connect } from 'react-redux';
import { PrimaryButton, Spinner, SpinnerSize, Dropdown, IDropdownOption, TextField } from 'office-ui-fabric-react';
import { LabelledTextField } from './common/labelled-text-field';
import * as ServiceClient from '../store/service-client/index';
import { MessageBox, MessageBoxType, MessageBoxSize, Breadcrumbs } from './common/index';
import { Link } from 'react-router-dom';
import { AuthenticatedUser } from '../auth';

type WorkspaceStateProps = WorkspaceStateStore.CreateWorkspaceState
    & typeof WorkspaceStateStore.actionCreators
    & { currentUser: AuthenticatedUser }
    & RouteComponentProps<{}>;

type WorkspaceState = {
    name: string;
    currentPeriod: PeriodOptions;
    durationUnit: string;
    duration: number;
};

export enum PeriodOptions { LastHour, Today, Yesterday, Last7Days, Custom }


export class WorkspaceStorageLanding extends React.Component<WorkspaceStateProps, WorkspaceState> {
    serviceClient: ServiceClient.ServiceClient;
    constructor(props: any) {
        super(props);
        this.state = {
            name: '',
            currentPeriod: PeriodOptions.LastHour,
            durationUnit: 'Days',
            duration: 0,
        };
    }

    componentWillUnmount() {
        this.props.clearData();
    }

    onCurrentPeriodChange = (event: React.FormEvent<HTMLDivElement>, item: IDropdownOption) => {
        const po: PeriodOptions = parseInt(item.key.toString(), 10);
        this.setState({ currentPeriod: po });
    }

    render() {
        if (this.props.isLoading) {
            return (<Spinner size={SpinnerSize.large} />);
        }

        if (this.props.errorMessage !== undefined) {
            return (
                <div style={{ width: '100%', marginTop: '1em' }}>
                    <MessageBox type={MessageBoxType.Error} size={MessageBoxSize.Normal}>
                        <h3 style={{ margin: 0 }}>Error: {this.props.errorMessage}</h3>
                    </MessageBox>
                </div>
            );
        }

        if (this.props.createdWorkspace) {
            return (
                <div style={{ width: '100%', marginTop: '1em' }}>
                    <MessageBox type={MessageBoxType.Success} size={MessageBoxSize.Normal}>
                        <h3 style={{ margin: 20 }}>Workspace {this.props.createdWorkspace} has been created.</h3>
                        <p><Link to={`/view-workspace/${encodeURI(this.props.createdWorkspace)}`}>View Workspace</Link></p>
                    </MessageBox>
                </div>
            );
        }

        const periodOptions = [
            { key: PeriodOptions.LastHour, text: 'last hour' },
            { key: PeriodOptions.Today, text: 'today' },
            { key: PeriodOptions.Yesterday, text: 'yesterday' },
            { key: PeriodOptions.Last7Days, text: 'last 7 days' },
            { key: PeriodOptions.Custom, text: 'custom date range' },
        ];

        const durationUnits = [
            { key: 'Days', text: 'Days' },
            { key: 'Weeks', text: 'Weeks' },
            { key: 'Months', text: 'Months' }
        ];


        return (
            <React.Fragment>
                <Breadcrumbs
                    links={[
                        {
                            to: this.props.match.url,
                            text: `Create New Workspace`
                        }
                    ]}
                />

                <h1>Create New Workspace</h1>
                <LabelledTextField
                    label="New Workspace Name"
                    value={this.state.name}
                    onChange={(_, v) => this.setState({ name: v! })}
                />

                <div className="ms-Grid-row">
                    <div className="ms-Grid-col  ms-md2"><b>Period Time</b></div>
                    <div className="ms-Grid-col  ms-md10">
                        <div className="ms-Grid-row">
                            <div className="ms-Grid-col  ms-md1" />
                            <div className="ms-Grid-col ms-md11">
                                <Dropdown
                                    onChange={this.onCurrentPeriodChange}
                                    placeholder="Select Period time"
                                    options={periodOptions}
                                    selectedKey={periodOptions[this.state.currentPeriod].key}
                                />
                            </div>
                        </div>
                    </div>
                </div>

                {this.state.currentPeriod === PeriodOptions.Custom &&
                    <div className="ms-Grid-row" style={{ paddingTop: '10px' }}>
                        <div className="ms-Grid-col  ms-md2" />
                        <div className="ms-Grid-col  ms-md10" >
                            <div className="ms-Grid-row">
                                <div className="ms-Grid-col  ms-md1" />
                                <div className="ms-Grid-col ms-md11">
                                    <div className="ms-Grid-row">
                                        <div className="ms-Grid-col  ms-md6">
                                            <Dropdown
                                                label="Custom Duration (unit)"
                                                selectedKey={this.state.durationUnit}
                                                onChange={(event, item: IDropdownOption) => this.setState({ durationUnit: item.key.toString() })}
                                                placeholder="Select an option"
                                                options={durationUnits}
                                            /></div>
                                        <div className="ms-Grid-col  ms-md6">
                                            <TextField label="Duration"
                                                placeholder="Please enter duration"
                                                type="number"
                                                value={this.state.duration.toString()}
                                                onChange={(_, val) => {
                                                    if (!val || val.length <= 3) {
                                                        this.setState({ duration: Number(val) })
                                                    } else {
                                                        this.setState({ duration: Number(val.slice(0, 3)) })
                                                    }
                                                }} />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                }

                <p style={{ marginLeft: 250 }}>
                    <PrimaryButton onClick={() => this.createWorkspace()} disabled={!this.state.name || this.state.name.length === 0}>
                        Create Workspace
                    </PrimaryButton>
                </p>
            </React.Fragment>
        );
    }


    createWorkspace(): void {
        const workspaceName = this.state.name;
        const currentPeriod = PeriodOptions[this.state.currentPeriod].toString();
        let durationUnit = '';
        let duration = 0;
        if (this.state.currentPeriod === PeriodOptions.Custom) {
            durationUnit = this.state.durationUnit;
            duration = this.state.duration;
        }
        this.state.durationUnit;
        this.props.createWorkspace(workspaceName, currentPeriod, duration, durationUnit, this.props.currentUser);
    }
}

const mapStateToProps = (state: ApplicationState) => state.createWorkspace;

// Wire up the React component to the Redux store
export default (connect(mapStateToProps, WorkspaceStateStore.actionCreators)(WorkspaceStorageLanding));