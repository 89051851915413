import * as ServiceClient from './service-client/index';
import { PeriodOptions } from '../components/view-workspace/exceedances-table-filter';
import { addHours, startOfDay, addDays, startOfYesterday, endOfYesterday, subDays, subWeeks, subMonths } from 'date-fns';
import { AuthenticatedUser } from '../auth/index';
import { IWorkspaceTimePeriod } from './slice-reducers/common-types';

// STATE - This defines the type of data maintained in the Redux store.
export interface ViewWorkspaceState {
    isInitialLoadLoading?: boolean;
    initialLoadErrorMessage?: string;

    workspaceName: string;

    userWorkspaces?: ServiceClient.UserWorkspace[];
    currentExceedances: ExceedancesRowProps[];
    filteredExceedances: ExceedancesRowProps[];
    unRefreshedExceedances?: ExceedancesRowProps[];
    unRefreshedExceedancesCount?: number;
    updatesLoadErrorMessage?: string;
    emptyWorkspace?: boolean;
    suppressionRules?: ServiceClient.SuppressionRule[];
    dataLoadedAt?: Date;
    putSuppressonRuleInProgress?: boolean;
    putSuppressonRuleSuccess?: boolean;
    putSuppressonRuleError?: string;
    canEdit: boolean;

    suppressedTagCount?: number,
    totalTagCount?: number,
    exceedanceTagCount?: number,
    displayExceedanceType: DisplayExceedanceType;
    allEnvelopes: ExceedancesRowProps[];
    startTime: Date;
    endTime: Date;
    filterText: string;
    user?: AuthenticatedUser;
    selectedTags?: ServiceClient.TagEnvelopeDefinition[];
    workspaceTimePeriod?: IWorkspaceTimePeriod;
}
export interface ExceedancesRowProps extends ServiceClient.TagEnvelopeDefinition {
    filterText?: string[];
    suppressed: boolean;
}

export interface ExceedanceEventProps {
    time: Date;
    threshold: MinMax;
    value: number;
}

export interface ExceedanceGroup extends ServiceClient.WorkspaceGroup {
    key: string;
    id: number;
    name: string;
    startIndex: number;
    count: number;
    level: number
}

export type MinMax = { min?: number, max?: number };


export type DisplayExceedanceType = 'EXCEEDANCE' | 'ALL' | 'SUPPRESSED'

export function getStartEndTime(option: PeriodOptions) {
    let startTime: () => Date = () => new Date();
    let endTime: () => Date = () => new Date();
    switch (option) {
        case PeriodOptions.Last7Days: {
            startTime = () => addDays(startOfDay(new Date()), -7);
            break;
        }
        case PeriodOptions.LastHour: {
            startTime = () => addHours(new Date(), -1);
            break;
        }
        case PeriodOptions.Today: {
            startTime = () => startOfDay(new Date());
            break;
        }
        case PeriodOptions.Yesterday: {
            startTime = startOfYesterday;
            endTime = endOfYesterday;
            break;
        }
        default:
            break;
    }

    return { startTime, endTime };
}

export function getCustomStartEndTime(durationUnit: string, duration: number) {
    let startTime: () => Date = () => new Date();
    let endTime: () => Date = () => new Date();
    if (durationUnit === 'Days') {
        startTime = () => subDays(new Date(), duration);
    } else if (durationUnit === 'Weeks') {
        startTime = () => subWeeks(new Date(), duration);
    } else {
        startTime = () => subMonths(new Date(), duration);
    }
    return { startTime, endTime };
}

export function roundDateTimeDownToTheHour(date: Date) {
    let newDate = date;

    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    return newDate;
}
