import * as React from 'react';
import { ColourManager } from '../common';
import { ActionButton, TextField, DefaultButton, Button, IconButton } from 'office-ui-fabric-react';
import TagPicker, { TagPickerStyle } from '../tag-picker/tag-picker';
import * as ServiceClient from '../../store/service-client';
import { TagHandler } from '../tag-picker/tag-handlers';

export type BasicProps = {
    equation: Equation
    onDelete?: Function
    onChange?: Function
};

export type Equation = {
    _id: number
    selectedTag?: string[]
    brackets: {}
    operation: {}
    constant?: string
    tagHandler?: TagHandler<ServiceClient.TagEnvelopeDefinition>
}
type BasicState = Equation & {
    dropBoxVal: string,
    display:{}
}

export class Basic extends React.Component<BasicProps, BasicState> {
    constructor(props: BasicProps) {
        super(props);
        this.state = { ...this.props.equation, dropBoxVal: '',
        display:{'+':'block', "-":'block', '*':'block', '/':'block'} }
        
    }

    change = () => { if (this.props.onChange) { this.props.onChange(this.state); } };
    delete = () => { if (this.props.onDelete) { this.props.onDelete(); } };

    handleBrackets(btn: string) {
        var value = this.state.brackets[btn]
        this.state.brackets[btn] = !value
        this.setState({brackets:this.state.brackets})

    }
    handleOperators(btn: string) {
        const operators = ['+', '-', '*', '/']
        const value = this.state.operation[btn]
        if (value) {
            operators.forEach(element => {
                this.state.operation[element] = false
            });
        } else {
            operators.forEach(element => {
                if (element !== btn) {
                    this.state.operation[element] = false
                }
            });
            this.state.operation[btn] = true
        }

        this.setState({ operation: this.state.operation })
        operators.every(e=>this.state.operation[e]===false)
        ?operators.forEach(d=> this.state.display[d]='block')
        :operators.forEach(element => {
            if(this.state.operation[element]){
                this.state.display[element]='block'
            }else{
                this.state.display[element]='none'
            }
        });
    }

    renderOPerations() {
        if(this.state._id >1){
        return (
            <React.Fragment>
                <IconButton style={{ fontSize: 'xx--large',backgroundColor:'white', minWidth: '1px', color: this.state.operation['+'] ? 'red' : 'black', display:this.state.display['+']}} iconProps={{iconName:"add"}} onClick={e => this.handleOperators('+')}/>
                <IconButton style={{ fontSize: 'xx-large',backgroundColor:'white', minWidth: '1px', color: this.state.operation['-'] ? 'red' : 'black' , display:this.state.display['-']}} iconProps={{iconName:"calculatorSubtract"}} onClick={e => this.handleOperators('-')}/>
                <IconButton style={{ fontSize: 'xx-large',backgroundColor:'white', minWidth: '1px', color: this.state.operation['*'] ? 'red' : 'black' , display:this.state.display['*']}} iconProps={{iconName:"asterisk"}} onClick={e => this.handleOperators('*')}/>
                <Button style={{ fontSize: 'x-large',backgroundColor:'white', minWidth: '1px', color: this.state.operation['/'] ? 'red' : 'black', display:this.state.display['/'] }} onClick={e => this.handleOperators('/')} >/</Button>
            </React.Fragment>
        )
        }else{
            return
        }
    }

    renderValueTextBox() {
        if (this.state.dropBoxVal === "Value") {
            return (
                <div style={{ flex: 4, display: 'flex', justifyContent: 'flex-start' }}>
                    <Button style={{ fontSize: 'xx-large', paddingRight:'0px', minWidth: '1px',backgroundColor:'white' ,color: this.state.brackets['('] ? 'black' : 'lightgrey'}} onClick={e => this.handleBrackets('(')} >(</Button>
                    <div style={{ width: '400px' }}>
                        <TextField defaultValue={this.state.constant}
                            onChange={(_, v) => this.setState({ constant: String(v) }, this.change)}
                            styles={{
                                subComponentStyles: {
                                    label: {
                                        root: {
                                            fontWeight: 'bold',
                                            fontSize: '16px',
                                            lineHeight: '24px'
                                        }
                                    }
                                }
                            }}
                        />
                    </div>
                    <Button style={{ fontSize: 'xx-large', paddingRight:'0px', minWidth: '1px',backgroundColor:'white', color: this.state.brackets[')'] ? 'black' : 'lightgrey' }} onClick={e => this.handleBrackets(')')}>)</Button>
                </div>
            )
        }
        return
    }

    renderTagPicker() {
        if (this.state.dropBoxVal === "Tag") {
            return (
                <div style={{ flex: 4, display: 'flex', justifyContent: 'flex-start' }}>
                    <Button style={{ paddingRight:'0px',fontSize: 'xx-large', minWidth: '1px', color: this.state.brackets['('] ? 'black' : 'lightgrey', backgroundColor: 'white' }} onClick={e => this.handleBrackets('(')} >(</Button>
                    <TagPicker
                        handler={this.state.tagHandler!}
                        pickerStyle={TagPickerStyle.SINGLE_TAG}
                        onSelectionUpdated={(s: ServiceClient.TagEnvelopeDefinition[]) => this.setState({ selectedTag: s.map(t => t.tag) }, this.change)}
                        lockToSingleTag={false}
                        pickerLabelStyle = {{display:'none'}}
                        pickerBoxStyle = {{marginBottom:'1px'}}
                    />
                    <Button style={{ fontSize: 'xx-large', minWidth: '1px', color: this.state.brackets[')'] ? 'black' : 'lightgrey', backgroundColor:'white' }} onClick={e => this.handleBrackets(')')}>)</Button>
                </div>
            )
        }
        return
    }

    render() {
        const kebabMenu = (
            <React.Fragment>
                <ActionButton
                    onClick={(e: any) => { this.delete(); }}
                    styles={{ menuIcon: { display: 'none' }, root: { height: 'auto' }, icon: { color: ColourManager.StatusColours.darkRed, fontSize: '20px' } }}
                    iconProps={{ iconName: 'clear' }}
                />
            </React.Fragment>
        );
        return (
            <React.Fragment>
                <div style={{ display: 'flex', paddingTop: '1em', justifyContent: 'flex-start' }}>
                    <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-start' }}>
                        <DefaultButton onClick={() => this.setState({dropBoxVal:'Tag'})}>
                            Tag
                        </DefaultButton>
                        <DefaultButton onClick={() => this.setState({dropBoxVal:'Value'})}>
                            Value
                        </DefaultButton>
                        {this.renderOPerations()}  
                    </div>
                    {this.renderTagPicker()}
                    {this.renderValueTextBox()}
                    <div style={{ width: '30px', paddingTop: '5px', textAlign: 'right' }}>
                        {kebabMenu}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}