import * as React from 'react';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store/index';
import * as ManageSuppressionStore from '../../store/manage-suppression';

import {
    Panel, PanelType, DefaultButton, CommandBar, ActionButton, HoverCard, HoverCardType, ProgressIndicator
} from 'office-ui-fabric-react';
import { ColourManager, MessageBox, MessageBoxType, MessageBoxSize } from '../common/index';
import { SuppressionRule } from '../../store/service-client/index';
import { format, isFuture, getYear, isPast } from 'date-fns';
import { SuppressionLength } from '../../store/add-suppression';
import AddSuppression from './add-suppression-new';
import { AuthenticatedUser } from '../../auth/index';
import { TagPickerStyle } from '../tag-picker/tag-picker';
import { ExceedancesRowProps } from '../../store/view-workspace';

type ManageSuppressionListProps =
    ManageSuppressionStore.ManageSuppressionState & typeof ManageSuppressionStore.actionCreators & {
        workspace: string,
        initialSuppressionRules?: SuppressionRule[],
        onDismissed: any,
        currentUser: AuthenticatedUser,
        currentEnvelopes: ExceedancesRowProps[];
    };

type ManageSuppressionListState = { isOpen: boolean, isAdhocSuppressionOpened: boolean, isExpiredRules: boolean };

class ManageSuppressionList extends React.Component<ManageSuppressionListProps, ManageSuppressionListState> {
    tableRowStyle: React.CSSProperties = {
        display: 'flex',
        width: '100%',
        flexFlow: 'row nowrap',
        lineHeight: '20px',
        paddingTop: '13px',
    };

    tableRowBottom = {
        borderBottom: `1px solid ${ColourManager.PrimaryColours.sand}`,
        paddingBottom: '13px',
    };

    headerRowBaseStyle: React.CSSProperties = {
        ...this.tableRowStyle,
        fontWeight: 'bold',
        lineHeight: undefined,
        paddingBottom: '10px',
        borderBottom: `2px solid ${ColourManager.AppColour}`
    };

    constructor(props: ManageSuppressionListProps) {
        super(props);
        this.state = { isOpen: true, isAdhocSuppressionOpened: false, isExpiredRules: false };
    }

    componentDidMount() {
        this.props.reset(this.props.workspace, this.props.initialSuppressionRules);
    }

    onDismissed = () => {
        this.props.onDismissed();
    };

    render() {
        const errorMessage = this.props.saveErrorMessage
            ? (<MessageBox type={MessageBoxType.Error} size={MessageBoxSize.Baby}>{this.props.saveErrorMessage}</MessageBox>)
            : undefined;

        const successMessage = this.props.saveSuccess
            ? (
                <MessageBox type={MessageBoxType.Success} size={MessageBoxSize.Baby}>
                    Successfully saved.
                    <div><DefaultButton onClick={this.props.onDismissed}>Close</DefaultButton></div>
                </MessageBox>)
            : undefined;
            

        const filteredSuppressionRules = this.props.suppressionRules
            ? (
                this.state.isExpiredRules
                    ? this.props.suppressionRules.filter(r => (r.end_time) && isPast(r.end_time)).sort((a, b) => !a.end_time > !b.end_time ? -1 : !a.end_time < !b.end_time ? 1 : 0).map((r, i) => this.renderRow(r, i, true))
                    : (this.props.suppressionRules.filter(r => (!r.end_time) || isFuture(r.end_time)).map((r, i) => this.renderRow(r, i)))
            )
            : [] as SuppressionRule[];


        return (
            <React.Fragment>
                <Panel
                    isOpen={this.state.isOpen}
                    headerText={`Manage Suppression Rules for Workspace ${this.props.workspaceName}`}
                    type={PanelType.large}
                    onRenderFooterContent={() => (
                        <div>
                            <DefaultButton text="Close" onClick={() => this.setState({ isOpen: false })} />
                            {this.props.saveInProgress ? <ProgressIndicator /> : undefined}
                        </div>
                    )}
                    onDismissed={this.onDismissed}
                >
                    <div style={{ marginBottom: '10px', display: this.props.saveSuccess || this.props.saveErrorMessage ? 'flex' : 'none' }}>
                        {errorMessage}
                        {successMessage}
                    </div>


                    <div>
                        <CommandBar
                            styles={{ root: {} }}
                            items={[
                                {
                                    key: 'add',
                                    iconProps: { iconName: 'Add' },
                                    text: 'New Suppression Rule',
                                    onClick: () => this.setState({ isAdhocSuppressionOpened: true })
                                },
                                {
                                    key: 'details',
                                    iconProps: { iconName: 'History' },
                                    text: 'View Expired Suppression Rules',
                                    style: { backgroundColor: this.state.isExpiredRules ? 'rgb(207, 205, 203)' : undefined },
                                    onClick: () => this.setState({ isExpiredRules: !this.state.isExpiredRules })
                                },
                            ]}
                        />
                    </div>

                    <div>
                        <div style={this.headerRowBaseStyle}>
                            {/* <div style={{ flex: 2 }}>&nbsp;</div> */}
                            <div style={{ flex: 2 }}>Tag Name</div>
                            <div style={{ flex: 1.5 }}>Period</div>
                            <div style={{ flex: 1 }}>Updated By</div>
                            <div style={{ flex: 0.7 }}>Updated At</div>
                            <div style={{ textAlign: 'right', flex: 0.2 }}>&nbsp;</div>
                        </div>
                    </div>

                    {filteredSuppressionRules.length === 0
                        ? <p style={{ textAlign: 'center', paddingTop: '2em' }}>No suppression rules</p>
                        : filteredSuppressionRules}

                    {this.state.isAdhocSuppressionOpened
                        ? (
                            <AddSuppression
                                tagPickerStyle={TagPickerStyle.MULTI_TAG}
                                allowInformantTag
                                enableTagPicker
                                tags={[]}
                                workspaceName={this.props.workspaceName}
                                onDismissed={() => this.setState({ isAdhocSuppressionOpened: false }, () => { this.props.updateSuppressionList(); })}
                                showStartDate={true}
                                showEndDatePickerOnly={true}
                            />)
                        : null}

                </Panel>
            </React.Fragment>
        );
    }

    renderRow(rule: SuppressionRule, i: number, hideKebab?: boolean) {
        const kebabMenu = (
            <React.Fragment>
                <ActionButton
                    styles={{ menuIcon: { display: 'none' }, root: { height: 'auto' } }}
                    iconProps={{ iconName: 'MoreVertical' }}
                    menuProps={{
                        items: [
                            {
                                key: 'end-suppression',
                                text: 'End Suppression Now',
                                iconProps: { iconName: 'Completed' },
                                onClick: () => { this.props.suppressionEndNow(rule, this.props.currentUser.email); }
                            },
                            {
                                key: 'extend-suppression',
                                text: 'Extend Suppression',
                                iconProps: { iconName: 'PaddingRight' },
                                subMenuProps: {
                                    items: [
                                        { key: SuppressionLength.RestOfToday.valueOf().toString(), text: 'for the rest of today' },
                                        { key: '1', text: 'for 1 hour', onClick: () => { this.props.extendSuppression(rule.identifier!, 1, this.props.currentUser.email); } },
                                        { key: '8', text: 'for 8 hours', onClick: () => { this.props.extendSuppression(rule.identifier!, 8, this.props.currentUser.email); } },
                                        { key: '24', text: 'for 24 hours', onClick: () => { this.props.extendSuppression(rule.identifier!, 24, this.props.currentUser.email); } },
                                        { key: '168', text: 'for 7 days', onClick: () => { this.props.extendSuppression(rule.identifier!, 168, this.props.currentUser.email); } },
                                        { key: 'custom', text: 'until a specific date ...' },
                                    ]
                                }
                            },
                        ]
                    }}
                />
            </React.Fragment>
        );

        const fromDate = rule.start_time ? format(rule.start_time, 'ddd D MMM YYYY, HH:mm') : '';
        const toDate = (rule.end_time
            ? (getYear(rule.end_time) > 2029 ? 'until further notice' : 'to ' + format(rule.end_time, 'ddd D MMM YYYY, HH:mm'))
            : '');
        const editedDate = rule.modified_date ? format(rule.modified_date!, 'ddd D MMM YYYY, HH:mm') : '';
        const tagList = rule.suppressed_tags.length < 3 ? rule.suppressed_tags.join(', ') : rule.suppressed_tags.slice(0, 3).join(', ');
        const tagAndOthers = rule.suppressed_tags.length > 3 ? `and ${rule.suppressed_tags.length - 3} others` : undefined;


        const informantTag = rule.informant_tag ? rule.informant_tag.tag : undefined;
        let informantState = rule.informant_tag ? rule.informant_tag.state : ''

        return (
            <React.Fragment key={i}>
                <div style={this.tableRowStyle}>
                    <div style={{ flex: 2 }}>
                        <HoverCard
                            instantOpenOnClick={true}
                            plainCardProps={{
                                renderData: rule.suppressed_tags,
                                onRenderPlainCard: (item: string[]) => {
                                    return (<div style={{ padding: '8px' }}>
                                        <ul>{item.map((tag, index) => (<li key={index}>{tag}</li>))}</ul>
                                    </div>);
                                }
                            }}
                            type={HoverCardType.plain}
                        >
                            {tagList} <span style={{ borderBottom: '1px dotted black' }}>{tagAndOthers}</span>
                        </HoverCard>
                    </div>
                    <div style={{ flex: 1.5 }}>
                        {informantTag
                            ? (<React.Fragment><b>Informant:</b> {informantTag} ( { informantState} )<br /> {toDate}</React.Fragment>)
                            : (<React.Fragment>{fromDate} {toDate}</React.Fragment>)
                        }

                    </div>
                    <div style={{ flex: 1,  paddingRight: '10px' }}>{rule.modified_by}</div>
                    <div style={{ flex: 0.7 }}>{editedDate}</div>
                    <div style={{ textAlign: 'right', flex: 0.2 }}>{hideKebab ? undefined : kebabMenu}</div>
                </div>
                <div style={{ ...this.tableRowStyle, ...this.tableRowBottom, paddingTop: undefined }}>
                    <div style={{ flex: 1, fontSize: '12px' }}>
                        <b>Reason: </b>{rule.comment}
                    </div>
                </div>
            </React.Fragment>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => state.manageSuppression;
// Wire up the React component to the Redux store
export default (connect(mapStateToProps, ManageSuppressionStore.actionCreators)(ManageSuppressionList));