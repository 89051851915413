import * as React from 'react';
import { Dialog, DialogType, DialogFooter, DefaultButton, PrimaryButton } from 'office-ui-fabric-react';
import { SecondaryColours } from './colour-manager';

export enum ConfirmationType {
    NORMAL,
    DELETE
}
type ConfirmationProps = {
    title?: string,
    type?: ConfirmationType,
    onConfirm: any,
    onCancel?: any,
    show?: boolean
};
export class Confirmation extends React.Component<ConfirmationProps, {}> {
    constructor(props: any) {
        super(props);
    }

    cancel() {
        if ( this.props.onCancel ) {
            this.props.onCancel();            
        }
    }

    confirm() {
        if ( this.props.onConfirm ) {
            this.props.onConfirm();
        }
    }

    render() {
        let confirmButtonText: string;
        let styles: any = {};
        let title = this.props.title ? this.props.title : 'Question';

        switch (this.props.type) {
            case ConfirmationType.DELETE:
                confirmButtonText = 'Delete';
                styles.style = { backgroundColor: SecondaryColours.darkOrange };
                break;

            default:
                confirmButtonText = 'OK';
                break;
        }
        return (
            <Dialog
                title={title}
                hidden={!this.props.show}
                dialogContentProps={{type: DialogType.normal}}
                modalProps={{isBlocking: false}}
            >
                {this.props.children ? this.props.children : 'Are you sure you want to do this?'}
                <DialogFooter>
                    <DefaultButton onClick={() => this.cancel()}>Cancel</DefaultButton>
                    <PrimaryButton onClick={() => this.confirm()} {...styles}>{confirmButtonText}</PrimaryButton>
                </DialogFooter>
            </Dialog>
        );
    }
}
