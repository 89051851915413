import React from 'react';
import { NavLink } from 'react-router-dom';
import { ColourManager } from '.';
import { IconEye } from './icon-surveillance'
import { getTheme } from 'office-ui-fabric-react/lib/Styling';

const theme = getTheme();

type SolutionMastheadProps = {
    title: string,
    initial: string,
    colour: string ,
    links?: { text: string, href: string }[]
};

export class SolutionMasthead extends React.Component<SolutionMastheadProps, {}> {
    render() {
        const divStyles: React.CSSProperties = {
            height: '46px',
            display: 'flex',
        };
        const h1Styles: React.CSSProperties = {
            padding: 0,
            margin: 0,
            paddingLeft: 5,
            marginRight: 60,
            marginLeft: 5,
            position: 'relative',
            fontSize: '25px',
            lineHeight: '25px',
            fontWeight: 'normal',
            alignItems: 'center',
            alignSelf: 'center'
        };
        const logoStyles: React.CSSProperties = {
            paddingTop: 0,
            paddingLeft: ((94 - 72) / 2),
            fontSize: "30px",
            fontWeight: "bold",
            marginLeft: "50px",
            color: this.props.colour
        };
        const ulStyles: React.CSSProperties = {...h1Styles, fontSize: '16px', listStyle: 'none'};
        const liStyles: React.CSSProperties = {
            display: 'inline-block',
            marginLeft: '5px',
            paddingRight: '20px',
            borderRight: `1px solid ${ColourManager.PrimaryColours.darkGrey}`
        };
        const links = (this.props.links
                        ? this.props.links.map((l, i) => {
                            let liStyle = {...liStyles};
                            if (i === 0) {
                                liStyle.marginLeft = '0px';
                            }
                            if (i === this.props.links!.length - 1) {
                                liStyle.borderRight = '';
                            }
                            return (
                                <li key={i} style={liStyle}>
                                    <NavLink
                                        to={l.href}
                                        style={{ textDecoration: 'none', color: 'black' }}
                                        activeStyle={{ color: theme.palette.themePrimary }}
                                    >
                                        {l.text}
                                    </NavLink>
                                </li>
                            );
                        })
                        : null);
        return (
            <div style={divStyles}>
                <div style={logoStyles}>
                    <IconEye height="50" width="50" title="mainIcon" color='#fff' secondaryColor='#fff' />
                </div>
                <h1 style={h1Styles}>
                    <NavLink to="/" style={{ textDecoration: 'none', color: '#fff' }}>Surveillance<b>Hub</b></NavLink>
                </h1>
                <ul style={ulStyles}>
                    {links}
                </ul>
            </div>
        );
    }
}
