import "rc-editor-mention/assets/index.css";
import React from "react";
import Mention, { toEditorState } from "rc-editor-mention";
import { ContentState } from 'draft-js';
import * as ServiceClient from '../../store/service-client/index';
import { ActionButton, Label } from 'office-ui-fabric-react';
import { ColourManager, AppearanceManager } from '../common';

type MentionEditorProps = { finalEquation: string, viewGrid: boolean, onChange?: Function, onDelete?: Function }
type MentionEditorState = MentionEditorProps & {
  suggestions: any
  defaultValue: any
  editorState: any
}
let data: string[] = []

export class MentionEditor extends React.Component<MentionEditorProps, MentionEditorState> {
  constructor(props: MentionEditorProps) {
    super(props);
    this.state = {
      ...props,
      suggestions: [],
      defaultValue: null,
      editorState: toEditorState('')
    };
  }

  change = () => { if (this.props.onChange) { this.props.onChange(this.state.finalEquation); } };
  delete = () => { if (this.props.onDelete) { this.props.onDelete(); } };

  componentDidUpdate(prevProps: MentionEditorProps, prevState: MentionEditorState) {
    const editorState = this.state.editorState as ContentState
    if (this.props.finalEquation !== prevProps.finalEquation &&
      this.props.finalEquation !== '' &&
      editorState.getPlainText() === '') {
      this.setState({
        finalEquation: this.props.finalEquation,
        editorState: toEditorState(this.props.finalEquation)
      });
    }
  }

  async getTagsFromClient(value: string) {
    data = []
    const results = await ServiceClient.getServiceClient().searchForTags(value)
    results.forEach(t => data.push(t.name))
  }

  onSearchChange = async (value: any) => {
    if (value !== '') {
      await this.getTagsFromClient(value);
    }
    this.setState({
      suggestions: data
    });
  };

  onChange = (editorState: ContentState) => {
    this.setState({ editorState })
    this.setState({ finalEquation: editorState.getPlainText() }, this.change)
  };

  render() {
    const { suggestions, editorState } = this.state;
    const kebabMenu = (
      <React.Fragment>
        <ActionButton
          onClick={(e: any) => { this.delete(); }}
          styles={{ menuIcon: { display: 'none' }, root: { height: 'auto' }, icon: { color: ColourManager.StatusColours.darkRed, fontSize: '20px' } }}
          iconProps={{ iconName: 'clear' }}
        />
      </React.Fragment>
    );
    if (this.props.viewGrid) {
      return (
        <div style={{ display: 'flex', paddingTop: '1em' }}>
          <div style={{ flex: 1 }}>
            <div style={{ display: 'flex' }}>
              <Label style={{ ...AppearanceManager.FormLabelStyles, flexShrink: 1 }}>Equation</Label>
            </div>
            <div style={{overflowY:'scroll'}}>
            <Mention
              style={{  width: '100%', minHeight: '100px',fontSize: '16px',fontFamily: 'Roboto, sans-serif',borderRadius: '0px',borderColor: '#C4C4C4',padding: '2px', }}
              onSearchChange={this.onSearchChange}
              defaultValue={this.state.defaultValue}
              value={editorState}
              onChange={this.onChange}
              suggestions={suggestions}
              suggestionStyle={{ fontFamily: 'Roboto', fontWeight: 800 }}
              mode="immutable"
            />
            </div>
          </div>
          <div style={{ width: '30px', paddingTop: '5px', textAlign: 'right' }}>
            {kebabMenu}
          </div>
        </div>
      );
    }
    else {
      return (<div></div>)
    }
  }
}
