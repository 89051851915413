export const greyBlue = { palette: {
    themePrimary: '#adceff',
    themeLighterAlt: '#b6d3ff',
    themeLighter: '#bfd9ff',
    themeLight: '#c8deff',
    themeTertiary: '#d1e4ff',
    themeSecondary: '#dae9ff',
    themeDarkAlt: '#e3eeff',
    themeDark: '#ecf4ff',
    themeDarker: '#f5f9ff',
    neutralLighterAlt: '#585a60',
    neutralLighter: '#5e6067',
    neutralLight: '#696b71',
    neutralQuaternaryAlt: '#6f7178',
    neutralQuaternary: '#74767d',
    neutralTertiaryAlt: '#8a8d93',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#515359',
  }}

  export const blue = { palette: {
    themePrimary: '#a9cafc',
    themeLighterAlt: '#97b4e1',
    themeLighter: '#849ec5',
    themeLight: '#7188a9',
    themeTertiary: '#5f718d',
    themeSecondary: '#4c5b72',
    themeDarkAlt: '#3a4556',
    themeDark: '#272f3a',
    themeDarker: '#14181e',
    neutralLighterAlt: '#20346f',
    neutralLighter: '#253975',
    neutralLight: '#2e427e',
    neutralQuaternaryAlt: '#344884',
    neutralQuaternary: '#3a4d89',
    neutralTertiaryAlt: '#51649d',
    neutralTertiary: '#c8c8c8',
    neutralSecondary: '#d0d0d0',
    neutralPrimaryAlt: '#dadada',
    neutralPrimary: '#ffffff',
    neutralDark: '#f4f4f4',
    black: '#f8f8f8',
    white: '#1b2f69',
  }}

  export const mainBlue = { palette:{
    themePrimary: '#1c405a',
    themeLighterAlt: '#f2f6f8',
    themeLighter: '#cbdae4',
    themeLight: '#a3bccd',
    themeTertiary: '#5b819c',
    themeSecondary: '#2b526d',
    themeDarkAlt: '#193950',
    themeDark: '#153044',
    themeDarker: '#0f2432',
    neutralLighterAlt: '#eceae7',
    neutralLighter: '#e8e6e3',
    neutralLight: '#deddda',
    neutralQuaternaryAlt: '#cfcdcb',
    neutralQuaternary: '#c6c4c2',
    neutralTertiaryAlt: '#bebcba',
    neutralTertiary: '#cfd0d1',
    neutralSecondary: '#a0a1a3',
    neutralPrimaryAlt: '#757678',
    neutralPrimary: '#636466',
    neutralDark: '#4b4c4e',
    black: '#373839',
    white: 'white',
}}

export const orange = { palette:{
  themePrimary: '#e05600',
  themeLighterAlt: '#fef8f4',
  themeLighter: '#fae1d2',
  themeLight: '#f6c8ac',
  themeTertiary: '#ed955f',
  themeSecondary: '#e4681b',
  themeDarkAlt: '#ca4d00',
  themeDark: '#ab4100',
  themeDarker: '#7e3000',
  neutralLighterAlt: '#faf9f8',
  neutralLighter: '#f3f2f1',
  neutralLight: '#edebe9',
  neutralQuaternaryAlt: '#e1dfdd',
  neutralQuaternary: '#d0d0d0',
  neutralTertiaryAlt: '#c8c6c4',
  neutralTertiary: '#a19f9d',
  neutralSecondary: '#605e5c',
  neutralPrimaryAlt: '#3b3a39',
  neutralPrimary: '#323130',
  neutralDark: '#201f1e',
  black: '#000000',
  white: '#ffffff',
}}
