import { Reducer } from 'redux';
import { AppThunkAction } from './index';
import * as ServiceClient from './service-client/index';
import { EditEnvelopeStateProps, EnvelopeStateConditions } from './edit-envelope';
//import { forEach, groupBy, Dictionary} from 'lodash'
import { ColourManager } from '../components/common'
import { isNumber } from 'util';
//import { envelopeSelector } from './selectors/envelope-selector';


// STATE - This defines the type of data maintained in the Redux store.
export interface BulkUploadState {
    isLoading: boolean;
    isSuccess: boolean;
    errorMessage?: string;
    fileUploaded?: boolean;
    createdWorkspace?: string;
    updateMessage?: string[];
    envelopeErrors?: string[];
    workspacesToCreate?: BulkCreateWorkspaceWithEnvelopes[];
    csvError: boolean
}

export interface BulkCreateWorkspaceWithEnvelopes {
    workspace: string;
    envelopes: BulkEditEnvelope[];
    groups: BulkEditGroup[];
    uploaded: boolean;
}

export interface BulkEditState extends EditEnvelopeStateProps {
    isValid: boolean;
    errors: string[];
}

export interface BulkEditEnvelope {
    tag: string;
    states: BulkEditState[];
    uploaded: boolean;
}

export interface BulkEditGroup {
    group: string;
    envelopes: BulkEditEnvelope[];
    uploaded: boolean;
}

// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.
export interface CreateWorkspace {
    type: 'CREATE_WORKSPACE';
}

export interface CreateWorkspaceSuccess {
    type: 'CREATE_WORKSPACE_SUCCESS';
    createdWorkspace: string;
}

export interface CreateWorkspaceError {
    type: 'CREATE_WORKSPACE_ERROR';
    message: string;
}

interface ClearData {
    type: 'CLEAR_DATA';
}

export interface CreateWorkspaceWithEnvelope {
    type: 'CREATE_WORKSPACE_WITH_ENVELOPES'
    workspaces?: BulkCreateWorkspaceWithEnvelopes[]
}

export interface CreateWorkspaceWithEnvelopeSuccess {
    type: 'CREATE_WORKSPACE_WITH_ENVELOPES_SUCCESS';
    createdWorkspace: string;
    workspaces?: BulkCreateWorkspaceWithEnvelopes[];
    errors?: string[];
}

export interface CreateWorkspaceWithEnvelopeUpdate {
    type: 'CREATE_WORKSPACE_WITH_ENVELOPES_UPDATE';
    message: string;
    workspaces?: BulkCreateWorkspaceWithEnvelopes[];
}

export interface CreateWorkspaceWithEnvelopeError {
    type: 'CREATE_WORKSPACE_WITH_ENVELOPES_ERROR';
    message: string;
    workspaces?: BulkCreateWorkspaceWithEnvelopes[];
}

export interface ShowWorkspacesWithEnvelopesToCreate {
    type: 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE';
    workspaces?: BulkCreateWorkspaceWithEnvelopes[];
}

export interface ShowWorkspacesWithEnvelopesToCreateError {
    type: 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE_ERROR';
    message?: string;
}

export interface ShowWorkspaceWithEnvelopesToCreateValidating {
    type: 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE_VALIDATING'
}
export interface ProcessingUploadType {
    type: 'PROCESSING_UPLOAD'
}

export interface InvalidUploadFileType {
    type: 'INVALID_UPLOAD_FILE'
}

export interface ValidUploadFileType {
    type: 'VALID_UPLOAD_FILE'
}


// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = CreateWorkspace | CreateWorkspaceSuccess | CreateWorkspaceError | ClearData
    | CreateWorkspaceWithEnvelope | CreateWorkspaceWithEnvelopeSuccess | CreateWorkspaceWithEnvelopeError
    | CreateWorkspaceWithEnvelopeUpdate | ShowWorkspacesWithEnvelopesToCreate | ShowWorkspacesWithEnvelopesToCreateError
    | ShowWorkspaceWithEnvelopesToCreateValidating | ProcessingUploadType | InvalidUploadFileType | ValidUploadFileType;


//Check Envelopes Dont Exist
async function checkEnvelopesDontExist(workspaces: BulkCreateWorkspaceWithEnvelopes[]): Promise<BulkCreateWorkspaceWithEnvelopes[]> {
    try {
        const spaces = workspaces.map((w) => w.workspace);
        const client = ServiceClient.getServiceClient();

        const allSpaces = await client.getWorkspaces();
        const xist = spaces.filter((s) => {
            return allSpaces.map((as) => as.name).indexOf(s) >= 0;
        });

        if (xist && xist.length > 0) {
            // Get all of the Envelopes for the Existing Workspaces.
            const wsEnvs = await Promise.all(xist.map(async (s) => {
                try {
                    const envs = await client.getWorkspaceEnvelopes(s, { requestStates: false, explodeEnvelopesByState: false });
                    return { workspace: s, envelopes: envs ? envs : [] };
                }
                catch (er) {
                    return { workspace: s, envelopes: [] };
                }
            }));

            // Find any envelopes for updloading that already exist and mark them as errored.
            return workspaces.map((w) => {
                const spaceEnvs = wsEnvs.find((s) => s.workspace.toLocaleUpperCase() == w.workspace.toUpperCase())!.envelopes;
                return {
                    ...w, envelopes: w.envelopes.map((e) => {
                        if (spaceEnvs && spaceEnvs.length > 0 && spaceEnvs.map((env) => env.tag).indexOf(e.tag) >= 0) {
                            return {
                                ...e,
                                states: [{ name: "All", errors: ["Tag Already Exists In Workspace"], isValid: false, _id: 0, colour: "", triggerCondition: EnvelopeStateConditions.Outside }]
                            }
                        }
                        else {
                            return { ...e };
                        }
                    })
                }
            });
        }
        else {
            return workspaces;
        }
    } catch (error) {
        throw error;
    }
}


function checkValidFileHeaders(data: any[]): boolean {
    const firstEL = data[0];
    const rowProperties = Object.keys(firstEL);
    const validHeaders = rowProperties.some((s: any) => s == "Workspace") &&
        rowProperties.some((s: any) => s == "Tag") &&
        rowProperties.some((s: any) => s == "Min") &&
        rowProperties.some((s: any) => s == "Max");
    return validHeaders;
}

function stringToColor(color: string): ColourManager.StatusColours {
    let col: ColourManager.StatusColours = ColourManager.StatusColours.darkRed;
    switch (color.trim().toUpperCase()) {
        case "RED":
            col = ColourManager.StatusColours.darkRed;
            break;
        case "ORANGE":
            col = ColourManager.StatusColours.orange;
            break;
        case "GREEN":
            col = ColourManager.StatusColours.greenGreen;
            break;
        case "BLUE/GREY":
        case "BLUE":
        case "GREY":
            col = ColourManager.StatusColours.blueGrey;
            break;
        default:
            col = ColourManager.StatusColours.darkRed;
    }
    return col;
}

function isValid(row: EditEnvelopeStateProps): BulkEditState {
    let errors: string[] = [];

    if (row.value1 && !isNumber(row.value1)) {
        errors.push("Min Value is not a number")
    }

    if (row.value2 && !isNumber(row.value2)) {
        errors.push("Max Value is not a number")
    }

    if (row.value1 && row.value2 && row.value1 > row.value2) {
        errors.push("Min Value is greater than Max Value")
    }

    return { ...row, isValid: errors.length === 0, errors: errors } as BulkEditState;
}

function rowColumnsToEnvelopeProps(Tag: string, State?: string, Colour?: string, Type?: string, Min?: any, Max?: any, Description?: string, Short_Description?: string): BulkEditState {
    let state: EditEnvelopeStateProps;
    let stateName = State ? State : "OUT OF RANGE";
    let colour = Colour ? stringToColor(Colour) : ColourManager.StatusColours.darkRed;
    Type = Type ? Type.trim().toUpperCase() : undefined;

    if (Type === "BELOW" || Min && (!Max === undefined || Max === "NA" || Max === "Null" || Max === null)) {
        state =
        {
            _id: 0,
            name: stateName,
            colour: colour,
            triggerCondition: EnvelopeStateConditions.Below,
            value1: parseFloat(Min),
            description: Description || "",
            short_description: Short_Description || "",
        }

    }
    else if (Type === "ABOVE" || Max && (!Min === undefined || Min === "NA" || Min === "Null" || Min === null)) {
        state =
        {
            _id: 0,
            name: stateName,
            colour: colour,
            triggerCondition: EnvelopeStateConditions.Above,
            value1: Max,
            description: Description || "",
            short_description: Short_Description || "",
        }

    }
    else {
        let tCond = EnvelopeStateConditions.Outside;
        switch (Type) {
            case "EQUAL":
            case "EQUALTO":
                tCond = EnvelopeStateConditions.EqualTo;
                break;
            case "FLAT":
            case "FLATLINE":
                tCond = EnvelopeStateConditions.FlatLine;
                break;
            case "INBETWEEN":
            case "INSIDE":
                tCond = EnvelopeStateConditions.InBetween;
                break;
            default:
                tCond = EnvelopeStateConditions.Outside;
        }
        state =
        {
            _id: 0,
            name: stateName,
            colour: colour,
            triggerCondition: tCond,
            value1: Min,
            value2: Max,
            description: Description || "",
            short_description: Short_Description || "",
        }
    }
    return isValid(state);
}

// function rowToEnvelopeProps(row:any): BulkEditState{
//     let state: EditEnvelopeStateProps;
//     let stateName = (row.State) ? row.State : "OUT OF RANGE";
//     let colour = row.Colour ? stringToColor(row.Colour) : ColourManager.StatusColours.darkRed;
//     if(row.Type){
//         row.Type = row.Type.trim().toUpperCase();
//     }

//     if(row.Type === "BELOW" || row.Min && (!row.Max === undefined || row.Max === "NA" || row.Max === "Null" || row.Max === null) ) {
//         state = 
//             {
//                 _id: 0,
//                 name: stateName, 
//                 colour: colour, 
//                 triggerCondition: EnvelopeStateConditions.Below,
//                 value1: row.Min,
//                 description: row.Description || "",
//                 short_description: row.Short_Description || "",
//             }

//     }
//     else if(row.Type === "ABOVE" || row.Max && (!row.Min === undefined || row.Min === "NA" || row.Min === "Null" || row.Min === null)) {
//         state = 
//             {
//                 _id: 0,
//                 name: stateName, 
//                 colour: colour, 
//                 triggerCondition: EnvelopeStateConditions.Above,
//                 value1: row.Max,
//                 description: row.Description || "",
//                 short_description: row.Short_Description || "",
//             }

//     }
//     else {
//         let tCond = EnvelopeStateConditions.Outside;
//         switch(row.Type){
//             case "EQUAL":
//                 tCond = EnvelopeStateConditions.EqualTo;    
//                 break;
//             case "FLAT":
//             case "FLATLINE":
//                 tCond = EnvelopeStateConditions.FlatLine;
//                 break;
//             case "INBETWEEN":
//             case "INSIDE":
//                 tCond = EnvelopeStateConditions.InBetween;    
//                 break;
//             default:
//                 tCond = EnvelopeStateConditions.Outside;    
//         }
//         state = 
//             {
//                 _id: 0,
//                 name: stateName, 
//                 colour: colour, 
//                 triggerCondition: tCond,
//                 value1: row.Min,
//                 value2: row.Max,
//                 description: row.Description || "",
//                 short_description: row.Short_Description || "",
//             }
//     }
//     return isValid(state);
// }

function findDuplicates(arr: string[]) { return arr.filter((item: string, index: number) => arr.indexOf(item) != index) };


function groupByWorkspace(data: any[]): BulkCreateWorkspaceWithEnvelopes[] {
    let nullRemoved = data.filter((d: any) => { return d.Workspace != null });

    const workspaces = nullRemoved.reduce((c, { Workspace, Group, Tag, State, Type, Colour, Min, Max, Description }) => {
        //Create the Workspace Object if it doesn't exist
        if (!c[Workspace]) c[Workspace] = <BulkCreateWorkspaceWithEnvelopes>{ workspace: Workspace, groups: [], envelopes: [], uploaded: false };
        const workspace = c[Workspace];
        //Create the Group Object if it doesn't exist.
        if (Group) {
            if (!workspace.groups.find((g: any) => g.group === Group)) workspace.groups.push(<BulkEditGroup>{ group: Group, envelopes: [], uploaded: false });
            const group = workspace.groups.find((g: any) => g.group === Group);
            if (!group.envelopes.find((e: BulkEditEnvelope) => e.tag === Tag.trim())) group.envelopes.push({ tag: Tag.trim(), states: [], uploaded: false });
            const envelope = group.envelopes.find((e: BulkEditEnvelope) => e.tag === Tag.trim());
            envelope.states.push(rowColumnsToEnvelopeProps(Tag, State, Colour, Type, Min, Max, Description));
        } else {
            //Or Create the Envelope Object if it doesn't exist
            if (!workspace.envelopes.find((e: BulkEditEnvelope) => e.tag === Tag.trim())) workspace.envelopes.push({ tag: Tag.trim(), states: [], uploaded: false });
            const envelope = workspace.envelopes.find((e: BulkEditEnvelope) => e.tag === Tag.trim());
            envelope.states.push(rowColumnsToEnvelopeProps(Tag, State, Colour, Type, Min, Max, Description));
        }
        return c;
    }, new Object());

    return Object.values(workspaces);
};

// function mapToWorkspaceEnvelopes(groupedByWorkspace: Dictionary<any[]>):BulkCreateWorkspaceWithEnvelopes[] {
//     let workspaces: BulkCreateWorkspaceWithEnvelopes[] = [];
//     forEach(groupedByWorkspace, (data,ws) => {
//         let groupByEnv =  groupBy(data,'Tag');
//         let envs: BulkEditEnvelope[] = [];
//         forEach(groupByEnv, (eData, e) => {
//             const states = eData.map((stateData:any)=> rowToEnvelopeProps(stateData));
//             let env: BulkEditEnvelope = {
//                 tag: e.trim(),
//                 states: states,
//                 uploaded: false
//             }
//             envs.push(env);
//         });
//         workspaces.push({ workspace: ws, envelopes: envs, uploaded: false, groups: []});
//     });
//     return workspaces;
// }

// function mapToWorkspaceGroups(groupedByWorkspace: Dictionary<any[]>):BulkCreateWorkspaceWithEnvelopes[] {
//     let workspaces: BulkCreateWorkspaceWithEnvelopes[] = [];

//     forEach(groupedByWorkspace, (data,ws) => {
//         if(data['0']['Group'])
//         {
//             const groupByGroup = groupBy(data,'Group');
//             const groups: BulkEditGroup[] = [];
//             forEach(groupByGroup, (gdata,g)=> {
//                 console.log(gdata);
//                 let groupByEnv =  groupBy(gdata,'Tag');
//                 const envs :BulkEditEnvelope[]= [];

//                 forEach(groupByEnv, (eData, e)=> {
//                     const states = eData.map((stateData:any)=> rowToEnvelopeProps(stateData));
//                     let env: BulkEditEnvelope = {
//                         tag: e.trim(),
//                         states: states,
//                         uploaded: false
//                     }
//                     envs.push(env);            
//                 })
//                 groups.push({group: g, envelopes: envs, uploaded: false});
//             });
//             workspaces.push({ workspace: ws, groups: groups, uploaded: false, envelopes:[]});
//         }
//         else {
//             let groupByEnv =  groupBy(data,'Tag');
//             let envs: BulkEditEnvelope[] = [];
//             forEach(groupByEnv, (eData, e) => {
//                 const states = eData.map((stateData:any)=> rowToEnvelopeProps(stateData));
//                 let env: BulkEditEnvelope = {
//                     tag: e.trim(),
//                     states: states,
//                     uploaded: false
//                 }
//                 envs.push(env);
//             });
//             workspaces.push({ workspace: ws, envelopes: envs, uploaded: false, groups: []});
//         }


//     });
//     return workspaces;
// }

function duplicateStateCheck(envelope: BulkEditEnvelope): BulkEditEnvelope {
    const stateNames = envelope.states.map((s) => s.name);
    const duplicates = findDuplicates(stateNames);
    if (duplicates && duplicates.length > 0) {
        return { ...envelope, states: envelope.states.map((s) => { return duplicates.some((d) => d == s.name) ? { ...s, isValid: false, errors: ["Duplicate state name."] } : { ...s } }) };
    }
    return { ...envelope };
}

function duplicateCheck(workspaces: BulkCreateWorkspaceWithEnvelopes[]) {
    const afterDuplicateCheck: BulkCreateWorkspaceWithEnvelopes[] = workspaces.map((w) => {
        return {
            ...w,
            envelopes: w.envelopes.map((e) => { return duplicateStateCheck(e); }),
            groups: w.groups.map(g => {
                return { ...g, envelopes: g.envelopes.map((e) => { return duplicateStateCheck(e) }) }
            })
        }
    });
    return afterDuplicateCheck;
};

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    showWorkspacesToCreate: (data: any[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'PROCESSING_UPLOAD' });
        if (data && data.length) {


            if (!checkValidFileHeaders(data)) {
                dispatch({ type: 'INVALID_UPLOAD_FILE' });
            }
            else {
                dispatch({ type: 'VALID_UPLOAD_FILE' });
                try {
                    //Group By Workspace.
                    let groupedByWorkspace = groupByWorkspace(data);

                    //const byGroup = mapToWorkspaceGroups(groupedByWorkspace);
                    //const workspaces = mapToWorkspaceEnvelopes(groupedByWorkspace);

                    //ensure there are no duplicate envelope states.
                    const afterDuplicateCheck = duplicateCheck(groupedByWorkspace);

                    //ensure none of the workspace -  envelopes already exist.
                    dispatch({ type: 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE_VALIDATING' });
                    if (afterDuplicateCheck && afterDuplicateCheck.length > 0) {
                        const wsToReturn = (afterDuplicateCheck && afterDuplicateCheck.length > 0) ? await checkEnvelopesDontExist(afterDuplicateCheck) : undefined
                        dispatch({ type: 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE', workspaces: wsToReturn });
                    }
                    else {
                        dispatch({ type: 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE', workspaces: afterDuplicateCheck });
                    }
                }
                catch {
                    dispatch({ type: 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE_ERROR', message: "Failed to validate workspace and envelopes." });
                }
            }
        } else {
            dispatch({ type: 'INVALID_UPLOAD_FILE' });
        }

    },
    createWorkspace: (workspaceName: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'CREATE_WORKSPACE' });
        try {
            await ServiceClient.getServiceClient().createWorkspace(workspaceName);
            dispatch({ type: 'CREATE_WORKSPACE_SUCCESS', createdWorkspace: workspaceName });
        } catch (ex) {
            dispatch({ type: 'CREATE_WORKSPACE_ERROR', message: ex.toString() });
        }
    },
    clearData: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'CLEAR_DATA' });
    },
    createWorkspaceWithEnvelopes: (workspaces: BulkCreateWorkspaceWithEnvelopes[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'CREATE_WORKSPACE_WITH_ENVELOPES', workspaces: workspaces });
        const client = ServiceClient.getServiceClient();

        const allWorkspaces = await client.getWorkspaces();

        let errors = [];
        try {
            for (const workspace of workspaces) {
                if (!allWorkspaces.some((w) => w.name == workspace.workspace)) {
                    await client.createWorkspace(workspace.workspace);
                }
                try {
                    //ENVELOPES
                    //get all the envelopes.
                    const envsToCreate = workspace.envelopes.concat(workspace.groups.reduce((c: BulkEditEnvelope[], g) => { return c.concat(g.envelopes) }, []));

                    await Promise.all(envsToCreate.filter((e) => !e.states.some((s) => s.errors && s.errors.length > 0)).map(async (e) => {
                        // Add Envelopes to Surveillance API    
                        try {
                            await client.createEnvelopeNew(workspace.workspace, e.tag, e.states)

                            e.uploaded = true;
                            dispatch({ type: 'CREATE_WORKSPACE_WITH_ENVELOPES_UPDATE', workspaces: workspaces, message: `Uploaded ${e.tag}` })
                        }
                        catch (ex) {
                            //Handle the Error when Creating Envelope
                            errors.push(`Error adding tag ${e.tag} to workspace ${workspace.workspace}`)
                        }
                    }));
                    //GROUPS
                    await Promise.all(workspace.groups.map(async (g) => {
                        try {
                            var group: ServiceClient.WorkspaceGroup = { id: 0, name: g.group, tags: g.envelopes.map(e => e.tag), workspace: workspace.workspace, expanded: false, groups: [] };
                            await client.addWorkspaceGroup(workspace.workspace, group);
                            //g.uploaded = true;
                            dispatch({ type: 'CREATE_WORKSPACE_WITH_ENVELOPES_UPDATE', workspaces: workspaces, message: `Uploaded ${g.group}` })
                        }
                        catch (ex) {
                            errors.push(`Error adding tag ${g.group} to workspace ${workspace.workspace}`)
                        }
                    }));

                    workspace.uploaded = true;
                    dispatch({ type: 'CREATE_WORKSPACE_WITH_ENVELOPES_UPDATE', message: `Created ${workspace.workspace}`, workspaces: workspaces });
                }
                catch (ex) {
                    errors.push(`Error Adding Envelopes to ${workspace.workspace}: ${ex.message}`);
                }
            }
            const wsNames = workspaces.map((w) => w.workspace).join(',');
            dispatch({ type: 'CREATE_WORKSPACE_WITH_ENVELOPES_SUCCESS', createdWorkspace: wsNames, errors: errors });
        }
        catch (ex) {
            dispatch({ type: 'CREATE_WORKSPACE_WITH_ENVELOPES_ERROR', message: ex.toString() });
        }
    },
    setWorkspacesForUpload: (workspaces: BulkCreateWorkspaceWithEnvelopes[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE', workspaces: workspaces });
    }
};

// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
export const reducer: Reducer<BulkUploadState> = (state: BulkUploadState, action: KnownAction) => {
    switch (action.type) {
        case 'CREATE_WORKSPACE':
            return { ...state, isLoading: true, isSuccess: false, createdWorkspace: undefined };
        case 'CREATE_WORKSPACE_SUCCESS':
            return { ...state, isLoading: false, isSuccess: true, createdWorkspace: action.createdWorkspace };
        case 'CREATE_WORKSPACE_ERROR':
            return { ...state, isLoading: false, isSuccess: false, errorMessage: action.message };
        case 'CLEAR_DATA':
            return { ...state, isLoading: false, isSuccess: false, createdWorkspace: undefined, workspacesToCreate: [] };
        case 'CREATE_WORKSPACE_WITH_ENVELOPES':
            return { ...state, isLoading: true, isSuccess: false, createdWorkspace: undefined, workspacesToCreate: action.workspaces };
        case 'CREATE_WORKSPACE_WITH_ENVELOPES_UPDATE':
            const messages = state.updateMessage ? [...state.updateMessage, action.message] : [action.message];
            return { ...state, isLoading: true, isSuccess: false, updateMessage: messages, workspacesToCreate: action.workspaces };
        case 'CREATE_WORKSPACE_WITH_ENVELOPES_SUCCESS':
            return { ...state, isLoading: false, isSuccess: true, createdWorkspace: action.createdWorkspace, envelopeErrors: action.errors };
        case 'CREATE_WORKSPACE_WITH_ENVELOPES_ERROR':
            return { ...state, isLoading: false, isSuccess: false, errorMessage: action.message };
        case 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE':
            return { ...state, isLoading: false, isSuccess: false, workspacesToCreate: action.workspaces };
        case 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE_ERROR':
            return { ...state, isLoading: false, isSuccess: false, errorMessage: action.message };
        case 'SHOW_WORKSPACE_WITH_ENVELOPES_TO_CREATE_VALIDATING':
            return { ...state, isLoading: true, isSuccess: false };
        case 'INVALID_UPLOAD_FILE':
            return { ...state, isLoading: false, isSuccess: false, csvError: true }
        case 'VALID_UPLOAD_FILE':
            return { ...state, isLoading: true, isSuccess: false, csvError: false }
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || { count: 0 };
};