import { createSelector } from 'reselect';
import { ApplicationState } from '..';
import { RouteComponentProps } from 'react-router-dom';
import { Workspace } from '../slice-reducers/common-types';

const selectedWorkspace = (state:ApplicationState, props: RouteComponentProps<{ workspaceName: string }>) => {
    
    const workspaces  = state.entities.workspaces.byId;
    const { workspaceName } = props.match.params;
    const ws:Workspace = workspaces[workspaceName];
    return ws;
}

export const workspaceSelector = createSelector([selectedWorkspace],(workspace)=> {
    return workspace;
});