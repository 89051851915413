import { combineReducers } from 'redux' 
import { TagCurrentValue } from './common-types'
//import { removeItemFromArray } from '../reducerUtilities';

export interface TagsCurrentValueState {
    byId: Record<string, TagCurrentValue[]>;
}

type AddTagCurrentValueAction = ({type: 'ADD_TAG_CURRENT_VALUES', payload: { tags: TagCurrentValue[]}});

export type TagCurrentValueAction = AddTagCurrentValueAction;

function addTags(state: Record<string, TagCurrentValue[]>, action: AddTagCurrentValueAction){
    const { payload } = action;
    const { tags } = payload; 
    let existingTags = {...state};

    tags.forEach(tag => {
        existingTags[tag.tag] = existingTags[tag.tag] ? existingTags[tag.tag] : [];
        existingTags[tag.tag].push(tag);
    });

    return existingTags;
}

function currentValueById(state: Record<string,TagCurrentValue[]> = <Record<string, TagCurrentValue[]>>{}, action: TagCurrentValueAction){
    switch (action.type){
        case 'ADD_TAG_CURRENT_VALUES':
            return addTags(state, action);
        default:
            return state;
    }
}

export const TagCurrentValueReducer = combineReducers({
    byId: currentValueById
});