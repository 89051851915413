import * as React from 'react';
import { IPersonaProps, PersonaCoin, PersonaSize, ActionButton } from 'office-ui-fabric-react';
import { SecondaryColours } from '../common/colour-manager';
export class RolePersona extends React.Component<{persona: IPersonaProps, onDelete?: any, hideDelete?: boolean}, {}> {
    constructor(props: any) {
        super(props);
    }
    stylePrimary: React.CSSProperties = {
        fontSize: '1.15em', fontWeight: 800, textAlign: 'left',
        overflow: 'hidden', textOverflow: 'ellipsis'
    };

    styleSecondary: React.CSSProperties = {
        textAlign: 'left'
    };

    getUserColour(auth: string, role: string) {
        let switcharoon = `${auth}-${role}`;
        switch (switcharoon) {
            case 'Member-Group': {
                return SecondaryColours.darkGreen;
            }
            case 'Member-User': {
                return SecondaryColours.green;
            }
            case 'Owner-Group': {
                return SecondaryColours.orange;
            }
            case 'Owner-User': {
                return SecondaryColours.darkOrange;
            }
            default:
                return 'black';
        }
    }

    onDelete() {
        if ( this.props.onDelete ) {
            this.props.onDelete( this.props.persona.text, this.props.persona.tertiaryText);
        }
    }

    render() {
        return (
            <div style={{ marginBottom: '1em', display: 'flex', width: '100%', padding: '0.2em' }}>
                <PersonaCoin
                    size={PersonaSize.size40}
                    imageInitials={this.props.persona.imageInitials!}
                    style={{ flexBasis: 'min-content', marginRight: '1em' }}
                    styles={{
                        initials: {
                            background: `${this.getUserColour(this.props.persona.tertiaryText!, 
                                                              this.props.persona.secondaryText!)} !important`,
                        }
                    }}
                />
                <div style={{ flexGrow: 1 }}>
                    <div style={{...this.stylePrimary}}>
                        {this.props.persona.text}
                    </div>
                    <div style={{ marginTop: '0.5em' }}>
                        <div style={this.styleSecondary}>
                            {this.props.persona.secondaryText}
                        </div>
                    </div>                    
                </div>
                <div style={{ flexBasis: 'min-content', textAlign: 'right' }} >
                    {this.props.onDelete && !this.props.hideDelete ? (
                        <ActionButton
                            style={{color: SecondaryColours.darkOrange}}
                            iconProps={{iconName: 'Trash', style: {fontSize: '1.5em'}}}
                            onClick={() => this.onDelete()}
                        />
                    ) : undefined}
                </div>
            </div>            
        );
    }
}