import * as React from 'react';
import { ActionButton, Label, Dropdown, TextField, Icon } from 'office-ui-fabric-react';
import { SwatchColorPicker } from 'office-ui-fabric-react'
import { LabelledTextField, AppearanceManager, ColourManager } from '../common';
import { EditEnvelopeStateProps, EnvelopeStateConditions, numberOfTextFields } from '../../store/edit-envelope';
import { EditDescription } from '../state-description/edit-description'


type StateProps = { state: EditEnvelopeStateProps; onChange?: Function; onDelete?: Function; };
type StateState = EditEnvelopeStateProps;
export class State extends React.Component<StateProps, StateState> {
    constructor(props: StateProps) {
        super(props);
        this.state = {...props.state};
    }

    
    stateColours =[
        { color: ColourManager.StatusColours.blueGrey, label: 'Blue/Grey', id:'Blue/Grey' },
        { color: ColourManager.StatusColours.greenGreen, label: 'Green' , id:'Green'},
        { color: ColourManager.StatusColours.orange, label: 'Orange' , id:'Orange'},
        { color: ColourManager.StatusColours.darkRed, label: 'Red' , id:'Red'}]

    stateColourIdByColor(colour:string): string { 
        
        const col = this.stateColours.find(c => c.color == colour);
        if(col)
        {
            return col.id;
        }
        return 'Blue/Grey';
    } 

    change = () => { if (this.props.onChange) { this.props.onChange(this.state); } };
    delete = () => { if (this.props.onDelete) { this.props.onDelete(); } };
    
    render() {
        const kebabMenu = (
            <React.Fragment>
                <ActionButton
                    onClick={ (e: any) => { this.delete();}}
                    styles={{ menuIcon: { display: 'none' }, root: { height: 'auto' }, icon: {color:ColourManager.StatusColours.darkRed, fontSize:'20px'} }}
                    iconProps={{ iconName: 'clear' }}
                />
            </React.Fragment>
        );

        return (
            <React.Fragment>
                <div style={{ borderTop: '1px solid #eeeeee', borderBottom: '1px solid #eeeeee', paddingTop: '10px', paddingBottom: '10px' }}>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, flexFlow: 'row', display:'flow', justifyContent: 'space-between' }}>
                            <TextField label={'Name'} defaultValue={this.state.name} 
                                onChange={(_, v) => this.setState({ name: String(v) }, this.change)}
                                styles={{
                                    field: { backgroundColor: this.state.colour, color: this.state.colour ? 'white' : 'black' }, 
                                    fieldGroup: {marginTop:'8px'},
                                    subComponentStyles: { label:{ root : { width: '250px',
                                        fontWeight: 'bold',
                                        fontSize: '16px',
                                        lineHeight: '24px'
                                        }}}
                                }}
                            />
                        </div>
                        <div style={{ flex: 1, paddingLeft: '5em' }}>
                            <div style={{ display: 'flex' }}>
                                <Label style={{ ...AppearanceManager.FormLabelStyles, flexShrink: 1 }}>Colour</Label>
                            </div>
                            <div>
                                <SwatchColorPicker columnCount={5} selectedId={ this.stateColourIdByColor(this.state.colour)} cellShape={'circle'} colorCells={this.stateColours} onColorChanged={(_, color:ColourManager.StatusColours ) => this.setState({ colour: color }, this.change) } />
                            </div>
                        </div>
                        <div style={{ width: '30px', paddingTop: '5px', textAlign: 'right' }}>
                            {kebabMenu}
                        </div>
                    </div>
                    <div style={{ display: 'flex' }}>
                        <div style={{ flex: 1, display: 'flex' }}>
                            <Label style={{ ...AppearanceManager.FormLabelStyles, flexShrink: 1 }}>Trigger when</Label>
                            <Dropdown
                                label={undefined}
                                style={{ width: '250px' }}
                                styles={{ root: { paddingRight: '1em' } }}
                                options={[
                                    { key: EnvelopeStateConditions.Above, text: 'above' },
                                    { key: EnvelopeStateConditions.Below, text: 'below' },
                                    { key: EnvelopeStateConditions.EqualTo, text: 'equal to' },
                                    { key: EnvelopeStateConditions.InBetween, text: 'in between' },
                                    { key: EnvelopeStateConditions.Outside, text: 'outside' },
                                    { key: EnvelopeStateConditions.FlatLine, text: 'flat line' },
                                ]}
                                defaultSelectedKey={this.state.triggerCondition}
                                onChange={(_, v) => this.setState({ triggerCondition: Number(v!.key) }, this.change)}
                            />
                            {numberOfTextFields(this.state.triggerCondition) > 0
                                ? <LabelledTextField
                                    label=""
                                    labelStyles={{ display: 'none' }}
                                    defaultValue={String(this.state.value1)}
                                    onChange={(_, v) => this.setState({ value1: Number(v) }, this.change)}
                                />
                                : undefined}
                            {numberOfTextFields(this.state.triggerCondition) > 1
                                ? (<React.Fragment>
                                    &nbsp; &mdash;
                                    <TextField
                                        style={{ width: '200px' }}
                                        styles={{ root: { paddingLeft: '1em' } }}
                                        defaultValue={this.state.value2 ? String(this.state.value2) : ''}
                                        onChange={(_, v) => this.setState({ value2: Number(v) }, this.change)}
                                    />
                                </React.Fragment>)
                                : <span style={{ width: '200px' }} />}
                        </div>
                    </div>
                    <div style={{ display: 'flex 3'}}>
                        <div style={{ display: 'flex 3'}} ><Label style={{ ...AppearanceManager.FormLabelStyles, flexShrink: 1 }}>Description / Commentary</Label></div>
                        <div style={{ flexFlow: 'row'}}>
                            <span>This allows the entry of Markdown and will be displayed when the user clicks on a state.</span>
                            <a href="https://github.com/adam-p/markdown-here/wiki/Markdown-Cheatsheet" target="_blank" title='Markdown cheatsheet' ><Icon style={{fontWeight: 'bold', fontSize:'20px', marginLeft:'20px', color: ColourManager.SecondaryColours.darkOrange}} iconName="Info" /></a>
                        </div> 
                        <EditDescription description={this.state.description || ""} stateName={this.state.name} onChange={(value:any)=> this.setState({description: value}, this.change)} />
                    </div>
                </div>
            </React.Fragment>
        );
    }
}