import * as React from 'react';
import { connect } from 'react-redux'
import { Shimmer, ShimmerElementType, DefaultButton, Icon} from 'office-ui-fabric-react';
import { ColourManager, MessageBox, MessageBoxType, MessageBoxSize } from '../common/index';
import { ExceedancesRowProps } from '../../store/view-workspace';
import { PeriodOptions } from './exceedances-table-filter';
import { SuppressionRule, TagEnvelopeDefinition, TagEnvelopeState, WorkspaceGroup, STATE_NO_DATA } from '../../store/service-client/index';
import { AuthenticatedUser } from '../../auth/index';
import AddSuppression from './add-suppression-new';
import { PrimaryColours } from '../common/colour-manager';
import { TagPickerStyle } from '../tag-picker/tag-picker';
import { IconButton, PrimaryButton } from 'office-ui-fabric-react/lib/Button';
import { RouteComponentProps } from 'react-router';
import { DisplayExceedanceType } from '../../store/view-workspace'
import { ApplicationState } from '../../store';
import { actionCreators } from '../../store/action-creators/view-workspace-action-creator'; 
import AddGroup from './add-to-group';
import { anythingIncludesText } from '../../utils/FilterText';
import { ExceedancesGroup } from './exceedance-group';



export const ExceedancesTableRowStyle: React.CSSProperties = {
    display: 'flex',
    width: '100%',
    flexFlow: 'row nowrap',
    lineHeight: '20px',
    paddingTop: '13px',
    paddingBottom: '13px',
    borderBottom: `1px solid ${ColourManager.PrimaryColours.sand}`
};

export const ExceedancesHeaderRowBaseStyle: React.CSSProperties = {
    ...ExceedancesTableRowStyle,
    fontWeight: 'bold',
    lineHeight: undefined,
    cursor: 'pointer'
};

type ExceedancesTableProps = {
    rows?: ExceedancesRowProps[];
    isLoading?: boolean;
    error?: string;
    loadTagInfo?: any;
    //filterText: string;
    filterMode?: string;
    currentPeriod: PeriodOptions;
    emptyWorkspace?: boolean;
    currentUser: AuthenticatedUser;
    suppressionRules?: SuppressionRule[];
    workspaceName: string;
    onCloseAdhocSuppression: any;
    exceedanceDisplayType:DisplayExceedanceType;
    onShowAllTagsChange(props: DisplayExceedanceType): any;
    onSortByColumn(column:string , descending: boolean): any;
    sortColumn?: string,
    sortDescending?: boolean,
    canEdit:boolean,
    periodStartTime: () => Date,
    periodEndTime: () => Date,
    totalTagCount: number,
    exceedenceCount: number,
    suppressionCount: number,
    workspaceGroups?: WorkspaceGroup[],
    selectedTags: TagEnvelopeDefinition[],
    clearSelectedTags:any,
    expandGroup:any,
    expandAllGroups:any,
    deleteGroup: any,
    filterTwo:string
   
} & RouteComponentProps<{workspaceName:string}>;

type ExceedancesTableState = {
    suppressionRules?: SuppressionRule[],
    selectionEnabled: boolean,
    selectionInProgress: boolean,
    selectedTags: TagEnvelopeDefinition[],
    currentSelectingValue?: boolean,
    isAddSuppressionOpen?: boolean,
    isNonIEBrowserP2Open?: boolean,
    addGroupOpen: boolean,
    allGroupsExpanded: boolean,
    rowGroups: Dictionary<ExceedancesRowProps[]>,
    showNoData:boolean,
} ;

export interface Dictionary<T> {
    [key: string]: T;
}
export class ExceedancesTable extends React.Component<ExceedancesTableProps, ExceedancesTableState> {
    constructor(props: any) {
        super(props);
        const wsGroups = this.props.workspaceGroups || [];
        const groups: Dictionary<ExceedancesRowProps[]> = wsGroups.reduce((c,g)=> {
            c[g.id] = this.mapRowsToGroups(g);
            return c;
        }, {});
        this.state = { selectedTags: [], 
            selectionEnabled: false, 
            selectionInProgress: false, 
            addGroupOpen:false,
            allGroupsExpanded: this.groupsExpanded(),
            rowGroups: groups,
            showNoData: true
        };
    }

    mapConstraintsValuesToString(states: TagEnvelopeState[]){
        var results = states.map(s=> s.conditions.map(c=> [c.low_threshold, c.high_threshold]));
        var values = results.reduce((p, c) => p.concat(c)).reduce((p,c)=> p.concat(c)).filter(v=> v);

        return values;
    }
    
    _addToGroup() {
        this.setState({addGroupOpen: true});
    }

    _closeAddGroup(){
        this.setState({addGroupOpen: false});
    }

    _clearSelection() {
        this.props.clearSelectedTags();
        //this.setState({ selectedTags: [] });
    }

    _newSuppression() {
        this.setState({ isAddSuppressionOpen: true });
    }

    onShowNoData(){
        this.setState({showNoData: !this.state.showNoData});
     }

    _closeAddSuppression() {
        this.setState({ isAddSuppressionOpen: false });
    }
    

    _onColumnClick(ev: React.MouseEvent<HTMLElement>, column: string): void {
        const { sortColumn,sortDescending } = this.props;
        let newDesc: boolean = false;

        if(sortColumn)
        {
            if (sortColumn === column) {
                newDesc = !sortDescending;
            } else {
                newDesc = true;
            }
        }
        
        //DO THE SORTING
        this.props.onSortByColumn(column,newDesc);
    }
    
    
    _copyAndSort<T>(items: T[], columnKey: string, isSortedDescending?: boolean): T[] {
      const key = columnKey as keyof T;
      return items.slice(0).sort((a: T, b: T) => ((isSortedDescending ? a[key] < b[key] : a[key] > b[key]) ? 1 : -1));
    }

    _sortIcon(column:string) {
        if(column === this.props.sortColumn){
            if(this.props.sortDescending){
                return (<IconButton iconProps={{ iconName: 'SortUp'}} title="Sort Descending" style={{height:"20px"}} />)
            }
            return (<IconButton iconProps={{ iconName: 'SortDown'}} title="Sort Ascending" style={{height:"20px"}} />)
        }
        return ""
    }

    groupsExpanded(){
        return this.props.workspaceGroups && this.props.workspaceGroups.length > 0 && this.props.workspaceGroups.every(g=> g.expanded != undefined && g.expanded === true) ? true : false
    }

    noDataExceendanceCount(){
        return this.props.rows!.filter(r=> r.states.some(s=> s.name!= STATE_NO_DATA && s.met) && !r.suppressed).length;
    }

    renderSelectionTools() {
        return (
            <div
                style={{
                    display: 'flex', alignItems: 'center', padding: '0.2em', marginTop: '0.4em',
                    borderTop: 'solid 1px ' + PrimaryColours.sand, borderBottom: 'solid 2px ' + PrimaryColours.sand
                }}
            >
                <div style={{
                    marginBottom: "8px",
                    padding: "5px 0px",
                    flex: 3,
                    display: 'flex',
                    transitionDuration: '0.3s',
                    justifyContent: 'space-between',
                    height:'32px'
                    }}>
                    <div style={{display:'flex', flexFlow: 'row'}} >
                        <div style={{display: 'flex', flexFlow:'row', height:'32px'}}>
                            <DefaultButton toggle checked={this.props.exceedanceDisplayType === 'ALL'} onClick={(e: any) => this.props.onShowAllTagsChange('ALL')}>Tags</DefaultButton>
                            <PrimaryButton onClick={(e: any) => this.props.onShowAllTagsChange('ALL')}>{this.props.totalTagCount ? this.props.totalTagCount : 0 }</PrimaryButton>
                        </div>
                        <div style={{display: 'flex', flexFlow:'row', height:'32px'}}>
                            <DefaultButton toggle checked={this.props.exceedanceDisplayType === 'EXCEEDANCE'} onClick={(e: any) => this.props.onShowAllTagsChange('EXCEEDANCE')}>Exceedances</DefaultButton>
                            <PrimaryButton onClick={(e: any) => this.props.onShowAllTagsChange('EXCEEDANCE')}>{this.props.exceedenceCount ? (this.state.showNoData ? this.props.exceedenceCount : this.noDataExceendanceCount()) : 0 }</PrimaryButton>
                        </div>
                        <div style={{display: 'flex', flexFlow:'row', height:'32px'}}>
                            <DefaultButton toggle checked={this.props.exceedanceDisplayType === 'SUPPRESSED'} onClick={(e: any) => this.props.onShowAllTagsChange('SUPPRESSED')} >Suppressions</DefaultButton>
                            <PrimaryButton onClick={(e: any) => this.props.onShowAllTagsChange('SUPPRESSED')}>{this.props.suppressionCount ? this.props.suppressionCount : 0 }</PrimaryButton>
                        </div>
                        <div style={{display: 'flex', flexFlow:'row', alignContent:'flex-end', height:'32px'}}>
                            <DefaultButton onClick={(e: any) => this.onShowNoData()} style={{backgroundColor: 'transparent', color: this.state.showNoData ? '#000' : 'rgb(164, 38, 44)', fontWeight: this.state.showNoData ? 'normal' : 'bold' }}  >
                                <Icon iconName={this.state.showNoData ? 'View' : 'Hide2' } style={{marginRight:"5px"}}  />
                                {this.state.showNoData ? 'Showing No Data State' : 'No Data State Hidden'}
                            </DefaultButton>
                        </div>
                    </div>
                </div>
                { this.props.canEdit ? 
                    (<div>
                        <div
                            style={{
                                flex: 4,
                                display: 'flex',
                                transitionDuration: '0.3s',
                                opacity: this.props.selectedTags && this.props.selectedTags.length > 0 ? 1 : 0,
                                justifyContent: 'space-between'
                            }}
                        >
                            <DefaultButton
                                menuProps={{
                                    items: [
                                        {
                                            key: 'suppression', text: 'Add New Suppression', disabled: this.props.selectedTags && this.props.selectedTags.length === 0,
                                            iconProps: { iconName: 'Hide' }, onClick: () => this._newSuppression()
                                        },
                                        {
                                            iconProps: { iconName: 'GroupedList' }, key: 'addToGroup', text: 'Add To Group',
                                            onClick: (r) => this._addToGroup(), disabled: this.props.selectedTags.length === 0
                                        },
                                        {
                                            iconProps: { iconName: 'Delete' }, key: 'clear', text: 'Clear Selection',
                                            onClick: (r) => this._clearSelection(), disabled: this.props.selectedTags && this.props.selectedTags.length === 0
                                        }
                                    ]
                                }}
                            >
                                With selected items ({this.props.selectedTags && this.props.selectedTags.length})...
                            </DefaultButton>
                        </div>
                        <div style={{ flex: 2 }}>
                            {this.state.isAddSuppressionOpen === true
                                ? (
                                    <AddSuppression
                                        tagPickerStyle={TagPickerStyle.MULTI_TAG}
                                        enableTagPicker
                                        allowInformantTag
                                        showEndDatePickerOnly
                                        showStartDate
                                        tags={this.props.selectedTags || []}
                                        workspaceName={this.props.workspaceName}
                                        onDismissed={() => { this._closeAddSuppression(); }}
                                    />)
                                : undefined}
                                { this.state.addGroupOpen === true
                                ? (<AddGroup
                                        tags={this.props.selectedTags.map(t=> t.tag)}
                                        currentUser={this.props.currentUser}
                                        workspaceName={this.props.workspaceName}
                                        onDismissed={() => { this._closeAddGroup(); }}
                                        show={this.state.addGroupOpen}
                                    />)
                                : undefined}
                        </div>
                </div>
                ): undefined}
            </div>
        );
    }
    renderHeader() {
        return (
            <React.Fragment>
                {this.renderSelectionTools()}
                <div className='ms-Grid'>
                    <div
                        className='ms-borderColor-themePrimary ms-Grid-Row'
                        style={{
                            ...ExceedancesHeaderRowBaseStyle,
                            paddingBottom: '10px',
                            borderBottom: `2px solid rgb(50, 111, 154)`
                        }}
                    >
                        <div className='ms-Grid-col ms-sm6 ms-xl3 ms-xxl3 ms-xxxl3' >
                            <span style={{display: this.props.workspaceGroups && this.props.workspaceGroups.length ? 'inline-block': 'none' }}><IconButton iconProps={{ iconName: this.state.allGroupsExpanded ? 'ChevronDownSmall' : 'ChevronRightSmall' }} onClick={this.props.expandAllGroups} /></span>
                            <span onClick={ (e:any)=> {this._onColumnClick(e,"tag")}}>Tag</span><span>{this._sortIcon("tag")}</span>
                        </div>
                        <div className='ms-Grid-col ms-xl4 ms-xxl3 ms-xxxl3 ms-hiddenLgDown' style={{ textAlign: 'left' }} onClick={ (e:any)=> {this._onColumnClick(e,"description")}}>
                            <span>Description</span><span>{this._sortIcon("description")}</span>
                        </div>
                        <div className='ms-Grid-col ms-xxl1 ms-xxxl1 ms-hiddenXlDown' style={{ textAlign: 'center' }} onClick={ (e:any)=> {this._onColumnClick(e,"unit")}}>
                            <span>Unit</span><span>{this._sortIcon("unit")}</span>
                        </div>
                        <div className='ms-Grid-col ms-sm6 ms-xl4 ms-xxl4 ms-xxxl4' style={{ textAlign: 'center' }} onClick={ (e:any)=> {this._onColumnClick(e,"state")}}>
                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-sm12 ms-xxl8'>State</div>
                                <div className='ms-Grid-col ms-xxl4 ms-hiddenXlDown'>Range</div>
                            </div>
                        </div>
                        <div className='ms-Grid-col ms-xl1 ms-xxl1 ms-xxxl1 ms-hiddenLgDown' onClick={ (e:any)=> {this._onColumnClick(e,"currentValue")}}>
                            <div className='ms-Grid-row'>
                                <div className='ms-Grid-col ms-xl9 ms-hiddenLgDown'><span>Value</span><span>{this._sortIcon("currentValue")}</span></div>
                                <div className='ms-Grid-col ms-sm13 ms-xl2'></div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        );
    }

    renderLoading() {
        const shimmerRow = (key: number) => {
            return (
                <div key={key} style={ExceedancesTableRowStyle}>
                    <div key={key} style={{ flex: 1 }}>
                        <Shimmer
                            key={key}
                            shimmerElements={[
                                { type: ShimmerElementType.gap, width: '1%' },
                                { type: ShimmerElementType.line, width: '33%' },
                                { type: ShimmerElementType.gap, width: '1%' },
                                { type: ShimmerElementType.line, width: '35%' },
                                { type: ShimmerElementType.gap, width: '1%' },
                                { type: ShimmerElementType.line, width: '8%' },
                                { type: ShimmerElementType.gap, width: '1%' },
                                { type: ShimmerElementType.line, width: '8%' },
                                { type: ShimmerElementType.gap, width: '1%' },
                                { type: ShimmerElementType.line, width: '5%' },
                                { type: ShimmerElementType.gap, width: '1%' },
                            ]}
                        />
                    </div>
                </div>
            );
        };

        return (
            <React.Fragment>
                {[...Array(10)].map((_, i) => shimmerRow(i))}
            </React.Fragment>
        );
    }

    mapRowsToGroups(group:WorkspaceGroup){
        const rows = this.props.rows || [];
        const filteredRows = rows.filter(r=> group.tags.some(t=> r.tag === t)).filter(r=> anythingIncludesText(this.props.filterTwo || '', [r.tag,r.description || '', ...r.states.map(s=> s.name)]))
        return filteredRows;
    }

    render() {
        const wsGroups = this.props.workspaceGroups || [];
        const groups: Dictionary<ExceedancesRowProps[]> = wsGroups.reduce((c,g)=> {
                c[g.id] = this.mapRowsToGroups(g);
                return c;
            },{});
        
        let body;
        if (this.props.emptyWorkspace || this.props.error !== undefined) {
            let content;
            if (this.props.emptyWorkspace) {
                content = (
                    <MessageBox type={MessageBoxType.Information} size={MessageBoxSize.Normal}>
                        <h3 style={{ margin: 20 }}>No envelopes are defined.</h3>
                        <p style={{margin:20}}>This workspace has no envelopes defined. To create a new one, select <Icon iconName='EditMail' style={{marginLeft:'10px'}} /> New Envelope .</p>
                    </MessageBox>
                );
            } else {
                content = (
                    <MessageBox type={MessageBoxType.Error} size={MessageBoxSize.Normal}>
                        <h2 style={{ margin: 20, flex:1 }}>There was a problem.</h2>
                        <div style={{flex: 3}}>
                            <p>Please try again in a few moments.</p>
                            <p>If the problem persists contact IT Helpdesk.</p>
                        </div>
                    </MessageBox>
                );
            }
            body = (
                <div style={{ width: '100%', marginTop: '1em' }}>
                    {content}
                </div>
            );
        } else if (this.props.isLoading) {
            body = this.renderLoading();
        } else if (this.props.rows === undefined || this.props.rows.length === 0) {
            body = (
                <div style={{ width: '100%', marginTop: '1em' }}>
                    <MessageBox type={MessageBoxType.Information} size={MessageBoxSize.Compact}>
                        <h3 style={{ margin: 0 }}>No envelope states have been {this.props.exceedanceDisplayType === 'SUPPRESSED' ? 'suppressed' : 'triggered'}.</h3>
                    </MessageBox>
                </div>
            );
        } else {
            //Render With Groups.
            const rowGroups  = this.props.workspaceGroups || [] //&& this.props.workspaceGroups.length > 0 ? this.mapGroupsToRowGroups(this.props.workspaceGroups, this.props.rows || []) : [];
            
            body = rowGroups.map((g,i)=> (<ExceedancesGroup 
            key={g.name}    
            group={g}
            expanded={g.expanded || false}
            rows={groups[g.id]}
            exceedanceType={this.props.exceedanceDisplayType}
            canEdit={this.props.canEdit}
            expand={this.props.expandGroup}
            deleteGroup={this.props.deleteGroup}
            showNoData={this.state.showNoData}
        />));
        }

        return (
            <React.Fragment>
                <div
                    onMouseLeave={() => this.setState({ selectionInProgress: false })}
                >
                    {this.renderHeader()}
                    {body}
                </div>
            </React.Fragment>
        );
    }

    

    _setTagSelected(tag: TagEnvelopeDefinition, checked: boolean): TagEnvelopeDefinition[] {
        let tags = this.state.selectedTags.filter(s => s.tag !== tag.tag);
        if (checked) {
            return tags.concat(tag);
        }
        return tags;
    }

    
}



const mapStateToProps = (state:ApplicationState) => {
    const { viewWorkspace } = state;
    const selectedTags = viewWorkspace.selectedTags || [];
    return {
        selectedTags: selectedTags,
    }
}

const mapDispatchToProps = {
    clearSelectedTags : actionCreators.clearSelectedTags,
    expandGroup: actionCreators.expandGroup,
    expandAllGroups: actionCreators.expandAllGroups,
    deleteGroup: actionCreators.deleteGroup

}

export default connect(mapStateToProps, mapDispatchToProps)(ExceedancesTable);