import { SecondaryColours } from './colour-manager';

export function getPlantColor(plantName: string): string {
    switch (plantName.toUpperCase()) {
        case 'PLUTO':
        case 'PGP':
            return SecondaryColours.green;
        case 'PRP':
            return SecondaryColours.darkGreen;
        case 'KGP':
            return SecondaryColours.darkOrange;
        default:
            return SecondaryColours.aqua;
    }
}