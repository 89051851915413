import React from 'react'
import { Markdown } from '../view-workspace/markdown'


type ViewDescriptionState = {
    
}

type ViewDescriptionProps =  {
    description : string,
    stateName : string,
}


export class ViewDescription extends React.Component<ViewDescriptionProps, ViewDescriptionState>{
    constructor(props: any) {
        super(props);
        this.state = {
            
        };
    }
    render() {
        return (
            <React.Fragment>
                <Markdown description={this.props.description} />
                {this.props.children}
            </React.Fragment>)
            }
}