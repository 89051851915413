import { IPersonaProps } from 'office-ui-fabric-react';
import { TagDetail, TagEnvelopeDefinition } from '../../store/service-client/index';
import * as ServiceClient from '../../store/service-client/index';
import { allOfTheseIncludesText } from '../../utils/FilterText';

export interface TagHandler<T> {
    searchForTags(searchText: string): Promise<T[]>|T[];
    convertTagToPersona(tag: T): IPersonaProps;
    matches(t1: T, t2: T): boolean;
}

export type KnownTag = TagDetail|ServiceClient.TagEnvelopeDefinition|ServiceClient.TagExceeedance;

export class ModelApiTagHandler implements TagHandler<TagDetail> {
    async searchForTags(searchText: string): Promise<TagDetail[]> {
        let result = await ServiceClient.getServiceClient().searchForTags(searchText);
        return result;
    }  
    
    convertTagToPersona(tag: TagDetail): IPersonaProps {
        return {
            text: tag.name,
            secondaryText: tag.description ? tag.description : '',
            tertiaryText: tag.unitOfMeasure ? tag.unitOfMeasure : '',
            imageInitials: tag.plantName ? tag.plantName : ''
        } as IPersonaProps;
    }

    matches(t1: TagDetail, t2: TagDetail) {
        return t1 && t2 && t1.name === t2.name;
    }
}

export class ModelApiTagDefinitionTagHandler implements TagHandler<TagEnvelopeDefinition> {
    async searchForTags(searchText: string): Promise<TagEnvelopeDefinition[]> {
        let result = await ServiceClient.getServiceClient().searchForTags(searchText);
        let td = result.map(r => ({
            id: r.id,
            description: r.description,
            plantName: r.plantName,
            states: [],
            tag: r.name,
            unit: r.unitOfMeasure
        } as TagEnvelopeDefinition));
        return td;
    }  
    
    convertTagToPersona(tag: TagEnvelopeDefinition): IPersonaProps {
        return {
            imageInitials: tag.plantName ? tag.plantName : '',
            text : tag.tag,
            secondaryText: tag.description ? tag.description : '',
            tertiaryText: tag.unit ? tag.unit : ''
        } as IPersonaProps;
    }
    
    matches(t1: TagEnvelopeDefinition, t2: TagEnvelopeDefinition): boolean {
        return t1 && t2 && t1.tag === t2.tag;
    }
}

export class EnvelopeTagHandler implements TagHandler<TagEnvelopeDefinition> {
    envelopes: TagEnvelopeDefinition[] = [];
    constructor(tags: TagEnvelopeDefinition[]) {
        if ( tags && tags.length > 0 ) {
            this.envelopes = tags;
        }
    }

    searchForTags(searchText: string): TagEnvelopeDefinition[] {
        if(searchText) {
        let terms = searchText ? searchText.trim().split(' ') : [];
        return this.envelopes.filter( s => {
            return allOfTheseIncludesText(terms, [s.tag, s.description!, s.unit!, s.plantName!]);
        });
        } else {
            return this.envelopes
        }
    }   
    
    convertTagToPersona(tag: TagEnvelopeDefinition): IPersonaProps {
        return {
            imageInitials: tag.plantName ? tag.plantName : '',
            text : tag.tag,
            secondaryText: tag.description ? tag.description : '',
            tertiaryText: tag.unit ? tag.unit : ''
        } as IPersonaProps;
    }
    
    matches(t1: TagEnvelopeDefinition, t2: TagEnvelopeDefinition): boolean {
        return t1 && t2 && t1.tag === t2.tag;
    }
}