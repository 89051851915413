import React from 'react'
import ReactMde from 'react-mde';
import * as Showdown from 'showdown';
import "react-mde/lib/styles/css/react-mde-all.css";

type EditDescriptionProps = {
    stateName: string,
    description: string,
    onChange: Function
}

type EditDescriptionState = {
    description: string
    selectedTab: SelectedTabType
}

type SelectedTabType = "write" | "preview";

export class EditDescription extends React.Component<EditDescriptionProps,EditDescriptionState>{
    constructor(props:any){
        super(props);
        this.state = {
            description :  this.props.description,
            selectedTab : "write"
        }
    }


    componentDidUpdate(prop: EditDescriptionProps,state: EditDescriptionState){
        if(prop.description != this.props.description && this.props.description != this.state.description){
            this.setState({ description: this.props.description });
        }
    }

    render(){
        const converter = new Showdown.Converter({
            tables: true,
            simplifiedAutoLink: true,
            strikethrough: true,
            tasklists: true
        });
        
        return (<React.Fragment>
            <ReactMde
                value={this.state.description}
                onChange={ (value:any)=> this.setState({description:value}, this.props.onChange(value))}
                selectedTab={this.state.selectedTab}
                onTabChange={ ()=> {this.setState({selectedTab: this.state.selectedTab === "write" ? "preview" : "write"})}}
                generateMarkdownPreview={markdown =>
                    Promise.resolve(converter.makeHtml(markdown))
                }
            />
        </React.Fragment>)
    }
}