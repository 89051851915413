import * as ServiceClient from '../service-client';
import { Envelope, State, Condition,  Workspace } from '../slice-reducers/common-types';
import { ConditionsToDisplayRange } from '../../utils/condition-converters';

export async function LoadWorkspaceEnvelopes(dispatch:any, getState:any, workspaceName:string){
    const {entities } = getState();
    const { workspaces }  = entities;
    const { byId: workspacesById} = workspaces;
    const { envelopes } = entities;
    const { allIds, byId } = envelopes;
    const currentWorkspace: Workspace = workspacesById[workspaceName];
    if(currentWorkspace && currentWorkspace.envelopes && currentWorkspace.envelopes.length && currentWorkspace.envelopes.every((e:number)=> allIds.some((ae: number)=> ae == e))){
        const alreadyLoaded:ServiceClient.TagEnvelopeDefinition[]  = currentWorkspace.envelopes.map(e=> byId[e])
        .map((e:Envelope)=> {
            //const states = e.states ? e.states.map(s=> { return {...s, met: s.met || false}}) : []
            const tagDef: ServiceClient.TagEnvelopeDefinition = {
                id: e.id.toString(),
                tag: e.tag,
                states: e.states.map(s=> { return {...s, met: s.met || false}})
            }
            return tagDef;
        });
        return alreadyLoaded; 
    }
    //const envelopes = byId && Object.values(byId).length > 0 ? byId : _getWorkspacesEnvelopes(workspaceName,dispatch);
    const envs = _getWorkspacesEnvelopes(workspaceName, dispatch);
    return envs;
}
export async function ForceLoadWorkspaceEnvelopes(dispatch:any, workspaceName:string){
    return await _getWorkspacesEnvelopes(workspaceName, dispatch);
}

async function _getWorkspacesEnvelopes(workspaceName:string, dispatch:any){
    const serviceClient = ServiceClient.getServiceClient();
    const envs = await serviceClient.getWorkspaceEnvelopes(workspaceName, <ServiceClient.EnvelopeOptions>{ explodeEnvelopesByState: true, requestStates: true });
    dispatch({type:'GET_ENVELOPES',payload: {envelopes: envs, workspaceName: workspaceName}});
    return envs;
}

function mapCondition(condition: ServiceClient.StateCondition){
    const myCondition: Condition = {
        id: condition.id || (new Date().getTime() + Math.random() * 10).toString(),
        formula_type: condition.formula_type,
        low_threshold: condition.low_threshold,
        high_threshold: condition.high_threshold
    }
    return myCondition;
}
function mapState(state: ServiceClient.TagEnvelopeState){
    const myState: State = { 
        name: state.name,
        id: state.name,
        priority: state.priority,
        description: state.description,
        short_description: state.short_description,
        colour: state.colour,
        displayName: ConditionsToDisplayRange(state.conditions),
        conditions: state.conditions && state.conditions.length > 0 ? state.conditions.map(c=> { return mapCondition(c)}) : [],
    }
    return myState;
}

export function MapEnvelopes(envs:ServiceClient.TagEnvelopeDefinition[]){
    const envelopes = envs.reduce((map,env)=> {
        
        const states = (env.states && env.states.length > 0) ? env.states.map(s=> { return mapState(s)}) : [];
        const envelope: Envelope = { 
            id: parseInt(env.id), 
            tag: env.tag, 
            states: states
        }
        map[envelope.id] = envelope;
        return map;
    }, <Record<number,Envelope>>{});
    return envelopes;
}