import { Reducer } from 'redux'
import { ViewWorkspaceState, ExceedancesRowProps, DisplayExceedanceType } from "../view-workspace";
import * as ServiceClient from '../service-client';
import { AuthenticatedUser } from '../../auth';
import { ExportToCsv } from 'export-to-csv';
import { RemoveGroupFromWorkspaceAction } from './common-actions';
import { IWorkspaceTimePeriod } from './common-types';


export interface InitialLoadError { type: 'INITIAL_LOAD_ERROR'; errorMessage: string; }
export interface RefreshCurrent { type: 'REFRESH_CURRENT'; }

export interface ClearData { type: 'CLEAR_DATA_VIEW_WORKSPACE'; }
export interface LoadSuppressionRulesSuccess { type: 'LOAD_SUPPRESSION_RULES_SUCCESS'; suppressionRules: ServiceClient.SuppressionRule[]; }
export interface LoadSuppressionRulesError { type: 'LOAD_SUPPRESSION_RULES_ERROR'; errorMessage: string; }

export interface FilterExceedances { type: 'FILTER_EXCEEDANCES'; }
export interface FilterExceedancesSuccess {
    type: 'FILTER_EXCEEDANCES_SUCCESS';
    allExceedances: ExceedancesRowProps[];
    filteredExceedances: ExceedancesRowProps[];
    suppressedTagCount: number,
    displayExceedanceType: DisplayExceedanceType,
    exceedanceTagCount: number,
    startTime: Date,
    endTime: Date
}

export interface ExportDashboardToCsv { type: 'EXPORT_DASHBOARD_CSV'; dashboardInfo: any[]; startTime: Date; endTime: Date; }
export interface InitialLoad { type: 'INITIAL_LOAD'; workspaceName: string; currentUser: AuthenticatedUser }
export interface InitialLoadTagsInWorkspace { type: 'INITIAL_LOAD_TAGS_IN_WORKSPACE'; canEdit: boolean; totalTagCount: number }
export interface NoEnvelopesDefined { type: 'NO_ENVELOPES_DEFINED'; emptyWorkspace: boolean; canEdit: boolean; }
export interface InitialLoadTagDetails { type: 'INITIAL_LOAD_TAG_DETAILS'; tagDetails: Record<string, ServiceClient.TagDetail>; }
export interface SetSelectedTagsAction { type: 'SET_SELECTED_TAGS', payload: { selectedTags: ServiceClient.TagEnvelopeDefinition[] } }
export interface ClearSelectedTagsAction { type: 'CLEAR_SELECTED_TAGS' };
export interface InitialLoadResponse {
    type: 'INITIAL_LOAD_RESPONSE';
    initialExceedances: ExceedancesRowProps[];
    initialSuppressionRules: ServiceClient.SuppressionRule[];
    canEdit: boolean;
    exceedanceCount: number,
    suppressedTagCount: number,
    displayExceedanceType: DisplayExceedanceType,
    startTime: Date,
    endTime: Date
}
export interface GroupExpandedAction {
    type: 'GROUP_EXPANDED',
    payload: { workspaceGroup: ServiceClient.WorkspaceGroup }
}

export interface GroupsExpandedAction {
    type: 'GROUPS_EXPANDED',
    payload: { workspaceGroups: ServiceClient.WorkspaceGroup[] }
}

export interface LoadWorkspaceTimePeriod {
    type: 'LOAD_WORKSPACE_TIME_PERIOD',
    payload: { workspaceTimePeriod: IWorkspaceTimePeriod }
}

export interface LoadWorkspaceTimePeriodError {
    type: 'LOAD_WORKSPACE_TIME_PERIOD_ERROR',
    payload: { workspaceGroups: ServiceClient.WorkspaceGroup[] }
}






export type ViewWorkspaceAction = InitialLoadError | InitialLoad | InitialLoadResponse
    | RefreshCurrent | ClearData
    | LoadSuppressionRulesError | LoadSuppressionRulesSuccess
    | FilterExceedances | FilterExceedancesSuccess
    | NoEnvelopesDefined | InitialLoadTagDetails | InitialLoadTagsInWorkspace
    | ExportDashboardToCsv | SetSelectedTagsAction | ClearSelectedTagsAction | GroupExpandedAction
    | GroupsExpandedAction | RemoveGroupFromWorkspaceAction
    | LoadWorkspaceTimePeriod | LoadWorkspaceTimePeriodError


export const viewWorkspaceReducer: Reducer<ViewWorkspaceState> = (state: ViewWorkspaceState, action: ViewWorkspaceAction) => {
    switch (action.type) {
        case 'INITIAL_LOAD': {
            return {
                isInitialLoadLoading: true,
                emptyWorkspace: false,
                workspaceName: action.workspaceName,
                exceedanceTagCount: 0,
                totalTagCount: 0,
                suppressedTagCount: 0,
                suppressionRules: undefined,
                canEdit: false,
                displayExceedanceType: 'EXCEEDANCE',
                currentExceedances: [],
                filteredExceedances: [],
                allEnvelopes: [],
                startTime: state.startTime,
                endTime: state.endTime,
                filterText: '',
                user: action.currentUser,
                // workspaceTimePeriod: undefined
            };
        }
        case 'REFRESH_CURRENT': {
            return {
                ...state,
                isInitialLoadLoading: true,
                emptyWorkspace: false,
                exceedanceTagCount: 0,
                suppressedTagCount: 0,
                suppressionRules: undefined,
            };
        }
        case 'INITIAL_LOAD_TAGS_IN_WORKSPACE': {
            return {
                ...state, canEdit: action.canEdit, totalTagCount: action.totalTagCount
            }
        }
        case 'INITIAL_LOAD_RESPONSE': {
            return {
                ...state,
                currentExceedances: action.initialExceedances,
                suppressionRules: action.initialSuppressionRules,
                isInitialLoadLoading: false,
                filteredExceedances: [],
                dataLoadedAt: new Date(),
                canEdit: action.canEdit,
                exceedanceTagCount: action.exceedanceCount,
                suppressionTagCount: action.suppressedTagCount,
                displayExceedanceType: action.displayExceedanceType,
                startTime: action.startTime,
                endTime: action.endTime
            };
        }

        case 'NO_ENVELOPES_DEFINED': {
            return { ...state, emptyWorkspace: true, isInitialLoadLoading: false, canEdit: action.canEdit };
        }
        case 'INITIAL_LOAD_ERROR':
            return {
                isInitialLoadLoading: false,
                workspaceName: state.workspaceName,
                initialLoadErrorMessage: action.errorMessage,
                currentExceedances: state.currentExceedances,
                filteredExceedances: state.currentExceedances,
                canEdit: false,
                displayExceedanceType: 'EXCEEDANCE',
                startTime: state.startTime,
                endTime: state.endTime,
                filterText: '',
                allEnvelopes: [],
            };

        case 'CLEAR_DATA_VIEW_WORKSPACE': {
            return {
                currentExceedances: [],
                filteredExceedances: [],
                exceedanceTagCount: 0,
                totalTagCount: 0,
                suppressedTagCount: 0,
                dataLoadedAt: undefined,
                workspaceName: '',
                suppressionRules: undefined,
                canEdit: false,
                displayExceedanceType: 'EXCEEDANCE',
                allEnvelopes: [],
                startTime: state.startTime,
                endTime: state.endTime,
                filterText: ''

            };
        }

        case 'LOAD_SUPPRESSION_RULES_SUCCESS': {
            return { ...state, suppressionRules: action.suppressionRules };
        }

        case 'FILTER_EXCEEDANCES': {
            return { ...state, isInitialLoadLoading: true };
        }

        case 'FILTER_EXCEEDANCES_SUCCESS': {
            return {
                ...state, isInitialLoadLoading: false,
                currentExceedances: action.allExceedances,
                filteredExceedances: action.filteredExceedances,
                exceedanceTagCount: action.exceedanceTagCount,
                suppressedTagCount: action.suppressedTagCount,
                displayExceedanceType: action.displayExceedanceType,
                startTime: action.startTime,
                endTime: action.endTime
            };
        }

        case 'EXPORT_DASHBOARD_CSV': {
            const start = action.startTime.toLocaleString().replace(' ', '').replace(',', '_');
            const end = action.endTime.toLocaleString().replace(' ', '').replace(',', '_');
            const filename = `${state.workspaceName}-${start}-${end}`;
            const options = {
                fieldSeparator: ',',
                quoteStrings: '"',
                showLabels: true,
                showTitle: false,
                title: state.workspaceName,
                useTextFile: false,
                useBom: true,
                useKeysAsHeaders: true,
                filename: filename
            };
            const csvExporter = new ExportToCsv(options);
            csvExporter.generateCsv(action.dashboardInfo);
            break;
        }
        case 'INITIAL_LOAD_TAG_DETAILS': {
            return { ...state, tagDetails: action.tagDetails };
        }
        //case 'GROUP_ADDED': {
        // const wsgroups = state.workspaceGroups || [];
        // const newGroups = Utils.updateItemInArray(wsgroups, action.group.id, (item:ServiceClient.WorkspaceGroup) => { return Utils.updateObject(item,action.group)});
        // return {...state, workspaceGroups: newGroups}
        //}
        case 'SET_SELECTED_TAGS': {
            const { payload } = action;
            const { selectedTags } = payload;
            return { ...state, selectedTags: selectedTags }
        }
        case 'CLEAR_SELECTED_TAGS': {
            return { ...state, selectedTags: [] }
        }
        // case 'GROUP_EXPANDED': {
        //     return {...state, workspaceGroups: action.workspaceGroups }
        // }
        case 'LOAD_WORKSPACE_TIME_PERIOD': {
            const { payload } = action;
            const { workspaceTimePeriod } = payload;
            return { ...state, workspaceTimePeriod: workspaceTimePeriod }
        }
        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || { currentExceedances: undefined, filteredExceedances: undefined };
};