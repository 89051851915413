import * as React from 'react';

const notExact = '<>';
const greaterThan = '>';
const lessThan = '<';
export class SupportedFunctions extends React.Component<any, any>{
    constructor(props: any) {
        super(props);
    }

    displayValue(val: string) {
        return '{' + val + '}'
    }

    render() {
        return (
            <React.Fragment>
                    <div className='ms-Grid'  >
                        <p style={{ fontSize: '16px' }}><b>mean</b></p>
                        <p style = {{}}>Returns the average (arithmetic mean) of arguments</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                mean(c(x,y,z...))
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                AVERAGE, AVG, AVERAGEIF
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                mean(2,3,4,4)=3.25
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>==</p>
                        <p>Compares 2 strings and returns TRUE if both the values are same</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                x==y
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                EXACT
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                x==2 When x is equal to 2 function will return TRUE
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>!=</p>
                        <p>Compares 2 strings and returns TRUE if both the values are not same</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                x!=y
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                {notExact}
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                x!=2 When x is any value other than 2 function will return TRUE
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>max</p>
                        <p>Return the largest numeric value in a range of values</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                max(c(x,y,z...))
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                MAX
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                max(1,2,45,7,2) = 45
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>min</p>
                        <p>Return the smallest numeric value in a range of values</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                min(c(x,y,z...))
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                MIN
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                min(1,2,45,7,2) = 1
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>sqrt</p>
                        <p>Return square root of a number</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                sqrt(x) = y
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                SQRT
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                sqrt(36) = 6
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>^</p>
                        <p>Return the result of a number raised to a given power</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                x^y
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                ^, POWER
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                3^2 = 9
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>if</p>
                        <p>Returns a defined value if condition is TRUE</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                if(condition){this.displayValue('statement')}
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                IF
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                if(x==10) {this.displayValue('1')} when x is equal to 10 function will return 1
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>ifelse</p>
                        <p>Returns a defined value if condition is true or false</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                if(condition) {this.displayValue('statement')} else {this.displayValue('statement')}
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                IFELSE
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                if(x{greaterThan}y) {this.displayValue('x')} else{this.displayValue('y')}
                            when x= 7 , y=5 function will return 7
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>&&</p>
                        <p>Requires more than one condition to be true at the same time and returns either true or false</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                condition1 && condition2
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                AND
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                x{greaterThan}0 && y{lessThan}0
                            When x greater than 0 and y less than 0, function will return TRUE
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>||</p>
                        <p>Requires either condition to be truw to return TRUE</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                condition1 || condition2
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                OR
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                x{greaterThan}0 || y{lessThan}0
                            When x greater than 0 or y less than 0, function will return TRUE
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>length</p>
                        <p>Return the count of values that are numbers, generally cells
                        that contain numbers. values can be supplied as constants
                        reference or ranges</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                length(c(x,y,z...))
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                COUNT, COUNTIF
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                length(1,4,3,6,4,2) = 6
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>sum</p>
                        <p>Returns the sum of values supplied</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                sum(c(x,y,z...))
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                SUM, SUMIF
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                sum(1,2,3,4) = 10
                            </div>
                        </div>
                        <p style={{ paddingTop: '1em', fontWeight: 'bold', fontSize: '16px' }}>abs</p>
                        <p>Return the absolute value of a number. In other words:the
                        abs function removes the minus sign from a negative number, making it positive</p>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                R syntax
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ overflowWrap: 'break-word', minWidth: '100px' }} >
                                abs(x-y)
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }}>
                                Similar Excel functions
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                ABS
                            </div>
                        </div>
                        <div className='ms-Grid-row' style={{ marginBottom: '5px', marginTop: '5px' }}>
                            <div className='ms-Grid-col ms-sm3' style={{ overflowWrap: 'break-word', minWidth: '100px', backgroundColor: 'lightgrey' }} >
                                Example
                            </div>
                            <div className='ms-Grid-col ms-sm9' style={{ minWidth: '100px', overflowWrap: 'break-word' }} >
                                abs(1-6) = 5
                            </div>
                        </div>
                    </div>
            </React.Fragment>
        )
    }
}