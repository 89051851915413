import React from 'react';
import { ColourManager } from '../common'
import { TagEnvelopeState } from '../../store/service-client/index'
import { StateDescription, StateDisplayType  } from '../state-description'
// import { DefaultButton } from 'office-ui-fabric-react';


export type ExceedanceRowStateState = {
    displayType: StateDisplayType
};

type ExceedanceRowStateProps = {
    workspace: string,
    envelopeId: string,
    tag: string,
    rowState: TagEnvelopeState,
    canEdit:boolean,
    suppressed: boolean,
    currentValue?: string
} ;

export class ExceedanceRowState extends React.Component<ExceedanceRowStateProps, ExceedanceRowStateState> {
    constructor(props: any) {
        super(props);
        this.state = {
            displayType: "none"
        };
    }

    showStateDescription(){
        this.setState({displayType: 'readonly'});
    }
    
    render(){
        return (
        <React.Fragment>
            <div className='ms-Grid-row' style={{marginBottom: '5px'}}>
            <div className='ms-Grid-col ms-sm12 ms-xxl8' style={{cursor: 'pointer',textAlign: 'center', height: '25px', color: '#fff', opacity: this.props.suppressed ? 0.5 : this.props.rowState.met ? 1 : (this.props.rowState.name === "no-data"&& this.props.currentValue === undefined)?1:0.2, backgroundColor: this.props.rowState.colour ? this.props.rowState.colour : ColourManager.StatusColours.blueGrey}} onClick={ ()=> {this.showStateDescription()}}>
                    <div style={{display:'flex', justifyContent: 'space-around', height: '100%', alignItems:'center'}}>
                        {this.props.rowState.name}
                    </div>
                    {/* <DefaultButton onClick={(e:any )=> {this.setState({displayType:'readonly'})}} text={this.props.rowState.name} style={{color: '#fff', backgroundColor: this.props.rowState.colour ? this.props.rowState.colour : ColourManager.StatusColours.blueGrey}} /> */}
                </div>
                <div className='ms-Grid-col ms-xxl4 ms-hiddenXlDown' style={{textAlign:'center'}}><small>{this.props.rowState.displayRange}</small></div>
                { this.props.rowState.name != "no-data" ? (<StateDescription 
                    envelopeId={this.props.envelopeId}
                    description={this.props.rowState.description} 
                    stateName={this.props.rowState.name} 
                    displayType={this.state.displayType} 
                    canEdit={this.props.canEdit} 
                    onDisplayTypeChange={(value:any)=> {this.setState({displayType: value})}}
                />) : undefined}
            </div>
        </React.Fragment>
        );
    }
}
