import * as React from 'react';
import { TextField, ITextField, IconButton } from 'office-ui-fabric-react';

export interface FilterOptions {
    filter: string;
    filterBouncePeriod?: number;
    initialFocus?: boolean;
    hideLabel?: boolean;
    hideGarbageIcon?: boolean;
    isInCommandBar?: boolean;
}

type FilterProps = FilterOptions & { onFilterChange?(props: FilterOptions): any };

export class FilterBox extends React.Component<FilterProps, FilterOptions> {
    DEFAULT_FILTER_TIMEOUT = 500;

    constructor(props: any) {
        super(props);
        this.state = {initialFocus: true, ...props};
        if ( this.state.filterBouncePeriod === undefined ) {
            this.state = {...this.state, filterBouncePeriod: this.DEFAULT_FILTER_TIMEOUT, filter: ''};
        }
    }

    filterTimeout: any = undefined;
    
    onFilterTextChange = (val: string) => {        
        if ( this.filterTimeout !== undefined ) {
            clearTimeout(this.filterTimeout);
        }
        this.filterTimeout = setTimeout(
            () => {
                let newstate = {...this.state, filter: val};
                this.setState(newstate, () => {
                    if (this.props.onFilterChange) {
                        this.props.onFilterChange(newstate);
                   }
                });
            },
            this.state.filterBouncePeriod);
    };

    onFilterChange() {
        if (this.props.onFilterChange) {
            this.props.onFilterChange(this.state);
       }
    }

    componentDidUpdate() {
        if ( this.state.initialFocus ) {
            this.focusFilter();
        }
    }    

    private filterRef = React.createRef<ITextField>();
    focusFilter() {
        const node = this.filterRef.current;
        if ( node ) {
            node.focus();
        }
     }
    render() {
        const eventValue = 'value';
        const tfRootStyles = (this.props.isInCommandBar ? { flex: 1, height: '100%', marginTop: 4 } : { flex: 1 });
        return (
            <React.Fragment>    
                <div style={{ display: 'flex', flexDirection: 'row', minWidth: 200 }}>
                    <TextField
                        componentRef={this.filterRef}
                        autoFocus
                        placeholder="Search..."
                        accessKey="s"
                        type="search"
                        label={this.props.hideLabel ? undefined : 'Filter'}
                        styles={{
                            root: tfRootStyles,
                            subComponentStyles: {
                                input: { root: this.props.isInCommandBar ? { height: '100%' } : { height: '15em'} },
                                label: { root: { fontWeight: 'bold' } }
                            }
                        }}
                        value={this.state.filter}
                        onChange={(e) => this.onFilterTextChange(e.target[eventValue])}
                    />
                    {this.props.hideGarbageIcon
                        ? undefined
                        : (
                            <IconButton
                                style={{ alignSelf: 'flex-end'}}
                                iconProps={{iconName: 'Delete'}} 
                                onClick={() => { this.onFilterTextChange(''); this.focusFilter(); }}
                            />
                        )}
                </div>
            </React.Fragment>
        );
    }
}