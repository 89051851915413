import * as React from 'react';
import * as SearchTags from '../../store/search-tags';
import { TagExceeedance, TagDetail, TagEnvelopeDefinition } from '../../store/service-client/index';
import { ApplicationState } from '../../store/index';
import { connect } from 'react-redux';
import { TagHandler, KnownTag } from './tag-handlers';
import { ListPeoplePicker, NormalPeoplePicker, BasePeoplePicker, IPeoplePickerProps }
    from 'office-ui-fabric-react/lib/components/pickers/PeoplePicker/PeoplePicker';
import { IPersonaProps } from 'office-ui-fabric-react/lib/components/Persona/Persona.types';
import { TagSelectionItem, TagSelectionItemStyle } from './tag-selection-item';
import { IBasePicker, DefaultButton, Label, IPickerItemProps, Spinner } from 'office-ui-fabric-react';
import './tag-picker.css';

type TagPickerProps = SearchTags.SearchTagsState & typeof SearchTags.actionCreators &
{
    tags?: KnownTag[];
    handler: TagHandler<TagDetail | TagEnvelopeDefinition | TagExceeedance>,
    onSelectionUpdated?: any,
    pickerStyle: TagPickerStyle,
    inputDisabled?: boolean,
    allDisabled?: boolean,
    selection?: string,
    delay?: number,
    lockToSingleTag?: boolean,
    showTagsOnLoad?: boolean,
    pickerLabelStyle?:any,
    pickerBoxStyle?:any
};

type TagPickerState = {
    suggestions: KnownTag[],
    selected: KnownTag[],
    currentSearch?: string,
    pickerDisabled: boolean,
    searchText?: string,
    key: string
};

export enum TagPickerStyle { SINGLE_TAG, MULTI_TAG }

export class TagPicker extends React.Component<TagPickerProps, TagPickerState> {
    constructor(props: any) {
        super(props);
        let uniqid = btoa(new Date().getTime().toString() + Math.random());
        this.state = {
            pickerDisabled: this.props.inputDisabled === true && (!(this.props.tags && this.props.tags.length > 1)),
            //suggestions: this.props.tags ? this.props.tags : [],
            suggestions: [],
            selected: this.props.tags ? this.props.tags : [],
            currentSearch: '',
            searchText: this.props.selection ? this.props.selection : '',
            key: uniqid
        };
    }

    static getDerivedStateFromProps(props: TagPickerProps, state: TagPickerState) {
        let suggestions = undefined;
        if (props.searchedTags && (props.searchKey === state.key || props.showTagsOnLoad)) {
            suggestions = { suggestions: props.searchedTags.filter( t => state.selected.indexOf(t) < 0) };
        }
        let handler = undefined;
        if ( props.handler ) {
            handler = { handler: props.handler };
        }
        return {...suggestions, ...handler};
    }

    private beginSearching = async (filterText: string) => {
        try {
            await this.props.searchingForTags(filterText, this.props.handler, this.state.key);
            this.setState({ currentSearch: filterText });
        } catch (r) {
            // tslint:disable-next-line:no-console
        }

        let results: IPersonaProps[] = [];
        if (this.props.searchIsLoading === false && this.state.suggestions.length > 0) {
            results = this.prepareSuggestions(this.state.suggestions);
        }

        return results;
    }

    prepareSuggestions(tags: KnownTag[]): IPersonaProps[] {
        let selected = this._pickerRef.current!.items!.map(i => i.text);

        let results: IPersonaProps[] = tags
            .map(this.props.handler.convertTagToPersona)
            .filter(p => selected.indexOf(p.text) < 0);

        results.sort((s1: IPersonaProps, s2: IPersonaProps) => {
            if (s1.text && s2.text) {
                return s1.text.localeCompare(s2.text);
            }
            return 0;
        });

        return results;
    }

    _onItemSelected(selectedItem: IPersonaProps): IPersonaProps | null {
        if (this._pickerRef.current !== undefined &&
            this._pickerRef.current!.items!.find(i => i.text === selectedItem.text)) {
            return null;
        }
        if (this.props.pickerStyle === TagPickerStyle.SINGLE_TAG) {
            this.setState({ pickerDisabled: true });
        }

        const selectedTags: TagEnvelopeDefinition[] = [ { tag: selectedItem.text } as TagEnvelopeDefinition ];

        if (this.props.onSelectionUpdated) {
            this.props.onSelectionUpdated(selectedTags);
        }
        return selectedItem;
    }

    _onMultiItemSelected(selectedItem: IPersonaProps): void {
        let selectedTag: KnownTag|undefined = undefined;
        let suggested = this.state.suggestions.filter( s => {
            let persona = this.props.handler.convertTagToPersona(s);
            if ( persona.text !== selectedItem.text ) {
                return true;
            } else {
                selectedTag = s;
                return false;
            }
        });
        let selected = this.state.selected.filter( s => {
            let persona = this.props.handler.convertTagToPersona(s);
            return persona.text !== selectedItem.text;
        }).concat(selectedTag!);
        this.setState( {suggestions: suggested, selected: selected}, () => {
            if ( this.props.onSelectionUpdated ) {
                this.props.onSelectionUpdated( selected );
            }
        });
        if ( this._pickerRef.current ) {
            let picker = this._pickerRef.current as BasePeoplePicker;
            picker.setState( {items : picker.items.concat( this.props.handler.convertTagToPersona(selectedTag!))});
        }
    }

    _onRenderSelectedItem(proops: IPickerItemProps<IPersonaProps>): JSX.Element {
        return (
            <TagSelectionItem
                item={proops.item}
                key={`selected-${proops.item.text}`}
                tagSelectionItemStyle={TagSelectionItemStyle.COMPACT}
                onClick={() => {
                    let picker = this._pickerRef.current as BasePeoplePicker;
                    picker.setState({ items: picker.items.filter(i => i.text !== proops.item.text) });
                    this._removeSelectedItem(proops.item);
                }}
            />
        );
    }

    _removeSelectedItem(removedItem: IPersonaProps) {
        this.setState(   {selected: this.state.selected.filter( s => {
            let persona = this.props.handler.convertTagToPersona(s);
            return persona.text !== removedItem.text;
                    })}, () => {
                        if ( this.props.onSelectionUpdated ) {
                            this.props.onSelectionUpdated(this.state.selected);
                        }
        });
    }

    _onRenderSuggestionsItem(item: IPersonaProps): JSX.Element {
        return (
            <TagSelectionItem
                item={item}
                key={`suggestion-${item.text}`}
                tagSelectionItemStyle={TagSelectionItemStyle.NORMAL}
                searchText={this.state.currentSearch}
            />
        );
    }

    _onClearSelection() {
        let picker = (this._pickerRef.current as BasePeoplePicker);
        this.setState({ pickerDisabled: false, selected: [], searchText: ''}, () => {
            picker.setState({ items: [] });
            let protectedKey = 'input';
            picker[protectedKey].current!.clear();
        });
    }

    componentDidMount() {
        //this.props.clearSearchedTags();
        this.beginSearching('');
    }

    _pickerRef = React.createRef<IBasePicker<IPersonaProps>>();
    render() {
        let single = this.props.pickerStyle === TagPickerStyle.SINGLE_TAG;
        let multi = this.props.pickerStyle === TagPickerStyle.MULTI_TAG;
        let labelStyles = {fontWeight: 800, flex: 1};
        labelStyles = {...labelStyles, ...this.props.pickerLabelStyle};
        let pickerBoxStyles = {display:'flex', marginBottom:'2em'}
        pickerBoxStyles = {...pickerBoxStyles, ...this.props.pickerBoxStyle}
        
        if ( multi && this._pickerRef.current && this.props.handler ) {
            let picker = this._pickerRef.current as BasePeoplePicker;
            let items = this.state.selected.map( s => this.props.handler.convertTagToPersona(s));
            picker.setState( {items : items});
        }

        let defaultPersonas: IPersonaProps[] = [];
        let tagSearchFn = this.beginSearching;

        if (this.props.lockToSingleTag) {
            const tagDetails = this.state.selected as TagEnvelopeDefinition[];
            defaultPersonas = tagDetails.map(s => ({ text: s.tag, imageInitials: s.plantName ? s.plantName : ''}) as IPersonaProps);
            tagSearchFn = async () => { return defaultPersonas; };
        }

        let pickerProps: IPeoplePickerProps = {            
            componentRef: this._pickerRef,
            disabled: this.props.allDisabled === true || this.state.pickerDisabled,
            onItemSelected: (item: IPersonaProps) => this._onItemSelected(item),
            onRenderItem: (pickerItem: IPickerItemProps<IPersonaProps>) => this._onRenderSelectedItem(pickerItem),
            onRenderSuggestionsItem: (item: IPersonaProps) => this._onRenderSuggestionsItem(item),
            onResolveSuggestions: tagSearchFn,
            pickerCalloutProps: {
                styles: {
                    calloutMain: { 
                        minWidth: '36em',
                        display: multi ? 'none' : undefined
                    }
                }
            },
            pickerSuggestionsProps: { noResultsFoundText: 'No tags found', loadingText: 'Loading Tags ...' },
            resolveDelay: this.props.delay ? this.props.delay : 500,
            inputProps: {                
                defaultVisibleValue: this.state.searchText,
            },
            defaultSelectedItems: defaultPersonas
        };

        let title = { title: single ? undefined : 'Click to remove' };
        let theCursor = single ? undefined : 'pointer';
        let style = { 
            className: 'selectedSuggestions',
            style : {
                display: 'flex', justifyContent: 'space-between',
                cursor: theCursor, width: '40em',
            }
        };
        let clearSelection = this.props.lockToSingleTag ? undefined : 
            (
                <DefaultButton
                    disabled={this.props.allDisabled === true ? true : false} 
                    style={{ flex: 1  }}
                    onClick={() => {
                        this._onClearSelection();
                    }}
                >
                    Clear Selection
                </DefaultButton>
            );
        
        return (
            <div style={pickerBoxStyles}>
                <div
                    {...title}
                    {...style}
                >             
                    <Label style={labelStyles}>Tag</Label>
                    <div className={multi ? 'selectedSuggestions' : undefined} style={{ flex: 5 }}>
                        {this.props.pickerStyle === TagPickerStyle.SINGLE_TAG ? 
                            (<NormalPeoplePicker {...pickerProps} />)
                            : (<ListPeoplePicker  {...pickerProps} />)}
                        {this.props.tags && !this.props.handler ? (<Spinner/>) : undefined}
                    </div>
                    <div style={{ flex: 0.5 }} />
                    {clearSelection}
                </div>
                <div className={multi ? 'suggestionsVisible' : 'suggestionsHidden'}>
                    {this.state.suggestions
                        .filter( _ => this.props.handler )
                        .map( suggestion => this.props.handler.convertTagToPersona(suggestion))
                        .map( (persona, i) => {
                            let suggestionItem = this._onRenderSuggestionsItem(persona);
                            return (
                                <div 
                                    key={`suggestionItem-${i}`}
                                    style={{cursor: 'pointer'}} 
                                    onClick={() => this._onMultiItemSelected(persona)}
                                >                                    
                                    {suggestionItem}
                                </div>
                            );
                        })}
                </div>
            </div>
        );
    }
}

const mapStateToProps = (state: ApplicationState) => state.searchTags;
// Wire up the React component to the Redux store
export default (connect(mapStateToProps, SearchTags.actionCreators)(TagPicker));