import { combineReducers } from 'redux'
import { removeItemFromArray } from '../reducerUtilities'
import { Workspace } from './common-types';
import { AddWorkspaceAction, 
    RenameWorkspaceAction, 
    DeleteWorkspaceAction, 
    GetWorkspaceAction, 
    AddGroupToWorkspaceAction, 
    AddEnvelopeToWorkspaceAction,
    RemoveEnvelopeFromWorkspaceAction, 
    RemoveGroupFromWorkspaceAction, 
    AddSuppressionToWorkspaceAction, 
    RemoveSuppressionFromWorkspaceAction,
    } from './common-actions'
import { GroupAddedAction} from '../actions/group'
import { GroupAction, GetGroupsAction } from './workspace-group'
import { GetEnvelopesAction } from '../actions/envelope'


export interface WorkspacesState {
    byId: Record<string, Workspace>;
    allIds: string[];
}



export type WorkspaceAction = AddWorkspaceAction | RenameWorkspaceAction | DeleteWorkspaceAction | GetWorkspaceAction | AddGroupToWorkspaceAction | AddEnvelopeToWorkspaceAction | RemoveEnvelopeFromWorkspaceAction | RemoveGroupFromWorkspaceAction | AddSuppressionToWorkspaceAction | RemoveSuppressionFromWorkspaceAction | GroupAddedAction | GetEnvelopesAction | GroupAction;

//Get All Workspaces reducer
function getWorkspaces(state: Record<string, Workspace>, action:GetWorkspaceAction): Record<string,Workspace>{
    const { payload } = action;
    const { workspaces } = payload;
    const newState = workspaces.reduce((map,ws)=> { 
        map[ws.name] = ws;
        return map;
    },<Record<string,Workspace>>{});
    return newState;
}

//Add case reducer 
function addWorkspace(state:Record<string, Workspace>, action:AddWorkspaceAction): Record<string,Workspace>{
    const {payload } = action;
    const { workspace } = payload;
    const ws = state[workspace.name];
    if(ws){
        return state;
    }
    const newWorkspaces = {
        ...state,
        [workspace.name]:workspace
    };
    return newWorkspaces;
}

//Update case reducer
function renameWorkspace(state: Record<string,Workspace>, action:RenameWorkspaceAction):Record<string, Workspace> {
    const { payload } = action;
    const { oldName, newName } = payload;
    const stateWorkspace:Workspace = state[oldName];
    const newstate = {...stateWorkspace, name: newName};
    
    delete state[oldName];
    const newWorkspaces = {
        ...state,
        [newName]: newstate
    };
    return newWorkspaces;
}

function addGroup(state: Record<string, Workspace>, action: GroupAddedAction ): Record<string,Workspace>{
    const { group } = action;
    //const { workspace, group } = payload;
    const ws = state[group.workspace];
    const groups = ws && ws.groups || [];
    const newState  = groups.some((g:number)=> g == group.id) ? state 
        : {
            ...state,
            [group.workspace]: {
                ...ws,
                groups: groups.concat(group.id)
            }
        }
    return newState;
    
}

function addGroups(state: Record<string, Workspace>, action: GetGroupsAction){
        const { payload } = action;
        const { groups} = payload;
        // Get the current workspace
        const ws = state[groups[0].workspace];
        const wsGroups = ws.groups || [];
        //Get the new group to add to the workspace
        const toAdd = groups.filter(g=> wsGroups.some((gg:number)=> gg === g.id) === false).map(g=> g.id);
        //Create a new array of the existing plus new 
        ws.groups = wsGroups.concat(toAdd);
        return {
            ...state,
            [ws.name] : {...ws }
        }
    }

function removeGroup(state: Record<string,Workspace>, action: RemoveGroupFromWorkspaceAction): Record<string,Workspace>{
    const {payload } = action;
    const {workspace, group } = payload;
    const ws = state[workspace];
    return {
        ...state,
        [workspace]: {
            ...ws,
            groups: removeItemFromArray(ws.groups!,group.id)
        }
    }
}

function addTag(state: Record<string, Workspace>, action: AddEnvelopeToWorkspaceAction ): Record<string,Workspace>{
    const { payload } = action;
    const { workspace, envelope } = payload;
    const ws = state[workspace];
    return {
        ...state,
        [workspace]: {
            ...ws,
            tags: (ws.tags || []).concat(envelope.tag)
        }
    }
}

function addTagsToEnvelope(state: Record<string,Workspace>, action: GetEnvelopesAction): Record<string,Workspace>{
    const { payload } = action;
    const { workspaceName, envelopes } = payload;
    const ws:Workspace = state[workspaceName];
    if(ws){
        const wsTags = ws.tags || [];
        const tags = envelopes.map(e=> e.tag).filter(t=> wsTags.some(wt=> wt == t) === false);
        ws.tags = wsTags.concat(tags);

        const envs = ws.envelopes || [];
        const envsToAdd = envelopes.filter(e=> envs.some(we=> we === parseInt(e.id)) === false);
        ws.envelopes = envs.concat(envsToAdd.map(e=> parseInt(e.id)));
        return {
            ...state,
            [workspaceName] : {...ws}
        }
    }
    else
    {
        return {
            ...state,
            [workspaceName] : {name: workspaceName, urlName: workspaceName, tags: envelopes.map(e=> e.tag)}
        }
    }
    return state;
}

function removeTag(state:Record<string,Workspace>, action: RemoveEnvelopeFromWorkspaceAction): Record<string,Workspace>{
    const {payload } = action;
    const {workspace, envelope } = payload;
    const ws = state[workspace];
    return {
        ...state,
        [workspace]: {
            ...ws,
            tags: removeItemFromArray(ws.groups || [], envelope.tag)
        }
    }
}

//Delete case reducer
function deleteWorkspace(workspacesState: Record<string,Workspace>, action: DeleteWorkspaceAction){
    const { payload } = action;
    const { workspace } = payload; 
    delete workspacesState[workspace];
    const newWorkspaces = {...workspacesState};
    return newWorkspaces;
}

function removeSuppression(state:Record<string,Workspace>, action: RemoveSuppressionFromWorkspaceAction){
    const {payload } = action;
    const {workspace, suppression } = payload;
    const ws = state[workspace];
    return {
        ...state,
        [workspace]: {
            ...ws,
            suppressions: removeItemFromArray(ws.suppressions || [], suppression.id)
        }
    }
}

//Workspace By Id Slice
function workspacesById(state= <Record<string,Workspace>>{}, action: WorkspaceAction) {
    switch(action.type){
        case 'ADD_ENVELOPE_TO_WORKSPACE':
            return addTag(state,action);
        case 'REMOVE_ENVELOPE_FROM_WORKSPACE':
            return removeTag(state,action);
        case 'ADD_WORKSPACE':
            return addWorkspace(state, action)
        case 'RENAME_WORKSPACE':
            return renameWorkspace(state,action);
        case 'DELETE_WORKSPACE':
            return deleteWorkspace(state, action);
        case 'GET_WORKSPACES':
            return getWorkspaces(state, action);
        // case 'ADD_SUPPRESSION_TO_WORKSPACE':
        //     return addSuppression(state,action);
        case 'REMOVE_SUPPRESSION_FROM_WORKSPACE':
            return removeSuppression(state, action);
        case 'GET_ENVELOPES': 
            return addTagsToEnvelope(state,action);
        case 'GET_GROUPS': 
            return addGroups(state,action);
        // case 'ADD_GROUP_TO_WORKSPACE':
        //     return addGroup(state,action);
        case 'REMOVE_GROUP_FROM_WORKSPACE':
            return removeGroup(state,action);  
        case 'GROUP_ADDED':
            return addGroup(state,action);
        default:
            return state;
    }
}


function addWorkspaceId(state: string[], action: AddWorkspaceAction){
    const { payload } = action;
    const { workspace } = payload;
    if(state.some(s=> s == workspace.name)){
        return state;
    }
    else{
        return [...state, workspace.name]
    }
}

function deleteWorkspaceId(state: string[], action:DeleteWorkspaceAction){
    const { payload } = action;
    const { workspace } = payload;
    return removeItemFromArray(state,workspace);
}

function getWorkspaceIds(state: string[], action:GetWorkspaceAction){
    const {payload } = action;
    const { workspaces } = payload;
    return workspaces.map(w=> w.name);
}

function allWorkspaces(state: string[] = [], action:WorkspaceAction){
    switch(action.type){
        case 'ADD_WORKSPACE':
            return addWorkspaceId(state, action);
        case 'DELETE_WORKSPACE':
            return deleteWorkspaceId(state, action);
        case 'GET_WORKSPACES':
                return getWorkspaceIds(state, action);
            default:
            return state;
    }
}

//Workspaces slice reducer
export const workspacesReducer = combineReducers({
    byId: workspacesById,
    allIds: allWorkspaces
});


