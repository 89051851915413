
import { combineReducers, Reducer } from 'redux'
import { envelopesReducer as EnvelopesReducer } from './envelope'
//import { exceedeanceReducer as ExceedanceReducer } from './exceedence'
//import { suppressionsReducer as SuppressionReducer } from './suprression'
import { tagDetailsReducer as TagDetailsReducer } from './tag-detail'
import { TagCurrentValueReducer } from './tag-current-value'
import { UserWorkspaceReducer, UserWorkspaceAction } from './user-workspace'
import { workspacesReducer as WorkspaceReducer, WorkspaceAction } from './workspace'
import { EntityState } from '../entity-state';
import { workspaceGroupsReducer as GroupReducer } from './workspace-group'
type KnownAction = UserWorkspaceAction | WorkspaceAction;

export const reducer: Reducer<EntityState,KnownAction> = combineReducers({ 
    //conditions: ConditionsReducer, 
    envelopes: EnvelopesReducer, 
    //envelopeStates: EnvelopeStateReducer, 
    //exceedances: ExceedanceReducer, 
    //suppressions: SuppressionReducer, 
    tagDetails: TagDetailsReducer,
    tagCurrentValues: TagCurrentValueReducer,
    userWorkspaces: UserWorkspaceReducer,
    workspaces: WorkspaceReducer,
    workspaceGroups: GroupReducer
   });