import { AppThunkAction } from '../index';
import * as ServiceClient from '../service-client';
import { AddGroupAction,  GroupAddedAction ,GroupAddErrorAction, ClearGroupAction } from '../actions/group';
import { AddGroupToWorkspaceAction, RemoveTagFromGroupAction } from '../slice-reducers/common-actions'
import { GetGroupsAction } from '../slice-reducers/workspace-group';


export type GroupAction = AddGroupAction | AddGroupToWorkspaceAction | GroupAddErrorAction | ClearGroupAction | GroupAddedAction | RemoveTagFromGroupAction | GetGroupsAction;

export const actionCreators = {
    updateGroup: (group: ServiceClient.WorkspaceGroup): AppThunkAction<GroupAction> => async (dispatch, getState) => {
        try{
            dispatch({type: 'ADD_GROUP'});
            const newGroup = await ServiceClient.getServiceClient().updateWorkspaceGroup(group);
            dispatch({type:'GROUP_ADDED', group: newGroup});
        } catch(e){
            dispatch({type: 'GROUP_ADD_ERROR', errorMessage:`Unable to update group ${group.name}`});
        }
    },
    addGroup: (group: ServiceClient.WorkspaceGroup): AppThunkAction<GroupAction> => async(dispatch, getState) => {
        try{
            dispatch({type: 'ADD_GROUP'});
            const serviceClient =  ServiceClient.getServiceClient();
            const newGroup = await serviceClient.addWorkspaceGroup(group.workspace, group);
            dispatch({type:'GROUP_ADDED',  group: newGroup});
        } catch(e){
            dispatch({ type: 'GROUP_ADD_ERROR', errorMessage: e.toString() });
        }
    },
    removeTagFromGroup: (group : ServiceClient.WorkspaceGroup, tag: string): AppThunkAction<GroupAction> => async(dispatch, getState) => {
        
        const serviceClient = ServiceClient.getServiceClient();
        const newGroup = await serviceClient.removeTagFromWorkspaceGroup(group, [tag]);
        dispatch({type: 'REMOVE_TAG_FROM_GROUP', payload: { workspace: group.workspace, group:group.id, tag: tag}});
        
        dispatch({type: 'GROUP_ADDED', group: newGroup});
        
    },
    clearGroupData: (): AppThunkAction<GroupAction> => async(dispatch, getState) => {
        dispatch({type: 'CLEAR_GROUP_DATA'});
    }
}