import { gql, ApolloClient, NormalizedCacheObject } from 'apollo-boost';
import {
    ServiceClient, TagExceeedance, TagDetail, TagValue,
    EnvelopeOptions, TagEnvelopeDefinition, Formula, SuppressionRule,
    WorkspaceRole, AuthType, UserWorkspace, STATE_NO_DATA, WorkspaceGroup
} from '.';
//import { Workspace } from '../workspace-common-types';
import { createHttpLink } from 'apollo-link-http';
import { setContext } from 'apollo-link-context';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { acquireTokenAsync } from '../../auth';
import { Envelope, ConditionType } from '../envelope-common-types';
import { convertSearchTextToOdataQuery } from '../../utils/FilterText';
import { EnvelopeProps, EditEnvelopeStateProps, EnvelopeStateConditions, EditEnvelopeExpressionProps } from '../edit-envelope';
import * as survAPI from './surveillanceapi'
import { ColourManager } from '../../components/common';

export class ApolloServiceClient implements ServiceClient {

    client: ApolloClient<NormalizedCacheObject>;
    constructor(client: any = undefined) {
        if (client !== undefined) {
            this.client = client;
        } else {
            this.client = this.getApolloClient();
        }
    }


    /* istanbul ignore next line */
    private getApolloClient() {
        const httpLink = createHttpLink({ uri: process.env.REACT_APP_GRAPHQL_URL });
        const authLink = setContext(async (op, { headers }) => {
            const token = await acquireTokenAsync(process.env.REACT_APP_SURVEILLANCE_RESOURCE!);
            return {
                headers: { ...headers, authorization: token }
            };
        });

        return new ApolloClient({
            link: authLink.concat(httpLink),
            cache: new InMemoryCache({
                addTypename: false
            })
        });
    }

    async getAllWorkspaces(): Promise<any> {
        const { data }: any = await this.client.query({
            query: gql`query GetAllWorkspaces {
                getAllWorkspaces {
                    workspaceName
                    currentPeriod
                    periodStartTime
                    periodEndTime
                }
            }`
        });
        return data.getAllWorkspaces;
    }

    async getPeriodForWorkspace(workspaceName: String): Promise<any> {
        const { data }: any = await this.client.query({
            query: gql`query GetPeriodForWorkspace($workspaceName:String) {
                getPeriodForWorkspace(workspaceName:$workspaceName) {
                    workspaceName
                    currentPeriod
                    duration
                    durationUnit
                }
            }`,
            variables: { workspaceName }
        });
        return data.getPeriodForWorkspace;
    }

    async putPeriodForWorkspace(workspaceName: String, currentPeriod: String, duration: number, durationUnit: string): Promise<any> {
        const { data } = await this.client.mutate({
            mutation: gql`mutation PutPeriodForWorkspace($workspaceName: String, $currentPeriod: String, $duration: Int, $durationUnit: String ) {
                putPeriodForWorkspace(workspaceName: $workspaceName, currentPeriod: $currentPeriod, duration: $duration, durationUnit: $durationUnit) 
            }`,
            variables: { workspaceName, currentPeriod, duration, durationUnit }
        });
        return data.putPeriodForWorkspace;
    }

    async getTagType(tagName: String): Promise<string> {
        const { data }: any = await this.client.query({
            query: gql`query GetTagType($tagName:String) {
                getTagType(tagName:$tagName) {
                    tagName
                    tagType
                }
            }`,
            variables: { tagName }
        });
        //Tag created before this change will have normal tag type
        return data.getTagType[0] === undefined?'Normal':data.getTagType[0].tagType;
    }

    async putTagType(tagName:string, tagType:string):Promise<any>{
        const { data } = await this.client.mutate({
            mutation: gql`mutation PutTagType($tagName: String, $tagType: String) {
                putTagType(tagName: $tagName, tagType: $tagType) 
            }`,
            variables: { tagName, tagType }
        });
        return data.putTagType;
    }

    async getUnitAndExecutionSchedule(attribute: string): Promise<any> {
        return await survAPI.getUnitAndExecutionSchedule(attribute)
    }

    async asyncForEach(array: any, callback: any) {
        for (let index = 0; index < array.length; index++) {
            await callback(array[index], index, array);
        }
    }

    async getWorkspaceEnvelopes(workspaceName: string, options?: EnvelopeOptions): Promise<TagEnvelopeDefinition[]> {
        //return await this.fetchEnvelopes(workspaceName, options) as TagEnvelopeDefinition[];
        return await survAPI.getWorkspaceEnvelopes(workspaceName, options || { requestStates: true, explodeEnvelopesByState: true })
    }

    async getWorkspaceEnvelopesExploded(workspaceName: string, options?: EnvelopeOptions): Promise<TagEnvelopeDefinition[]> {
        let envelopes = await survAPI.getEnvelopes(workspaceName, true);
        return envelopes;
    }

    async getExpressionForEnvelopes(workspaceName:string, tagName:string):Promise<EditEnvelopeExpressionProps>{
        let expression = await survAPI.getExpressionForEnvelope(workspaceName, tagName);
        return expression;
    }

    async putExpressionForEnvelopes(workspaceName:string, tagName:string, calculations?:EditEnvelopeExpressionProps){
        let expression = await survAPI.putExpressionForEnvelope(workspaceName, tagName, calculations);
        return expression;
    }

    async getWorkspaceEnvelope(workspaceName: string, envelopeId: string): Promise<TagEnvelopeDefinition> {
        let data = await survAPI.getWorkspaceEnvelope(workspaceName, envelopeId);
        return data;
    }

    async getExceedance(workspaceName: string, tags: string[], startTime: Date, endTime: Date, hideDataPoints: boolean): Promise<TagExceeedance[]> {
        const data: TagExceeedance[] = await survAPI.getExecedance(workspaceName, tags, startTime, endTime, hideDataPoints).catch((ex) => { return ex });
        return data;
    }

    async getTagDetailsForWorkspace(workspaceName: string): Promise<TagDetail[]> {
        return await survAPI.getModelDetailsForWorkspace(workspaceName) as Promise<TagDetail[]>;
    }

    async getTagsDetails(tags: string[]): Promise<TagDetail[]> {
        return await survAPI.getTagDetails(tags.join(','));
    }

    async getTagsHighWater(tags: string[]): Promise<TagValue[]> {
        return await survAPI.getTagHiWater(tags.join(','));
    }

    async getTagsLastValue(tags: string[], start_time: Date, end_time: Date): Promise<TagValue[]> {
        return await survAPI.getTagLastValue(tags.join(','), start_time, end_time);
    }

    async getHighWatersForWorkspace(workspaceName: string): Promise<TagValue[]> {
        return await survAPI.getHighWatersForWorkspace(workspaceName) as Promise<TagValue[]>;
    }

    async getWorkspaces(): Promise<any[]> {
        const data: any[] = await survAPI.getWorkspaces();
        return data;
    }

    async getWorkspaceSecurity(workspaceName: string): Promise<WorkspaceRole[]> {
        let roles = await survAPI.getWorkspaceSecurity(workspaceName);
        return roles;
    }

    async deleteEnvelope(workspaceName: string, envelopeId: string): Promise<boolean> {
        return survAPI.deleteEnvelope(workspaceName, envelopeId);
    }

    async createWorkspace(workspaceName: string): Promise<string> {
        return await survAPI.createWorkspace(workspaceName);
    }

    async searchForTags(searchText: string): Promise<TagDetail[]> {
        const queryFilter = transformTagSearchText(searchText);
        const data = await survAPI.searchTags(queryFilter);
        return data.value.map((d: any) => ({
            id: d.id, name: d.name,
            description: d.description,
            unitOfMeasure: d.unit_of_measure,
            plantName: d.plant_name
        }));
    }

    async createEnvelope(workspaceName: string, envelope: Envelope): Promise<string> {
        let data = await survAPI.createEnvelope(workspaceName, envelope);
        return data;
    }

    async deleteWorkspace(workspaceName: string): Promise<boolean> {
        const resp = await survAPI.deleteWorkspace(workspaceName);
        return resp;
    }

    async getFormulas(): Promise<Formula[]> {
        const data: any = await survAPI.getFormulas();
        return data.map((f: any) => ({ formula_type: f.name, description: f.description } as Formula));
    }

    async getActiveSuppressionRules(workspaceName: string): Promise<SuppressionRule[]> {
        const data = await survAPI.getSuppressionsForWorkspace(workspaceName);
        return data.map((r: any) => r as SuppressionRule).filter((s: SuppressionRule) => s.end_time == null || s.end_time > Date.now());
    }

    async getAllSuppressionRules(workspaceName: string): Promise<SuppressionRule[]> {
        const data = await survAPI.getSuppressionsForWorkspace(workspaceName);
        return data;
    }

    async getExpiredSuppressionRules(workspaceName: string): Promise<SuppressionRule[]> {
        const data = await survAPI.getSuppressionsForWorkspace(workspaceName);
        return data.map((r: any) => r as SuppressionRule).filter((s: SuppressionRule) => s.end_time != null && s.end_time < Date.now());
    }

    async putSuppressionRule(workspaceName: string, rule: SuppressionRule): Promise<SuppressionRule> {
        if (rule.identifier) {
            var data = await survAPI.updateSuppression(workspaceName, parseInt(rule.identifier), rule.end_time!);
            return data;
        }
        else {
            var data = await survAPI.createSuppression(workspaceName, rule);
            return data
        }
    }

    async getUserWorkspaces(username: string): Promise<UserWorkspace[]> {
        try {
            let roles = await survAPI.getUserSecurity(username);
            return roles;

        } catch (error) {
            return error;
        }
    }

    async addWorkspaceUser(workspaceName: string, principal: string, authmode: AuthType): Promise<void> {
        await survAPI.addUserToWorkspace(workspaceName, principal, authmode);
        return;
    }


    async deleteWorkspaceUser(workspaceName: string, principal: string, authmode: AuthType): Promise<void> {
        await survAPI.removeWorkspaceUser(workspaceName, principal, authmode);
        return;
    }

    getOwnerMemberOrThrow(authType: AuthType): string {
        switch (authType) {
            case AuthType.MEMBER:
                return 'member';
            case AuthType.OWNER:
                return 'owner';
            default:
                throw new Error(`Unexpected authtype ${authType}`);
        }
    }

    async renameWorkspace(workspaceName: string, newWorkspaceName: string): Promise<void> {
        await survAPI.renameWorkspace(workspaceName, newWorkspaceName);
    }

    async createEnvelopeNew(workspaceName: string, tag: string,states: EditEnvelopeStateProps[], calculations?:EditEnvelopeExpressionProps): Promise<string> {
        if(calculations !== undefined){
            tag = await survAPI.createExpressionModel(workspaceName,calculations);
            this.putTagType(tag, "Expression")
        }
        else{
        this.putTagType(tag, 'Normal')
        }
        return await survAPI.createEnvelopeNew(workspaceName, tag, states);
    }

    async putEnvelopeNew(workspaceName: string, tag: string, envelopeId: string, states: EditEnvelopeStateProps[]): Promise<TagEnvelopeDefinition> {
        let data = await survAPI.putEnvelope(workspaceName, tag, envelopeId, states);
        return data;
    }

    async updateEnvelope(workspace: string, env: TagEnvelopeDefinition): Promise<TagEnvelopeDefinition> {
        return await survAPI.updateEnvelope(workspace, env);
    }

    async addEnvelopes(workspaceName: string, tags: EnvelopeProps[]): Promise<string> {
        let envelopeData: string[] = [];

        for (const t of tags) {
            let data = await this.createEnvelopeNew(workspaceName, t.tag, t.states);
            envelopeData.push(data);
        }
        return envelopeData.join(',');
    }

    async addWorkspaceGroup(workspaceName: string, group: WorkspaceGroup) {
        return await survAPI.addWorkspaceGroup(group);
    };
    async removeWorkspaceGroup(workspaceName: string, groupId: number) {
        return await survAPI.removeWorkspaceGroup(workspaceName, groupId);
    };
    async getWorkspaceGroupExceedences(workspaceName: string, group: string, starrt_time: Date, end_time: Date) {
        return await survAPI.getWorkspaceGroupExceedence(workspaceName, group, starrt_time, end_time);
    };
    async getWorkspaceGroups(workspaceName: string) {
        return await survAPI.getWorkspaceGroups(workspaceName);
    };

    async updateWorkspaceGroup(group: WorkspaceGroup) {
        return await survAPI.updateWorkspaceGroup(group);
    };

    async removeTagFromWorkspaceGroup(group: WorkspaceGroup, tags: string[]) {
        return await survAPI.removeTagFromWorkspaceGroup(group, tags);
    }
}

export function transformTagSearchText(searchText: string): string {
    let filter = convertSearchTextToOdataQuery(searchText);
    return encodeURI(filter);
}

export function buildEnvelopeData(tag: string, states: EditEnvelopeStateProps[]): string {
    let envelopeStates = {};

    states.forEach((s, i) => {
        let conditions = [];
        switch (s.triggerCondition) {
            case EnvelopeStateConditions.Above: {
                conditions.push({ formula_type: 'max', low_threshold: s.value1 });
                break;
            }
            case EnvelopeStateConditions.Below: {
                conditions.push({ formula_type: 'min', high_threshold: s.value1 });
                break;
            }
            case EnvelopeStateConditions.Outside: {
                conditions.push({ formula_type: 'min', high_threshold: s.value1 });
                conditions.push({ formula_type: 'max', low_threshold: s.value2 });
                break;
            }
            case EnvelopeStateConditions.InBetween: {
                conditions.push({ formula_type: 'raw', low_threshold: s.value1, high_threshold: s.value2 });
                break;
            }
            case EnvelopeStateConditions.EqualTo: {
                conditions.push({ formula_type: 'equal', low_threshold: 0, high_threshold: 1, formula_config: { variant: s.value1 } });
                break;
            }
            case EnvelopeStateConditions.FlatLine: {
                conditions.push({ formula_type: 'stdev', high_threshold: 0 });
                break;
            }
            default: break;
        }

        let stateInput = {
            priority: i,
            conditions: conditions,
            colour: s.colour ? s.colour : ColourManager.StatusColours.blueGrey,
            description: s.description,
        };

        envelopeStates[s.name] = stateInput;

    });

    envelopeStates[STATE_NO_DATA] = { priority: 9999, conditions: [{ formula_type: 'count', high_threshold: 0 }] };

    let envelopeInput = {
        tag: tag,
        states: envelopeStates
    };

    return JSON.stringify(envelopeInput);
}

export function transformEnvelopeInput(envelope: Envelope): string {
    let envelopeStates = {};
    envelope.envelopeStates.forEach(s => {
        let conditions = [];
        if (s.condition.type === ConditionType.LOW || s.condition.type === ConditionType.LOWHIGH) {
            conditions.push({
                formula_type: 'min',
                high_threshold: s.condition.low_threshold
            });
        }
        if (s.condition.type === ConditionType.HIGH || s.condition.type === ConditionType.LOWHIGH) {
            conditions.push({
                formula_type: 'max',
                low_threshold: s.condition.high_threshold
            });
        }
        if (s.condition.type === ConditionType.CUSTOM) {
            conditions.push({ ...s.condition });
        }

        let stateInput = {
            priority: s.priority,
            conditions: conditions
        };

        envelopeStates[s.name] = stateInput;
    });

    let envelopeInput = {
        tag: envelope.tag,
        states: envelopeStates
    };

    return JSON.stringify(envelopeInput);
}

