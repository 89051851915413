import * as React from 'react';
import { CommandBar, ICommandBarItemProps, ContextualMenuItemType, Label, DefaultButton } from 'office-ui-fabric-react';
import { FilterOptions, FilterBox } from '../common/filter-box';
import { DateTimePicker } from '../common';
//import { DisplayExceedanceType } from '../../store/view-workspace'

export type ExceedancesTableFilterOptions = FilterOptions & {
    currentPeriod: PeriodOptions;
    periodStartTime: () => Date;
    periodEndTime: () => Date;
    //exceedanceDisplayType:DisplayExceedanceType;
    sortColumn?: string;
    sortDescending?: boolean;
};

type ExceedancesTableFilterProps = ExceedancesTableFilterOptions
    & {
        disabled?: boolean,
        onFilterChange?(props: ExceedancesTableFilterOptions): any,
        onCreateEnvelope?(): any,
        onManageSuppressionRules?(): any,
        onRefresh?(): any,
        onPeriodChange?(currentPeriod: PeriodOptions, startTime: Date, endTime: Date): any,
        onExport?(): any,
        exportDisabled?: boolean,
        workspaceUrlName: string,
        isOwnerOrMember: boolean,
    };

export enum PeriodOptions { LastHour, Today, Yesterday, Last7Days, Custom }

export class ExceedancesTableFilter extends React.Component<ExceedancesTableFilterProps, ExceedancesTableFilterOptions> {

    constructor(props: any) {
        super(props);
        this.state = {
            ...props,
            filterMode: 'Clear',
            //exceedanceDisplayType: 'EXCEEDANCE',
            sortColumn: undefined,
            sortDesending: false
        };
    }

    componentDidUpdate(prevProps: ExceedancesTableFilterProps, prevState: ExceedancesTableFilterOptions) {
        if (prevProps.currentPeriod !== this.props.currentPeriod) {
            this.setState({
                currentPeriod: this.props.currentPeriod
            });
            if (this.props.currentPeriod === PeriodOptions.Custom) {
                this.setState({
                    periodStartTime: this.props.periodStartTime,
                    periodEndTime: this.props.periodEndTime
                });
            } else {
                this.setState({
                    periodStartTime: () => new Date(),
                    periodEndTime: () => new Date()
                });
            }
        }
    }

    onFilterChange(e?: FilterOptions) {
        if (e) {
            this.setState({ ...this.state, filter: e.filter }, () => {
                if (this.props.onFilterChange) {
                    this.props.onFilterChange(this.state);
                }
            });
        } else {
            if (this.props.onFilterChange) {
                this.props.onFilterChange(this.state);
            }
        }
    }

    onCurrentPeriodChange(option: PeriodOptions) {
        this.setState({ currentPeriod: option });
        if (this.props.onPeriodChange !== undefined) {
            this.props.onPeriodChange(option, this.state.periodStartTime(), this.state.periodEndTime());
        }
    }

    onCustomPeriodChange() {
        if (this.props.onPeriodChange !== undefined) {
            this.setState({ currentPeriod: PeriodOptions.Custom });
            this.props.onPeriodChange(PeriodOptions.Custom, this.state.periodStartTime(), this.state.periodEndTime());
        }
    }

    onExport() {
        if (this.props.onExport) {
            this.props.onExport();
        }
    }

    createEnvelope() {
        if (this.props.onCreateEnvelope) {
            this.props.onCreateEnvelope();
        }
    }

    manageSuppressionRules() {
        if (this.props.onManageSuppressionRules) {
            this.props.onManageSuppressionRules();
        }
    }

    refresh() {
        if (this.props.onRefresh) {
            this.props.onRefresh();
        }
    }

    render() {
        const customDateRangeStart = (
            <div style={{ width: '335px' }}>
                <Label>
                    Start Date:
                </Label>
                <DateTimePicker
                    onSelectDate={(date: Date) => this.setState({ periodStartTime: () => { return date }})}
                    //onSelectTime={(hour: number) => this.setState({ periodStartTime: setHours(this.state.periodStartTime!, hour) })}
                    defaultDate={this.state.periodStartTime()}
                    defaultTime={this.state.periodStartTime()!.getHours()}
                />
            </div>
        );

        const customDateRangeEnd = (
            <div>
                <Label>
                    End Date:
                </Label>
                <DateTimePicker
                    onSelectDate={(date: Date) => this.setState({ periodEndTime: () => { return date }})}
                    //onSelectTime={(hour: number) => this.setState({ periodEndTime: setHours(this.state.periodEndTime!, hour) })}
                    defaultDate={this.state.periodEndTime()}
                    defaultTime={this.state.periodEndTime()!.getHours()}
                />
            </div>
        );

        const periodOptions = [
            { key: PeriodOptions.LastHour, text: 'last hour' },
            { key: PeriodOptions.Today, text: 'today' },
            { key: PeriodOptions.Yesterday, text: 'yesterday' },
            { key: PeriodOptions.Last7Days, text: 'last 7 days' },
            { key: PeriodOptions.Custom, text: 'custom date range' },
        ];

        let periodOptionsSubmenu = periodOptions.filter(p => p.key !== PeriodOptions.Custom).map(p => ({
            key: p.key.toString(),
            name: p.text,
            canCheck: true,
            checked: this.state.currentPeriod === p.key,
            onClick: () => { this.onCurrentPeriodChange(p.key); }
        } as ICommandBarItemProps));

        periodOptionsSubmenu.push({
            key: PeriodOptions.Custom.toString(),
            name: 'custom date range',
            canCheck: true,
            checked: this.state.currentPeriod === PeriodOptions.Custom,
            subMenuProps: {
                items: [{
                    key: 'a',
                    onRender: (_, dismiss) => {
                        return (
                            <React.Fragment>
                                <div style={{ padding: 10 }}>
                                    <div>
                                        {customDateRangeStart}
                                        {customDateRangeEnd}
                                    </div>
                                    <div style={{ marginTop: '10px' }}>
                                        <DefaultButton
                                            iconProps={{ iconName: 'Filter' }}
                                            text="Filter"
                                            onClick={() => {
                                                this.onCustomPeriodChange();
                                                dismiss(null, true);
                                            }}
                                        />
                                    </div>
                                </div>
                            </React.Fragment>);
                    }
                }]
            }
        });

        const barItemsLeft: ICommandBarItemProps[] = [
            {
                key: 'refresh',
                name: 'Refresh',
                iconProps: { iconName: 'Refresh' },
                disabled: this.props.disabled,
                onClick: () => { this.refresh(); },
            },
            {
                key: 'refreshDivider',
                itemType: ContextualMenuItemType.Divider,
                disabled: true,
            },
            {
                key: 'add',
                name: 'New Envelope',
                iconProps: { iconName: 'EditMail' },
                disabled: this.props.disabled || !this.props.isOwnerOrMember,
                onClick: () => { this.createEnvelope(); }
            },
            {
                key: 'manageSuppression',
                name: 'Manage Suppression',
                iconProps: { iconName: 'Hide' },
                disabled: this.props.disabled || !this.props.isOwnerOrMember,
                onClick: () => { this.manageSuppressionRules(); }
            },
            {
                key: 'share',
                name: 'Export',
                iconProps: { iconName: 'Share' },
                disabled: this.props.disabled,
                subMenuProps: {
                    items: [
                        {
                            key: 'exportData',
                            name: 'Export as CSV',
                            iconProps: { iconName: 'CloudDownload' },
                            disabled: this.props.exportDisabled,
                            onClick: () => { this.onExport(); }
                        },
                        {
                            key: 'email',
                            iconProps: { iconName: 'MailForward' },
                            text: 'Email a link',
                            onClick: (ev: any) => {
                                const url = this.props.workspaceUrlName;
                                window.location.href = `mailto:?subject=Surveillance%20Hub%20Workspace%20` +
                                    `${this.props.workspaceUrlName}&body=` +
                                    `${window.location.origin}/view-workspace/${encodeURIComponent(encodeURIComponent(url.trim()))}`;
                            }
                        }
                    ]
                }
            }
        ];

        const barItemsRight: ICommandBarItemProps[] = [
            {
                key: 'currentPeriod',
                name: `Period: ${periodOptions[this.state.currentPeriod].text}`,
                iconProps: { iconName: 'Clock' },
                disabled: this.props.disabled,
                subMenuProps: {
                    items: periodOptionsSubmenu
                }
            },
            {
                key: 'search',
                disabled: this.props.disabled,
                onRender: () => (
                    <FilterBox
                        filter={this.state.filter}
                        hideLabel={true}
                        hideGarbageIcon={true}
                        isInCommandBar={true}
                        initialFocus={false}
                        onFilterChange={(p) => {
                            this.setState({ filter: p.filter || '' }, () => { this.props.onFilterChange!(this.state); });
                        }}
                    />
                )
            }
        ];

        return (
            <div>
                <CommandBar
                    styles={{ root: {} }}
                    items={barItemsLeft}
                    farItems={barItemsRight}
                />
            </div>
        );
    }
}
