import { combineReducers, Reducer } from 'redux'
import { UserWorkspace } from '../service-client';
import { KnownAction } from '../search-tags';
import { MapUserWorkspaces } from '../loading-data/user-workspaces' 

export interface UserWorkspacesState {
    byId: Record<string, UserWorkspace>;
    allIds: string[];
}

type GetUserWorkspacesAction = { type: 'GET_USER_WORKSPACES', payload: { workspaces: UserWorkspace[] }};

export type UserWorkspaceAction = GetUserWorkspacesAction ;

function getUserWorkspaces(state: Record<string, UserWorkspace>, action: GetUserWorkspacesAction){
    const { payload} = action;
    const { workspaces } = payload;
    
    const newState = MapUserWorkspaces(workspaces);
    
    return {
        ...newState
    };
}


//WorkspaceGroup By Id Slice
function userWorkspacesById(state= <Record<string,UserWorkspace>>{}, action: UserWorkspaceAction) {
    switch(action.type){
        case 'GET_USER_WORKSPACES':
            return getUserWorkspaces(state,action);
        default:
            return state;
    }
}


function addUserWorkspaceIds(state: string[], action: GetUserWorkspacesAction){
    const { payload } = action;
    const { workspaces } = payload;
    return workspaces.map(w=> w.workspace);
}

function allUserWokspaces(state: string[] = [], action:UserWorkspaceAction){
    switch(action.type){
        case 'GET_USER_WORKSPACES':
            return addUserWorkspaceIds(state, action);
        default:
            return state;
    }
}

//Workspaces slice reducer
export const UserWorkspaceReducer: Reducer<UserWorkspacesState, KnownAction> = combineReducers({
    byId: userWorkspacesById,
    allIds: allUserWokspaces
});