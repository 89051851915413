import { AuthenticatedUser } from '../../auth/index'
import * as ServiceClient from '../service-client';


export async function LoadUserWorkspaces(dispatch: any, getState: any, user: AuthenticatedUser): Promise<Record<string, ServiceClient.UserWorkspace>> {

    const { entities } = getState();
    const { userWorkspaces } = entities;
    let { byId } = userWorkspaces;
    const userSpaces = byId && Object.values(byId).length > 0 ? byId : await _getUserWorkspaces(user, dispatch);
    return userSpaces;
}

async function _getUserWorkspaces(user: AuthenticatedUser, dispatch: any): Promise<Record<string, ServiceClient.UserWorkspace>> {
    const serviceClient = ServiceClient.getServiceClient();
    const workspaces = await serviceClient.getUserWorkspaces(user.email) || [];
    dispatch({ type: 'GET_USER_WORKSPACES', payload: { workspaces: workspaces } });
    const userSpaces = MapUserWorkspaces(workspaces);
    return userSpaces;
}

export async function ForceLoadUserWorkspaces(dispatch: any, getState: any, user: AuthenticatedUser): Promise<Record<string, ServiceClient.UserWorkspace>> {
    const userSpaces = await _getUserWorkspaces(user, dispatch);
    return userSpaces;
}

export function MapUserWorkspaces(workspaces: ServiceClient.UserWorkspace[]) {
    if (!workspaces) { return <Record<string, ServiceClient.UserWorkspace>>{}; }
    return workspaces.reduce((map, g) => {
        map[g.workspace] = g;
        return map;
    }, <Record<string, ServiceClient.UserWorkspace>>{});
}
