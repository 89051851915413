import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { connect } from 'react-redux';
import { ApplicationState } from '../../store';
import * as EditEnvelopeStore from '../../store/edit-envelope';
import { Breadcrumbs, MessageBox, MessageBoxType, MessageBoxSize } from '../common';
import { TextField,DefaultButton, CommandBar, ProgressIndicator, ContextualMenuItemType, ICommandBarItemProps, PrimaryButton } from 'office-ui-fabric-react';
import { State } from './state';
import { Link } from 'react-router-dom';
import TagPicker, { TagPickerStyle } from '../tag-picker/tag-picker';
import { TagHandler, ModelApiTagDefinitionTagHandler } from '../tag-picker/tag-handlers';
import * as ServiceClient from '../../store/service-client';
import { Confirmation, ConfirmationType } from '../common/confirmation';
import { FontSizes } from '@uifabric/fluent-theme/lib/fluent/FluentType';
import { Expression } from './Expression';
import {Equation} from './Basic';
import {HelpPanel} from './HelpPanel';
import { getMentions, toEditorState } from "rc-editor-mention";

type EditEnvelopeProps =
    typeof EditEnvelopeStore.actionCreators
    & EditEnvelopeStore.EditEnvelopeState
    & RouteComponentProps<{ workspaceName: string, envelopeId?: string }>;

type EditEnvelopeState = {
    isMultiTag: boolean;
    tags: string[];
    states: EditEnvelopeStore.EditEnvelopeStateProps[];
    tagHandler?: TagHandler<ServiceClient.TagEnvelopeDefinition>;
    isDeleteConfirmationVisible?: boolean;
    calculations?:EditEnvelopeStore.EditEnvelopeExpressionProps
    viewExpressionComponent:boolean
    basicExpression:Equation[]
    complexExpression:string
    viewHelpPanel:boolean
};

export class EditEnvelope extends React.Component<EditEnvelopeProps, EditEnvelopeState> {

    constructor(props: EditEnvelopeProps) {
        super(props);
        this.state = {
            isMultiTag: false,
            tags: [props.tag],
            states: [],
            tagHandler: new ModelApiTagDefinitionTagHandler(),
            calculations:undefined,
            viewExpressionComponent:false,
            basicExpression:[],
            complexExpression:'',
            viewHelpPanel:false
        };
    }

    componentDidMount() {
        if (this.isEditEnvelope()) {
            this.props.editEnvelope(this.props.match.params.workspaceName, this.props.match.params.envelopeId!);
        } else {
            this.props.newEnvelope(this.props.match.params.workspaceName);
        }
    }

    componentDidUpdate(prevProps: EditEnvelopeProps, prevState: EditEnvelopeState) {
        if (!prevProps.isDeleteSuccess && this.props.isDeleteSuccess) {
            return this.props.history.push(`/view-workspace/${this.props.match.params.workspaceName}`);
        }
        if (this.isEditEnvelope() && this.props.stateProps && this.props.stateProps !== prevProps.stateProps) {
            this.setState({
                tags: [this.props.tag],
                states: this.props.stateProps!,
            });
        }
        if(this.props.calculations !== prevProps.calculations && this.props.calculations!== undefined){
            this.setState({
                calculations:this.props.calculations,
                viewExpressionComponent:true
            })
        }
    }

    isEditEnvelope = () => this.props.match.params.envelopeId !== undefined;

    addNewState = () => this.setState({
        states: [...this.state.states, { name: '', colour: '', triggerCondition: 0, value1: 0, _id: new Date().getTime() + (Math.random() * 10) }]
    });

    saveEnvelope() {
        if(this.state.calculations !== undefined){
            this.saveInputTagDetails();
            this.saveExpressionDetails();
        }
        this.props.saveEnvelope(this.props.workspaceName, this.state.tags,this.state.states, this.props.match.params.envelopeId, this.state.calculations);
    }

    saveInputTagDetails(){
        let inputTags = this.props.calculations === undefined? []:this.props.calculations!.inputTags
        if (this.state.basicExpression !== undefined && this.state.basicExpression.length > 0){
            let basic = this.state.basicExpression
            for (let index = 0; index < basic.length; index++) {
                if(basic[index].selectedTag !== undefined)
                inputTags = inputTags!.concat(basic[index].selectedTag!)
            }
        }
        else{
            const contentState = toEditorState(this.state.complexExpression);
            let mentions = getMentions(contentState);
            mentions.forEach((item: string, index: string | number, array: { [x: string]: any; }) => {
                array[index] = item.replace('@','')
            });
            inputTags = mentions;
        }
        
        const newCalculations = this.state.calculations;
        newCalculations!.inputTags = inputTags;

        this.setState({calculations: newCalculations})
    }

    saveExpressionDetails(){
        let finalExpression:string
        if (this.state.basicExpression !== undefined && this.state.basicExpression.length > 0) {
            let basic = this.state.basicExpression

            //For first row
            finalExpression = basic[0].brackets['('] ? '(' : '';
            finalExpression += basic[0].selectedTag === undefined ? basic[0].constant : ` ${basic[0].selectedTag} `;
            finalExpression += basic[0].brackets[')'] ? ')' : ''

            //For subsequent rows
            for (var i: number = 1; i < basic.length; i++) {
                var openingBracket = basic[i].brackets['('] ? '(' : ''
                var operator = Object.keys(basic[i].operation).find(key => basic[i].operation[key] === true)
                var tagOrValue = basic[i].selectedTag === undefined ? basic[i].constant : ` ${basic[i].selectedTag} `
                var closingBracket = basic[i].brackets[')'] ? ')' : ''

                finalExpression += basic[i - 1].brackets[')'] ? openingBracket + operator : operator+openingBracket
                finalExpression += tagOrValue + closingBracket
            }
        }else{
            finalExpression = this.state.complexExpression.replace(/@/g,'');
        }

        const newCalculations = this.state.calculations;
        newCalculations!.expression = finalExpression.trim();

        this.setState({calculations: newCalculations})
    }

    deleteEnvelope() {
        this.props.deleteEnvelope(this.props.workspaceName, this.props.match.params.envelopeId);
    }

    changeState(changedState: any, index: number) {
        let newStates = this.state.states;
        newStates[index] = changedState;
        this.setState({ states: newStates });
    }

    deleteState(index: number) {
        let newStates = this.state.states;
        newStates.splice(index, 1);
        this.setState({ states: newStates });
    }

    deleteExpression(){
        this.setState({viewExpressionComponent:false, calculations:undefined})
    }

    changeExpression(changedState: any) {
        let newCalculations = this.state.calculations === undefined?{}:this.state.calculations;
        newCalculations!.description = changedState["description"];
        newCalculations!.schedule = changedState["schedule"];
        newCalculations!.validFrom = changedState["validFrom"];
        newCalculations!.unit_of_measure = changedState["unit_of_measure"];

        this.setState({calculations: newCalculations, 
            basicExpression:changedState["basicExpressions"],
            complexExpression:changedState["complexExpressions"]})
    }

    subHeaderStyle() {
        return {fontSize: FontSizes.size12, fontWeight: 600, borderBottom: '1px solid #e1dfdd', marginTop: '5px', marginBottom: '5px'}
    }

    header = () => this.isEditEnvelope() ? `Edit ${this.props.tag}` : 'Create Envelope';

    renderBreadcrumbs = () => (
        <div>
            <Breadcrumbs
                links={[
                    {
                        to: `/view-workspace/${this.props.match.params.workspaceName}`,
                        text: `${this.props.match.params.workspaceName}`
                    },
                    {
                        to: this.props.match.url,
                        text: this.header()
                    }
                ]}
            />
        </div>
    );

    renderToolbar = () => {
        let items: ICommandBarItemProps[] = [
            {
                key: 'save',
                text: 'Save Changes',
                iconProps: { iconName: 'Save' },
                onClick: (_) => this.saveEnvelope()
            },
            {
                key: 'divider',
                itemType: ContextualMenuItemType.Divider,
                disabled: true,
            },
            {
                key: 'addState',
                text: 'Add State',
                iconProps: { iconName: 'Add' },
                onClick: (_) => this.addNewState()
            },
            {
                key: 'divide',
                itemType: ContextualMenuItemType.Divider,
                disabled: true,
            },
            {
                key: 'expression',
                text: 'Expression',
                iconProps: { iconName: 'Add' },
                onClick:(_) => { this.setState({ viewExpressionComponent: true }); }
            },
            {
                key: 'Help',
                text: 'Help',
                iconProps: { iconName: 'help' },
                onClick:(_) => { this.setState({ viewHelpPanel: true }); }
            }
        ];
        if (this.isEditEnvelope()) {
            items.pop();
            items.push({
                key: 'deleteEnvelope',
                text: 'Delete Envelope',
                iconProps: { iconName: 'Delete' },
                onClick: (_) => { this.setState({ isDeleteConfirmationVisible: true }); }
            });
        }
        return (
            <div style={{ paddingBottom: '1em' }}>
                <CommandBar
                    items={items}
                />
            </div>
        );
    };

    selectInputName(name:string){
        const calculations = this.state.calculations === undefined?{}:this.state.calculations;
        calculations!.name = name
        this.setState({calculations:calculations})
    }

    renderTagPickerOrDisplay() {
        if (this.isEditEnvelope()) {
            return (
                <div className='ms-Grid'>
                    <div className='ms-Grid-row'>
                        <div className='ms-Grid-col ms-sm-12' style={{fontSize: FontSizes.size42}}>{this.props.tag}</div>
                    </div>
                    <div className='ms-Grid-row'>
                        <div className='ms-Grid-col ms-sm-6'>
                            <div style={this.subHeaderStyle()}>Description</div>
                            <div>{ this.props.tagDetail ? this.props.tagDetail!.description : '--'}</div>
                        </div>
                        <div className='ms-Grid-col ms-sm-3'>
                            <div style={this.subHeaderStyle()}>Unit</div>
                            <div>{ this.props.tagDetail ? this.props.tagDetail!.unitOfMeasure : '--'}</div>
                        </div>
                        <div className='ms-Grid-col ms-sm-3'>
                            <div style={this.subHeaderStyle()}>Last Recorded Value</div>
                            <div>{ this.props.currentValue ? this.props.currentValue.value : '--'}</div>
                        </div>
                    </div>
                    {/* <LabelledTextField
                        defaultValue={this.props.tag}
                        label={'Tag Name'}
                        disabled={true}
                    /> */}
                </div>
            );
        } else {
            if(this.state.viewExpressionComponent){
                return(
                    <TextField placeholder = "Actual output name will be the name here prefixed with the modelId and modelVersion "
                    label = {"Name"}
                    onChange={(_, v) => this.selectInputName(String(v))}
                    styles={{
                        fieldGroup: { marginTop: '8px' },
                        subComponentStyles: {
                            label: {
                                root: {
                                    width: '170px',
                                    fontWeight: 'bold',
                                    fontSize: '16px',
                                    lineHeight: '24px'
                                }
                            }
                        }
                    }}>
                    </TextField>
                )
            } else {
                return (
                    <div>
                        <TagPicker
                            handler={this.state.tagHandler!}
                            pickerStyle={TagPickerStyle.SINGLE_TAG}
                            onSelectionUpdated={(s: ServiceClient.TagEnvelopeDefinition[]) => this.setState({ tags: s.map(t => t.tag) })}
                            lockToSingleTag={false}
                        />
                        </div>
                );
            }
        }
    }

    renderExpressionRow(){
        return (
            <Expression
                calculations={this.state.calculations!}
                onDelete={() => this.deleteExpression()}
                onChange={(r: any) => this.changeExpression(r)}
                viewExpressionComponent = {this.state.viewExpressionComponent}
                tagHandler = {this.state.tagHandler}
            />
        );
    }

    helpPanelDismissed = () => {
        this.setState(
            { viewHelpPanel: false });
    }

    renderStateRow() {
        return this.state.states.map((s, i) => (
            <State
                key={s._id}
                state={s}
                onChange={(r: any) => this.changeState(r, i)}
                onDelete={() => this.deleteState(i)}
            />));
    }

    renderAddState() {
        return (
            <p>
                <PrimaryButton iconProps={{iconName:'Save'}} onClick={() => this.saveEnvelope()} style={{marginRight:'10px'}} >Save Changes</PrimaryButton>
                <DefaultButton iconProps={{ iconName: 'Add' }} onClick={() => this.addNewState()}>
                    Add new state
                </DefaultButton>
            </p>
        );
    }

    renderSuccess() {
        let workspaceName = this.props.match.params.workspaceName;
        const liStyle = { lineHeight: '2em' };
        return (
            <div>
                <Breadcrumbs
                    links={[
                        {
                            to: `/view-workspace/${this.props.match.params.workspaceName}`,
                            text: `Workspace: ${this.props.match.params.workspaceName}`
                        },
                        {
                            to: this.props.match.url,
                            text: `Create Envelope`
                        }
                    ]}
                />

                <div style={{ width: '100%', marginTop: '1em' }}>
                    <MessageBox type={MessageBoxType.Success} size={MessageBoxSize.Normal}>
                        <h3 style={{ margin: 0 }}>Surveillance envelope has been created.</h3>
                        <ul style={{ listStyle: 'none', paddingLeft: 0 }}>
                            <li style={liStyle}><Link to={`/view-workspace/${workspaceName}`}>View Workspace {workspaceName}</Link></li>
                        </ul>
                    </MessageBox>
                </div>
            </div>
        );
    }

    renderConfirmDeleteEnvelope = () => (
        <Confirmation
            type={ConfirmationType.DELETE}
            show={this.state.isDeleteConfirmationVisible}
            title="Delete Envelope?"
            onConfirm={() => this.deleteEnvelope()}
            onCancel={() => this.setState({ isDeleteConfirmationVisible: false })}
        >
            <p>Are you sure you want to delete this envelope?</p>
            {this.props.isDeleting ? <ProgressIndicator /> : null}
            {this.props.deleteErrorMessage
                ? <MessageBox size={MessageBoxSize.Baby} type={MessageBoxType.Error}>{this.props.deleteErrorMessage}</MessageBox>
                : null}
        </Confirmation>
    );

    render() {
        if (this.props.isLoading) {
            return (
                <div>
                    {this.renderBreadcrumbs()}
                    {/* <h1>{this.header()}</h1> */}
                    <div>Loading ...</div>
                    <ProgressIndicator />
                </div>
            );
        }
        if (this.props.isSuccess) {
            return this.renderSuccess();
        }

        let errorRender = null;
        if (this.props.errorMessage !== undefined) {
            errorRender = (
                <div>
                    <MessageBox type={MessageBoxType.Error}>
                        <h3>{this.props.errorMessage}</h3>
                    </MessageBox>
                </div>
            );
        }

        return (
            <div>
                {this.renderBreadcrumbs()}
                <div style={{ height:"20px" }}></div>
                {this.renderToolbar()}
                {this.props.isSaving ? <ProgressIndicator /> : null}
                {errorRender}
                {this.renderTagPickerOrDisplay()}
                {this.renderExpressionRow()}
                <div style={{fontSize: FontSizes.size28, marginTop: '28px'}}>States</div>
                {this.renderStateRow()}
                {this.renderAddState()}
                {this.renderConfirmDeleteEnvelope()}
                {this.state.viewHelpPanel?<HelpPanel 
                viewHelpPanel={this.state.viewHelpPanel}
                onDismissed={this.helpPanelDismissed}
                />:null}
            </div>
        );
    }

}

const createStateToProps = (state: ApplicationState) => state.editEnvelope;

// Wire up the React component to the Redux store
export default (connect(createStateToProps, EditEnvelopeStore.actionCreators)(EditEnvelope));