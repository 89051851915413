import * as ViewWorkspace from './view-workspace';
import * as CreateWorkspace from './create-workspace';
import * as SecureWorkspace from './secure-workspace';
import * as ListWorkspaces from './list-workspaces';
import * as EditWorkspace from './edit-workspace';
import * as SearchTags from './search-tags';
import * as AddSuppression from './add-suppression';
import * as ManageSuppression from './manage-suppression';
import * as EditEnvelope from './edit-envelope';
import * as BulkUpload from './bulk-upload';
import * as AddGroup from './add-group';


import { EntityState } from './entity-state';
import * as EntityReducer  from './slice-reducers'
import { viewWorkspaceReducer } from './slice-reducers/view-workspace';

export interface ApplicationState {
    viewWorkspace: ViewWorkspace.ViewWorkspaceState;
    createWorkspace: CreateWorkspace.CreateWorkspaceState;
    listWorkspaces: ListWorkspaces.ListWorkspacesState;
    editWorkspace: EditWorkspace.EditWorkspaceState;
    searchTags: SearchTags.SearchTagsState;
    addSuppression: AddSuppression.AddSuppressionState;
    manageSuppression: ManageSuppression.ManageSuppressionState;
    secureWorkspace: SecureWorkspace.SecureWorkspaceState;
    editEnvelope: EditEnvelope.EditEnvelopeState;
    bulkUpload: BulkUpload.BulkUploadState;
    addGroup: AddGroup.AddGroupState;
    entities: EntityState;
}

export const reducers = {
    viewWorkspace: viewWorkspaceReducer,
    createWorkspace: CreateWorkspace.reducer,
    listWorkspaces: ListWorkspaces.reducer,
    editWorkspace: EditWorkspace.reducer,
    searchTags: SearchTags.reducer,
    addSuppression: AddSuppression.reducer,
    manageSuppression: ManageSuppression.reducer,
    secureWorkspace: SecureWorkspace.reducer,
    editEnvelope: EditEnvelope.reducer,
    bulkUpload: BulkUpload.reducer,
    addGroup: AddGroup.reducer,
    //workspaces: Workspace.workspacesReducer
    entities: EntityReducer.reducer
};

export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}

export const dispatchLogger = (_: any) => (next: (arg0: any) => void) => (action: { type: any; }) => {
    //PinpointAnalytics.record('dispatch', { actionType: action.type, payload: JSON.stringify(action) });
    let result = next(action);
    return result;
};