import { createSelector } from 'reselect';
import { WorkspaceGroup } from '../service-client';
import * as EntityTypes from '../slice-reducers/common-types';
import { ApplicationState } from '..';
import { workspaceSelector } from './workspace-selector';

const selectGroups = (state:ApplicationState ) => state.entities.workspaceGroups.byId;

const selectedGroupIds = createSelector([workspaceSelector], (workspace) => {
    return workspace && workspace.groups ? workspace.groups : [];
})

export const workspaceGroupSelector = createSelector([selectGroups, selectedGroupIds],(groups, groupIds)=> {
    const wGroups = groupIds.map(g=> { 
        const group:EntityTypes.WorkspaceGroup = groups[g];
        if(group)
        {
            return <WorkspaceGroup>{ ...group, workspace: group.workspace, expanded: group.expanded};
        }
        return undefined
    });
    return wGroups.filter(g=> g !== undefined).sort((a,b)=> a!.name > b!.name ? 1 : -1);
});