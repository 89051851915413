
import * as ServiceClient from '../service-client'
const CONCURRENCY = 50;

export async function LoadTagsDetails(dispatch:any, getState:any, tags:string[]){
    
    const { entities } = getState();
    const { tagDetails } = entities;
    const { byId, allIds } = tagDetails;

    const tagdetails: Record<string,ServiceClient.TagDetail> = allIds && tags.every(t=> allIds.some((a:string)=> a == t)) ? byId : await _getTagDetails(dispatch, byId, allIds, tags);
    return tagdetails;

}

export async function ForceLoadTagDetails(dispatch:any, getState: any, tags:string[]){
    const { entities } = getState();
    const { tagDetails } = entities;
    const { byId, allIds } = tagDetails;
    return await _getTagDetails(dispatch, byId, allIds, tags);
}

async function _getTagDetails(dispatch: any, tagMap: Record<string,ServiceClient.TagDetail>, allIds: string[],tags: string[]): Promise<Record<string,ServiceClient.TagDetail>>{
    const serviceClient = ServiceClient.getServiceClient();
    const tagsToGet = tags.filter(t=> allIds.some(a=> a==t) == false);

    if(tagsToGet.length > 0){
        for(let x: number = 0; x < tagsToGet.length; x+=CONCURRENCY)
        {
            const endRange = (x+CONCURRENCY) > tagsToGet.length ? tagsToGet.length : x+CONCURRENCY;
            const tags = tagsToGet.slice(x,endRange);
            const tagDetails = await serviceClient.getTagsDetails(tags);
            dispatch({type: 'GET_TAG_DETAILS', payload: { tags: tagDetails}});
            tagDetails.map(t=> tagMap[t.name] = t);
        }
    }
    return tagMap;
}