// eslint-disable-next-line
import React from 'react';

const redirectMessages = [
  'AADSTS16002', // old sid - https://github.com/salvoravida/react-adal/issues/46
  'AADSTS50076', // MFA support - https://github.com/salvoravida/react-adal/pull/45
  'AADSTS50079', // MFA support
];

function shouldAcquireNewToken(message) {
  return redirectMessages.reduce((a, v) => a || message.includes(v), false);
}

export function msalGetToken(instance, resourceGuiId, callback) {
  return new Promise((resolve, reject) => {
    var request = {
      scopes: [`${resourceGuiId}/.default`]
    };
    instance.acquireTokenSilent(request).then((response) => {
      resolve(response['accessToken']);
    })
    .catch(function(e) {
      console.error(e);
      reject({ e });
    });
  });
}

export function runWithMsal(instance, app, doNotLogin) {
  instance.handleRedirectPromise().then(function() {
    if (doNotLogin) {
      app();
    } else {
      const currentAccounts = instance.getAllAccounts();
      if (currentAccounts.length === 0) {
        const loginRequest = {
          scopes: ["openid", "profile", "user.read"]
        };
        instance.loginRedirect(loginRequest);
      } else {
        instance.setActiveAccount(currentAccounts[0]);
        app();
      }
    }
  }).catch(function(e) { console.error(e); });
}