import { Reducer } from 'redux';
import { AppThunkAction } from './index';
import * as ServiceClient from './service-client/index';
import { Formula } from './service-client/index';
import { ConditionsToDisplayRange } from '../utils/condition-converters'
import { AuthenticatedUser } from '../auth';
import { AddGroupAction, GroupAddedAction, GroupAddErrorAction } from './actions/group'
import { actionCreators as groupActionCreators } from './action-creators/group-action-creator'
import { LoadUserWorkspaces } from './loading-data/user-workspaces';
import { LoadTagsDetails } from './loading-data/tag-detail';
import { LoadTagCurrentValues } from './loading-data/tag-current-value';
import { LoadWorkspaceEnvelopes } from './loading-data/envelopes'


// STATE - This defines the type of data maintained in the Redux store.
export interface EditWorkspaceState {
    dataReady?: boolean;
    currentEnvelopes?: ServiceClient.TagEnvelopeDefinition[];
    detailsLoaded?: boolean;
    envelope?: ServiceClient.TagEnvelopeDefinition;
    errorMessage?: string;
    formulaErrorMessage?: string;
    isEnvelopeMetadataLoaded?: boolean;
    reloadEnvelope?: boolean;
    emptyWorkspace?: boolean;
    deleteEnvelopeSucceeded?: boolean;
    formulas?: Formula[];
    redirectTo?: string;
    canEdit: boolean;
    groups: ServiceClient.WorkspaceGroup[],
    user: AuthenticatedUser
}

export interface LoadAllEnvelopes { type: 'LOAD_ALL_ENVELOPES'; }
export interface LoadAllEnvelopesError { type: 'LOAD_ALL_ENVELOPES_ERROR'; errorMessage: string; }
export interface LoadingEnvelopes { type: 'LOADING_ENVELOPES'; dataReady: boolean; emptyWorkspace: boolean; currentUser: AuthenticatedUser }
export interface LoadedEnvelopes { type: 'LOADED_ENVELOPES'; dataReady: boolean; envelopes: any[]; }
export interface NoEnvelopesDefined { type: 'NO_ENVELOPES_DEFINED'; emptyWorkspace: boolean; }
export interface ClearEnvelopeData { type: 'CLEAR_ENVELOPE_DATA'; }
export interface DeleteWorkspace { type: 'DELETE_WORKSPACE'; }
export interface DeleteWorkspaceError { type: 'DELETE_WORKSPACE_ERROR'; errorMessage: string; }
export interface DeleteWorkspaceSuccess { type: 'DELETE_WORKSPACE_SUCCESS'; redirectTo: string; }
export interface LoadTagDetails { type: 'LOAD_TAG_DETAILS'; }
export interface LoadTagDetailsError { type: 'LOAD_TAG_DETAILS_ERROR'; errorMessage: string; }
export interface LoadedTagDetails { type: 'LOADED_TAG_DETAILS'; currentEnvelopes: ServiceClient.TagEnvelopeDefinition[]; }
export interface Unauthorized { type: 'UNAUTHORIZED'; canEdit:boolean }

type KnownAction = 
    LoadAllEnvelopes | NoEnvelopesDefined | LoadAllEnvelopesError | LoadingEnvelopes | LoadedEnvelopes |
    LoadTagDetails | LoadedTagDetails | LoadTagDetailsError | ClearEnvelopeData | DeleteWorkspace | DeleteWorkspaceSuccess | DeleteWorkspaceError | Unauthorized | GroupAddedAction | GroupAddErrorAction | AddGroupAction;

// ACTIONS - things that can be done
export const actionCreators = {
    loadAllEnvelopes: (user: AuthenticatedUser, workspaceName: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'LOADING_ENVELOPES', dataReady: false, emptyWorkspace: false, currentUser: user });
        try {
            const userWorkspaces = await LoadUserWorkspaces(dispatch,getState, user);
            
            const canEdit = userWorkspaces[workspaceName] != undefined;

            if(!canEdit){
                dispatch({ type:'UNAUTHORIZED', canEdit:canEdit});
            }
            else
            {
                const tagsInWorkspace = await LoadWorkspaceEnvelopes(dispatch,getState,workspaceName);
                
                if (!(tagsInWorkspace && tagsInWorkspace.length > 0)) {
                    dispatch({ type: 'NO_ENVELOPES_DEFINED', emptyWorkspace: true });
                    return;
                }
                const tagsToGet = tagsInWorkspace.map(t=> t.tag);
                const tagDetails =  await LoadTagsDetails(dispatch,getState,tagsToGet);
                const currentValues = await LoadTagCurrentValues(dispatch,getState,tagsToGet);
                const updatedTime = new Date();
                Object.values(currentValues).map(v=> { 
                    if(tagDetails[v.tag]) {
                        tagDetails[v.tag].currentValueTime = v.time;
                        tagDetails[v.tag].currentValue = v.value;
                        tagDetails[v.tag].updated = updatedTime;
                    }
                });


                let tagsInWorkspaceWithColours = tagsInWorkspace.map(tag => 
                    {
                    const tagDetail = tagDetails[tag.tag];
                    const toReturn = {
                    ...tag, currentValue: (tagDetail) ? tagDetail!.currentValue : '--', 
                    description: (tagDetail) ? tagDetail.description : '--',
                    plantName: (tagDetail) ? tagDetail.plantName : '',
                    unit: (tagDetail) ? tagDetail.unitOfMeasure : '--',  
                    states: (tag.states && tag.states.length > 0) ? tag.states.filter(s => s.name !== ServiceClient.STATE_NO_DATA).map(tst => ({
                        ...tst, displayRange: ConditionsToDisplayRange(tst.conditions),  
                        })) : []
                    };
                    return toReturn;
                });

                dispatch({
                    type: 'LOADED_ENVELOPES', dataReady: true,
                    envelopes: tagsInWorkspaceWithColours
                });
                dispatch({ type: 'LOAD_TAG_DETAILS' });
            }
        } catch (e) {
            dispatch({ type: 'LOAD_ALL_ENVELOPES_ERROR', errorMessage: e.toString() });
        }
    },
    loadTagDetails: (currentEnvelopes: ServiceClient.TagEnvelopeDefinition[]): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            if (currentEnvelopes.length === 0) {
                throw new Error('No tags provided');
            }
            let envelopes = currentEnvelopes.map(e => e.tag);
            let batches: string[][] = [];
            let batchSize = 20;
            let batchCounter = 0;
            do {
                batches.push(envelopes.splice(batchCounter, Math.min(envelopes.length, batchSize)));
                batchCounter += (batchSize + 1);
            } while (batchCounter < envelopes.length);
            let results = await Promise.all(
                batches.map((tags: string[]) => ServiceClient.getServiceClient().getTagsDetails(tags)));
            let reduced = results.reduce((p, c) => p.concat(c));

            let updatedEnvelopes: ServiceClient.TagEnvelopeDefinition[] = currentEnvelopes.map(envelope => {
                let detail = reduced.find(t => t.name === envelope.tag) as ServiceClient.TagDetail;

                return {
                    ...envelope,
                    description: detail ? detail.description : '',
                    unit: detail ? detail.unitOfMeasure : '',
                    plantName: detail && detail.plantName ? detail.plantName : ''
                };
            });
            dispatch({ type: 'LOADED_TAG_DETAILS', currentEnvelopes: updatedEnvelopes });
        } catch (e) {
            dispatch({ type: 'LOAD_TAG_DETAILS_ERROR', errorMessage: e.toString() });
        }
    },
    clearEnvelopes: (): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'CLEAR_ENVELOPE_DATA' });
    },
    deleteWorkspace: (workspaceName: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'DELETE_WORKSPACE' });
        try {
            // get current envelopes.
            let currentEnvelopes = await ServiceClient.getServiceClient().getWorkspaceEnvelopes(workspaceName);
            // delete them all
            await Promise.all(currentEnvelopes.map(envelope => {
                return ServiceClient.getServiceClient().deleteEnvelope(workspaceName, envelope.id);
            }));
            // attempt to delete the workspace
            await ServiceClient.getServiceClient().deleteWorkspace(workspaceName);
            dispatch({ type: 'DELETE_WORKSPACE_SUCCESS', redirectTo: '/' });
        } catch (e) {
            dispatch({ type: 'DELETE_WORKSPACE_ERROR', errorMessage: e.toString() });
        }
    },
    renameWorkspace: (workspaceName: string, newWorkspaceName: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        try {
            const serviceClient = ServiceClient.getServiceClient();
            await serviceClient.renameWorkspace(workspaceName, newWorkspaceName);
            dispatch({ type: 'DELETE_WORKSPACE_SUCCESS', redirectTo: `/view-workspace/${newWorkspaceName}` });
        } catch (e) {
            dispatch({ type: 'DELETE_WORKSPACE_ERROR', errorMessage: e.toString() });
        }
    },
    addGroup: groupActionCreators.addGroup
    
};


const initialState = { currentEnvelopes: [], redirectTo: undefined, canEdit: true };

// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
export const reducer: Reducer<EditWorkspaceState> = (state: EditWorkspaceState, action: KnownAction) => {
    let newState = state || Object.assign({}, initialState);
    switch (action.type) {
        case 'DELETE_WORKSPACE':
            newState = { ...newState, errorMessage: undefined, redirectTo: undefined };
            break;
        case 'DELETE_WORKSPACE_SUCCESS':
            newState = { ...newState, redirectTo: action.redirectTo };
            break;
        case 'DELETE_WORKSPACE_ERROR':
            newState = { ...newState, errorMessage: action.errorMessage };
            break;
        case 'LOAD_ALL_ENVELOPES_ERROR':
            newState = { ...newState, errorMessage: action.errorMessage, reloadEnvelope: false };
            break;
        case 'LOADED_ENVELOPES':
            newState = { ...newState, dataReady: action.dataReady, currentEnvelopes: action.envelopes };
            break;
        case 'NO_ENVELOPES_DEFINED':
            newState = { ...newState, emptyWorkspace: action.emptyWorkspace };
            break;
        case 'LOADING_ENVELOPES':
            newState = { ...newState, emptyWorkspace: action.emptyWorkspace, detailsLoaded: false, user: action.currentUser };
            break;
        case 'LOADED_TAG_DETAILS':
            newState = { ...newState, currentEnvelopes: action.currentEnvelopes, dataReady: undefined, detailsLoaded: true };
            break;
        case 'CLEAR_ENVELOPE_DATA':
            newState = { ...newState, currentEnvelopes: [], dataReady: false, detailsLoaded: false, redirectTo: undefined };
            break;
        case 'UNAUTHORIZED':
            newState = {...newState, currentEnvelopes:[], dataReady:true, detailsLoaded:true, canEdit:false};
            break;
        //case 'GROUP_ADDED':
            //newState = {...newState, groups: [...state.groups || [],action.group]};
            break;
        default:
            break;
    }
    return newState;
};