import { Reducer, combineReducers } from 'redux'
import { removeItemFromArray } from '../reducerUtilities';
import { Envelope } from './common-types'; 
import { AddEnvelopeToWorkspaceAction, RemoveEnvelopeFromWorkspaceAction } from './common-actions';
import { GetEnvelopesAction } from '../actions/envelope'
import { MapEnvelopes } from '../loading-data/envelopes';

export interface EnvelopeState {
    byId: Record<number, Envelope>;
    allIds: number[];
}

//Actions

export type EnvelopeAction = AddEnvelopeToWorkspaceAction | RemoveEnvelopeFromWorkspaceAction | GetEnvelopesAction;

function getEnvelopes(state: Record<number, Envelope>, action: GetEnvelopesAction){
    const { payload } = action;
    const { envelopes } = payload;
    const toAdd = MapEnvelopes(envelopes);
    return {
        ...state,
        ...toAdd
    }
}

function addEnvelope(state: Record<number,Envelope>, action: AddEnvelopeToWorkspaceAction)
{
    const { payload } = action;
    const { envelope } = payload;
    return {
        ...state,
        [envelope.id]: {...envelope}
    }
}

function removeEnvelope(state: Record<number,Envelope>, action: RemoveEnvelopeFromWorkspaceAction){
    const { payload } = action;
    const { envelope } = payload;
    delete state[envelope.id];
    return {
        ...state
    }
}

const envelopesById: Reducer<Record<number,Envelope>> = (state: Record<number, Envelope> = <Record<number, Envelope>>{}, action: EnvelopeAction) => {
    switch(action.type){
        case 'GET_ENVELOPES':
            return getEnvelopes(state,action);
        case 'ADD_ENVELOPE_TO_WORKSPACE':
            return addEnvelope(state,action);
        case 'REMOVE_ENVELOPE_FROM_WORKSPACE':
            return removeEnvelope(state,action);
        default:
            return state;
    }
}

function addEnvelopeIds(state: number[], action: GetEnvelopesAction){
    const { payload } = action;
    const { envelopes } = payload;
    const ids = envelopes.map(e=> parseInt(e.id));
    return state.concat(ids);
}

function addEnvelopeId(state: number[], action: AddEnvelopeToWorkspaceAction){
    const { payload } = action;
    const { envelope } = payload;
    if(state.some(s=> s==envelope.id)){
        return state;
    }
    return state.concat(envelope.id);
}

function removeEnvelopeId(state: number[], action: RemoveEnvelopeFromWorkspaceAction){
    const { payload } = action;
    const { envelope } = payload;
    return removeItemFromArray(state, envelope.id);
}

const allEnvelopes: Reducer<number[]> = (state: number[] = [], action: EnvelopeAction) => {
    switch(action.type){
        case 'GET_ENVELOPES':
            return addEnvelopeIds(state,action);
        case 'ADD_ENVELOPE_TO_WORKSPACE':
            return addEnvelopeId(state,action);
        case 'REMOVE_ENVELOPE_FROM_WORKSPACE':
            return removeEnvelopeId(state,action);
        default:
            return state;
    }
}

export const envelopesReducer = combineReducers({
    byId: envelopesById,
    allIds: allEnvelopes
});