import { Reducer } from 'redux';
import { AppThunkAction } from './';
import * as ServiceClient from './service-client';
import { EnvelopeStateToDisplayProps } from '../utils/condition-converters';
import { LoadGroupsForWorkspace } from './loading-data/workspace-group';
import { AddEnvelopeToWorkspaceAction } from './slice-reducers/common-actions';
import { ForceLoadWorkspaceEnvelopes } from './loading-data/envelopes';
import { LoadTagsDetails } from './loading-data/tag-detail';
import { TagCurrentValue } from './slice-reducers/common-types';
import { LoadTagCurrentValues } from './loading-data/tag-current-value';


export interface EditEnvelopeState {
    isLoading?: boolean;
    workspaceName: string;
    envelopeId?: string;
    tag: string;
    errorMessage?: string;
    isSaving?: boolean;
    isSuccess?: boolean;
    isDeleting?: boolean;
    isDeleteSuccess?: boolean;
    deleteErrorMessage?: string;
    stateProps?: EditEnvelopeStateProps[];
    workspaceGroups: ServiceClient.WorkspaceGroup[];
    tagDetail?: ServiceClient.TagDetail; 
    currentValue?: TagCurrentValue;
    calculations?:EditEnvelopeExpressionProps;
}

export interface EnvelopeProps {
    tag: string,
    states: EditEnvelopeStateProps[]
    calculations:EditEnvelopeExpressionProps
}

export interface EditEnvelopeStateProps {
    _id: number;
    name: string;
    colour: string;
    triggerCondition: EnvelopeStateConditions;
    value1?: number;
    value2?: number;
    description?: string;
    short_description?: string;
}

export interface EditEnvelopeExpressionProps{
    description?:string;
    schedule?:string;
    validFrom?:Date;
    expression?:string;
    name?:string;
    inputTags?:string[];
    unit_of_measure?:string;
}

export function numberOfTextFields(triggerCondition: EnvelopeStateConditions) {
    switch (triggerCondition) {
        case EnvelopeStateConditions.Above:
        case EnvelopeStateConditions.Below:
        case EnvelopeStateConditions.EqualTo:
            return 1;
        case EnvelopeStateConditions.InBetween:
        case EnvelopeStateConditions.Outside:
            return 2;
        case EnvelopeStateConditions.FlatLine:
        default:
            return 0;
    }
}

export enum EnvelopeStateConditions { Above, Below, InBetween, Outside, EqualTo, FlatLine }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export interface NewEnvelope { type: 'NEW_ENVELOPE'; workspaceName: string; }
export interface EditEnvelopeLoading { type: 'EDIT_ENVELOPE_LOADING'; workspaceName: string; envelopeId: string; }
export interface EditEnvelopeLoaded {
    type: 'EDIT_ENVELOPE_LOADED';
    workspaceName: string;
    envelopeId: string;
    tag: string;
    stateProps: EditEnvelopeStateProps[];
    workspaceGroups: ServiceClient.WorkspaceGroup[];
    tagDetail?: ServiceClient.TagDetail;
    currentValue?: TagCurrentValue;
    calculations?:EditEnvelopeExpressionProps;
}
export interface EditEnvelopeError { type: 'EDIT_ENVELOPE_ERROR'; message: string; }
export interface SaveEnvelopeWorking { type: 'SAVE_ENVELOPE_WORKING'; }
export interface SaveEnvelopeSuccess { type: 'SAVE_ENVELOPE_SUCCESS'; }
export interface SaveEnvelopeError { type: 'SAVE_ENVELOPE_ERROR'; message: string; }
export interface DeleteEnvelopeWorking { type: 'DELETE_ENVELOPE_WORKING'; }
export interface DeleteEnvelopeSuccess { type: 'DELETE_ENVELOPE_SUCCESS'; }
export interface DeleteEnvelopeError { type: 'DELETE_ENVELOPE_ERROR'; message: string; }

type KnownAction = NewEnvelope
    | EditEnvelopeLoading | EditEnvelopeLoaded | EditEnvelopeError
    | SaveEnvelopeWorking | SaveEnvelopeSuccess | SaveEnvelopeError
    | DeleteEnvelopeWorking | DeleteEnvelopeSuccess | DeleteEnvelopeError
    | AddEnvelopeToWorkspaceAction;

// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).
export const actionCreators = {
    newEnvelope: (workspaceName: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'NEW_ENVELOPE', workspaceName });
    },
    editEnvelope: (workspaceName: string, envelopeId: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'EDIT_ENVELOPE_LOADING', workspaceName, envelopeId });
        try {
            const serviceClient = ServiceClient.getServiceClient();
            const result = await serviceClient.getWorkspaceEnvelope(workspaceName, envelopeId);
            const states = result.states.filter(s => s.name !== ServiceClient.STATE_NO_DATA)
                .map(s => EnvelopeStateToDisplayProps(s));
           
                //Get the tag details
            const tagDetails = await LoadTagsDetails(dispatch, getState, [result.tag]);
            const tagDetail = tagDetails && tagDetails[result.tag] ? tagDetails[result.tag] : undefined;
            const tagType = await serviceClient.getTagType(tagDetail!.name)

            let calculations = tagType === 'Expression'?await serviceClient.getExpressionForEnvelopes(workspaceName, tagDetail!.name):undefined;
            if (calculations !== undefined) {
                calculations!.inputTags!.forEach(function(x) { 
                    var pattern = new RegExp(x, 'g');
                    calculations!.expression = calculations!.expression!.replace(pattern, `@${x}`) 
                });
            }
            
            const currentValues = await LoadTagCurrentValues(dispatch,getState,[result.tag]);
            const currentValue = currentValues && currentValues[result.tag] ? currentValues[result.tag] : undefined
            
            //get the workspace groups
            const workspaceGroups = await LoadGroupsForWorkspace(dispatch,getState,workspaceName);

            dispatch({ type: 'EDIT_ENVELOPE_LOADED', workspaceName, envelopeId, tag: result.tag, stateProps: states, calculations:calculations, workspaceGroups: workspaceGroups, tagDetail: tagDetail, currentValue: currentValue});

        } catch (e) {
            dispatch({ type: 'EDIT_ENVELOPE_ERROR', message: e.toString() });
        }
    },
    saveEnvelope: (workspaceName: string, tags: string[], states: EditEnvelopeStateProps[], envelopeId?: string, calculations?:EditEnvelopeExpressionProps):
        AppThunkAction<KnownAction> => async (dispatch, getState) => {
            if (states.some(p =>  p.name === undefined || p.name.length === 0)) {
                dispatch({ type: 'SAVE_ENVELOPE_ERROR', message: 'Please ensure names are set for all states.' });
                return;
            }
            if (states.some(p => p.colour === undefined || p.colour.length === 0)) {
                dispatch({ type: 'SAVE_ENVELOPE_ERROR', message: 'Please ensure colours are set for all states.' });
                return;
            }
            if (states.some(p => numberOfTextFields(p.triggerCondition) > 0 && p.value1 === undefined)) {
                dispatch({ type: 'SAVE_ENVELOPE_ERROR', message: 'Please ensure values are set for all states.' });
                return;
            }
            if (states.some(p => numberOfTextFields(p.triggerCondition) === 2 && p.value2 === undefined)) {
                dispatch({ type: 'SAVE_ENVELOPE_ERROR', message: 'Please ensure values are set for all states.' });
                return;
            }
            dispatch({ type: 'SAVE_ENVELOPE_WORKING' });
            try {
                const serviceClient = ServiceClient.getServiceClient();
                if (envelopeId) {
                    await serviceClient.putEnvelopeNew(workspaceName, tags[0], envelopeId, states);
                    if(calculations!== undefined){
                    await serviceClient.putExpressionForEnvelopes(workspaceName, tags[0], calculations)
                    }
                    await ForceLoadWorkspaceEnvelopes(dispatch,workspaceName);
                    dispatch({ type: 'SAVE_ENVELOPE_SUCCESS' });
                    
                } else {
                    await serviceClient.createEnvelopeNew(workspaceName, tags[0],states, calculations);
                    await ForceLoadWorkspaceEnvelopes(dispatch,workspaceName);
                    dispatch({ type: 'SAVE_ENVELOPE_SUCCESS' });
                }
            } catch (e) {
                const msg: string = e.message;
                if (msg.startsWith('GraphQL error:') && msg.endsWith('403')) {
                    dispatch({ type: 'SAVE_ENVELOPE_ERROR', message: 'You do not have permission to edit this workspace' });
                    return;
                }
                dispatch({ type: 'SAVE_ENVELOPE_ERROR', message: e.toString() });
            }
        },
    deleteEnvelope: (workspaceName: string, envelopeId?: string): AppThunkAction<KnownAction> => async (dispatch, getState) => {
        dispatch({ type: 'DELETE_ENVELOPE_WORKING' });
        try {
            if (envelopeId === undefined || envelopeId === null || ! `${envelopeId}`.match(/[0-9]+/g)) {
                return dispatch({ type: 'DELETE_ENVELOPE_ERROR', message: `Badly formatted envelope id: ${envelopeId}` });
            }

            if (await ServiceClient.getServiceClient().deleteEnvelope(workspaceName, envelopeId) === true) {
                return dispatch({ type: 'DELETE_ENVELOPE_SUCCESS' });
            }
            throw new Error('Unknown error');
        } catch (e) {
            const msg: string = e.message;
            if (msg.startsWith('GraphQL error:') && msg.endsWith('403')) {
                dispatch({ type: 'DELETE_ENVELOPE_ERROR', message: 'You do not have permission to edit this workspace' });
                return;
            }
            return dispatch({ type: 'DELETE_ENVELOPE_ERROR', message: e.toString() });
        }
    }
};

// REDUCER - For a given state and action, returns the new state.
// To support time travel, this must not mutate the old state.
export const reducer: Reducer<EditEnvelopeState> = (state: EditEnvelopeState, action: KnownAction) => {
    switch (action.type) {
        case 'NEW_ENVELOPE': {
            return { isLoading: false, workspaceName: action.workspaceName, envelopeId: undefined, tag: '', workspaceGroups: state.workspaceGroups };
        }
        case 'EDIT_ENVELOPE_LOADING': {
            return { isLoading: true, workspaceName: action.workspaceName, envelopeId: action.envelopeId, tag: '', workspaceGroups: state.workspaceGroups };
        }
        case 'EDIT_ENVELOPE_LOADED': {
            return {
                isLoading: false,
                workspaceName: action.workspaceName,
                envelopeId: action.envelopeId,
                tag: action.tag,
                stateProps: action.stateProps,
                workspaceGroups: state.workspaceGroups,
                tagDetail: action.tagDetail,
                currentValue: action.currentValue,
                calculations:action.calculations
            };
        }
        case 'EDIT_ENVELOPE_ERROR': {
            return { ...state, isLoading: false, isSaving: false, isSuccess: false, errorMessage: action.message };
        }
        case 'SAVE_ENVELOPE_WORKING': {
            return { ...state, isSaving: true };
        }
        case 'SAVE_ENVELOPE_SUCCESS': {
            return { ...state, isSaving: false, isSuccess: true };
        }
        case 'SAVE_ENVELOPE_ERROR': {
            return { ...state, isSaving: false, isSuccess: false, errorMessage: action.message };
        }
        case 'DELETE_ENVELOPE_WORKING': {
            return { ...state, isDeleting: true };
        }
        case 'DELETE_ENVELOPE_ERROR': {
            return { ...state, isDeleting: false, deleteErrorMessage: action.message };
        }
        case 'DELETE_ENVELOPE_SUCCESS': {
            return { ...state, isDeleting: false, isDeleteSuccess: true };
        }
        default:
            break;
    }

    // For unrecognized actions (or in cases where actions have no effect), must return the existing state
    //  (or default initial state if none was supplied)
    return state || {};
};

//  ((s, i) => return { name: s.name, priority: i, colour: s.colour, condition: { type: 'CUSTOM' } } )