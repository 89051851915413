import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import { Breadcrumbs, MessageBox, MessageBoxType } from '../common/index';
import { ApplicationState } from '../../store/index';
import { connect } from 'react-redux';
import * as SecureWorkspaceStore from '../../store/secure-workspace';
import { WorkspaceSecurityView } from './view-workspace-security';
import { AuthenticatedUser } from '../../auth/index';

type SecureWorkspaceProps =
    RouteComponentProps<{workspaceName: string}> &
    typeof SecureWorkspaceStore.actionCreators &
    SecureWorkspaceStore.SecureWorkspaceState & {
        currentUser: AuthenticatedUser
    };
class SecureWorkspace extends React.Component<SecureWorkspaceProps, {}> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        if ( (!this.props.workspaceRoles) || this.props.workspaceRoles.length === 0 ) {
            this.props.getWorkspaceRoles(this.props.match.params.workspaceName);
        }
    }

    static getDerivedStateFromProps(props: SecureWorkspaceProps, state: any) {
        if ( props.reloadRoles === true ) {
            props.getWorkspaceRoles( props.match.params.workspaceName);
        }
        return {};
    }

    render() {
        let ws = this.props.match.params.workspaceName;
        return (
            <React.Fragment>
                <Breadcrumbs 
                    links={[
                        { to: `/view-workspace/${ws}`, text: `${ws}` },
                        { to: `/secure-workspace/${ws}`, text: 'Permissions'}
                    ]} 
                />
                <WorkspaceSecurityView
                    workspaceName={ws}
                    roles={this.props.workspaceRoles}
                    isLoading={this.props.isWorkspaceSecurityLoading}
                    currentUser={this.props.currentUser}
                    addUserCallback={(workspace: string, u: string, a: string) => {
                         this.props.addWorkspaceUser(this.props.match.params.workspaceName, u, a);
                        }}
                    deleteUserCallback={this.props.deleteWorkspaceUser}
                />
                {this.props.errorMessage ? (
                    <MessageBox type={MessageBoxType.Error}>
                        <h2>{this.props.errorMessage}</h2>
                    </MessageBox>
                ) : undefined}
            </React.Fragment>
        );
    }
}
const mapStateToProps = (state: ApplicationState) => state.secureWorkspace;
export default withRouter(connect(mapStateToProps, SecureWorkspaceStore.actionCreators)(SecureWorkspace));