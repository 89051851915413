import * as React from 'react';
import { BrowserRouter as Router, Route, Switch, Link } from 'react-router-dom';
import { AuthenticatedUser, getAdUser } from './auth';
import { GlobalTab } from './components/common/global-tab';
import './App.css';
import { MessageBox, MessageBoxType } from './components/common';

import ListWorkspaces from './components/list-workspaces';
import ExceedanceDashboard from './components/view-workspace';
import CreateWorkspace from './components/create-workspace';
import SecureWorkspace from './components/secure-workspace';
import EditEnvelope from './components/edit-envelope';
import BulkUpload from './components/bulk-upload';

export default class App extends React.Component<{}, { user: AuthenticatedUser }> {
    style: React.CSSProperties = {
        margin: 94 + 20,
        marginTop: 20,
        //width: '1300px'

    };

    constructor(props: {}) {
        super(props);
    }

    componentWillMount() {
        const user = getAdUser();
        this.setState({ user });
        //PinpointAnalytics.setUser(user);
    }

    public render() {
        return (
            <Router>
                <React.Fragment>
                <GlobalTab user={this.state.user} />
                    <div className="content-area" style={this.style}>
                        <Switch>
                            <Route
                                exact
                                path="/"
                                render={(routeProps) => (
                                    <ListWorkspaces
                                        currentUser={this.state.user}
                                        {...routeProps}
                                    />
                                )}
                            />
                            <Route
                                exact
                                path="/view-workspace/:workspaceName"
                                render={(routeProps) => (
                                    <ExceedanceDashboard
                                        currentUser={this.state.user}
                                        {...routeProps}
                                    />)}
                            />
                            <Route exact path="/create-workspace" render={(routeProps) => (<CreateWorkspace currentUser={this.state.user} {...routeProps} />)} />
                            <Route exact path="/bulk-upload" component={BulkUpload} />
                            <Route
                                exact
                                path="/secure-workspace/:workspaceName" 
                                render={
                                    (routeProps) => (<SecureWorkspace currentUser={this.state.user} {...routeProps}/>)}
                            />
                            <Route exact path="/edit-envelope/:workspaceName/:envelopeId?" component={EditEnvelope} />
                            <Route component={PageNotFound} />
                        </Switch>
                    </div>
                </React.Fragment>
            </Router>
        );
    }
}

class PageNotFound extends React.Component<{}, {}> {
    render() {
        return (
            <React.Fragment>
                <MessageBox type={MessageBoxType.Error}>
                    <h2>Page Not Found</h2>
                    <p>Please check the address and try again.</p>
                    <p><Link to="/">Go to the Home Page</Link></p>
                </MessageBox>
            </React.Fragment>
        );
    }
}