import * as React from 'react';
import { connect } from 'react-redux';
import {
    PrimaryButton,
    DefaultButton,
    ProgressIndicator,
    ComboBox,
    IComboBoxOption,
    IComboBox,
    Dialog,
    DialogFooter,
    DialogType,
    ChoiceGroup,
    IChoiceGroupOption,
    TextField,
} from 'office-ui-fabric-react';
import { ApplicationState } from '../../store/index';
import { actionCreators } from '../../store/action-creators/group-action-creator'
import { AuthenticatedUser } from '../../auth/index';
import { MessageBox, MessageBoxType, MessageBoxSize, } from '../common/index';
import { WorkspaceGroup } from '../../store/service-client/index';
import { AddGroupState } from '../../store/add-group';
import { workspaceGroupSelector } from '../../store/selectors/workspace-group-selector';
import { withRouter } from 'react-router-dom';



type actions = typeof actionCreators;
// interface Group {
//     id: number,
//     group: string
// }

export type AddToGroupProps = AddGroupState & actions & {
    currentUser: AuthenticatedUser,
    tags: string[],
    workspaceName: string,
    onDismissed: any,
    show: boolean,
    workspaceGroups: WorkspaceGroup[]
};

type LocalState = {
    groupOptions: IComboBoxOption[],
    selectedGroup?: IComboBoxOption;
    addNew: boolean
};

class AddGroup extends React.Component<AddToGroupProps, LocalState> {
    constructor(props: AddToGroupProps) {
        super(props);

        this.state = {
            groupOptions: this.getoptions(),
            selectedGroup: undefined,
            addNew: this.props.workspaceGroups.length > 0 ? false : true
        };
    }

    getoptions = (): IComboBoxOption[] => {
        const options = this.props.workspaceGroups.map(p => { return { key: p.id, text: p.name } });
        return options;
    }


    onSubmit = () => {
        const group: WorkspaceGroup = {
            id: parseInt(this.state.selectedGroup!.key.toString()),
            name: this.state.selectedGroup!.text,
            tags: this.props.tags,
            groups: [],
            workspace: this.props.workspaceName,
            expanded: false
        };

        if (this.state.selectedGroup && this.state.selectedGroup.key.toString() === "-1") {
            //This is a new Group.
            this.props.addGroup(group);
        } else {
            //Just update existing group
            this.props.updateGroup(group);
        }
    };

    onChange = (evt: React.FormEvent<IComboBox>, option?: IComboBoxOption, index?: number, value?: string) => {
        const selectedOption: IComboBoxOption = option ? option : { key: "-1", text: value! };
        this.setState({ selectedGroup: selectedOption });
    }

    onDismissed = () => { this.props.onDismissed(); };

    componentDidMount() {

    }

    componentDidUpdate(prev: AddToGroupProps) {
        if (prev.workspaceGroups != this.props.workspaceGroups) {
            this.setState({ groupOptions: this.getoptions() });
        }
        if (this.props.success) {
            this.onDismissed();
        }
    }

    componentWillUnmount() {
        this.props.clearGroupData();
    }

    addNewChange = (option: IChoiceGroupOption) => {
        const optionVal: boolean = option.key === "AddNew";
        this.setState({ addNew: optionVal });
    }

    render() {

        const errorMessage = this.props.error
            ? (<MessageBox type={MessageBoxType.Error} size={MessageBoxSize.Baby}>{this.props.error}</MessageBox>)
            : undefined;

        return (
            <React.Fragment>
                <Dialog
                    hidden={!this.props.show}
                    onDismiss={this.onDismissed}
                    dialogContentProps={{
                        type: DialogType.largeHeader,
                        title: 'Add To Group',
                        subText: ''
                    }}
                    modalProps={{
                        isBlocking: false,
                        styles: { main: { maxWidth: 450 } }
                    }}
                >
                    <ChoiceGroup defaultSelectedKey={this.state.addNew ? "AddNew" : "List"} options={[{ key: "List", text: "Add to Existing Group" }, { key: "AddNew", text: "Create New Group" }]} onChange={(_, v) => { if (v) { this.addNewChange(v) } }} />

                    {this.state.addNew && (<TextField label={'New Group Name'} defaultValue="" onChange={(_, v) => this.setState({ selectedGroup: { key: "-1", text: v ? v.toString() : "" } })} />)}
                    {!this.state.addNew && (<ComboBox
                        label="Select Group"
                        key={this.props.workspaceName + 'group_select'}
                        allowFreeform={false}
                        autoComplete='on'
                        options={this.state.groupOptions}
                        onChange={this.onChange}
                    />)}

                    <DialogFooter>
                        {this.props.error && this.props.error.length > 0 && errorMessage}
                        {this.props.inProgress && (<ProgressIndicator />)}
                        <PrimaryButton disabled={this.props.inProgress || (this.props.error !== undefined && this.props.error.length > 0)} onClick={this.onSubmit} text="Save" />
                        <DefaultButton disabled={this.props.inProgress} onClick={this.onDismissed} text="Cancel" />
                    </DialogFooter>
                </Dialog>
            </React.Fragment>
        );

    }
}

const mapStateToProps = (state: ApplicationState, ownProps: any) => {
    const { addGroup } = state;
    //const { viewWorkspace } = state;
    //const { workspaceGroups } = viewWorkspace;
    return {
        inProgress: addGroup.inProgress,
        error: addGroup.error,
        success: addGroup.success,
        workspaceGroups: workspaceGroupSelector(state, ownProps)
    };
};

const mapDispatchToProps = actionCreators;

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(AddGroup));
