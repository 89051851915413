import React from 'react';

type IconSurveillanceProps = {
    title: string,
    width: string,
    height: string,
    color: string,
    secondaryColor?: string
};

export class IconSurveillance extends React.Component<IconSurveillanceProps, {}> {
    render(){
        return (
            <svg xmlns="http://www.w3.org/2000/svg" width={this.props.width} height={this.props.height} viewBox="0 0 517.968 517.968" transform="scale(-1, 1)" >
                <title id={this.props.title}>Surveillance Hub Icon</title>
                    <path fill={this.props.color} d="M71.949,266.084l96.129,50.671c-1.03,0.334-2.09,0.602-3.159,0.814l-52.726,9.899
                        c-15.311,2.879-30.074-7.212-32.955-22.534L71.949,266.084z M517.805,312.97v-21.299h-28.234v28.477l-76.522-0.011l-38.589-38.589
                        l-1.716,0.065l35.5-6.664l-1.125-6.009l18.022-3.382l0.569-121.949l-40.383,9.3l-1.041-5.59L0,219.484l193.216,95.836
                        l147.404-27.671l60.728,60.728h88.387v25.981h28.233V312.97H517.805z"/>
            </svg>
        )
    }
}

export class IconEye extends React.Component<IconSurveillanceProps, {}> {
    render(){
        return (
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 512 512" width={this.props.width} height={this.props.height} >
                <title id={this.props.title}>Surveillance Hub Eye Icon</title>
                <g>
                    <g>
                        <path fill={this.props.color} d="M508.177,245.995C503.607,240.897,393.682,121,256,121S8.394,240.897,3.823,245.995c-5.098,5.698-5.098,14.312,0,20.01
                            C8.394,271.103,118.32,391,256,391s247.606-119.897,252.177-124.995C513.274,260.307,513.274,251.693,508.177,245.995z M256,361
                            c-57.891,0-105-47.109-105-105s47.109-105,105-105s105,47.109,105,105S313.891,361,256,361z"/>
                    </g>
                </g>
                <g>
                    <g>
                        <path fill={(this.props.secondaryColor) ? this.props.secondaryColor : '#000000'} d="M271,226c0-15.09,7.491-28.365,18.887-36.53C279.661,184.235,268.255,181,256,181c-41.353,0-75,33.647-75,75
                            c0,41.353,33.647,75,75,75c37.024,0,67.668-27.034,73.722-62.358C299.516,278.367,271,255.522,271,226z"/>
                    </g>
                </g>
            </svg>
        )
    }
}