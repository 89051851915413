import * as React from 'react';
import { IPersonaProps, PersonaCoin, PersonaSize } from 'office-ui-fabric-react';
import { PrimaryColours } from '../common/colour-manager';
import Highlighter from 'react-highlight-words';
import { getPlantColor } from '../common/plant-color';

type TagSelectionItemProps = {
    item: IPersonaProps,
    tagSelectionItemStyle: TagSelectionItemStyle,
    searchText?: string,
    onClick?: any
};

export enum TagSelectionItemStyle { COMPACT, NORMAL }

export class TagSelectionItem extends React.Component<TagSelectionItemProps, {}> {
    terms: string[] = [];
    constructor(props: any) {
        super(props);
        if ( this.props.searchText !== undefined ) {
            this.terms = this.props.searchText.split(' ').map(t => t.replace(/[@|#](.*)/, '$1'));
        }
    }

    stylePrimary: React.CSSProperties = {
        fontSize: '1.15em', fontWeight: 800, textAlign: 'left',
        overflow: 'hidden', textOverflow: 'ellipsis', maxWidth: '18em'
    };

    styleSecondary: React.CSSProperties = {
        textAlign: 'left'
    };

    styleTertiary: React.CSSProperties = {
        textAlign: 'right', fontWeight: 600
    };

    _highlightStyle: React.CSSProperties = {
        backgroundColor: PrimaryColours.sand,
        fontWeight: 600,
        padding: '0.2em',
        borderRadius: '0.4em'
    };

    render() {
        if ( this.props.tagSelectionItemStyle === TagSelectionItemStyle.COMPACT ) {
            return this._compact;
        } else {
            return this._normal;
        }
    }

    _compact: JSX.Element =  (
        <div style={{display:'flex', flexFlow:'column'}}>
        <div 
            key={`selected-${this.props.item.text}`}
            style={{ display: 'flex', flexFlow:'row', width: '100%', marginTop:'0.2em' }}
            onClick={() => this.props.onClick ? this.props.onClick(this.props.item) : undefined}
        >
                <PersonaCoin 
                        size={PersonaSize.size28}
                        imageInitials={this.props.item.imageInitials}
                        style={{ flexBasis: 'min-content', marginRight: '0.5em' }}
                        styles={{
                            initials: {
                                background: `${getPlantColor(this.props.item.imageInitials!)} !important`,
                                overflow: 'hidden'
                            }
                        }}
                />
                <div style={{ flexGrow: 1, lineHeight: '28px' }}>
                        {this.props.item.text}
                </div>
        </div>
        </div>
    );

    _normal: JSX.Element = (
        <div style={{ marginBottom: '0.5em', display: 'flex', width: '100%', padding: '0.2em' }} >
            <PersonaCoin
                size={PersonaSize.size40}
                imageInitials={this.props.item.imageInitials!}
                style={{ fontVariantCaps: 'all-petite-caps', flex: 1 }}
                styles={{
                    initials: {
                        background: `${getPlantColor(this.props.item.imageInitials!)} !important`,
                        fontSize: '0.9rem'
                    }
                }}
            />
            <div style={{ flex: 4 }}>
                <div style={this.stylePrimary}>
                    {this.terms.length > 0 ? (
                        <Highlighter searchWords={this.terms} textToHighlight={this.props.item.text} highlightStyle={this._highlightStyle} />
                    ) : this.props.item.text}
                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginTop: '0.5em' }}>
                    <div style={this.styleSecondary}>
                        {this.terms.length > 0 ? (
                            <Highlighter 
                                searchWords={this.terms}
                                textToHighlight={this.props.item.secondaryText} 
                                highlightStyle={this._highlightStyle}
                            />
                        ) : this.props.item.secondaryText}
                    </div>
                    <div style={this.styleTertiary}>
                        {this.terms.length > 0 ? (
                            <Highlighter 
                                searchWords={this.terms}
                                textToHighlight={this.props.item.tertiaryText}
                                highlightStyle={this._highlightStyle} 
                            />
                        ) : this.props.item.tertiaryText}
                    </div>
                </div>

            </div>
         </div>
    );
  
}