
import * as ServiceClient from '../service-client'
import { TagCurrentValue } from '../slice-reducers/common-types';
import { isWithinRange } from 'date-fns';

const BatchSize = 50;

export async function LoadTagCurrentValues(dispatch: any, getState: any, tags: string[], start_time?: Date, end_time?: Date) {

    const { entities } = getState();
    const byId: Record<string, TagCurrentValue[]> = entities.tagCurrentValues.byId;
    let currentValues: Record<string, TagCurrentValue> = {};

    const endTime = end_time ? end_time : new Date();

    // get from state if time exists within 60 seconds
    if (byId) {
        tags.forEach(t => {
            const match = byId[t] ? byId[t].find(tg => isWithinRange(endTime, tg.time, new Date(tg.time.getTime() + 60000))) : undefined;
             if (match) {
                 currentValues[t] = match; 
             }
        });
    }

    // get tags not in state from backend
    const missingTags = tags.filter(t => Object.keys(currentValues).indexOf(t) == -1);
    if (missingTags.length > 0) {
        const loadedValues = await ForceLoadTagCurrentValues(dispatch, getState, missingTags, start_time, endTime);
        currentValues = {
            ...currentValues,
            ...loadedValues
        };
    }

    return currentValues;
}

async function ForceLoadTagCurrentValues(dispatch: any, getState: any, tags: string[], start_time?: Date, end_time?: Date) {
    const serviceClient = ServiceClient.getServiceClient();
    let currentValues: Record<string, TagCurrentValue> = {};
    const tagBatches = splitArray(tags, BatchSize);
    let promises: Promise<ServiceClient.TagValue[]>[] = [];
    let addToState = false;

    // if dates are provided the end date is more than 60 seconds in the past - use last value instead of highwater
    if (start_time && end_time && end_time <= new Date((Date.now() - 60000))) {
        promises = tagBatches.map(batch => serviceClient.getTagsLastValue(batch, start_time, end_time));
        // add values from the past into state so that it can be re-used if required
        addToState = true;
    }
    else {
        promises = tagBatches.map(batch => serviceClient.getTagsHighWater(batch));
    }

    const results = await Promise.all(promises);
    results.forEach(result => mapInto(result, currentValues));
    if (addToState) {
        dispatch({type: 'ADD_TAG_CURRENT_VALUES', payload: { tags: Object.values(currentValues)}});
    }

    return currentValues;
}


function splitArray (items: any[], size: number) {
    var results = [];
    
    while (items.length) {
        results.push(items.splice(0, size));
    }
    
    return results;
}

function mapInto (from: ServiceClient.TagValue[], into: Record<string, TagCurrentValue>) {
    from.forEach(tag => {
        into[tag.name] = {
            tag: tag.name, 
            value: tag.value,
            fetched: new Date(), 
            time: new Date(tag.time)
        }
    });
}